/* ************* Media Queries Tablets ****************** */
.dayCount span {
   font-size: 14px;
}

/*app-pages{position: relative;overflow: hidden;display:block;}*/
@media only screen and (min-width:1700px) {
   .dayCount span {
      font-size: 14px;
   }

}
@media only screen and (min-width:1601px) and (max-width:2100px) {
    .customer360 .customerTiles .cols3{
        height:161px;
    }
}

@media only screen and (min-width:1501px) and (max-width:2500px) {
    .customer360 .customerTiles .cols3{
        height:161px;
    }
}

@media only screen and (max-width:1600px) {
    .customerTiles .cols3 {
      width: 33.33%;
   }
}

@media only screen and (min-width:1590px) {
   .orderStatus.odStatus .statusBx .stBtn {
      float: right;
   }

   .statusBx {
      display: block;
      width: auto;
      clear: both;
   }

   .tableOut {
      max-width: 100%;
      overflow: auto !important;
   }

   .borderBox.quoteDesign {
      position: relative;
   }

   .borderBox.quoteDesign .contBtn.titleBtnCol {
      position: absolute;
   }

}

@media only screen and (max-width:1590px) {

   /* .perInfo {
      height: 666px;
   } 
   .timelineBorderBox {
    height: 1266px;
   }
   */

   /*.timelineRight {
      height: 1075px;
   }*/
   .orderBox .odStatus.cols2 {
      width: 27%;
   }
   
   .timelineRightAction {
      height: 1146px;
   }

   .orderPdetails .orderStatus {
      max-width: 35%;
   }

   .orderPdetails .custDetail {
      width: 65%;
   }

   .quoteDesign .orderAddress.cols6 {
      width: 45%;
   }

   .orderStatus {
      padding-right: 0;
      float: right;
      width: auto;
      max-width: 20%;
   }

   .orderStatus .statusBx .stBtn {
      float: right;
   }

   .orderStatus .statusBx {
      width: 100%;
   }

   .poShipping .cols6:first-child {
      width: 155px;
   }

   .dayCount span {
      font-size: 13px;
   }

   .iconBlocks {
      padding: 10px;
   }

   .amountRow {
      font-size: 27px;
   }

}

@media only screen and (max-width:1480px) {
   .res-4 {
      width: 33.33333333% !important;
   }

   .res-6 {
      width: 50% !important;
   }

   .res-12 {
      width: 100% !important;
   }

   .res-5 {
      width: 41.66666667% !important;
   }

   .res-4 {
      width: 33.33333333% !important;
   }

   .res-3 {
      width: 25% !important;
   }

   .res-2 {
      width: 16.66666667% !important;
   }

   .csvRadioRow .radioGroup {
      margin-top: 5px;
   }

   .csvRadioRow .radioGroup .radio-label {
      width: 100%;
   }

   .csvRadioRow .csvFullCol .radioGroup .radio-label {
      width: auto;
   }

   .csvRadioRow .cols8,
   .csvRadioRow .cols4 {
      width: 50%;
   }

   .iconBlocks {
      padding: 10px;
   }

   .amountRow {
      font-size: 28px;
   }

   .timelineRight {
      max-height: 510px;
   }
   .cols-md-1 {
      width: 8.33% !important;
   }

   .cols-md-2 {
      width: 16.66666667% !important;
   }

   .cols-md-3 {
      width: 25% !important;
   }

   .cols-md-4 {
      width: 33.33333333% !important;
   }

   .cols-md-5 {
      width: 41.66666667% !important;
   }

   .cols-md-6 {
      width: 50% !important;
   }

   .cols-md-7 {
      width: 58.33333333% !important;
   }

   .cols-md-8 {
      width: 66.66666667% !important;
   }

   .cols-md-9 {
      width: 75% !important;
   }

   .cols-md-10 {
      width: 83.33333333% !important;
   }

   .cols-md-11 {
      width: 91.66666667% !important;
   }

   .cols-md-12 {
      width: 100% !important;
   }

}

@media only screen and (max-width:1479px) {

   /*
   .perInfo {
      height: 687px;
   }

   .timelineBorderBox {
      height: 1287px;
   } */

   /* .timelineRight {
      height: 1095px;
   }*/
   .timelineRightAction {
      height: 1166px;
   }

   .addNewSpd .cols2 {
      width: 33.33333333%;
   }

   .addNewSpd .cols6 {
      width: 100%;
   }

   .reportFilter .filterCols {
      padding-right: 0;
   }

   .filterCols .reportBtn .filterSearch {
      position: relative;
      top: 0;
      right: auto;
      float: right;
   }

   .filterCols .reportBtn {
      margin-top: 15px;
   }

   .RIAfilter .filterCols .cols7.colsP0 {
      width: 50%;
   }

   .RIAfilter .filterCols .cols5.colsP0 {
      width: 50%;
   }

   .rulesRow .cols3 {
      width: 50%;
   }

   .rulesRow .cols2 {
      width: 33.33333337%;
   }

}

@media only screen and (max-width:1439px) {
   .cols.colsName {
      width: 35%;
   }

   .cols.colsQty {
      width: 15%;
   }

   .cols.colsPrice {
      width: 15%;
   }

   .cols.colsDiscount {
      width: 11%;
   }

   .cols.colsTax {
      width: 20%;
   }

   .cols.colsUom {
      width: 15%;
   }

   .cols.colsTotal {
      width: calc(20% - 120px);
   }

   .cols1.fullBtn {
      min-width: 100px;
   }

   .addQuotPro .configBtn ~ .btnCol {
      width: 265px;
      margin-right: 0;
   }

   .addQuotPro .configBtn ~ .btnCol .button {
      margin-right: 10px;
      width: auto;
   }

   .addQuotPro .configBtn ~ .btnCol .button:nth-child(2) {
      margin-right: 0;
   }

   .cols.colsName.configBtn {
      width: 24%;
   }

   .addQuotPro .configBtn ~ .cols.colsUom,
   .addQuotPro .configBtn ~ .cols.colsPrice {
      width: 13.5%;
   }

  /* .customerTiles .cols3 {
      width: 33.33%;
   }*/

}

@media only screen and (max-width:1366px) {
   .timelineRight {
      height: calc(80% - 120px) !important;
   }

   .crmTable > div.ng-star-inserted {
      min-height: 80%;
   }

   .statisticBlock {
      margin-bottom: 5px;
   }

   .equalHight .borderBox.chartAreaBox {
      margin-bottom: 20px;
   }

   .newTileBlock .numberBlock strong {
      font-size: 22px;
   }

   .contractIcon,
   .customerIcon,
   .salesPersonIcon {

      /*max-width: 85px;*/
   }

   .customerSection .customerTabbing ul {
      margin: 0 0 10px;
   }

   ul.newsListing {
      height: 375px;
   }

   .custDetail {
      width: 65%;
      padding-right: 30px;
   }

   .orderBox .orderDetail.cols4 {
      width: 30%
   }

   .orderStatus {
      max-width: 28%;
   }

   .quoteDesign .orderAddress.cols6 {
      width: 42%;
   }

   .orderPdetails .custDetail {
      width: 55%;
      padding-right: 30px;
   }

   .orderPdetails .orderStatus {
      max-width: 45%;
   }

   .remarkAuto {
      float: left;
      margin: 0 15px 25px 0;
      width: 100%;
   }

   .spCharCols .row .cols3 {
      width: 50%;
   }

   .customerSection.tabContainer h2 {
      margin-bottom: 10px;
   }

   .custRight {
      width: 100%;
      position: relative;
   }

   .customerSection .customerTabbing {
      width: 100%;
      margin: -10px 0 0;
   }

   .customerSection .dateFilter {
      position: absolute;
      right: 0;
      top: -50px;
   }

   .productSec .productImg {
      margin: 0 40px 35px 0px;
   }

   .cols6 .cpListing {
      min-height: 160px;
   }

   .filterCols .equalAfter {
      padding-left: 0;
   }

   .filterCols .equalAfter::after {
      left: -10px;
   }

   .YTDfilter .filterCols form>.cols3 {
      width: 100% !important;
   }

   .dyReportCols {
      width: 100% !important;
   }

   .borderBox {
      padding: 15px 25px;
      margin-bottom: 20px;
   }

   .customerDetail.custdtlTop .noteBx {
      margin-top: 0px !important;
   }

   .chartAreaBox .dx-field.alignRight {
      width: 160px;
      margin-top: 10px;
      margin-bottom: 0;
   }

   .chartAreaBox h2.chartTitle {
      margin-bottom: 0;
      line-height: 62px;
   }

   .newBillAddress .editIco {
      right: 0px;
      top: 5px;
   }

   .borderBox.newBillAddress {
      padding: 15px 25px;
   }

   #crmCustomerDetail .crmTable {
      padding: 15px 25px 5px 25px;
   }

   .crmCustomerDetail .contDtl {
      padding: 10px 40px 10px 15px;
   }

   #crmCustomerDetail .cols7 {
      position: relative;
      margin-bottom: 0 !important;
   }

   #crmCustomerDetail .cols7 .contBtn.titleBtnCol {
      position: absolute;
      right: 45px;
      top: 5px;
   }

   #crmCustomerDetail .cols7 .timelineRight .tableOut {
      margin-bottom: 0;
   }

   #crmCustomerDetail .cols5 .cols12 .subTitleRow h2 {
      margin-bottom: 10px !important;
   }

   #crmCustomerDetail .cols5 .cols12 .subTitleRow .hrLine {
      display: table;
   }

   #crmCustomerDetail .cols7 .tabbing ul {
      margin-bottom: 0px;
   }

   div#exportContainer {
      margin-top: 0;
   }

   .customerDetail.custdtlTop .noteBx {
      margin-top: 0px !important;
   }

   .logOutIcon {
      margin-right: 0;
      width: 18px;
   }

   .signOut {
      margin-right: 0;
   }

   .rightTop {
      padding: 9px 20px 0px 0;
   }

   .dx-datagrid-header-panel .dx-toolbar {
      margin-bottom: 15px !important;
      margin-top: 10px;
   }

   .tabbing ~ div .dx-datagrid-header-panel .dx-toolbar {
      margin-top: 0 !important;
   }

   .productDtl > h2 {
      margin-bottom: 0;
      line-height: 40px;
   }

   .tabbing ul {
      margin-bottom: 10px;
   }

   .proDtlSlider li {
      padding: 0 !important;
      height: auto;
   }


   .separator .feildCol::after {
      right: 20px;
   }

   .dayCount span {
      font-size: 16px;
   }

}

@media only screen and (max-width:1365px) {

   /* .customerSection .customerTabbing { width: 100%; margin: 0; } */
   .summaryTx {
      width: 52%;
   }

   .summaryTx .contTx {
      margin: 0 0 15px;
   }

   .statisticBlock .cols3 .contractIcon {
      display: block;
      margin: 0 auto;
      text-align: center;
   }

   .statisticBlock .cols3 .growthUpDown {
      padding: 0;
      text-align: center;
      top: 0;
   }

   .statisticBlock .cols3 .blockText {
      margin: 0px;
      text-align: center;
      width: 100%;
   }

   .perInfo .custLogo {
      position: relative;
      top: 0;
      left: 0;
   }

   .perInfo .contListing {
      padding-left: 0;
   }

   .perInfo {
      height: 811px;
   }

   .timelineBorderBox {
      height: 1356px;
   }

   .leadPage .filter.filterCols .cols5 {
      width: 60%;
   }

   .leadPage .filter.filterCols .cols3 {
      width: 40%;
   }

   .leadPage .filter.filterCols .cols4 {
      width: 100%;
   }

   .timelineNote {
      height: 295px;
   }

   .timelineRightAction {
      height: 1236px;
   }

   .editAgreeDetail .cols12 .cols5 {
      width: 33.33333333%;
   }

   .editAgreeDetail .cols12 .cols7 {
      width: 66.66666667%;
   }

   .twrCustRight {
      float: right;
      width: 100%;
      position: relative;
   }

   .twrCustRight .twrDate {
      position: absolute;
      right: 0;
      top: -50px;
      margin-left: 0;
   }

   .adChartRange {
      margin-top: 10px;
   }

   .adChartRange ul li {
      margin-bottom: 12px;
   }

   .acrNm {
      line-height: 15px;
   }

   .twrChart .chartArea {
      padding-top: 20px;
   }

   .aspdFilter .filterCols .cols7 {
      width: 50%;
   }

   .aspdFilter .filterCols .cols5 {
      width: 50%;
   }

   .lconTitleBx,
   .lconListing li {
      padding: 10px 15px;
   }

   .lconTitleBx h3 {
      font-size: 21px;
   }

   .ecaRemark strong {
      display: block;
   }

   .spCols3 {
      width: 33.33333333%;
   }

   .planTitle h3 {
      font-size: 24px;
      line-height: 28px;
   }

   .planTitle h4 {
      font-size: 32px;
      line-height: 26px;
   }

   .ucTitle {
      font-size: 32px;
   }

   .filter.memberFilter > .row > .cols1 {
      width: 25%;
   }

   .filter.memberFilter > .row > .cols2 {
      width: 50%;
      text-align: left !important;
   }

   .filter .feildCol.checkboxGroup {
      min-height: 25px;
   }

   .filter.memberFilter > .row > .cols2 .button {
      float: none;
      width: auto;
   }

   .multiBtnsRow .btnsGroup .button:last-child {
      height: auto;
      font-size: 16px;
   }
   .selectContract {
      float: left;
  }

  /* Add New Button theme change */
   .cols12addbtn {
      width: calc(100% - 40px);
   }

   .cols11addbtn {
      width: calc(91.66666667% - 40px);
   }

   .cols7addbtn {
      width: calc(58.33333333% - 40px);
   }

   .cols6addbtn {
      width: calc(50% - 40px);
   }

   .cols5addbtn {
      width: calc(41.66666667% - 40px);
   }

   .cols3addbtn {
      width: calc(25% - 40px);
   }

   .cols2addbtn {
      width: calc(16.66666667% - 40px);
   }

   .cols1addbtn {
      width: calc(8.33% + 40px);
   }

   .cols1searchbtn {
      width: calc(8.33% + 55px);
   }

   .cols11searchbtn {
      width: calc(91.67% - 55px);
   }
/* Add New Button theme change */

}

@media only screen and (max-width:1279px) {
   .inputmgbtablet .dx-field{
      margin-bottom:20px !important;
   }
   .rulescontent .cols3,
   .rulescontent .cols1 {
      width: 33.33%;
      margin-bottom: 25px;
      text-align: left !important;
   }
   .orderBox.EQDetails .custDetail .cols6.datePicker {
       width: 100%;
       padding: 0;
   }
   .mainContentIncentxConfig #scrollViewTabIncentxConfig {
      width: calc(76% - 70px);
      right: 35px;
   }  

   .formControls.editAgreeDetail .cols6 .remarkBx.fullNote.ecaRemark{
       margin-top: 10px !important;
   }

   
   .notifyDays {
       max-width: 100% !important;
       width: 100%;
       margin-right: 0;
   }
   .filterToggleOut.bspFilter .searchFilter .cols3 {
      width: 100%;
   }

   .filterToggleOut.bspFilter .searchFilter .cols7,
   .filterToggleOut.bspFilter .searchFilter .cols2 {
      width: 50%;
   }

   .newTileBlock .numberBlock strong {
      font-size: 20px;
   }

   .cols2 {
      width: 50%;
   }

   .quoteDesign .orderAddress .addressBox {
      padding-right: 20px;
   }

   .custCntBx {
      min-height: 210px;
   }

   .rrRow .cols4 {
      width: 41.66666667%
   }

   .rrRow .cols6 {
      width: 41.66666667%
   }

   .rrRow .cols2 {
      width: 16.66666667%;
   }

   .spProduct .cols2 {
      width: 16.66666667%;
   }

   .spDetail .cols5 {
      width: 50%;
   }

   .spDetail .cols3 {
      width: 50%;
   }

   .spDetail .cols2 {
      width: 50%;
   }

   .anCustomer .cols2 {
      width: 16.66666667%;
   }

   .ambToAdd .cols2 {
      width: 16.66666667%;
   }

   .reportsSection .cols4,
   .dataSegment .cols4 {
      width: 41.66666667%;
   }

   .reportsSection .cols8,
   .dataSegment .cols8 {
      width: 58.33333333%;
   }

   .custSection .cols8 {
      width: 100%;
   }

   .custSection .cols4 {
      width: 50%;
   }

   .leadPage .contentRow>.row>.cols5.colsP0 {
      width: 100%;
      margin-bottom: 30px;
   }

   .leadPage .contentRow>.row>.cols7 {
      width: 100%;
   }

   .perInfo {
      min-height: inherit;
      height: auto;
      padding-bottom: 80px !important;
   }

   .perInfo .contListing {
      /*padding-left: 90px;*/
   }

   .tabbingNew li {
      font-size: 12px;
   }

   .tabbingNew li:first-child {
      width: 22.33%;
   }

   .tabbingNew li {
      width: 36.33%;
   }

   .tabbingNew li:first-child a {
      padding-left: 10px;
   }

   .tabbingNew li:last-child {
      width: 40.33%;
   }

   .tabbingNew li a {
      padding: 8px 0 8px 40px;
   }

   .perInfo .custLogo {
      position: absolute;
      top: 15px;
      left: 0px;
   }

   .perInfo .contListing .icoOut {
      width: auto;
   }

   .leadPage .filter.filterCols .cols5 {
      width: 41.66666667%;
   }

   .leadPage .filter.filterCols .cols3 {
      width: 25%;
   }

   .leadPage .filter.filterCols .cols4 {
      width: 33.33333333%;
   }

   .timelineRight,
   .timelineRightAction {
      height: auto;
   }

   .timelineBorderBox {
      height: auto;
      width: 100%;
   }

   .timelineNote {
      height: auto;
      max-height: 330px;
      margin-bottom: 0;
   }

   .leadPage .contentRow>.row>.cols7>.borderBox {
      padding-bottom: 0;
   }

   .editAgreeDetail .cols8 {
      width: 50%;
   }

   .editAgreeDetail .cols2 {
      width: 25%;
   }

   .editAgreeDetail .cols12 .cols5 {
      width: 100%;
   }

   .editAgreeDetail .cols12 .cols7 {
      width: 100%;
   }

   .editAgreeDetail .cols12 .cols5 .remarkBx {
      margin-bottom: 15px;
   }

   .remarkBx.fullNote.ecaRemark {
      margin-bottom: 0;
      min-width: 220px;
   }

   .twrCustRight {
      width: auto;
   }

   .twrCustRight .twrDate {
      position: relative;
      top: 0;
   }

   .twrChart .chartArea {
      padding-top: 40px;
   }

   .adChartRange {
      margin-top: 40px;
   }

   .twrChart .chartArea {
      width: 65%;
   }

   .bspFilter .searchFilter .cols5 {
      width: 100%;
   }

   .bspFilter .searchFilter .cols3 {
      width: 33.33333333%;
   }

   .bspFilter .searchFilter .cols2 {
      width: 33.33333333%;
   }

   .reportFilter .filterCols .cols3 {
      width: 66.66666667%;
   }

   .reportFilter .filterCols .cols3:first-child {
      width: 33.33333333%;
   }

   .reportFilter .filterCols .cols4 {
      width: 50%;
   }

   .reportBtn {
      width: 16.66666667%;
   }

   .reportFilter.YTDfilter .filterCols .cols4 {
      width: 66.66666667%;
   }

   .reportFilter.YTDfilter .filterCols .cols2 {
      width: 33.33333333%;
   }

   .reportFilter.YTDfilter .filterCols .reportBtn {
      width: 16.66666667%;
   }

   .RIAfilter .filterCols .cols2 {
      width: 16.66666667%;
   }

   .RIAfilter .filterCols .cols7.colsP0 {
      width: 100%;
   }

   .RIAfilter .filterCols .cols5.colsP0 {
      width: 100%;
   }

   .reportFilter.PRfilter .filterCols .cols3 {
      width: 50%;
   }

   .ABPfilter .cols6 {
      width: 100%;
   }

   .ABPfilter .cols2 {
      width: 33.33333333%;
   }

   .ABPfilter .cols2.reportBtn {
      width: 16.66666667%;
   }

   .ARfilter .filterCols .cols4 {
      width: 33.33333333%;
   }

   .ARfilter .filterCols .cols6 {
      width: 41.66666667%;
   }

   .ARfilter .filterCols .cols2 {
      width: 25%;
   }

   .ARfilter .filterCols .cols2.reportBtn {
      width: 16.66666667%;
   }

   .spFilter .filterCols .cols6 {
      width: 41.66666667%;
   }

   .spFilter .filterCols .cols3.inSearchCol {
      width: 33.33333333%;
   }

   .lconListing li .lconImg {
      display: inline-block;
      height: auto;
      width: 100%;
      float: left;
   }

   .lconListingBx {
      padding: 0;
      float: left;
      height: auto;
      width: 100%;
      margin-top: 10px;
   }

   .lconListing li {
      height: 130px;
   }

   .ecaCols {
      width: 100%;
   }

   .ecaRemark strong {
      display: inline-block;
   }

   .YTDfilter .filterCols .cols3 {
      width: 25% !important;
   }

   .exclusionsRow .cols4 {
      width: 37.5%;
   }

   .exclusionsRow .cols2 {
      width: 25%;
   }

   .exclusionsRow .cols2.fullBtn {
      float: right;
   }

   .editAgreeDetail .cols12 .ucsCols .cols5 {
      width: 40%;
   }

   .editAgreeDetail .cols12 .ucsCols .cols7 {
      width: 60%;
   }

   .ucsCols hr {
      margin-bottom: 33px;
   }

   .csvRadioRow .cols8,
   .csvRadioRow .cols4 {
      width: 100%;
   }

   .csvRadioRow .radioGroup .radio-label {
      width: auto;
   }

   .csvRadioRow .radioGroup {
      min-height: inherit;
      margin-bottom: 15px;
   }

   .bspFilter.salesFilter .searchFilter .cols5 {
      width: 33.33333333%;
   }

   .editAgreeDetail .BillBackType .cols8 {
      width: 66.66666666%;
   }

   .editReport.grayedBlock .cols4 {
      width: 25%;
   }

   .editReport.grayedBlock .cols2 {
      width: calc(35% - 140px);
   }

   .editReport.grayedBlock .cols3:last-child {
      width: 160px;
      float: right;
   }

   .reportDate ~ .cols5 {
      width: 35%;
   }

   .reportDate .cols2 {
      width: 20%;
   }

   .cols2.reportYear {
      width: 33%;
   }

   .productSearch .feildCol.radioGroup {
      margin-top: 10px;
      margin-bottom: 0;
   }

   .productSearch .feildCol.radioGroup .cols2 {
      padding-left: 0;
      width: 15%
   }

   .productSearch .feildCol.radioGroup .cols10 {
      width: 84%
   }

   .productSearch .feildCol.radioGroup .cols2 label {
      padding-left: 0;
   }

   .appSettingTabs .cols2 {
      width: 25%;
   }

   .appSettingTabs .cols8 {
      width: 50%;
   }

   .contentFilter .cols2,
   .contentFilter .cols10 {
      width: 100%;     
       /*padding-left:0;*/
   }

   .appConfigformRow .cols2.genPass {
      width: 16.66%;
   }

   .cols.colsDiscount {
      width: 14%;
   }

   .cols.colsName {
      width: 33%;
   }

   .cols.colsPrice {
      width: 13%;
   }

   .cols.colsQty {
      width: 13%;
   }

   .addQuotPro .btnCol {
      margin-top: 10px;
      margin-bottom: 5px;
   }

   .chargeBackform .cols6.chrgField {
      width: 50%;
   }

   .chargeBackform .cols4.chrgField {
      width: 30%;
   }

   .chargeBackform .cols2.chrgField {
      width: 20%;
   }

   .cols.colsName.configBtn {
      width: 26%;
   }

   .dayCount span {
      font-size: 14px;
   }

 .formControls.editAgreeDetail .datePicker {
      height: 60px;
   }
}

/*new*/
@media only screen and (max-width:1199px) {
   .noteBx {
      width: 100%;
   }

   .border-box .user-details {
      width: 25%;
   }

   .border-box .user-detailsright {
      width: 75%;
   }

   .border-box .user-detailsright .cols4,
   .border-box .user-detailsright .cols6 {
      width: 50%;
   }

   .border-box .user-detailsright .cols8 {
      width: 50%;
   }

   .orderPdetails .custDetail.custDetailFull .alignLeft.mrgT20 {
      margin-top: 5px !important;
      margin-left: 0 !important;
      margin-bottom: 10px;
   }

}

/*new*/
@media only screen and (max-width:1169px) {
   .equalHight .cols8,
   .equalHight .cols4 {
      width: 100%;
   }

   .rfpChart .subTitleRow {
      width: auto;
   }

   .rfpChart .tabbing {
      float: right;
      width: auto;
   }

   .rfpChart .tabbing ul li:last-child {
      margin-right: 0;
   }

   .equalHight .borderBox {
      min-height: inherit;
   }

   .dataSegment .cols8,
   .dataSegment .cols4 {
      width: 100%;
   }

   .dataSegment .customerSection {
      margin: 0 0 30px;
   }

   ul.newsListing {
      height: auto;
   }

   .customerSection .customerTabbing {
      width: auto;
      margin: -10px 0 0;
   }

   .customerSection .dateFilter {
      position: relative;
      top: 0;
      margin-left: 10px;
   }

   .productSec .productImg {
      margin: 0 30px 35px 0;
   }

   .owl-carousel .owl-dots {
      margin: 20px 0 20px;
   }

   .finalPrice span {
      font-size: 28px;
   }

   .rrRow .cols4 {
      width: 100%
   }

   .rrRow .cols6 {
      width: 66.66666667%
   }

   .rrRow .cols2 {
      width: 33.33333333%;
   }

   .spProduct .cols7 {
      width: 100%;
   }

   .spProduct .cols3 {
      width: 66.66666667%;
   }

   .spProduct .cols2 {
      width: 33.33333333%;
   }

   .contractIcon,
   .customerIcon,
   .salesPersonIcon {
      display: block;
      text-align: center;
      margin: 0 auto;
   }

   .blockText {
      margin-top: -5px;
      display: block;
      text-align: center;
   }

   .custRight {
      width: auto;
   }

   .statisticBlock .cols3 {
      width: 50%;      /* margin-bottom: 30px; */
   }

   .statisticBlock .cols3 .contractIcon {
      display: block;
      margin: 0;
      width: 100%;
      text-align: center;
      max-width: 100%;
   }

   /*.statisticBlock .cols3 .blockText {
      margin: 8px 0 0;
      text-align: left;
      width: auto;
      display: inline-block;
   }*/
   .statisticBlock .cols3 .growthUpDown {
      padding: 0 0 0 20px;
      text-align: left;
      top: -10px;
   }

   .statisticBlock .cols3 .numberBlock {
      min-height: 155px;
   }

   .adminStatisticBlock {
      margin-bottom: 0px;
   }

   .pbsLegend {
      margin-bottom: 15px;
   }

   .trpCustTbl table td br {
      display: none;
   }

   .trpCustTbl table td .dasTblTitle {
      min-width: 50%;
      padding: 0 !important;
      display: inline-block;
   }

   .trpCustTbl table {
      margin-bottom: 0;
   }

   .equalHight .cols4 .borderBox.mrgB0 {
      margin-bottom: 30px !important;
   }

   .bilToAddCont .cols4,
   .shipToAddCont .cols4 {
      width: 50%;
   }

   .contentRow .row .cols4:nth-child(3) .custCntBx {
      margin-top: 30px;
   }

   .bilToAddCont .custCntBx,
   .shipToAddCont .custCntBx {
      min-height: 190px;
   }

   .shipToAddCont .cols4:nth-child(3n+1),
   .bilToAddCont .cols4:nth-child(3n+1) {
      clear: none;
   }

   .shipToAddCont .cols4:nth-child(2n+1),
   .bilToAddCont .cols4:nth-child(2n+1) {
      clear: both;
   }

   .custDetail,
   .orderPdetails .custDetail {
      width: 100%;
      padding-right: 0;
   }

   .orderStatus,
   .orderPdetails .orderStatus {
      max-width: 100%;
      float: left;
      width: 100%;
      margin-top: 15px;
   }

   .statusBx.border0 {
      padding-left: 0;
   }

   .erAmout {
      float: left;
      margin: 20px 0 15px;
   }

   .tableOut.trpCustTbl {
      overflow-x: hidden;
      height: auto;
      overflow-y: auto;
      padding: 0;
   }

   .popBox .orderDetail .custDetail {
      width: 60%;
   }

   .popBox .orderPdetails .orderStatus {
      max-width: 40%;
      float: right;
      width: auto;
   }

   .RIAfilter .filterCols .cols10 {
      width: 100% !important;
   }

   .RIAfilter .filterCols .cols2 {
      width: 25%;
   }

   .RIAfilter .filterCols .cols7.colsP0 {
      width: 75%;
   }

   .RIAfilter .filterCols .cols5.colsP0 .cols5 {
      width: 41.66666667%;
   }

   .aspdFilter .filterCols .cols7 {
      width: 41.66666667%;
   }

   .aspdFilter .filterCols .cols5 {
      width: 58.33333333%;
   }

   .resOD .erAmout {
      float: right;
      margin: 0;
   }

   .resOD .custDetail {
      width: calc(100% - 170px);
   }

   .acdStatus {
      margin-top: 30px !important;
   }

   .umFilter .filterCols .cols4 {
      width: 41.66666667%;
   }

   .umFilter .filterCols .cols8 {
      width: 58.33333333%;
   }

   .odStatus {
      margin: 15px 0 10px;
      padding-left: 15px;
      width: 26%;
   }

   .quoteDesign .orderAddress.cols6 {
      width: 44%;
   }

   .ARfilter .filterCols .cols4 {
      width: 41.66666667%;
   }

   .ARfilter .filterCols .cols6 {
      width: 33.33333333%;
   }

   .spFilter .filterCols .cols3 {
      width: 25%;
   }

   .pvdFilter .cols4 {
      width: 66.66666667%;
   }

   .pvdFilter .cols3 {
      width: 33.33333333%;
   }

   .pvdFilter .cols5.inSearchCol {
      width: 100%;
   }

   .lconListing li {
      height: 140px;
   }

   .dyReportCols .cols9 {
      width: 100% !important;
   }

   .reportFilter.YTDfilter .filterCols {
      padding-right: 0 !important;
   }

   .editAgreeDetail .cols12 .ucsCols .cols5 {
      width: 45%;
   }

   .editAgreeDetail .cols12 .ucsCols .cols7 {
      width: 55%;
   }

   .statisticBlock.newStBlock .cols3 .numberBlock {
      min-height: 130px;
   }

   .csvBtn {
      padding: 9px 10px;
      margin: 0 15px 0 0 !important;
      float: left;
   }

   .ysccRow .cols4 {
      width: 50%;
   }

   .suctSearch .cols3 {
      width: 33.33333333%;
   }

   .suctSearch .cols9 {
      width: 66.66666666%;
   }

   .contentRow .signUpForm .cols8 .insideApp.packageCart ul {
      padding-left: 0;
      width: 100%;
   }

   .contentRow .signUpForm .cols8 .insideApp.packageCart ul li h4 {
      font-size: 15px;
   }

   .contentRow .signUpForm .cols8 .insideApp.packageCart.currentPack {
      padding-right: 10px;
   }

   .statisticBlock.newStBlock.newTileBlock .row>[class^=cols] {
      width: 25% !important;
   }

   .adminStatisticBlock.statisticBlock.newStBlock.newTileBlock .row>[class^=cols] {
      width: 16.6% !important;
   }

   .quoteForm .cols.colsTotal {
      width: calc(25% - 120px);
   }

   /*.orderBox.row > .orderDetail.cols4{
      width:100%;
   }*/
   .quoteDesign .orderAddress.cols6 {
      width: 50%;
   }

   .orderStatus.odStatus.cols2 {
      width: 100%;
   }

   .poShipping .cols6 span {
      display: inline-block;
      padding-right: 10px;
   }

   .quoteDesign .orderAddress .addressBox:first-child:before,
   .quoteDesign .orderAddress .addressBox:first-child:after {
      display: none;
   }

   .quoteDesign .orderAddress.detailsPage .addressBox:last-child {
      padding-left: 15px;
   }

   .quoteDesign .orderAddress.detailsPage .addressBox:first-child,
   .quoteDesign .orderAddress .addressBox:first-child {
      padding-left: 0px;
   }

   .odStatus .statusBx {
      padding-right: 20px;
   }

   .statusBx {
      padding-left: 5px;
      width: 50% !important;
      padding-right: 10px;
      margin-bottom: 10px;
   }

   .appConfigformRow .cols3 {
      width: 25%;
   }

   .appConfigformRow .cols4 {
      width: 31%;
   }

   .appConfigformRow .cols2.genPass {
      width: 18.66%;
   }
   .customerTiles .cols3{
		width:50%;
	}	
	.tableOut .odTable {
		min-width: 0px;
		max-width:100%;
	}
	app-delivery-details .orderStatus.odStatus.cols2:after{
		display:none;
	}
	.cols12.paymentBtmRow .remarkBx{
		margin-bottom:5px;
		width:100%;
	}
   .selectContract {
    float: left;
  }

  /* Add New Button theme change */
   .cols11addbtn {
      width: calc(100%);
  }

  .cols7addbtn {
      width: calc(58.33333333% - 0px);
  }

  .cols6addbtn {
      width: calc(50% - 0px);
  }

  .cols5addbtn {
      width: calc(41.66666667% - 0px);
  }

  .cols3addbtn {
      width: calc(25% - 0px);
  }

  .cols2addbtn {
      width: calc(16.66666667% - 0px);
  }

  .cols1addbtn {
      width: calc(8.33% + 0px);
  }

  .cols1addbtn{
      width: calc(50%) !important;
      text-align: left !important;
      /* margin-top: 10px; */
  }

  
  .cols1searchbtn {
      width: calc(100%);
      text-align: left !important;
      margin-top: 20px;
  }

  .proSearch .cols5 {
      width: 50%;
  }

  .cols11searchbtn {
      width: calc(100%);
  }
  .cols1addbtn .alignRight{
   float:left;
   }
 /* Add New Button theme change */
}

@media only screen and (max-width:1024px) {


.mainContentIncentxConfig .cols2.appTabFirst {
      width: 26%;
   }
   .mainContentIncentxConfig .cols5 {
      width: 41.66666667%;
   }

   
    
   .reportSearchFrm.cols10, .reportSearchFrm.cols10 .cols5, .reportSearchFrm.cols10 .cols4, .reportSearchFrm.cols10 .cols3 {
       width: 100%;
   }
   .formControls.editAgreeDetail .cols12 .dx-field.mrgT10 {
       margin-bottom: 0px;
   }
   
   .borderBox .row.proSearch .cols5,
   .borderBox .row.proSearch .cols6 {
      width: 100%;
      margin-bottom: 7px;
   }

   .borderBox .row.proSearch .cols1 {
      width: 14%;
   }

   .formControls.editAgreeDetail .cols2.datePicker {
      margin-left: 15px;
      margin-top: 0px !important;
   }

   .border-box .user-details {
      width: 30%;
   }

   .border-box .user-detailsright {
      width: 70%;
   }

   .formControls.editAgreeDetail .dx-field.feildCol {
      margin-bottom: 10px;
   }

   .border-box .user-details .userImage {
      width: 100px;
      height: 100px;
   }

   .border-box .user-details .userImage span {
      font-size: 50px;
      padding-top: 18px;
   }

   
   .newCustDetail,
   .crmHeight {
      height: auto !important;
   }

   .rightTop {
      padding-right: 5px;
   }

   .borderBox {
      padding: 15px 25px;
      margin-bottom: 20px;
   }

   .borderBox .subTitleRow h2 {
      margin-bottom: 0;
   }

   .adminCRM .newBillAddress .tabContainer > div:last-child .hrLine {
      display: none;
   }

   .customerDetail.custdtlTop .dx-texteditor {
   }

   .customerDetail.custdtlTop .noteBx {
      margin-top: 0px !important;
   }

   .chartAreaBox .dx-field.alignRight {
      width: 160px;
      margin-top: 10px;
      margin-bottom: 0;
   }

   .chartAreaBox h2.chartTitle {
      margin-bottom: 0;
      line-height: 62px;
   }

   .newBillAddress .editIco {
      right: 0px;
      top: 5px;
   }

   .borderBox.newBillAddress {
      padding: 15px 25px;
   }

   .newContactListing {
      height: auto !important;
   }

   .newBillAddress .tabContainer {
      height: auto !important;
   }

   #crmCustomerDetail .crmTable {
      padding: 15px 25px 15px 25px;
      height: auto !important;
   }

   .crmCustomerDetail .contDtl {
      padding: 10px 40px 10px 25px;
   }

   #crmCustomerDetail .cols7 {
      position: relative;
      margin-bottom: 0 !important;
   }

   #crmCustomerDetail .cols7 .contBtn.titleBtnCol {
      position: absolute;
      right: 40px;
      top: 5px;
   }

   #crmCustomerDetail .cols7 .timelineRight .tableOut {
      margin-bottom: 0;
   }

   #crmCustomerDetail .cols5 .cols12 .subTitleRow h2 {
      margin-bottom: 10px !important;
   }

   #crmCustomerDetail .cols5 .cols12 .subTitleRow .hrLine {
      display: table;
   }

   #crmCustomerDetail .cols7 .tabbing ul {
      margin-bottom: 0px;
   }

   div#exportContainer {
      margin-top: 0;
   }

   .cols4 {
      width: 50%;
   }

   .cols4.colsP0 {
      width: 100%;
   }

   .cols6.colsP0 {
      width: 100%;
   }

   .cols8.colsP0 {
      width: 100%;
   }

   .cols2.colsP0 {
      width: 100%;
   }

   .cols10.colsP0 {
      width: 100%;
   }

   .cols3 {
      width: 50%;
   }

   .cols5 {
      width: 50%;
   }

   .cols9 {
      width: 50%;
   }

   .res6 {
      width: 50% !important;
   }

   .res12 {
      width: 100% !important;
   }

   .res-4 {
      width: 50% !important;
   }

   .cols8 {
      width: 50%;
   }

   #content {
      padding: 20px;
   }

   .navigation-trigger {
      display: block;
   }

   .editProfile {
      top: 0px;
      right: 0;
      background-position: -216px 2px;
   }
   .sendboxCnt{
      top:28px;
   }

   #logo.logoOpen {
      width: 260px;
   }

   #logo.logoOpen a {
      width: 134px;
   }

   .syncERP a .syncHistory {
      line-height: 14px;
   }

   .rightTop {
      padding: 9px 30px 0px 0;
   }

   .notifyIcon,
   a:hover .notifyIcon {
      border-color: transparent;
      width: 18px !important;
      height: 18px !important;
      background-size: 100%;
      margin-top: 7px;
   }

  /* .notificationBar {
      margin-top: 5px;
   }*/

   .dropPopup {
      top: 61px;
   }

   .usrSettings {
      margin-top: 13px;
      margin-right: 10px;
   }

   .shipAddress>.cols4:nth-child(3n+1) {
      clear: none;
   }

   .syncERP {
      margin-top: 13px;
      margin-right: 20px;
   }

   .shipAddress>.cols4:nth-child(2n+1) {
      clear: left;
   }

   .contentRow .row .cols4:nth-child(3) .custCntBx {
      margin-top: 30px;
   }

   .popBox .cols8 {
      width: 66.66666667%;
   }

   .popBox .cols4 {
      width: 33.33333333%;
   }

   .anCustomer .cols3 {
      width: 25%;
   }

   .anCustomer .cols9 {
      width: 75%;
   }

   .anCustomer .cols5 {
      width: 41.66666667%;
   }

   .statisticBlock .cols4 {
      width: 33.33%;
   }

   .searchBar {
      width: 50px;
      position: relative;
      margin-top: 10px;
      margin-right: 25px;
   }

   .trialCnt {
      position: absolute;
      width: 58px;
      right: -70px;
      top: 15px;
   }

   .searchControl {
      float: left;
      width: 100%;
      height: 51px;
   }

   input[type="button"].searchBtn {
      padding: 0;
      width: 50px;
      height: 51px;
      background: transparent !important;
      position: relative;
      background: transparent url("https://cdn.incentx.com/assets/images/svgs/search2.svg") no-repeat center center !important;
      z-index: 9;
   }

   input[type="text"].searchField {
      background: transparent !important;
      position: absolute;
      right: 0;
      z-index: 10;
      width: 50px;
      min-width: auto;
      padding: 0;
      height: 51px;
      font-size: 0;
      border-color: transparent;
      cursor: pointer;
      -webkit-transition: all .2s ease-in-out;
      transition: all .2s ease-in-out;
   }

   input[type="text"].searchField:focus {
      background: #ffffff !important;
      width: 420px;
      padding: 0 50px 0 15px;
      font-size: 18px;
      border-color: #c8c8c8;
      z-index: 8;
      cursor: auto;
      -webkit-transition: all .4s ease-in-out;
      transition: all .4s ease-in-out;
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.1) !important;
   }

   .contentRow > .row > .cols5,
   .contentRow > .row > .cols7 {
      width: 100% !important;
      margin-bottom: 20px;
   }

   .chartAreaBox {
      padding: 20px 25px 15px 25px;
   }

   .borderBox.agreementBox {
      padding: 20px 25px;
   }

   .customerSection {
      padding: 30px 25px 5px 25px;
   }

   .editAgreeDetail .cols9 {
      width: 66.66666667%;
   }

   .editAgreeDetail .cols3 {
      width: 33.33333333%;
   }

   .editAgreeDetail .BillBackType .cols3 {
      width: 100%;
   }

   .editAgreeDetail .BillBackType .cols9 {
      width: 100%;
   }

   .editAgreeDetail .cols8 {
      width: 100%;
      margin-top: 10px;
      margin-bottom:15px;
   }

   .editAgreeDetail .cols3 .dx-field.mrgT20{
      margin-top: 10px !important;
    }


   .editAgreeDetail .cols2 {
      width: 33.33333333%;
   }

   .multiBtnsRow .button {
      margin-left: 30px;
      width: auto;
      margin-bottom: 0px;
      text-align: center;
      float: left;
   }

   .multiBtnsRow .btnsGroup {
      width: 100%;
   }

   .newUserForm form .row .cols6.colsP0 {
      width: 50%;
   }

   .newUserForm form .row .cols6.colsP0 .cols4 {
      width: 33.33333333%;
   }

   .newUserForm form .row .cols6.colsP0 .cols8 {
      width: 66.66666667%;
   }

   .editProfile:hover::after {
      display: none;
   }

   .bspFilter.salesFilter .searchFilter .cols4 {
      width: 33.33333333%;
   }

   .editAgreeDetail .BillBackType .cols4 {
      width: 33.33333333%;
   }

   .contentRow.contDtlBox .cols4 {
      margin-bottom: 20px;
   }

   .contDtlBox>.row>.cols8 {
      width: 100%;
   }

   .contBtn.titleBtnCol {
      right: 30px;
      float: right;
      position: relative;
      top: 60px;
   }

   .cvv {
      right: 50px;
   }

   .contentRow .signUpForm .cols8 .insideApp.packageCart.currentPack ul.planBullets li {
      width: 50%;
   }

   .contentRow .signUpForm .cols8 .insideApp.packageCart.currentPack ul.planBullets li:after {
      border-top: 1px dashed #ccc;
      width: 94%;
      height: 1px;
      position: absolute;
      bottom: -8px;
      content: "";
      left: 2.5%;
      border-right: 0;
   }

   .contentRow .signUpForm .cols8 .insideApp.packageCart ul li .blueColor {
      display: inline-block;
   }

   .orderBox.row > .orderDetail.cols4 {
      width: 100%;
   }

   .quoteDesign .orderAddress .addressBox {

      /*padding:0;*/
      padding: 0px 15px;
   }

   .quoteDesign .orderAddress.detailsPage .addressBox {
      padding: 0;
   }

   .addressBox.singleBlock {
      float: left;
   }

   .odStatus .statusBx {
      padding-right: 10px;
      margin-bottom: 10px;
   }

   .crmCustomerDetail .contDtl {
      padding: 10px 30px 10px 20px;
   }

   .timelineRight td.calB0x {

      /*replace new css*/
      padding-left: 25px !important;
      padding-right: 30px !important;
      width: 110px;      /*replace new css*/
   }

   .filter.memberFilter {
      margin: 0px 0px 10px 0px;
   }

   .filter.memberFilter > .row > .cols1 {
      width: 50%;
   }

   .multiBtnsRow {
      margin: 0;
   }

   .ucsCols hr {
      margin-bottom: 15px;
      margin-top: 10px;
   }

   .feildColAuto {
      margin-bottom: 10px !important;
   }

   .cols1.fullBtn {
      width: unset;
   }

   .cols1.fullBtn .fieldCol {
      margin-top: 0;
   }

   .formControls.editAgreeDetail > .row > .cols6 {
      width: 100%;
   }

   .formControls.editAgreeDetail > .row > .cols3 {
      width: 100%;
   }

   .filter.memberFilter > .row > .cols2 .button {
      float: right;
   }

   /*.searchResultSection {
      bottom: 100%;
      top: auto;
   }*/

   .quoteDesign .searchResultSection {
      bottom: auto;
      top: 100%;
   }

   .serachTable.tableOut {
      max-height: 240px;
   }

   .searchBoxIn .feildCol {
      min-height: 48px;
   }

   .cols12 .feildCol.radioGroup {
      min-height: auto;
   }

   .feildCol {
      min-height: 60px;
   }

   .quoteDesign .feildCol {
      min-height: 45px;
   }

   .absBtnTop {
      margin-top: -58px;
   }

   .absBtnTop .remarkBx.fullNote {
      padding: 5px 15px;
   }

   .absBtnTop .remarkBx.fullNote strong {
      font-size: 14px;
   }

   .fcoBorderBox .checkboxGroup {
      margin-bottom: 15px;
   }

   .cols4.btnCSVcols {
      width: 100%;
   }

   .stLbl {
      min-width: 70px;
   }

   .custNm {
      margin-bottom: 5px !important;
   }

   .subTitleRow .right.secondaryTitle {
      margin-top: -5px;
   }

   .orderStatus.odStatus.cols2 {
      margin-bottom: 0;
   }

   .statusBx {
      padding-left: 5px;
      width: 50% !important;
   }

   .popContent .statusBx {
      width: 100% !important;
   }

   .popContent .statusBx li {
      display: inline-block;
      width: 49%;
   }

   .quoteDesign .feildCol.radioGroup {
      margin-bottom: 0;
   }

   .quoteDesign .serchBox {
      margin-bottom: 15px;
   }

   .subTitleRow h2 {
      margin-bottom: 5px;
   }

   .customerDetail h2 {
      font-size: 18px;
   }

   .titleRow .titleBtnCol {
      margin-bottom: 10px;
   }

   .quoteForm .cols.colsName {
      width: 62%;
      clear: both;
   }

   .quoteForm .cols.colsUom {
      width: 38%;
   }

   .quoteForm .cols.colsPrice {
      width: 22%;
   }

   .quoteForm .cols.colsQty {
      width: 17%;
   }

   .quoteForm .cols.colsTotal {
      width: 23%
   }

   .cols.colsDiscount {
      width: 19%;
   }

   .addQuotPro .btnCol {
      float: right;
      margin-right: 10px;
      margin-top: 10px;
      width: 28%;
      max-width: 100px;
   }

   .quoteForm .configBtn.cols.colsName {
      width: 40%;
      clear: both;
   }

   .quoteForm .configBtn ~ .cols.colsUom {
      width: 38%;
   }

   .quoteForm .configBtn ~ .cols.colsPrice {
      width: 22%;
   }

   .quoteForm .configBtn ~ .cols.colsQty {
      width: 20%;
   }

   .quoteForm .configBtn ~ .cols.colsTotal {
      width: calc(63% - 310px);
   }

   .configBtn ~ .cols.colsDiscount {
      width: 20%;
   }

   .addQuotPro .configBtn ~ .btnCol {
      width: 270px;
      max-width: 270px;
   }

   .subTitleRow {
      margin-bottom: 5px;
   }

   .editReport.grayedBlock .cols4 {
      width: 25%;
   }

   .editReport.grayedBlock .cols2 {
      width: calc(42% - 140px);
   }

   .editReport.grayedBlock .cols3 {
      width: 25%;
   }

   .editReport.grayedBlock .cols3:last-child {
      width: 160px;
      float: right;
   }

   .cols3.reportRadioSelect {
      width: 25%;
   }

   .cols2.reportYear {
      width: 25%;
   }

   .feildCol.radioGroup {
      margin-bottom: 5px;
      margin-top: 5px;
   }

   .notificationMsgTab .tabTitles {
      width: 38%;
   }

   .notificationMsgTab .notiMessageTitle {
      border-bottom: 1px solid #e5e5e5;
      margin-bottom: 6px;
      padding-bottom: 3px;
   }

   .notificationMsgTab .notiMessageTitle .contTx .date-img {
      width: 20px;
      margin-right: 5px;
   }

   .rightBar .titleRow h1 {
      line-height: 20px;
      margin-top: 10px;
   }

   .topBreadcrumb {
      margin: 3px 0 8px 0;
   }

   .proSearch .cols4.cols4Add {
      width: 100%;
   }

   .proSearch .cols4.cols4Add .feildCol {
      width: 48%;
   }

   .contractDetailsText .cols3 {
      width: 250px;
      padding-right: 0;
   }

   .contractDetailsText .cols9 {
      width: calc(100% - 250px);
   }

   .productSearch .feildCol.radioGroup {
      display: table;
      clear: both;
      width: 100%;
   }

   .productSearch .feildCol.radioGroup .cols2 {
      padding-left: 0;
      width: 15%
   }

   .productSearch .feildCol.radioGroup .cols10 {
      width: 84%
   }

   .productSearch .feildCol.radioGroup .cols2 label {
      padding-left: 0;
   }

   .popContent.supportUserPop .cols4 {
      width: 33.33333333%
   }

   .popContent.supportUserPop .cols5 {
      width: 41.66666667%;
   }

   .popContent.supportUserPop .cols3 {
      width: 25%;
   }

   .vertTabTitle h3,
   .verticleTab ul .tabLink {
      font-size: 16px;
      line-height: 22px;
   }

   .verticleTab ul .tabLink {
      padding: 8px 10px;
   }

   .contentFilter label {
      font-size: 16px;
      line-height: 24px;
   }

   .appSettingTabs .cols8 {
      width: 56%
   }

   .appSettingTabs .cols2 {
      width: 22%;
   }

   .contentFilter .cols9 {
      width: calc(100% - 135px);
   }

   .contentFilter .cols3 {
      width: 130px;
   }

   .contentFilter .cols10 .cols5 {
      width: 44%;
   }

   .contentFilter .cols10 .cols4 {
      width: 28%;
   }

   .appSettingTabs .cols2 {
      width: 50%;
   }

   .appSettingTabs .cols2 {
      width: 50%;
   }

   .appSettingTabs .cols8 {
      width: 100%;
      border-top: 1px solid #dfdfdf;
   }

   .appSetSection {
      height: auto;
      margin-bottom: 10px;
   }

   .contentRow .signUpForm .cols8 .newCustomerForm .cols6 {
      margin-bottom: 15px;
   }

   .contentRow .signUpForm .cols8 .newCustomerForm .cols6 .feildCol {
      min-height: 40px;
   }

   .signUpForm .cols8 {
      padding-right: 10px;
   }

   .insideApp.packageCart h3 {
      font-size: 22px;
   }

   .newCustomerPage .digitsCount {
      top: 8px;
   }

   .digitsCount input[type="button"] {
      width: 25px;
      height: 25px;
      font-size: 22px;
      padding: 1px 4px;
   }

   .digitsCount .count {
      padding: 1px 8px;
      height: 25px;
      width: 35px;
   }

   .newCustomerForm .button.payBtn {
      font-size: 20px;
      padding: 10px 20px;
   }

   .insideApp.packageCart {
      padding: 25px 15px !important;
      margin: 0;
   }

   .signUpbtn {
      padding: 10px;
   }

   .poShipping .cols6:first-child {
      width: 205px;
   }

   .appConfigformRow .cols3,
   .appConfigformRow .cols4,
   .appConfigformRow .cols5 {
      width: 50%;
   }

   .appConfigformRow .cols3.twoFA {
      width: calc(50% - 100px);
   }

   .appConfigformRow .cols2.genPass {
      width: 90px !important;
      padding-right: 0;
      line-height: 18px;
      margin-top: 15px;
   }

   .orderStatus .statusBx .stBtn {
      float: none;
   }

   .statusBx span.txtRight {
      float: none;
   }

   .editAgreeDetail .cols9 {
      width: 100%;
   }

   .editReport.grayedBlock .cols4 {
      width: 27%;
   }

   .editReport.grayedBlock .cols2 {
      width: 44%;
   }

   .editReport.grayedBlock .cols3 {
      width: 29%;
   }

   .editReport.grayedBlock .cols3:last-child {
      width: 100%;
      float: right;
      margin-top: 15px;
   }

   .cols3.reportRadioSelect {
      width: 100%;
      /*new*/
      margin-top: 10px;
      /*new*/
   }

   .cols2.reportYear {
      width: 100%;
      margin-bottom: 15px;
   }

   .reportDate {
      width: 100%;
      float: left;
      margin-bottom: 20px;
   }

   .reportDate ~ .cols5 {
      width: 100%;
   }

   .reportDate .cols2 {
      width: 50%;
   }

   .cols5.passwordInfo {
      width: 40%;
   }

   .cols8.paymentPlans {
      width: 100%;
   }

   .cols8.paymentPlans ~ .cols4 {
      width: 100%;
   }

   .notificationMsgTab .tabDetails {
      width: calc(100% - 38.5%);
   }

   .quoteDesign .orderAddress .addressBox:first-child {
      padding-left: 15px;
   }

   .productDtl h3 {
      line-height: 22px;
   }

   .chargeBackform .cols6.chrgField,
   .chargeBackform .cols4.chrgField,
   .chargeBackform .cols2.chrgField {
      width: 50%;
      min-height: 60px;
   }

   .chargeBackform .cols6.chrgField,
   .chargeBackform .cols4.chrgField {
      margin-bottom: 10px;
   }

   .notificationMsgTab .custDis {
      padding: 13px 35px 13px 18px;
   }

   .customer360 .cols9.mrgT10 {
      width: 75%;
   }

   .amountRow {
      font-size: 30px;
   }

   .borderBox.appSettingTabs {
      padding: 15px 0px;
   }

   .appSettingHead {
      padding: 15px ?13px;
   }

   .popAccordion .accTrigger {
      background-size: 20px 20px;
      height: auto;
   }

   .totalRowCal.quoteGrid {
      width: 100%;
   }

}

@media only screen and (max-width:1023px) {

   .mainContentIncentxConfig #scrollViewTabIncentxConfig .cols7,
   .mainContentIncentxConfig #scrollViewTabIncentxConfig .cols6,
   .mainContentIncentxConfig #scrollViewTabIncentxConfig .cols5,
   .mainContentIncentxConfig #scrollViewTabIncentxConfig .cols4,
   .mainContentIncentxConfig #scrollViewTabIncentxConfig .cols3,
   .mainContentIncentxConfig #scrollViewTabIncentxConfig .cols2 {
      width: 100%;
   }

   .adminStatisticBlock.statisticBlock.newStBlock.newTileBlock .row>[class^=cols] {
      width: 33.33% !important;
   }

   .dataSegment .cols8 {
      width: 100%;
   }

   .dataSegment .cols4 {
      width: 100%;
   }

   .reportsSection .cols8 {
      width: 100%;
   }

   .reportsSection .cols4 {
      width: 100%;
   }

   .buyListing .cols3,
   .buyListing .cols4,
   .buyListing .cols2 {
      width: 50%;
      margin-bottom: 15px;
   }

   .formControls .exlargeCol {
      width: 56%;
   }

   .formControls .smallCol {
      width: 44%;
   }

   .formControls .largeCol {
      width: 34%;
   }

   .formControls .midiumCol {
      width: 33%;
   }

   .customerDetail .cols4,
   .customerDetail .cols3,
   .customerDetail .cols5 {
      width: 100%;
      margin-bottom: 5px;
   }

   .formControls .cols4 {
      width: 50%;
   }

   .chartArea {
      width: 100%;
      overflow-x: auto;
      z-index: 0;
      position: relative;
   }

   #SalesPerformance,
   #quarterlyReport {
      min-width: 660px !important;
   }

   .productSec .productImg {
      max-width: 280px;
   }

   .proDtlSlider li {
      height: 30px;
   }

   .proName {
      font-size: 30px;
      line-height: 32px;
   }

   .custDetail .noteBx.mrgB0 {
      margin-bottom: 30px !important;
   }

   .summaryTx {
      width: 50%;
   }

   .summaryOut .btnGroup {
      position: relative;
      right: auto;
      bottom: auto;
      width: 100%;
      text-align: center;
      margin: 15px 0 20px;
   }

   .summaryOut .btnGroup .button {
      margin: 0 10px;
      float: none;
      display: inline-block;
      vertical-align: top;
   }

   .billAddress>.cols6 {
      width: 100%;
   }

   .erAmout {
      float: left;
      margin: 15px 0 10px;
   }

   .accordion .accTrigger .accNm {
      width: 55%;
   }

   .accordion .accTrigger {
      height: 80px;
   }

   .button,
   input[type="submit"],
   input[type="button"],
   input[type="reset"],
   button,
   .button {
      font-size: 16px;
   }

   .productSec .productImg {
      max-width: 280px;
   }

   .proDtlSlider li {
      height: 280px;
   }

   .imgOut img {
      max-width: 240px;
      max-height: 240px;
   }

   .productDtl .contTx {
      margin-bottom: 15px;
   }

   .cols6 .cpListing {
      min-height: inherit;
   }

   .spdFilterCols .cols5 {
      width: 100%;
   }

   .spdFilterCols .cols3 {
      width: calc(50% - 120px);
   }

   .spdFilterCols .cols2 {
      width: 25%;
   }

   .spdFilterCols .cols2.inSearchCol {
      width: calc(25% + 120px);
   }

   /*.orderBox.EQDetails {
      padding-left: 0;
      width: calc(100% + 25px);
   }*/
   .EQDetails .orderDetail {

      /*padding-left: 105px;  06-11-2020 */
      display: inline-block;
      width: 100%;
   }

   /*06-11-2020*/
   .quoteDesign .orderAddress.cols6 {
      width: 100%;
   }

   .quoteDesign .orderAddress .addressBox:first-child:before,
   .quoteDesign .orderAddress .addressBox:first-child:after {
      display: none;
   }

   .EQDetails .addressBox .feildCol {
      max-width: 100%;
   }

   .editQuotDetail .cols9 {
      width: 66.66666667%;
   }

   .editQuotDetail .cols3 {
      width: 33.33333333%;
   }

   .editQuotDetail .cols2 {
      width: 33.33333333%;
   }

   .editQuotDetail .cols2 .button.addBtn {
      width: 100%;
      text-align: center;
      background-position: 38% center !important;
   }

   .separator .feildCol::after {
      top: 5px;
   }

   .bspFilter .searchFilter .cols3 {
      width: 40%;
   }

   .bspFilter .searchFilter .cols2 {
      width: 30%;
   }

   .tableOut .invoiceTbl {
      min-width: 1024px;
   }

   .reportFilter .filterCols .cols3:first-child {
      width: 50%;
   }

   .reportFilter .filterCols .cols3 {
      width: 50%;
   }

   .reportFilter .filterCols .cols10 {
      width: 66.66666667%;
   }

   .filterCols .reportBtn {
      width: 33.33333333% !important;
   }

   .reportFilter.YTDfilter .filterCols .cols4 {
      width: 50%;
   }

   .reportFilter.YTDfilter .filterCols .cols3:nth-child(3) {
      width: 66.66666667%;
   }

   .reportFilter.PRfilter .filterCols .cols10 {
      width: 50%;
   }

   .reportFilter.PRfilter .filterCols .reportBtn {
      width: 50% !important;
   }

   .reportFilter.PRfilter .filterCols .radioGroup label {
      padding-right: 20px;
   }

   .addNewBtnRow .cols6 {
      width: 100%;
   }

   .acdStatus {
      margin-top: 0 !important;
   }

   .ARfilter .filterCols .cols4 {
      width: 100%;
   }

   .ARfilter .filterCols .cols6 {
      width: 66.66666667%;
   }

   .ARfilter .filterCols .cols2 {
      width: 33.33333333%;
   }

   .spFilter .filterCols .cols6 {
      width: 100%;
   }

   .spFilter .filterCols .cols3 {
      width: 50%;
   }

   .spFilter .filterCols .cols3.inSearchCol {
      width: 50%;
   }

   .lconList {
      float: left;
      width: 100%;
   }

   .lconTitle {
      height: auto;
      text-align: center;
      padding: 5px 0;
   }

   .lconListing li {
      height: 140px;
      width: 33.33333333%;
      float: left;
      border-right: 1px solid #dadada;
   }

   .lconList {
      padding-bottom: 30px;
   }

   .lconListing li::after {
      bottom: -30px;
      height: 30px;
      background-size: 100% 100%;
   }

   .lconList {
      border-left: 0;
   }

   .lconListing {
      border-left: 1px solid #dadada;
   }

   .exclusionsRow .cols4 {
      width: 50%;
   }

   .exclusionsRow .cols2 {
      width: 50%;
   }

   .SRrow .cols6 {
      width: 100%;
   }

   .SRrow .cols6 {
      margin-bottom: 15px;
   }

   .appConfigBtn {
      margin-top: 0;
   }

   .editAgreeDetail .cols12 .ucsCols .cols5 {
      width: 50%;
      margin-top: 0px;
   }

   .editAgreeDetail .cols12 .ucsCols .cols7 {
      width: 50%;
   }

   .ucsData label {
      margin: 2px 0;
   }

   .bspFilter.salesFilter .searchFilter .cols5 {
      width: 100%;
   }

   .bspFilter.salesFilter .searchFilter .cols3 {
      width: 45%;
   }

   .bspFilter.salesFilter .searchFilter .cols4 {
      width: 55%;
   }

   .contentRow .signUpForm .cols8 .insideApp.packageCart ul li .blueColor {
      display: inline;
   }

   .contentRow .signUpForm .cols8 .newCustomerForm .cols6 {
      width: 100%;
   }

   .crmCustDetail>.cols5 {
      width: 100%;
   }

   .crmCustDetail>.cols5>cols12:first-child {
      padding: 0;
      clear: both;
      margin-bottom: 15px;
   }

   .crmCustDetail>.cols5>cols12:first-child>.borderBox {
      width: calc(50% - 30px);
      margin: 0px 15px;
   }

   .crmCustDetail>.cols5>cols12>.borderBox {
      width: 100%;
   }

   .crmCustDetail>.cols7 {
      width: 100%;
      margin-top: 15px;
   }

   #crmCustomerDetail .crmTable {
      max-height: auto !important;
      height: auto !important;
   }

   .emailStatus .notVerifyEmailIcon {
      position: absolute;
      left: -26px;
      top: 18px;
   }

   .emailStatus {
      position: absolute;
      left: 38.5%;
      top: 0;
      width: 220px;
      line-height: 16px;
      padding-bottom: 0;
      line-height: 16px;
      padding-top: 16px;
   }

   .cols8.paymentPlans {
      width: 100%;
   }

   .cols8.paymentPlans ~ .cols4 {
      width: 100%;
   }

   .insideApp.packageCart {
      margin-left: 0;
   }

   .totalRowCal.noBatchClm,
   .totalRowCal.noActClm {
      width: 100%;
   }

   .orderBox .orderDetail.cols4 {
      width: 100%;
   }

   .orderBox .orderDetail .contDtl .cols6.datePicker {
      padding-right: 0;
   }

}

@media only screen and (max-width:991px) {
   .userAddressEdit .addressDetails li:nth-child(2):after {
      display:none;
   }
   .userAddressEdit .addressDetails li:nth-child(3) {
       width: 100%;
   } 

   .custRight {
      width: 100%;
   }

   .customerSection .dateFilter {
      position: absolute;
      top: -50px;
      margin-left: 0px;
   }

   #revenueReport .highcharts-legend {
      transform: translate(38%, 4px);
   }

   .popBox .orderDetail .custDetail {
      width: 100%;
   }

   .popBox .orderPdetails .orderStatus {
      max-width: 100%;
      float: left;
      width: 100%;
      margin-bottom: 10px;
   }

   .aspdFilter .filterCols .cols7 {
      width: 100%;
   }

   .aspdFilter .filterCols .cols5 {
      width: 100%;
   }

   .memberFilter .button {
      top: 0px;
   }

   .memberFilter .button.ecaBtn {
      top: 0;
      margin-bottom: 25px;
   }

   .colDrag {
      width: 40%;
   }

   .colSFT {
      width: 60%;
   }

   .fileUploadInput h4 {
      font-size: 15px;
   }

   .emailStatus {
      font-size: 14px;
      position: relative;
      left: 100px;
   }

   .emailStatus a {
      display: block;
   }

   .ucsData label {
      width: 100%;
   }

   .editReport.grayedBlock .cols4 {
      width: 27%;
   }

   .editReport.grayedBlock .cols2 {
      width: 44%;
   }

   .editReport.grayedBlock .cols3 {
      width: 29%;
   }

   .editReport.grayedBlock .cols3:last-child {
      width: 100%;
      float: right;
      margin-top: 15px;
   }

   .cols3.reportRadioSelect {
      width: 100%;
   }

   .cols2.reportYear {
      width: 100%;
   }

   .reportDate {
      width: 100%;
      float: left;
      margin-bottom: 10px;
   }

   .reportDate ~ .cols5 {
      width: 100%;
   }

   .reportDate .cols2 {
      width: 50%;
   }

   .notificationMsg {
      width: 42%;
   }

   .notificationMsgTab .tabDetails {
      width: calc(100% - 42%);
   }

   .poShipping .cols6 {
      margin-bottom: 5px;
   }

   .orderStatus.odStatus.cols2 {
      margin-top: 10px;
   }

   .productSearch .feildCol.radioGroup .cols2 {
      width: 23%
   }

   .productSearch .feildCol.radioGroup .cols10 {
      width: 76%
   }

   .reportRadioSelect {
      margin-top: 10px;
   }

   .grayedBlock > .row > .cols4,
   .grayedBlock > .row > .cols2,
   .grayedBlock > .row > .cols3 {
      width: 100%;
      margin-bottom: 10px;
   }

}

@media only screen and (max-width:768px) {
   .sideBarExpand ul.navigation ul.subMenu li a {
      padding: 8px 5px 8px 70px;
   }

   .statisticBlock .numberBlock {
      padding: 15px 10px 0px;
   }

   .tableOut {
      clear: both;
      display: block;
      width: 100%;
   }

   .filter .largeCol {
      width: 100%;
      margin-bottom: 15px;
      padding-right: 0;
   }

   .filter .midiumCol {
      width: 30%;
   }

   .formControls .cols4 {
      width: 100%;
   }

   .signUpForm .formControls>.row>.cols6 {
      width: 100%;
      margin-bottom: 30px;
   }

   .signUpForm .formControls>.row {
      margin-bottom: 0;
   }

   .loginContainer {
      transform: none;
      top: 0;
      margin: 20% auto 2% auto;
   }

   .orderAddress {
      margin-top: 20px;
      padding-top: 10px;
   }

   .multiBtnsRow .btnsGroup {
      width: 100%;
   }

   .EQDetails .orderAddress {
      margin-top: 10px;
      padding-top: 15px;
   }

   .cvv input {
      padding: 5px 15px;
   }

   .cvv {
      position: static;
   }

   .quoteDesign .orderAddress.cols6 {
      width: 100%;
   }

   .quoteDesign .addressBox.singleBlock {
      float: left;
   }

   .quoteDesign .orderAddress .addressBox:first-child:before,
   .quoteDesign .orderAddress .addressBox:first-child:after {
      display: none;
   }

   .tabbing ul li,
   .ipad .tabbing ul li {
      margin-top: 0px;
   }

   .newBillAddress .tabbing .addBtn {
      top: 0px;
   }

   .tabbing ul li {
      margin-top: 0;
   }

   .titleRow h1 {
      margin-bottom: 10px !important;
   }

   .tabbing {
      margin-top: 0;
   }

   /* Add New Button theme change */
   .borderBox.newBillAddress {
      padding: 5px 25px 15px 25px;
   }
   /* Add New Button theme change */
}

@media only screen and (min-width:768px) {
   .rightDropIn {
      display: block !important;
   }

   .searchFilter {
      display: block !important;
   }

}

/* ************* Mobiles Media Queries ****************** */
@media only screen and (max-width:767px) {

   
   .footer .ftRight .cf img {
      width: 35%;
   }

   
   .tableOut table {
      min-width: 768px;
   }

   .tabbing {
      margin-top: 0;
   }

   .addNewProfile {
      position: relative;
      width: 100%;
      margin-bottom: 20px;
   }

   .addNewProfile .lconImg {
      text-align: center;
   }

   .newUserForm {
      padding-left: 0;
   }

   .proName {
      font-size: 28px;
   }

   .imgOut img {
      max-width: 100%;
   }

   .addressBox {
      width: 100%;
      margin-top: 15px;
   }

   .cols5-5 {
      width: 100%;
   }

   .addNewBtn .cols1 {
      width: 100%;
   }

   .addNewBtn .cols1 .feildCol {
      min-height: inherit;
      margin: -20px 0 10px;
   }

   .EQDetails .orderAddress {
      padding-top: 0px;
   }

   .filterCols2 {
      padding-right: 0;
   }

   .button.filterILbtn {
      float: right;
      margin-left: 30px;
   }

   .bilShipToAdd>.cols6 {
      width: 100%;
   }

   .bilShipToAdd>.cols6 .custCntBx {
      min-height: inherit;
      padding-bottom: 20px;
   }

   .borderBox .filterCols2 .button {
      position: relative;
      float: left;
      right: auto;
      top: auto;
   }

   .btnCol2 {
      float: right;
      margin-bottom: 10px;
   }

   .accordion .accTrigger .accNm {
      font-size: 16px;
   }

   .accordion .accTrigger .proNo {
      font-size: 15px;
   }

   .loginScreens {
      background: none !important;
   }

   .ucTitle {
      font-size: 34px;
   }

   .documentUpload {
      height: 180px;
   }

   .phoneCall .cols6 {
      width: 100%;
   }

   .phoneCall .feildCol.radioGroup {
      margin-top: 0;
      margin-bottom: 10px;
   }

   .ambToAdd .cols2 {
      width: 50%;
   }

   .popBox.ambToAdd .cols4 {
      width: 50%;
   }

   .ambToAdd .cols6:last-child {
      width: 100%;
   }

   .ambToAdd .cols6:last-child .feildCol {
      margin-top: 0;
      margin-bottom: 10px;
   }

   #logo.logoOpen {
      width: 185px;
   }

   .rightDrop {
      float: right;
      position: absolute;
      width: 8px;
      height: 8px;
      right: 10px;
      top: 15px;
   }

   .notificationBar {
      margin-top: 5px;
   }

   .notiCount {
      padding: 1px 4px;
      line-height: 17px;
      font-size: 10px;
   }

   .searchBar,
   input[type="text"].searchField,
   input[type="button"].searchBtn {
      width: 40px;
   }

   input[type="text"].searchField:focus {
      width: 275px;
   }

   .rightDropBtn {
      background: url("https://cdn.incentx.com/assets/images/svgs/menu_2.svg") no-repeat center center;
      display: inline-block;
      width: 100%;
      height: 100%;
      cursor: pointer;
   }

   .rightDropIn {
      position: absolute;
      top: 55px;
      right: 15px;
      width: 200px;
      background: #fff;
      padding: 10px 15px;
      box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
      display: none;
   }

   .syncERP {
      width: 100%;
      margin: 10px 0;
      padding-left: 40px;
   }

   .usrSettings {
      width: 100%;
      margin: 10px 0;
      padding-left: 40px;
   }

   .dropPopup {
      top: 56px;
      right: -40px;
   }

   .quarterlyReportBox .subTitleRow,
   .salesPerformanceSection .subTitleRow {
      display: inline-block;
      width: auto;
   }

   .chartTopRow .chartDate {
      position: relative;
      top: -7px;
   }

   .salesPerformanceSection .chartDate {
      position: relative;
      right: auto;
      float: right;
      margin-left: 10px;
      margin-bottom: 10px;
   }

   #SalesPerformance .highcharts-legend {
      transform: translate(-10px, 0) !important;
   }

   #quarterlyReport .highcharts-legend {
      transform: translate(-10px, 0) !important;
   }

   #SalesPerformance .highcharts-title,
   #quarterlyReport .highcharts-title {
      display: none;
   }

   .trpCustTbl table td .dasTblTitle {
      min-width: inherit;
   }

   .trpCustTbl table td br {
      display: block;
   }

   .twrCustRight {
      width: 100%;
      position: relative;
   }

   .twrCustRight .twrDate {
      margin: 0;
      position: absolute;
      right: 0;
      top: -55px;
   }

   .twrChart .chartArea {
      width: 100%;
      padding-top: 30px;
   }

   .adChartRange {
      width: 100%;
      margin-top: 20px;
   }

   .adCustRight {
      width: 100%;
      margin: -10px 0 20px;
      position: relative;
   }

   .custSection .viewAllBtn {
      position: absolute;
      right: -10px;
      top: -45px;
   }

   #revenueReport .highcharts-title {
      display: none;
   }

   #revenueReport .highcharts-legend {
      transform: translate(-10px, 0px) !important;
   }

   .timelineNote table {
      min-width: inherit;
   }

   .leadPage .filter.filterCols .cols5 {
      width: 60%;
   }

   .leadPage .filter.filterCols .cols3 {
      width: 40%;
   }

   .leadPage .filter.filterCols .cols4 {
      width: 100%;
   }

   .timelineRight .tableOut table {
      min-width: inherit;
   }

   .filterTogle {
      display: block;
   }

   .searchFilter {
      display: none;
      float: left;
      width: 100%;
      margin-top: 15px;
   }

   .topBtn .searchFilter {
      margin-top: 0;
   }

   .timelineRight.timelineDeals {
      padding-top: 5px;
   }

   .timelineDeals .tableOut {
      width: calc(100% + 30px);
   }

   .titleRow h1 {
      font-size: 24px;
      margin-bottom: 20px !important;
   }

   .inBtnSearch .filterSearch {
      position: absolute !important;
      right: 0 !important;
      top: 0 !important;
      width: 80px !important;
      padding: 9px 12px !important;
   }

   .inBtnSearch>.feildCol {
      width: calc(100% - 100px);
   }

   .bilToAddCont .cols4,
   .shipToAddCont .cols4 {
      width: 100%;
   }

   .contentRow .row .cols4:nth-child(2) .custCntBx {
      margin-top: 30px;
   }

   .bilToAddCont .custCntBx,
   .shipToAddCont .custCntBx {
      min-height: inherit;
      padding-bottom: 20px;
   }

   .custCntBx.newAdd {
      height: 80px;
   }

   .spdFilterCols .cols3 {
      width: 100%;
   }

   .spdFilterCols .cols2 {
      width: calc(50% - 60px);
   }

   .spdFilterCols .cols2.inSearchCol {
      width: calc(50% + 60px);
   }

   .multiBtnsRow .button:first-child {
      margin-top: 0;
      height: auto !important;
   }

   .editAgreeDetail .cols9 {
      width: 100%;
   }

   .editAgreeDetail .cols3 {
      width: 50%;
   }

   .editAgreeDetail .cols2 {
      width: 50%;
   }

   .radioGroup label {
      font-size: 15px;
      line-height: 24px;
   }

   .reportFilter .radioGroup>label {
      line-height: 15px;
      margin-bottom: 10px;
   }

   .editAgreeDetail .feildCol.radioGroup {
      margin: 10px 0;
   }

   .spCharCols .row .cols3 {
      width: 100%;
   }

   .EQDetails .addressBox .feildCol {
      max-width: inherit;
      padding-right: 0px;
   }

   .row.searchFilter {
      width: calc(100% + 30px);
      margin: 0 0 0 -15px;
   }

   .numberBlock strong {
      font-size: 28px;
   }

   .tableOut.trpCustTbl table {
      min-width: inherit;
   }

   .addNewSpd .cols2 {
      width: 50%;
   }

   .addNewSpd .cols6 {
      width: 50%;
   }

   .separator {
      margin-top: 0 !important;
   }

   .separator .radioGroup label {
      margin-bottom: 5px;
   }

   .bspFilter .filterTogle {
      margin-bottom: 20px;
   }

   .bspFilter .searchFilter {
      margin-top: 0;
   }

   .bspFilter .searchFilter form {
      margin-top: -8px;
   }

   .bspFilter .searchFilter .cols3 {
      width: 50%;
   }

   .bspFilter .searchFilter .cols2 {
      width: 50%;
   }

   .bspFilter .searchFilter .cols5 {
      width: 50%;
   }

   .listOrderBox .listInd {
      margin: 0 0 15px;
   }

   .reportFilter .filterCols .cols2 {
      width: 100%;
   }

   .reportFilter .filterCols .cols4 {
      width: 100%;
   }

   .reportFilter .filterCols {
      margin: 0;
   }

   .reportFilter .filterTogle {
      margin-bottom: 8px;
   }

   .reportBorderTop {
      margin-top: 22px;
   }

   .reportFilter .filterCols .button.filterSearch {
      position: relative;
      float: right;
      top: auto;
      bottom: auto;
      margin-top: -60px;
      right: 15px;
   }

   .reportFilter .filterCols .separator .feildCol::after {
      display: none;
   }

   .reportFilter .separator .feildCol {
      width: 100%;
      padding-right: 0;
      margin-right: 0;
   }

   .RIAfilter .filterCols .cols10 {
      width: 66.66666667% !important;
   }

   .RIAfilter .filterCols .cols2 {
      width: 33.33% !important;
      margin-top: 70px;
   }

   .RIAfilter .filterCols .cols7.colsP0 {
      width: 100%;
   }

   .reportFilter.PRfilter .filterCols .cols2 {
      width: 50%;
   }

   .reportFilter.PRfilter .filterCols .reportBtn {
      width: 100% !important;
      margin: 0 0 15px !important;
   }

   .reportFilter.PRfilter .filterCols .cols4 {
      width: 100%;
   }

   .aspdFilter .filterTogle {
      margin-bottom: 20px;
   }

   .aspdFilter .searchFilter {
      margin-top: 0;
   }

   .addressBox {
      padding-right: 0px;
   }

   .EQDetails .addressBox {
      padding-right: 15px;
   }

   .titleRow.umTitleRow {
      width: calc(100% - 55px);
   }

   .umFilter {
      position: relative;
      float: left;
   }

   .umFilter .filterTogle {
      float: right;
      position: absolute;
      right: 0;
      top: -62px;
   }

   .umFilter .filterCols {
      margin-top: 0px;
   }

   .umFilter .filterCols .cols8 {
      width: 100%;
   }

   .umFilter .filterCols .cols4 {
      width: 100%;
   }

   .odStatus {
      margin: 10px 0 10px;
   }

   .ABPfilter .filterCols .cols2 {
      width: 33.33333333%;
   }

   .ARfilter .filterCols .cols2 {
      width: 33.33333333%;
   }

   .spFilter .filterTogle {
      margin-bottom: 20px;
   }

   .spFilter .searchFilter {
      margin-top: 0;
   }

   .pgFilter .filterTogle {
      margin-bottom: 20px;
   }

   .pgFilter .searchFilter {
      margin-top: 0;
   }

   .pvdFilter .filterTogle {
      margin-bottom: 20px;
   }

   .pvdFilter .searchFilter {
      margin-top: 0;
   }

   .pvdFilter .cols4 {
      width: 50%;
   }

   .pvdFilter .cols3 {
      width: 50%;
   }

   .ypsBox .subTitleRow {
      display: inline-block;
      float: left;
      width: auto;
   }

   #yearlyPipeline .highcharts-title {
      display: none;
   }

   .lconListing li {
      width: 50%;
      height: 100px;
   }

   .lconListing li .lconImg {
      display: table-cell;
      width: 46px;
      float: none;
      height: 100%;
   }

   .lconListingBx {
      margin: 0;
      float: none;
      display: table-cell;
      height: 100%;
      padding-left: 10px;
   }

   #yearlyPipeline .highcharts-legend {
      transform: translate(-10px, 0);
   }

   .filterToggleOut.topBtn {
      float: left;
   }

   .tableOut .tableReports.mat-table th,
   .tableOut .tableReports.mat-table td {
      position: relative !important;
   }

   .mat-spinner-class .mat-progress-spinner svg {
      width: 50px !important;
      height: 50px !important;
      margin-top: 25px;
      margin-left: 25px;
   }

   .YTDfilter .filterCols .cols3 {
      width: 33.33333333% !important;
   }

   .ecaSearch .searchBoxIn {
      width: 100%;
   }

   .ecaSearch .button {
      width: auto;
      margin: 0 0 15px;
   }

   .exclusionsRow .cols4 {
      width: 100%;
   }

   .rulesRow .cols2 {
      width: 50%;
   }

   .rulesRow .cols2.fullBtn {
      float: right;
   }

   .newUserForm form .row .cols6.colsP0 .cols4 {
      width: 40%;
   }

   .newUserForm form .row .cols6.colsP0 .cols8 {
      width: 60%;
   }

   .escBorderBox .cols6 {
      width: 100%;
      margin-bottom: 25px;
   }

   .csvRadioRow .cols8,
   .csvRadioRow .cols4 {
      width: 50%;
   }

   .genPass {
      margin: 0 0 25px;
      width: auto !important;
   }

   .YTDfilter .feildCol.radioGroup {
      margin-bottom: 10px;
   }

   .eupSubscription {
      width: 100% !important;
   }

   .EUPbtn {
      width: auto !important;
      margin-top: 0;
      margin-bottom: 20px;
   }

   .spCols3 {
      width: 100%;
   }

   .ucTitle {
      font-size: 26px;
      padding: 0 30px;
   }

   .ysccRow .cols4 {
      width: 100%;
   }

   .editAgreeDetail .BillBackType .cols8,
   .editAgreeDetail .BillBackType .cols4 {
      width: 100%;
   }

   .suctSearch .cols3 {
      width: 50%;
   }

   .suctSearch .cols9 {
      width: 50%;
   }

   .cols.colsName {
      width: 70%;
   }

   .cols.colsQty {
      width: 30%;
   }

   .cols.colsPrice {
      width: 33.33333333%;
   }

   .cols.colsDiscount {
      width: 33.33333333%;
   }

   .cols.colsTax {
      width: 33.33333333%;
   }

   .cols.colsUom {
      width: 40%;
   }

   .cols.colsTotal {
      width: calc(60% - 120px);
   }

   .editQuotDetail.addQuotPro .cols.colsName {
      width: 100%;
   }

   .cols6.colSFT,
   .cols6.colDrag {
      width: 100%;
      margin-bottom: 15px;
   }

   .quoteDesign .orderAddress .addressBox {
      padding: 0;
   }

   .quoteDesign .EQDetails .orderAddress .addressBox {
      padding: 0px 15px;
   }

   .contentRow.leadProspectsRow.cf .rows>.row>.cols6 {
      width: 100%;
   }

   .chartAreaBox {
      padding: 15px;
   }

   h2.chartTitle {
      font-size: 26px;
   }

   .orderDetail.cols4 {
      width: 100%;
   }

   .orderStatus.odStatus.cols2 {
      width: 100%;
   }

   .grayedBlock > .row > .cols4,
   .grayedBlock > .row > .cols2,
   .grayedBlock > .row > .cols3 {
      width: 100%;
      margin-bottom: 10px;
   }

   .notifyDays {
      max-width: 100%;
   }

   .cols6.shiftTimeViewOnly {
      max-width: 240px;
      width: 240px;
   }

   .exSysConfig .cols4,
   .exSysConfig .cols8,
   .exSysConfig .cols6 {
      width: 100%;
   }

   .additionalDetails li {
      display: block;
   }

   .additionalDetails li:after {
      display: none;
   }

   .signUpbtn {
      padding: 10px 6px;
      font-size: 16px;
   }

   .insideApp.packageCart ul li.totalCoast h4 {
      font-size: 22px;
   }

   .listInd {
      padding: 0;
   }

   .quoteForm .cols.colsTotal {
      width: 28%;
   }

   .quoteForm {
      margin-bottom: 15px;
   }

   .insideApp.packageCart {
      padding: 25px 15px !important;
   }

   .poShipping .cols6:first-child {
      width: 205px;
   }

   .appConfigformRow .cols3,
   .appConfigformRow .cols4,
   .appConfigformRow .cols5 {
      width: 50%;
   }

   .appConfigformRow .cols3.twoFA {
      width: calc(50% - 100px);
   }

   .appConfigformRow .cols2.genPass {
      width: 90px !important;
      padding-right: 0;
      line-height: 18px;
      margin-top: 15px;
   }

}

@media only screen and (max-width:639px) {
   #logo {
      padding: 15px;
      width: auto;
   }

   .planBullets.inlineBullet li {
      display: block;
      width: 100%;
      border: none;
   }

   .newBillAddress .tabbing .addBtn {
      top: 0px;
   }

   .newBillAddress .tabbing {
      padding-top: 30px;
   }

   .crmTable .tabbing {
      margin-top: 15px;
   }

   .emailStatus .notVerifyEmailIcon {
      position: absolute;
      left: -26px;
      top: 18px;
   }

   .emailStatus {
      font-size: 12px;
      width: 140px;
      line-height: 16px;
      top: 5px;
      left: auto;
      right: 70px;
   }

   .statisticBlock .cols4 {
      display: block;
      width: 100%;
      margin-bottom: 20px;
   }

   .statisticBlock .numberBlock {
      padding: 15px;
      margin-bottom: 0;
   }

   .contractIcon,
   .customerIcon,
   .salesPersonIcon {
      display: inline-block;
      width: 100px;
   }

   .blockText {
      margin-top: 15px;
      display: inline-block;
      width: 45%;
      padding-left: 0;
      text-align: left;
   }

   .buyListing .cols3,
   .buyListing .cols4,
   .buyListing .cols2 {
      width: 100%;
      margin-bottom: 10px;
   }

   .customerSection h2 {
      margin-bottom: 15px;
   }

   .customerSection .dateFilter {
      position: relative;
      top: 0;
      margin: 25px 0 10px;
      float: left;
      width: 100%;
      max-width: 100%;
   }

   .subTitleRow h2 {
      display: inline-block;
      width: calc(100% - 30px);
   }

   .titleBtnCol.alignRight {
      margin: 10px auto 0;
   }

   .filter .midiumCol {
      width: 50%;
      margin-bottom: 15px
   }

   .formControls .exlargeCol {
      width: 100%;
      margin-bottom: 15px;
   }

   .formControls .smallCol {
      width: 100%;
      margin-bottom: 15px;
   }

   .formControls .largeCol {
      width: 100%;
      margin-bottom: 15px;
   }

   .formControls .midiumCol {
      width: 100%;
      margin-bottom: 15px;
   }

   .formControls .inputContols {
      margin-bottom: 10px;
   }

   .radioGroup label {
      margin-bottom: 10px;
   }

   .radioGroup>label {
      margin-bottom: 5px;
      margin-top: 5px;
   }

   .custLogo {
      left: calc(50% - 45px);
      top: 20px;
   }

   .customerListing ul li {
      padding: 125px 20px 20px 10px;
   }

   .customerListing ul li a,
   .customerListing ul li .custDtl {
      word-break: break-all;
      display: inline-block;
   }

   .regTitles h4 {
      font-size: 14px;
   }

   .otpInput {
      padding: 10px;
   }

   .rfpChart .tabbing {
      width: 100%;
   }

   .statisticBlock {
      margin-bottom: 15px;
   }

   #SalesPerformance .highcharts-legend {
      transform: translate(-10px, 10px) !important;
   }

   .borderBox .subTitleRow .listInd {
      margin-bottom: 20px;
      float: left;
      padding-left: 0;
   }

   .customerSection .dateFilter .datePicker {
      float: left;
      width: 46%;
      padding-left: 0;
   }

   .customerSection .dateFilter .datePicker:last-child {
      float: right;
   }

   .filterCols {
      padding-right: 0;
   }

   .filterCols .feildCol {
      min-height: 55px;
   }

   .filterCols .button {
      top: auto;
      bottom: auto;
      position: relative;
      float: right;
      margin-bottom: 15px;
      right: 15px;
      margin-top: 10px;
   }

   .addNewBtn {
      padding-right: 0;
   }

   .addNewBtn .addBtn {
      top: auto;
      bottom: auto;
      position: relative;
      float: right;
      margin-bottom: 15px;
      right: 15px;
   }

   .orderBox {
      padding-left: 0;
   }

   .custImg {
      position: relative;
      left: auto;
      margin: 0 auto 30px  auto;
      float: none;
   }

   .addCustomer h2 {
      width: auto;
      display: inline-block;
   }

   .contListing .custLogo {
      left: 5px;
      top: 15px;
   }

   .rrRow .cols6 {
      width: 100%;
   }

   .rrRow .cols2 {
      width: 100%;
   }

   .borderBox .btnCol {
      margin-top: 0;
      margin-bottom: 20px;
      text-align: right;
   }

   .accordion .accTrigger .accNm {
      line-height: 18px;
   }

   .accordion .accTrigger .proNo {
      line-height: 18px;
   }

   .accordion .accTrigger {
      height: 70px;
   }

   .ucBox p {
      margin-bottom: 10px;
      font-size: 16px;
      line-height: 22px;
   }

   .fullBtn .upgradeBtn.button {
      padding: 8px 10px;
      font-size: 16px;
   }

   .infoTitle {
      font-size: 25px;
      line-height: 21px;
   }

   .documentUpload {
      height: 130px;
   }

   .popBox .cols8 {
      width: 100%;
   }

   .popBox .cols4 {
      width: 100%;
   }

   .anCustomer .cols3 {
      width: 100%;
   }

   .anCustomer .cols5 {
      width: 100%;
   }

   .anCustomer .cols3:first-child {
      width: 40%;
   }

   .anCustomer .cols9 {
      width: 60%;
   }

   .salesPerformanceSection .chartDate {
      float: left !important;
      margin: 0;
      width: calc(50% - 15px);
   }

   .salesPerformanceSection .chartDate.chartDateFirst {
      margin-right: 30px;
   }

   .salesPerformanceSection .subTitleRow {
      width: 100%;
   }

   .agreementBox .subTitleRow {
      position: relative;
   }

   .agreementBox .viewAllBtn {
      margin-left: 0;
      position: absolute;
      right: -10px;
      top: 0;
   }

   .customerSection .customerTabbing {
      width: 100%;
   }

   .numberBlock strong {
      line-height: 28px;
   }

   .statisticBlock .cols3 {
      width: 100%;
      margin-bottom: 20px;
   }

   .statisticBlock .cols3 .blockText {
      margin: 13px 0 0;
   }

   .newTileBlock .numberBlock strong {
      font-size: 18px;
      margin-top: 8px;
      margin-bottom: 0;
      line-height: 26px;
   }

   .statisticBlock.newStBlock.newTileBlock .row>[class^=cols] {
      width: 50% !important;
   }

   .revReportBox .chartDate {
      margin: 0;
      position: absolute;
      right: 0;
   }

   .pbsLegend {
      padding-left: 0;
   }

   #territoryWiseRevenues {
      height: 170px !important;
      text-align: center;
   }

   .signupLogo {
      margin-bottom: 15px;
   }

   .signupLogo img {
      max-width: 180px;
   }

   .loginForm input[type="submit"],
   .loginForm input[type="button"],
   .signUpContainer input[type="submit"],
   .signUpContainer input[type="button"] {
      height: 44px;
   }

   .regTitles h3 {
      font-size: 20px;
   }

   .regNote {
      font-size: 14px;
   }

   .signUpForm {
      margin-top: 25px;
   }

   .perInfo .custLogo {
      position: relative;
      top: 0;
      left: 0;
   }

   .perInfo .contListing {
      padding-left: 0;
   }

   .leadPage .filter.filterCols .cols3 {
      width: 40%;
   }

   .leadPage .filter.filterCols .cols6 {
      width: 60%;
   }

   .leadPage .filter.filterCols .cols3:nth-child(3) {
      width: 70%;
   }

   .inBtn .button.filterSearch {
      margin: 10px 0 0;
      right: 0;
   }

   .notesFilter {
      padding-right: 120px;
   }

   .notesFilter .button.filterSearch {
      margin: 0;
      position: absolute;
      right: 0;
      bottom: 13px;
   }

   .proDtlSlider li {
      height: 240px;
      padding: 0px;
   }

   .filterCols .cols1,
   .filterCols .cols2,
   .filterCols .cols3,
   .filterCols .cols4,
   .filterCols .cols5,
   .filterCols .cols6,
   .filterCols .cols7,
   .filterCols .cols8,
   .filterCols .cols9,
   .filterCols .cols10,
   .filterCols .cols11,
   .filterCols .cols12 {
      padding-left: 10px;
      padding-right: 10px;
   }

   .filterCols .row {
      margin: 0 -10px;
   }

   .inBtn .feildCol {
      width: calc(100% - 62px) !important;
   }

   .inSearchCol>.feildCol {
      width: calc(100% - 110px);
   }

   .orderStatus,
   .orderPdetails .orderStatus {
      margin-top: 0px;
   }

   .editAgreeDetail .cols3 {
      width: 100%;
   }

   .editAgreeDetail .cols8 {
      width: 100%;
   }

   .editAgreeDetail .cols12 .cols5 .remarkBx {
      margin: 10px 0 20px;
   }

   .editAgreeDetail .feildCol.radioGroup {
      margin-top: 0;
   }

   .EQDetails .orderDetail {
      padding-left: 0;
   }

   .filterCols.spCharCols {
      padding-right: 100px;
   }

   .filterCols .equalAfter {
      min-width: 85px;
      width: 85px;
   }

   .EQDetails .addressBox .feildCol {
      padding-right: 100px;
   }

   .borderBox .editQuotDetail .btnCol {
      margin: 5px 0 20px;
   }

   .editQuotDetail .cols9 {
      width: 100%;
   }

   .editQuotDetail .cols3 {
      width: 50%;
   }

   .editQuotDetail .cols2 {
      width: 50%;
   }

   .editQuotDetail .cols2:last-child {
      float: right;
   }

   .addNewSpd .cols6 {
      width: 100%;
   }

   .addNewSpd .cols2 {
      width: 100%;
   }

   .separator .feildCol {
      width: 100%;
      float: left;
      padding-right: 0;
      margin-right: 0;
      position: relative;
   }

   .separator .feildCol::after {
      display: none;
   }

   .bspFilter .searchFilter .cols5 {
      width: 100%;
   }

   .bspFilter .searchFilter .cols2 {
      width: 100%;
   }

   .bspFilter .searchFilter .cols3 {
      width: 100%;
   }

   .btnGroup {
      float: right;
      width: 100%;
      text-align: center;
   }

   .btnGroup .button {
      float: none;
      display: inline-block;
      vertical-align: top;
   }

   .summaryOut .btnGroup .button {
      margin: 0 7px;
   }

   .btnGroup .button.orangeBg:first-child {
      margin-left: 0;
   }

   .btnGroup .button:last-child {
      margin-right: 0;
   }

   .summaryTx {
      width: 100%;
   }

   .reportFilter .filterCols .cols3 {
      width: 100%;
   }

   .reportFilter .filterCols .cols3:first-child {
      width: 100%;
   }

   .reportFilter.YTDfilter .filterCols .cols4 {
      width: 100%;
   }

   .reportFilter.YTDfilter .filterCols .cols3:nth-child(3) {
      width: 100%;
   }

   .reportFilter.YTDfilter .filterCols .cols2 {
      width: 100%;
   }

   .reportFilter .filterCols .button.filterSearch {
      margin-top: 0;
   }

   .RIAfilter .filterCols .cols10 {
      width: 100% !important;
   }

   .RIAfilter .filterCols .cols2 {
      width: 100% !important;
      margin-top: 0;
   }

   .RIAfilter .filterCols .cols5.colsP0 .cols5 {
      width: 50%;
   }

   .RIAfilter .filterCols .cols7.inSearchCol {
      width: 50%;
   }

   .RIAfilter .filterCols .cols7.inSearchCol .feildCol {
      width: 100%;
   }

   .RIAfilter .filterCols .button.filterSearch {
      margin-bottom: 25px;
   }

   .reportFilter.PRfilter .filterCols .cols3 {
      width: 100%;
   }

   .reportFilter.PRfilter .filterCols .cols2 {
      width: 100%;
   }

   .reportFilter.PRfilter .filterCols .cols10 {
      width: auto;
   }

   .reportFilter.PRfilter .filterCols .reportBtn {
      width: auto !important;
      float: right;
      margin: 10px 0 0 !important;
   }

   .aspdFilter .filterCols .cols5 .cols6 {
      width: 50% !important;
   }

   .aspdFilter .filterCols .inSearchCol>.feildCol {
      width: 100%;
   }

   .aspdFilter .filterCols .inSearchCol .button.filterSearch {
      margin: 0 0 25px;
   }

   .resOD .erAmout {
      float: left;
      margin: 10px 0 15px;
   }

   .resOD .custDetail {
      width: 100%;
   }

   .bhTitleRow .cols2 {
      width: 100% !important;
   }

   .bhTitleRow .cols10 {
      width: 100% !important;
   }

   .addNBtn .feildCol {
      width: 100%;
   }

   .addNBtn .addBtn {
      margin: 0 0 15px;
   }

   .odStatus {
      margin: 10px 15px 15px;
   }

   .ABPfilter .filterCols .cols2 {
      width: 100%;
   }

   .ABPfilter .filterCols .cols10 {
      width: 100%;
   }

   .ABPfilter .filterCols .cols2.reportBtn {
      width: 100% !important;
      margin-top: 0;
   }

   .ARfilter .filterCols .cols6 {
      width: 100%;
   }

   .ARfilter .filterCols .cols2 {
      width: 100%;
   }

   .reportFilter .filterCols .cols10 {
      width: 100%;
   }

   .ARfilter .filterCols .cols2.reportBtn {
      width: 100% !important;
   }

   .spFilter .filterCols .cols3 {
      width: 100%;
   }

   .spFilter .filterCols .cols3.inSearchCol {
      width: 100%;
   }

   .pvdBox .subTitleRow {
      position: relative;
   }

   .borderBox.pvdBox .subTitleRow .listInd {
      float: right;
      padding-left: 0;
      position: absolute;
      right: 0;
      top: 0;
   }

   .pvdFilter .cols4 {
      width: 100%;
   }

   .pvdFilter .cols3 {
      width: 100%;
   }

   .lconListing li {
      width: 100%;
      height: auto;
      padding: 20px;
   }

   .lagendBtn .btnOut a {
      height: 42px;
      line-height: 42px;
   }

   .mat-paginator-container {
      text-align: center !important;
      display: inline-block !important;
   }

   .mat-paginator-page-size {
      display: inline-block !important;
      margin: 0 0 10px !important;
      width: 100%;
   }

   .mat-paginator-range-actions {
      display: inline-block !important;
      width: 100%;
   }

   .mat-paginator-page-size-label {
      display: inline-block !important;
   }

   .mat-paginator-range-label {
      display: inline-block !important;
   }

   .ecaRemark strong {
      display: block;
   }

   .YTDfilter .filterCols .cols3 {
      width: calc(50% - 55px) !important;
   }

   .reportFilter.YTDfilter .filterCols .button.filterSearch {
      position: absolute;
      bottom: 13px;
      margin: 0;
      right: 0px;
   }

   .button.payBtn {
      font-size: 18px;
      padding: 10px 20px;
   }

   .feildColAuto {
      margin-bottom: 10px !important;
   }

   .rulesRow .cols3 {
      width: 100%;
   }

   .titleBtn h2 {
      width: auto !important;
      float: left;
      margin-right: 20px;
   }

   .ucsData label {
      width: 100%;
   }

   .statisticBlock.newStBlock .cols3 .numberBlock {
      min-height: inherit;
      padding: 18px 10px 5px 10px;
   }

   .csvRadioRow .cols8,
   .csvRadioRow .cols4 {
      width: 100%;
   }

   .ucBox .popHeader p {
      margin-bottom: 20px;
      font-size: 15px;
      line-height: 18px;
   }

   .ucTitle {
      font-size: 23px;
      margin-bottom: 10px;
   }

   .bspFilter.salesFilter .searchFilter .cols3 {
      width: 100%;
   }

   .bspFilter.salesFilter .searchFilter .cols4 {
      width: 100%;
   }

   .inputSelectOut .searchResultSection {
      width: calc(100% - 30px);
   }

   .inputSelectOut .searchResultBox {
      width: 100%;
   }

   .borderBox .btnCol {
      margin: 10px 10px 0 !important;
   }

   .adminStatisticBlock.statisticBlock.newStBlock.newTileBlock .row>[class^=cols] {
      width: 50% !important;
   }

   .newTileBlock .numberBlock .blockText {
      width: 100%;
   }

   .emailStatus {
      width: 100%;
      line-height: 16px;
      top: 60px;
      left: 0;
      right: 0;
      text-align: center;
      background: #fff;
      padding: 5px 0px;
      box-shadow: 3px 5px 12px #e5e5e5;
      border-top: 1px solid #f3f3f3;
   }

   .emailStatus a {
      display: inline-block;
   }

   .emailStatus .notVerifyEmailIcon {
      position: relative;
      top: 0px;
      left: 0;
   }

   .sideBar {
      top: 99px;
   }

   #content {
      padding: 65px 15px 20px 15px;
   }

   .cols1.fullBtn {
      width: 100%;
   }

   .orderStatus.odStatus.cols2 {
      padding: 0;
      width: 100%;
   }

   .cols8.paymentPlans .cols6 {
      width: 100%;
   }

   .contTx.icoDate {
      padding-left: 25px;
      width: 100%;
      font-size: 15px;
   }

   .contTx .date-img {
      width: 18px;
      top: 2px
   }

   .editAgreeDetail .cols12 .ucsCols .cols5 .button {
      height: auto;
      padding: 5px;
      line-height: 20px;
   }

   .newBillingAdd p {
      font-size: 15px;
   }

   .contentFilter {
      padding: 0;
   }

   .contentFilter .cols10 {
      width: 100%;
   }

   .contentFilter .cols10 .cols9 {
      margin-bottom: 5px;
   }

}

@media only screen and (max-width:599px) {
   .userProfile {
      margin-right: 15px;
   }

   .shipToAddCont .cols6 {
      margin-bottom: 20px;
   }

   .remarkBx.fullNote {
      margin-bottom: 5px;
   }

   .panelWithBtn {
      width: 100%;
   }

   .cols4 {
      width: 100%;
   }

   .cols3 {
      width: 100%;
   }

   .cols9 {
      width: 100%;
   }

   .cols6 {
      width: 100%;
   }

   .cols7 {
      width: 100%;
   }

   .cols5 {
      width: 100%;
   }

   .res6 {
      width: 100% !important;
   }

   .cols8 {
      width: 100%;
   }

   .cols2 {
      width: 100%;
   }

   .res6I {
      width: 50% !important;
   }

   .res-4 {
      width: 100% !important;
   }

   .contentRow .row .cols4:nth-child(2) .custCntBx {
      margin-top: 30px;
   }

   .custCntBx {
      min-height: inherit;
      padding-bottom: 20px;
   }

   .signUpContainer {
      padding: 25px 25px 15px;
   }

   .signUpForm .cols6,
   .signUpForm .cols6.colsP0 {
      width: 100%;
   }

   .cDate {
      font-size: 16px;
      line-height: 21px;
   }

   .cMonth {
      font-size: 12px;
   }

   .calB0x {
      padding-left: 20px !important;
      padding-right: 20px !important;
      width: 90px;
   }

   .timelineOrder table.colorList {
      min-width: 480px !important;
   }

   .newUserForm form .row .cols6.colsP0 {
      width: 100%;
   }

   .rulesRow .cols2 {
      width: 100%;
   }

   .suctSearch .cols3 {
      width: 100%;
   }

   .suctSearch .cols9 {
      width: 100%;
   }

   .cols.colsName {
      width: 100%;
   }

   .cols.colsQty {
      width: 50%;
   }

   .cols.colsPrice {
      width: 50%;
   }

   .cols.colsDiscount {
      width: 50%;
   }

   .cols.colsTax {
      width: 50%;
   }

   .cols.colsUom {
      width: 100%;
   }

   .cols.colsTotal {
      width: calc(100% - 120px);
   }

   .quoteDesign .custDetail>.cols5 {
      padding: 0;
   }

   .popCnt {
      max-width: 100%;
   }

   .productDtl h3 {
      font-size: 22px;
      line-height: 26px;
   }

   .notificationMsgTab .tabTitles {
      width: 100%;
      height: 200px;
      margin-bottom: 0;
   }

   .notificationMsgTab .tabDetails {
      width: 100%;
      border-top: 2px solid #ccc;
   }

   .trialCnt {
      right: -80px;
   }

   #logo {
      width: auto;
      padding: 15px;
   }

   .contBtn.titleBtnCol {
      right: 15px;
      top: 52px;
   }

   .sideBarClosed ul.navigation>li:hover .subMenu {
      margin-top: -35px !important;
   }

   .userDetail {
      display: none;
   }

   .userProfile:after {
      right: -10px;
   }

   .userImage {
      width: 32px;
      height: 32px;
      margin: 6px 0px;
   }

   .userImage span {
      line-height: 26px;
      font-size: 14px;
   }

   .cols6.shiftTimeViewOnly .cols6 {
      width: 50%;
   }

   .userProPhoto {
      width: 80px;
      height: 80px;
      margin: 0px auto 20px auto;
      display: block;
      float: none;
   }

   .userProfileEdit .customerDetail {
      width: 100%;
      padding: 0;
   }

   .dx-page-index .dx-texteditor-input {
      height: auto !important;
      padding: 0px 2px !important;
      text-align: center;
   }

   .memberFilter .button {
      top: 5px;
      width: auto;
      padding: 5px 10px;
      height: auto;
   }

   .quoteDesign .orderAddress .addressBox {
      width: 100%;
   }

   #gridContainer .dx-datagrid.dx-datagrid-borders > .dx-datagrid-header-panel {
      margin-bottom: 10px;
      height: auto;
   }

   .timeBetween .durationTime .row .cols6 {
      margin-bottom: 15px;
   }

   .inlineRadio .notificationRadio {
      width: 100%;
   }

   .inlineRadio .notificationRadio .dx-switch {
      float: right;
   }

   .filter.memberFilter .cols3 {
      margin-bottom: 10px;
   }

   .proDtlSlider li {
      padding-top: 0 !important;
   }

   .dx-dropdowneditor-input-wrapper .dx-texteditor-input {
      height: auto;
      padding: 5px 15px;
   }

   .dx-pager .dx-page-sizes .dx-page-size {
      margin-bottom: 5px;
   }

   .custDetailFull .button {
      margin-top: -35px;
      padding: 5px 8px;
      height: auto;
   }

}

@media only screen and (max-width:479px) {
   .crmCustomerDetail .contDtl {
      padding: 10px 40px 10px 15px;
   }

   .gridTitle.titleWithBtn h2 {
      display: block;
   }

   .addSupportusr {
      position: static;
      clear: both;
      text-align: right;
      display: block;
      margin-bottom: 5px;
   }

   .right.secondaryTitle {
      width: 100%;
   }

   .newTileBlock .numberBlock strong {
      font-size: 18px;
   }

   .statisticBlock .cols3 .blockText {
      margin: 5px 0px;
   }

   .emailStatus {
      width: 100%;
      line-height: 16px;
      top: 61px;
      left: 0;
      right: 0;
      text-align: center;
      background: #fff;
      padding: 5px 0px;
      box-shadow: 3px 5px 12px #e5e5e5;
   }

   .emailStatus .notVerifyEmailIcon {
      position: relative;
      top: 0px;
      left: 0;
   }

   #content {
      padding: 50px 15px 20px 15px;
   }

   .datePicker input[type="text"] {
      font-size: 12px;
      padding: 18px 12px;
   }

   .customerSection .dateFilter .datePicker:first-child {
      padding-left: 0;
   }

   .signUpForm .formControls>.row .cols5 {
      width: 100%;
      margin-bottom: 30px;
   }

   .signUpForm .formControls>.row .cols7 {
      width: 100%;
   }

   .otpInput input {
      width: 40px;
      font-size: 16px;
      letter-spacing: 0;
      margin-left: 0;
   }

   .optTime {
      right: 10px;
      font-size: 12px;
      top: 20px;
   }

   .loginContainer,
   .signUpContainer {
      padding: 20px;
   }

   .loginForm .formControls {
      margin-bottom: 10px;
   }

   .tabbing ul li a {
      padding: 0 0 5px;
   }

   .tabbing ul li {
      margin-top: 10px;
      font-size: 14px;
   }

   .salesPerformanceSection .chartDate {
      margin-left: 0;
   }

   .tableEntries .totalShow {
      width: 100%;
      text-align: center;
      margin-bottom: 10px;
   }

   .tableEntries .pagination {
      float: none;
      margin: 0 auto;
      display: inline-block;
   }

   .tableEntries {
      text-align: center;
   }

   .titleRow h1 {
      font-size: 21px;
   }

   .titleRow .titleBtnCol .button {
      width: 100%;
      text-align: center;
      padding: 5px 8px;
      height: auto;
   }

   .titleRow .titleBtnCol .button .icon {
      float: none;
      display: inline-block;
      vertical-align: top;
      margin-right: 5px;
   }

   .proName {
      font-size: 26px;
      line-height: 28px;
   }

   .finalPrice span {
      font-size: 26px;
   }

   .productDtl .contTx {
      margin-bottom: 15px;
      font-size: 15px;
   }

   .owl-carousel .owl-dots {
      margin: 20px 0 10px;
   }

   .alertBox {
      width: 280px;
      padding: 20px 15px 20px 80px;
   }

   .alertIcon {
      left: 10px;
   }

   .summaryTx .contTx .lbl {
      width: 100px;
   }

   .summaryOut .btnGroup .button {
      margin: 5px 0;
      width: 100%;
   }

   .summaryOut .btnGroup {
      margin: 10px 0 15px;
   }

   .addCustomer .subTitleRow .alignRight {
      width: auto;
      position: absolute;
      right: 0;
      top: 0;
   }

   .addCustomer .subTitleRow {
      position: relative;
   }

   .addCustomer .subTitleRow .alignRight .button {
      width: 100%;
   }

   .addCustomer .subTitleRow .button .icon {
      float: none;
      display: inline-block;
      vertical-align: top;
      margin-right: 5px;
   }

   .addCustomer h2 {
      width: 100%;
   }

   .cols12.separator.mrgT10 {
      margin-top: 0 !important;
   }

   .rrRow .button.filterSearch {
      margin-bottom: 0;
   }

   .btnCol2 {
      margin-bottom: 0;
   }

   .abutton.filterILbtn {
      line-height: 23px;
   }

   .btnCol2 .button {
      width: 100% !important;
      margin: 10px 0 !important;
   }

   .spProduct .cols3 {
      width: 100%;
   }

   .spProduct .cols2 {
      width: 100%;
   }

   .accordion .accTrigger .accNm {
      padding: 0 0 5px 10px;
      display: block;
      width: 100%;
      height: auto;
      font-size: 15px;
   }

   .accordion .accTrigger .proNo {
      padding-left: 10px;
      display: block;
      width: 100%;
      height: auto;
      font-size: 14px;
   }

   .accordion .accTrigger .lconImg {
      width: 30px;
   }

   .accordion .accTrigger .lconImg .iconImg {
      width: 30px;
      height: 30px;
      line-height: 30px;
      font-size: 15px;
   }

   .accordion .accTrigger {
      background-size: 20px;
      padding: 10px 30px 10px 0;
      min-height: 65px;
      height: auto;
   }

   .spDetail .cols5 {
      width: 100%;
   }

   .spDetail .cols3 {
      width: 100%;
   }

   .spDetail .cols2 {
      width: 100%;
   }

   .ucTitle {
      font-size: 24px;
   }

   .imgUc {
      margin: 70px 0 30px;
   }

   .infoBox p {
      font-size: 16px;
      line-height: 21px;
      margin-bottom: 25px;
   }

   .popBox {
      padding: 0 15px 30px;
   }

   .popHeader {
      padding: 20px 15px 0;
      margin: 0 -15px 20px;
   }

   .closePopup {
      right: 15px;
   }

   .upProcess {
      padding-left: 40px;
   }

   .upFileName {
      font-size: 15px;
   }

   .rangeOut.uploadRange {
      margin: 3px 0 2px;
   }

   .upProcess .rangeSize,
   .rangeProcess {
      font-size: 14px;
   }

   .upSec .fileIcon {
      width: 30px;
      height: 30px;
      top: 15px;
   }

   .upSec {
      margin-bottom: 25px;
   }

   .anCustomer .cols3:first-child {
      width: 100%;
      margin-bottom: 15px;
   }

   .anCustomer .cols9 {
      width: 100%;
   }

   .popTitle {
      font-size: 16px;
   }

   .ambToAdd .cols2 {
      width: 100%;
   }

   .popBox.ambToAdd .cols4 {
      width: 100%;
   }

   .popupAddress {
      padding: 30px 15px;
   }

   .subTitleRow h2 {
      font-size: 18px;
   }

   .button,
   input[type="submit"],
   input[type="button"],
   input[type="reset"],
   button,
   .button {
      font-size: 14px;
   }

   .tableEntries .totalShow {
      font-size: 14px;
   }

   #planBasedSubscription {
      min-width: 300px !important;
   }

   .twrCustRight .twrDate {
      position: relative;
      top: auto;
      right: auto;
      float: left;
   }

   .twrCustRight .tabbing {
      float: left;
      width: 100%;
      overflow-x: auto;
      margin: -15px 0 30px;
      height: 40px;
   }

   .borderBox.rfpChart {
      padding: 30px 25px 10px !important;
   }

   .borderBox {
      padding: 30px 25px;
   }

   .twrChart {
      padding: 30px 25px 10px;
   }

   .trpCustTbl .tooltiptext {
      width: 250px;
   }

   .leadPage .titleBtnCol {
      width: auto;
   }

   .perInfo .contListing .icoOut {
      width: 100%;
      padding: 0;
   }

   .perInfo .contListing .icoOut {
      word-wrap: anywhere;
   }

   .tabbingNew li a {
      padding: 12px 0 12px 25px;
      font-size: 14px;
   }

   .tabbingNew li a::before {
      border-top: 40px solid transparent;
      border-bottom: 40px solid transparent;
      border-left: 11px solid white;
      margin-top: -40px;
   }

   .tabbingNew li a::after {
      border-top: 40px solid transparent;
      border-bottom: 40px solid transparent;
      border-left: 10px solid #969696;
      margin-top: -40px;
   }

   .tabbingNew li:first-child a {
      padding-left: 15px;
   }

   .contDtl strong {
      font-size: 16px;
   }

   .notesFilter .button.filterSearch {
      min-width: 60px;
   }

   .notesFilter {
      padding-right: 80px;
   }

   .timelineDeals table tr td:first-child {
      padding-left: 25px;
      width: 85px;
   }

   .timelineDeals .tableOut {
      width: calc(100% + 25px);
      margin-left: 0;
   }

   .borderBox.noPaddBorder .subTitleRow {
      width: calc(100% - 40px);
      margin-left: 20px;
   }

   .finalPrice {
      font-size: 15px;
      margin: 0 0 15px;
   }

   .custCntBx {
      padding: 25px 25px 10px;
   }

   .bilToAddCont .custCntBx,
   .shipToAddCont .custCntBx {
      padding: 25px 25px 20px;
   }

   .contListing {
      padding: 15px 0 10px 95px;
   }

   .customerDetail h4 {
      font-size: 14px;
   }

   .customerDetail h2 {
      font-size: 18px;
   }

   .contListing .customerDetail .icoOut {
      width: 100%;
      padding-right: 0;
   }

   .locationTx,
   .icoOut {
      font-size: 15px;
   }

   .filterToggleOut.topBtn .filterTogle {
      top: -50px;
   }

   .filterToggleOut.topBtn2 .filterTogle {
      top: -57px;
   }

   .editAgreeDetail .cols2 {
      width: 100%;
   }

   .reportFilter .filterCols .cols10 {
      width: 100%;
   }

   .filterCols .reportBtn {
      width: 100% !important;
      margin-top: 0;
   }

   .RIAfilter .filterCols .cols10 {
      margin-bottom: 10px;
   }

   .bulletPoint li {
      width: 100%;
   }

   .inSearchCol>.feildCol {
      width: 100%;
   }

   .filterCols .inSearchCol .button.filterSearch {
      margin: 0 0 25px;
   }

   .titleRow.umTitleRow {
      width: 100%;
   }

   .titleRow.umTitleRow h1 {
      width: 100%;
      margin-bottom: 10px;
   }

   .titleRow.umTitleRow .titleBtnCol {
      margin-right: 55px;
   }

   .ABPfilter .radioGroup label {
      padding-right: 15px;
   }

   .radioGroup label {
      padding-right: 15px;
   }

   .tabbingNew {
      width: calc(100% - 50px);
      left: 25px;
      bottom: 25px;
   }

   .perInfo {
      padding-bottom: 70px !important;
   }

   .YTDfilter .filterCols .cols3 {
      width: 100% !important;
   }

   .reportFilter.YTDfilter .filterCols .button.filterSearch {
      position: relative;
      bottom: auto;
      margin: 10px 0;
      right: 10px;
   }

   .cdk-overlay-pane {
      max-width: calc(100% - 30px) !important;
   }

   .fileUploadInput {
      height: 270px;
   }

   .csvBtn {
      margin: 0 0 25px !important;
      width: 100% !important;
      text-align: center;
   }

   .cardRow {
      margin: 0px -15px;
   }

   .sideBarExpand {
      width: 100%;
      max-width: 100%;
      top: 99px;
   }

   .sideBar {

      /*new*/
      top: 60px;      /*new*/
   }

   .contBtn.titleBtnCol {
      top: 40px;
   }

   .noteBx {
      padding: 6px 10px;
   }

   .custDetail .noteBx.mrgB0 {
      margin-bottom: 10px !important;
   }

   .borderBox {
      padding: 15px 20px;
   }

   .subTitleRow h2 {
      display: inline;
   }

   .newBillAddress .tabbing .addBtn {
      top: 5px;
   }

   .tlNm {
      font-size: 14px;
      line-height: 14px;
   }

   .cols6.shiftTimeViewOnly .cols6 {
      width: 100%;
      border-bottom: 1px solid #c8c8c8;
   }

   .cols6.shiftTimeViewOnly .cols6:last-child {
      border-bottom: none;
   }

   .insideApp.packageCart.currentPack li {
      border-right: 0;
   }

   .cols6.shiftTimeViewOnly {
      max-width: 130px;
      margin: 5px 15px;
      clear: both;
      width: 130px;
   }

   .appSettingTabs .appSettingHead {
      padding: 0;
   }

   .appSettingHead h3 {
      line-height: 24px;
   }

   .timelineRight.timelineDeals {
      padding-left: 14px;
      margin-left: 0px;
   }

}

@media only screen and (max-width:410px) {
   #logo.logoOpen {
      width: 80px;
   }

   #logo.logoOpen a {
      width: 30px;
   }

   input[type="text"].searchField:focus {
      width: 255px;
   }

   .dropPopup {
      top: 56px;
      right: -75px;
      width: 270px;
      max-height: 300px;
   }

   .chartAreaBox {
      padding: 20px 15px 15px 15px;
   }

   .quarterlyReportBox .subTitleRow,
   .salesPerformanceSection .subTitleRow {
      width: 100%;
   }

   .chartTopRow .chartDate {
      top: 0;
      float: left;
      width: calc(50% - 10px);
      margin-top: 0;
   }

   .salesPerformanceSection .chartDate.chartDateFirst {
      margin-right: 20px;
   }

   .salesPerformanceSection .chartDate {
      width: calc(50% - 10px);
   }

   #quarterlyReport .highcharts-legend {
      transform: translate(-10px, 15px) !important;
   }

   #SalesPerformance .highcharts-legend {
      transform: translate(-10px, 15px) !important;
   }

   .salesPerformanceSection .chartDate:last-child {
      margin-right: 0;
   }

   .borderBox.agreementBox {
      padding: 25px 15px;
   }

   .customerSection,
   .latestNews {
      padding: 25px 15px 5px 15px;
   }

   .blockText {
      font-size: 14px;
   }

   .statisticBlock .cols3 .numberBlock {
      min-height: 150px;
   }

   .statisticBlock .cols3 .growthUpDown {
      top: -15px;
   }

   .revReportBox .chartDate {
      position: relative;
   }

   #revenueReport .highcharts-legend {
      transform: translate(-10px, 20px) !important;
   }

   .adCustRight .tabbing {
      width: 100%;
      overflow-x: auto;
      height: 51px;
      margin: 0;
   }

   .custSection .viewAllBtn {
      top: -35px;
   }

   .tableOut.trpCustTbl {
      overflow-x: auto;
   }

   .tableOut.trpCustTbl table {
      min-width: 350px;
   }

   .pbsLegend {
      min-width: 300px;
   }

   .borderBox {
      padding: 25px 15px;
   }

   #territoryWiseRevenues {
      height: 140px !important;
      min-width: 100% !important;
   }

   .twrChart {
      padding: 25px 15px 10px;
   }

   .leadPage .filter.filterCols .cols5 {
      width: 100%;
   }

   .leadPage .filter.filterCols .cols3 {
      width: 100%;
   }

   .timelineDeals table tr td:first-child {
      padding-left: 15px;
      width: 75px;
   }

   .timelineDeals .tableOut {
      width: calc(100% + 15px);
      margin-left: -15px;
   }

   .proName {
      font-size: 24px;
      line-height: 26px;
   }

   .finalPrice span {
      font-size: 24px;
   }

   .productDtl {
      margin: 0 0 10px;
   }

   .customerDetail .cols12 {
      padding: 0;
   }

   .contListingBox .contListing {
      padding: 0;
   }

   .contListing .custLogo {
      left: auto;
      top: auto;
      position: relative;
      margin: 0 0 10px;
   }

   .custCntBx {
      padding: 20px 15px 10px;
   }

   .bilToAddCont .custCntBx,
   .shipToAddCont .custCntBx {
      padding: 20px 15px 15px;
   }

   .spdFilterCols .cols2 {
      width: 50% !important;
   }

   .inSearchCol>.feildCol {
      width: 100%;
   }

   .spdFilterCols .button.filterSearch {
      top: 10px !important;
      margin-bottom: 25px !important;
   }

   .EQDetails .addressBox .feildCol {
      padding-right: 0;
   }

   .filterCols.spCharCols {
      padding-right: 20px;
   }

   .filterCols .equalAfter {
      width: 100%;
      position: relative;
      float: right;
      top: 0;
      right: 0;
      margin-top: 15px;
      padding: 0 20px;
   }

   .filterCols .equalAfter::after {
      left: 10px;
   }

   .editQuotDetail .cols3 {
      width: 100%;
   }

   .editQuotDetail .cols2 {
      width: 100%;
   }

   .btnGroup .button {
      width: 100%;
      margin: 20px 0 0;
   }

   .barcodeCols {
      padding-right: 15px;
      min-height: inherit;
   }

   .barcodeCols .barcode {
      position: relative;
      width: 100%;
      padding: 0 15px;
      margin: 10px 0;
   }

   .reportFilter .filterCols .button.filterSearch {
      margin-top: 10px;
   }

   .RIAfilter .filterCols .cols5.colsP0 .cols5 {
      width: 100%;
   }

   .RIAfilter .filterCols .cols7.inSearchCol {
      width: 100%;
   }

   .RIAfilter .filterCols .button.filterSearch {
      margin-top: 0 !important;
   }

   .reportFilter.PRfilter .filterCols .cols10 {
      width: 100%;
   }

   .reportFilter.PRfilter .filterCols .reportBtn {
      width: 100% !important;
      margin: 0 0 0 !important;
   }

   .titleRow h1 {
      line-height: 23px;
   }

   .titleRow .titleBtnCol {
      margin-bottom: 15px;
      margin-top: 0px;
   }

   #yearlyPipeline .highcharts-legend {
      transform: translate(-10px, 20px);
   }

   .lagendBtn .btnOut {
      padding: 0 5px;
   }

   .lagendBtn {
      padding: 15px 10px;
   }

   .contListingBox {
      padding: 10px 15px 15px !important;
   }

   .tabbingNew {
      width: calc(100% - 30px);
      left: 15px;
   }

   .forgotPass {
      font-size: 14px;
   }

   .otpInput input {
      width: 30px;
   }

   .exclusionsRow .cols2 {
      width: 100%;
   }

   .toast-container .ngx-toastr {
      padding: 15px 15px 15px 70px !important;
      min-width: 300px !important;
      min-height: 75px;
      background-size: 50px !important;
   }

   .toast-message {
      font-size: 15px;
      line-height: 18px;
   }

   .toast-title {
      font-size: 16px;
      margin-bottom: 4px;
   }

   .planBullets.inlineBullet li {
      width: 100%;
   }

   .trialCnt {
      right: -60px;
      width: 45px;
      top: 17px;
   }

}

@media only screen and (max-width:1830px) {
   .col-lg-12{
      width: 100% !important;
   }
}