@charset "utf-8";

/*====================================================================== 
Site Name: https://app.incentx.com/#/auth/login
------------------------------------------------------------------------ 
Blue Color: #21B3C6 
Purple Color: #706CCE 
Text Color: #767676
Text Color: #000000
------------------------------------------------------------------------ 
Layout Width: 100% 
Container Width: 1170px 
Responsive Layout: Yes 
=======================================================================*/

/*---------------------------Font---------------------------*/
@font-face {
    font-family: 'proxima_nova semiBold';
    src: url('fonts/proximanova-sbold.eot');
    src: url('fonts/proximanova-sbold.eot?#iefix') format('embedded-opentype'), url('fonts/proximanova-sbold.woff2') format('woff2'), url('fonts/proximanova-sbold.woff') format('woff'), url('fonts/proximanova-sbold.ttf') format('truetype'), url('fonts/proximanova-sbold.svg#proxima_novasemibold') format('svg');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'proxima_nova Bold';
    src: url('fonts/proximanova-bold.eot');
    src: url('fonts/proximanova-bold.eot?#iefix') format('embedded-opentype'), url('fonts/proximanova-bold.woff2') format('woff2'), url('fonts/proximanova-bold.woff') format('woff'), url('fonts/proximanova-bold.ttf') format('truetype'), url('fonts/proximanova-bold.svg#proxima_nova_rgbold') format('svg');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'proxima_nova';
    src: url('fonts/proximanova-reg.eot');
    src: url('fonts/proximanova-reg.eot?#iefix') format('embedded-opentype'), url('fonts/proximanova-reg.woff2') format('woff2'), url('fonts/proximanova-reg.woff') format('woff'), url('fonts/proximanova-reg.ttf') format('truetype'), url('fonts/proximanova-reg.svg#proxima_nova_rgregular') format('svg');
    font-weight: 400;
    font-style: normal;
}

/*------------------------- icon css start -------------------------*/
@font-face {
    font-family: 'IXPortal';
    src: url('fonts/IXPortal.eot?lkzi7s');
    src: url('fonts/IXPortal.eot?lkzi7s#iefix') format('embedded-opentype'), url('fonts/IXPortal.ttf?lkzi7s') format('truetype'), url('fonts/IXPortal.woff?lkzi7s') format('woff'), url('fonts/IXPortal.svg?lkzi7s#IXPortal') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
    font-family: 'IXPortal' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

[class^="dx-icon-"],
[class*=" dx-icon-"] {
    font-family: 'IXPortal';
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/*--------------------------- icon css end -------------------------*/
*,
*:before,
*:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
    border: 0;
    font-size: 100%;
    font-style: inherit;
    font-weight: inherit;
    margin: 0;
    outline: 0;
    padding: 0;
    vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
    display: block;
}

audio,
canvas,
video {
    display: inline-block;
}

audio:not([controls]) {
    display: none;
    height: 0;
}

html {
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
}

a {
    text-decoration: underline;
    text-decoration: none;
    outline: 0;
    cursor: pointer;
}

a:focus {
    outline: 0;
}

a:hover,
a:active {
    outline: 0;
}

a:hover {
    text-decoration: none;
    color: #5753bf;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: normal;
}

b,
strong,
dt,
th {
    font-family: 'proxima_nova semiBold';
}

em,
i {
    font-style: italic;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
dd,
table,
fieldset,
address {
    margin: 0;
    padding: 0;
}

img {
    -ms-interpolation-mode: bicubic;
    border: 0;
    vertical-align: middle;
    max-width: 100%;
    height: auto;
}

figure {
    margin: 0;
}

legend {
    white-space: normal;
}

q {
    quotes: '\201C''\201D''\2018''\2019'
}

small {
    font-size: 80%;
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sup {
    top: -0.5em;
}

sub {
    bottom: -0.25em;
}

table {
    border-collapse: separate;
    border-spacing: 0;
    width: 100%;
    empty-cells: show;
    border: none;
}

table table {
    margin: 0;
}

th,
td {
    border: none;
    padding: 8px 10px;
}

caption,
th,
td {
    font-weight: normal;
}

button,
input,
select,
textarea {
    margin: 0;
    vertical-align: baseline;
    background-color: transparent;
    border: none;
}

button,
input[type='button'],
input[type='reset'],
input[type='submit'] {
    cursor: pointer;
    -webkit-transition: 350ms ease;
    transition: 350ms ease;
}

input[type='text'],
input[type='email'],
input[type='tel'],
input[type='number'],
input[type='url'],
input[type='password'],
input[type='search'],
select,
textarea {
    border: 1px solid #e5e5e5;
    width: 100px;
    height: 30px;
    padding: 0;
    font-size: 100%;
    line-height: normal;
}

a:focus,
a:active,
button::-moz-focus-inner,
input[type='reset']::-moz-focus-inner,
input[type='button']::-moz-focus-inner,
input[type='submit']::-moz-focus-inner,
select::-moz-focus-inner,
input[type='file']>input[type='button']::-moz-focus-inner {
    border: 0;
    outline: 0;
}

:focus {
    outline: none;
}

::-moz-focus-inner {
    border: 0;
}

input[type='checkbox'],
input[type='radio'] {
    box-sizing: border-box;
    padding: 0;
    margin: 0 5px 0 0;
}

input[type='checkbox'] {
    -webkit-appearance: checkbox;
}

input[type='radio'] {
    -webkit-appearance: radio;
}

input[type='submit'],
input[type='button'],
input[type='reset'],
button,
.button {
    line-height: 20px;
    padding: 4px 10px;
    display: inline-block;
    text-decoration: none;
    border: 0 none;
    margin: 0;
    outline: 0 none;
}

textarea {
    overflow: auto;
    vertical-align: top;
    resize: vertical;
    height: 100px;
    max-width: 100%;
    min-width: 100%;
    max-height: 500px;
    min-height: 90px;
}

fieldset {
    border: 0 none;
    margin: 0;
    padding: 0;
}

label {
    display: inline-block;
    font-weight: normal;
    color: #999;
}

.button.grayButton,
.grayButton {
    background: #969696;
    color: #fff;
    box-shadow: 5px 0px 21px #ececec;
}

.button.grayButton:hover,
.grayButton:hover {
    background: #868686;
}

input,
textarea,
button,
select,
div,
a {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
}

.cf:before,
.cf:after,
.container:before,
.container:after,
.row:before,
.row:after,
ul:before,
ul:after,
.boxs:before,
.boxs:after,
.radioList:after,
.radioList:before {
    content: ' ';
    display: table;
}

.cf:after,
.container:after,
.row:after,
ul:after,
.boxs:after,
.radioList:after {
    clear: both;
}

/********* Common Classes *********/
hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 20px 0;
    padding: 0;
}

body,
button,
input,
select,
textarea {
    font-family: 'proxima_nova';
    color: #4a5861;
    font-size: 16px;
    line-height: 1.5;
}

body {
    background-color: #f3f3f3;
}

h1 {
    font-size: 2.308em;
}

h2 {
    font-size: 2.000em;
}

h3 {
    font-size: 1.692em;
}

h4 {
    font-size: 1.538em;
}

h5 {
    font-size: 1.385em;
}

h6 {
    font-size: 1.231em;
}

ul {
    padding: 0 0 0 15px;
}

ol {
    padding: 0 0 0 20px;
}

ul ul,
ol ol {
    margin: 10px 0;
}

ul.bulletText {
    list-style: none;
    padding: 0;
}

ul.bulletText li {
    background: url('https://cdn.incentx.com/assets/images/right-arrow.gif') no-repeat left 7px;
    padding: 2px 0 2px 15px;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
dd,
table,
fieldset,
address {
    margin: 0 0 25px;
}

input[type='text'],
input[type='email'],
input[type='tel'],
input[type='number'],
input[type='url'],
input[type='password'],
input[type='search'],
select,
textarea {
    background-color: #fff;
    border: 1px solid #e5e5e5;
    width: 100%;
    height: 42px;
    padding: 9px 15px;
    font-size: 15px;
    font-family: 'proxima_nova' !important;
    outline: none;
}

input[type='submit'],
input[type='button'],
input[type='reset'],
button,
.button {
    background-color: #1CCACD;
    color: #fff;
    border: 1px solid #1CCACD;
}

input[type='submit']:hover,
input[type='button']:hover,
input[type='reset']:hover,
button:hover,
.button:hover {
    color: #fff;
    background-color: #169bb2;
}

input[type='submit'].cancelBtn,
input[type='button'].cancelBtn,
input[type='reset'].cancelBtn {
    background-color: #888;
}

.alignLeft {
    float: left !important;
}

.alignRight {
    float: right !important;
}

.displayNone,
.hasJS noscript,
.hideThis {
    display: none !important;
    visibility: hidden;
}

.jsRequired {
    display: block;
    background-color: #C00;
    color: #fff;
    width: 100%;
    line-height: 30px;
    text-align: center;
    font-size: 12px;
    border: 0 none;
}

.mrgAuto {
    margin-right: auto !important;
    margin-left: auto !important;
}

.mrg0 {
    margin: 0 !important;
}

.mrgT0 {
    margin-top: 0 !important;
}

.mrgR0 {
    margin-right: 0 !important;
}

.mrgL0 {
    margin-left: 0 !important;
}

.mrgB0 {
    margin-bottom: 0 !important;
}

.mrgT10 {
    margin-top: 10px !important;
}

.mrgT20 {
    margin-top: 20px !important;
}

.mrgT30 {
    margin-top: 30px !important;
}

.mrgT25 {
    margin-top: 25px !important;
}

.mrgL20 {
    margin-left: 20px !important;
}

.mrgR20 {
    margin-right: 20px !important;
}

.mrgB20 {
    margin-bottom: 20px !important;
}

.mrgB30 {
    margin-bottom: 30px !important;
}

.mrgL10 {
    margin-left: 10px !important;
}

.mrgR10 {
    margin-right: 10px !important;
}

.mrgB10 {
    margin-bottom: 10px !important;
}

.padL20 {
    padding-left: 20px !important;
}

.padR20 {
    padding-right: 20px !important;
}

.padB20 {
    padding-bottom: 20px !important;
}

.padT20 {
    padding-top: 20px !important;
}

.padL15 {
    padding-left: 15px !important;
}

.padR15 {
    padding-right: 15px !important;
}

.padB15 {
    padding-bottom: 15px !important;
}

.padT15 {
    padding-top: 15px !important;
}

.padL10 {
    padding-left: 10px !important;
}

.padR10 {
    padding-right: 10px !important;
}

.padB10 {
    padding-bottom: 10px !important;
}

.padB0 {
    padding-bottom: 0 !important;
}

.padT0 {
    padding-top: 0 !important;
}

.padT10 {
    padding-top: 10px !important;
}

.padTB10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}

.padTB15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
}

.padTB20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
}

.pad10 {
    padding: 10px !important;
}

.pad15 {
    padding: 15px !important;
}

.pad20 {
    padding: 20px !important;
}

.pad25 {
    padding: 25px !important;
}

.pad30 {
    padding: 30px !important;
}

.padT0 {
    padding-top: 0px !important;
}

.padTB0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.width100p {
    width: 100% !important;
}

.width90p {
    width: 90% !important;
}

.width15p {
    width: 15% !important;
}

.width85p {
    width: 85% !important;
}

.width80p {
    width: 80% !important;
}

.width70p {
    width: 70% !important;
}

.width60p {
    width: 60% !important;
}

.width50p {
    width: 50% !important;
}

.width40p {
    width: 40% !important;
}

.width30p {
    width: 30% !important;
}

.width20p {
    width: 20% !important;
}

.width10p {
    width: 10% !important;
}

.fileSize {
    font-size: 12px;
    display: inline-block;
    vertical-align: middle;
    margin-left: 3px;
}

.extIcon {
    vertical-align: middle;
    margin-left: 3px;
}

.rsIcon {
    display: inline-block;
    margin-right: 2px;
    height: 11px;
    text-indent: -999px;
    overflow: hidden;
    width: 11px;
}

.bold {
    font-weight: bold;
}

.italic {
    font-style: italic;
}

.longWord,
.wordBreak {
    word-wrap: break-word;
}

/********** Error/Alert Messages **********/
.message {
    padding: 10px 38px 10px 10px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    position: relative;
}

.message.success {
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #d6e9c6;
}

.message.success a {
    color: #2b542c;
}

.message.error {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
}

.message.error a {
    color: #843534;
}

/********** Table **********/
.tableOut {
    margin-bottom: 10px;
}

table,
.tableData {
    border-collapse: separate;
    border-spacing: 0;
    width: 100%;
    empty-cells: show;
    border: 1px solid #e1e1e1;
    margin-bottom: 15px;
}

table table,
.tableData table {
    margin: 0;
    border: 1px solid #e1e1e1;
}

th,
td,
.tableData th,
.tableData td {
    border: none;
    padding: 15px 15px;
    vertical-align: middle;
}

caption,
th,
td {
    font-weight: normal;
    background: #fff;
}

th,
.tableData th {
    text-align: left;
    color: #222222;
    font-size: 16px;
    border-bottom: 1px solid #e1e1e1;
}

td,
.tableData td {
    color: #565656;
    font-size: 15px;
    padding: 8px 15px;
    border-bottom: 1px solid #e1e1e1;
}

tr:hover>td,
tr.active>td,
.tableData tr:hover>td {
    background: #f0f0fa !important;
}

.totalRow td {
    border-top: 1px solid #e1e1e1;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
    font-family: 'proxima_nova semiBold';
}

table tr.totalRowLast:hover td {
    background-color: transparent !important;
}

table tr:last-child td,
.tableData tr:last-child td {
    border-bottom: 0;
}

.tableScroll {
    overflow-y: hidden;
    overflow-x: auto;
}

.tableData th:first-child,
.tableData td:first-child {
    text-align: center;
    width: 100px;
}

.agreementList td:nth-child(6),
.agreementList th:nth-child(6) {
    text-align: center;
}

.addAgreement td:nth-child(4),
.addAgreement th:nth-child(4),
.addAgreement td:nth-child(5),
.addAgreement th:nth-child(5) {
    text-align: center;
}

.addAgreement td:nth-child(4),
.addAgreement th:nth-child(4) {
    width: 100px;
    position: relative;
}

.addAgreement td:nth-child(4) {
    text-align: left;
    padding-left: 30px;
}

.row {
    margin: 0 -15px;
}

.cols1,
.cols2,
.cols3,
.cols4,
.cols5,
.cols6,
.cols7,
.cols8,
.cols9,
.cols10,
.cols11,
.cols12 {
    float: left;
    padding-left: 15px;
    padding-right: 15px;
}

.cols12 {
    width: 100%;
}

.cols11 {
    width: 91.66666667%;
}

.cols10 {
    width: 83.33333333%;
}

.cols9 {
    width: 75%;
}

.cols8 {
    width: 66.66666667%;
}

.cols7 {
    width: 58.33333333%;
}

.cols6 {
    width: 50%;
}

.cols5 {
    width: 41.66666667%;
}

.cols4 {
    width: 33.33333333%;
}

.cols3 {
    width: 25%;
}

.cols2 {
    width: 16.66666667%;
}

.cols1 {
    width: 8.33%;
}

.boxs {
    list-style: none;
    padding: 0;
    margin: 0;
}

.boxs2 {
    margin-left: -2%;
}

.boxs3 {
    margin-left: -2%;
}

.boxs4 {
    margin-left: -2%;
}

.boxs5 {
    margin-left: -2%;
}

.boxs6 {
    margin-left: -2%;
}

.boxs>li,
.boxs>div {
    float: left;
    min-height: 1px;
    margin-left: 30px;
}

.boxs2>li,
.boxs2>div {
    width: 48%;
    margin-left: 2%;
}

.boxs3>li,
.boxs3>div {
    width: 31%;
    margin-left: 2%;
}

.boxs4>li,
.boxs4>div {
    width: 23%;
    margin-left: 2%;
}

.boxs5>li,
.boxs5>div {
    width: 18%;
    margin-left: 2%;
}

.boxs6>li,
.boxs6>div {
    width: 14.6%;
    margin-left: 2%;
}

.resTab {
    position: relative;
    border: 1px solid #00c5ad;
}

.resTab .tabNav {
    margin: 0;
    padding: 0;
    list-style: none;
}

.resTab .tabNav li {
    display: inline-block;
    margin: 0;
    position: relative;
    background-color: #00c5ad;
}

.resTab .tabNav li a {
    display: inline-block;
    padding: 10px 12px;
    text-decoration: none;
    font-size: 14px;
    font-weight: bold;
    color: #fff;
}

.resTab .tabNav .r-tabs-state-active a {
    color: #00c5ad;
    text-shadow: none;
    background-color: white;
}

.resTab .tabContent {
    padding: 15px;
    display: none;
}

.resTab .r-tabs-panel.r-tabs-state-active {
    display: block;
}

.resTab .r-tabs-accordion-title {
    display: none;
}

.resTab .tabNav .r-tabs-state-disabled {
    opacity: 0.5;
}

.resTab .r-tabs-accordion-title .r-tabs-anchor {
    display: block;
    padding: 10px;
    background-color: #00c5ad;
    color: #fff;
    font-weight: bold;
    text-decoration: none;
}

.resTab .r-tabs-accordion-title.r-tabs-state-active .r-tabs-anchor {
    background-color: #fff;
    color: #00c5ad;
    text-shadow: none;
}

.resTab .r-tabs-accordion-title.r-tabs-state-disabled {
    opacity: 0.5;
}

.noJS .resTab .tabContainer {
    height: 150px;
    overflow: hidden;
}

.noJS .resTab .tabContent {
    display: block;
    height: 150px;
}

.accordion {
    margin-bottom: 20px;
}

.accordion .accTrigger {
    background: url('https://cdn.incentx.com/assets/images/svgs/plus.svg') right center no-repeat;
    padding: 0 40px 0 0;
    cursor: pointer;
    display: table;
    table-layout: fixed;
    width: 100%;
    height: 90px;
    border-bottom: 1px solid #e1e1e1;
}

.noJS .accordion .accTrigger {
    background: none;
    padding-left: 0;
}

.accordion .accTrigger a {
    display: block;
    text-decoration: none;
    outline: 0;
}

.accordion .accTrigger.active {
    background-image: url('https://cdn.incentx.com/assets/images/svgs/minus.svg') !important;
}

.accordDetail {
    padding: 0 0 30px;
    display: none;
    border-bottom: 1px solid #e1e1e1;
    margin-top: -1px;
}

.noJS .accordDetail {
    display: block;
    padding: 10px 0;
}

.accordion .accTrigger .lconImg {
    display: table-cell;
    width: 47px;
    text-align: center;
    vertical-align: middle;
    height: 100%;
}

.accordion .accTrigger .accNm {
    display: table-cell;
    text-align: left;
    vertical-align: middle;
    padding-left: 20px;
    font-size: 18px;
    font-family: 'proxima_nova semiBold';
    color: #222;
    height: 100%;
    width: 35%;
}

.accordion .accTrigger .proNo {
    display: table-cell;
    text-align: left;
    vertical-align: middle;
    padding-left: 20px;
    font-size: 16px;
    font-family: 'proxima_nova semiBold';
    color: #767676;
    height: 100%;
}

.accordion .accTrigger .proNo span {
    color: #706cce;
}

.owl-carousel,
.owl-carousel .owl-item {
    -webkit-tap-highlight-color: transparent;
    position: relative;
}

.owl-carousel {
    display: none;
    width: 100%;
    z-index: 1;
}

.owl-carousel .owl-stage {
    position: relative;
    -ms-touch-action: pan-Y;
    touch-action: manipulation;
    -moz-backface-visibility: hidden;
}

.owl-carousel .owl-stage:after {
    content: '.';
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
}

.owl-carousel .owl-stage-outer {
    position: relative;
    overflow: hidden;
    -webkit-transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-item,
.owl-carousel .owl-wrapper {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-item {
    min-height: 1px;
    float: left;
    -webkit-backface-visibility: hidden;
    -webkit-touch-callout: none;
}

.owl-carousel .owl-item img {
    display: block;
    width: 100%;
}

.owl-carousel .owl-dots.disabled,
.owl-carousel .owl-nav.disabled {
    display: none;
}

.no-js .owl-carousel,
.owl-carousel.owl-loaded {
    display: block;
}

.owl-carousel .owl-dot,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-nav .owl-prev {
    cursor: pointer;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.owl-carousel.owl-loading {
    opacity: 0;
    display: block;
}

.owl-carousel.owl-hidden {
    opacity: 0;
}

.owl-carousel.owl-refresh .owl-item {
    visibility: hidden;
}

.owl-carousel.owl-drag .owl-item {
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.owl-carousel.owl-grab {
    cursor: move;
    cursor: grab;
}

.owl-carousel.owl-rtl {
    direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
    float: right;
}

.owl-carousel .animated {
    animation-duration: 1s;
    animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
    z-index: 0;
}

.owl-carousel .owl-animated-out {
    z-index: 1;
}

.owl-carousel .fadeOut {
    animation-name: fadeOut;
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }

}

.owl-height {
    transition: height .5s ease-in-out;
}

.owl-carousel .owl-item .owl-lazy {
    opacity: 0;
    transition: opacity .4s ease;
}

.owl-carousel .owl-item .owl-lazy:not([src]),.owl-carousel .owl-item .owl-lazy[src^=''] {
    max-height: 0;
}

.owl-carousel .owl-item img.owl-lazy {
    transform-style: preserve-3d;
}

.owl-carousel .owl-video-wrapper {
    position: relative;
    height: 100%;
    background: #222222;
}

.owl-carousel .owl-video-play-icon {
    position: absolute;
    height: 80px;
    width: 80px;
    left: 50%;
    top: 50%;
    margin-left: -40px;
    margin-top: -40px;
    cursor: pointer;
    z-index: 1;
    -webkit-backface-visibility: hidden;
    transition: transform .1s ease;
}

.owl-carousel .owl-video-play-icon:hover {
    -ms-transform: scale(1.3, 1.3);
    transform: scale(1.3, 1.3);
}

.owl-carousel .owl-video-playing .owl-video-play-icon,
.owl-carousel .owl-video-playing .owl-video-tn {
    display: none;
}

.owl-carousel .owl-video-tn {
    opacity: 0;
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    transition: opacity .4s ease;
}

.owl-carousel .owl-video-frame {
    position: relative;
    z-index: 1;
    height: 100%;
    width: 100%;
}

.owl-carousel .owl-nav {
    margin-top: 0px;
    text-align: center;
    -webkit-tap-highlight-color: transparent;
}

.owl-carousel .owl-nav [class*='owl-'] {
    color: #fff;
    font-size: 0;
    margin: 0;
    padding: 0;
    display: inline-block;
    cursor: pointer;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    border: 0;
    width: 35px;
    height: 35px;
}

.owl-carousel .owl-nav [class*=owl-]:hover {
    opacity: 0.5;
}

.owl-carousel .owl-nav .disabled {
    opacity: .5;
    cursor: default;
}

.owl-carousel .owl-nav .owl-prev {
    position: absolute;
    left: -35px;
    top: 50%;
    margin-top: -17px;
    background-position: 0 0;
}

.owl-carousel .owl-nav .owl-next {
    position: absolute;
    right: -35px;
    top: 50%;
    margin-top: -17px;
    background-position: -35px 0;
}

.owl-carousel .owl-dots {
    text-align: center;
    margin: 30px 0 20px;
}

.owl-carousel .owl-dots .owl-dot,
.noJS .owl-dots .owl-dot {
    display: inline-block;
    border: 2px solid transparent;
    padding: 8px;
    background: none;
    margin: 4px;
    border-radius: 30px;
    width: 26px;
    height: 26px;
    box-shadow: none !important;
}

.owl-carousel .owl-dots .owl-dot span,
.noJS .owl-dots .owl-dot a {
    width: 6px;
    height: 6px;
    margin: 0;
    background: #b8b8b8;
    display: block;
    -webkit-backface-visibility: visible;
    -webkit-transition: opacity 200ms ease;
    -moz-transition: opacity 200ms ease;
    -ms-transition: opacity 200ms ease;
    -o-transition: opacity 200ms ease;
    transition: opacity 200ms ease;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
}

.owl-carousel .owl-dots .owl-dot.active span,
.owl-carousel .owl-dots .owl-dot:hover span,
.noJS .owl-dots .owl-dot.active a {
    background: #05c1a0;
}

.owl-carousel .owl-dots .owl-dot.active {
    border-color: #05c1a0;
}

.noScript {
    display: none;
}

.no-js .owl-carousel {
    display: block;
}

.noJS .owl-controls {
    position: absolute;
    left: 0;
    bottom: -40px;
    width: 100%;
    text-align: center;
    -webkit-tap-highlight-color: transparent;
}

.noJS .owl-controls.noScript {
    display: block;
}

.noJS .sliderBanner .item {
    position: relative;
}

.noJS .carouselBlock {
    margin-right: -10px;
}

.noJS .carouselBlock .item {
    width: 220px;
    margin: 0 10px 10px 0;
    float: left;
}

.marquee {
    overflow: hidden;
}

.marqueeScrolling li {
    float: left;
    margin-right: 25px;
}

.noJS .btnMPause {
    display: none;
}

.noJS .marqueeScrolling {
    overflow-y: hidden;
    overflow-x: auto;
}

.noJS .marquee {
    width: 240%;
}

.noJS .ticker .stop {
    display: none;
}

.noJS .ticker ul {
    margin: 0;
}

.noJS .ticker .tickerDivBlock {
    height: 150px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.ui-datepicker,
.eventCalOut,
.calendarIcon {
    background: #fff;
    border: 1px solid #706cce;
}

.ui-datepicker th,
.ui-state-highlight {
    background-color: #f0f0fa;
    color: #565656 !important;
}

.ui-state-active,
.ui-datepicker tbody a:hover {
    background-color: #f0f0fa;
    color: #565656;
}

.ui-datepicker {
    color: #fff;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;
}

.ui-datepicker {
    width: 260px;
    padding: 8px;
    display: none;
}

.ui-datepicker .ui-datepicker-header {
    position: relative;
    padding: 0px 4px 10px 0px;
}

.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next {
    position: absolute;
    top: 5px;
    width: 32px;
    height: 22px;
}

.ui-datepicker .ui-datepicker-prev {
    left: 2px;
}

.ui-datepicker .ui-datepicker-next {
    right: 2px;
}

.ui-datepicker .ui-datepicker-prev span,
.ui-datepicker .ui-datepicker-next span {
    display: block;
    position: absolute;
    left: 50%;
    margin-left: -14px;
    top: 50%;
    margin-top: -8px;
    font-size: 12px;
}

.ui-datepicker .ui-datepicker-title {
    margin: 0px 35px;
    line-height: 1.8em;
    text-align: center;
}

.ui-datepicker .ui-datepicker-title select {
    font-size: 1em;
    margin: 1px 2px;
    height: 25px;
    background-color: #fff;
}

.ui-datepicker select.ui-datepicker-month-year {
    width: 100%;
}

.ui-datepicker select.ui-datepicker-month,
.ui-datepicker select.ui-datepicker-year {
    width: 46%;
    box-shadow: none;
    color: #222222;
    padding: 3px 6px;
    font-size: 14px;
}

.ui-datepicker table {
    width: 100%;
    font-size: 14px;
    border-collapse: collapse;
    margin: 0;
    border: 0 none;
}

.ui-datepicker th {
    padding: 4px;
    text-align: center;
    font-weight: bold;
    border: 0;
}

.ui-datepicker td {
    border: 0;
    padding: 1px;
    background-color: transparent;
}

.ui-datepicker tr:nth-child(2n+1) td,.ui-datepicker-calendar .tableRowOdd {
    background-color: transparent;
}

.ui-datepicker td span,
.ui-datepicker td a {
    display: block;
    padding: 4px;
    text-align: right;
    text-decoration: none;
}

.ui-datepicker .ui-datepicker-buttonpane {
    background-image: none;
    margin: 0;
    padding: 4px;
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
    overflow: hidden;
}

.ui-datepicker .ui-datepicker-buttonpane button {
    float: right;
    margin: 0;
    cursor: pointer;
    padding: 4px;
    width: auto;
    overflow: visible;
}

.eventCalOut {
    position: absolute;
    width: 222px;
    right: -222px;
    float: right;
    top: 70px;
    z-index: 22;
}

.calendarOut {
    padding: 5px;
}

.calendarOut .ui-datepicker {
    width: 100%;
}

.calendarOut .button {
    background-color: #2979ca;
    color: #fff;
    display: block;
    text-align: center;
    -webkit-border-radius: 3px 3px 3px 3px;
    border-radius: 3px 3px 3px 3px;
}

.calendarOut .button:hover {
    background-color: #173C84;
}

.ui-datepicker tbody a {
    color: #222;
}

.ui-datepicker tbody a.ui-state-active {
    color: #222222;
}

.ui-datepicker-title {
    font-size: 18px;
    font-weight: normal;
    color: #fff;
}

.ui-datepicker th {
    text-transform: uppercase;
    font-weight: normal;
    color: #fff;
    font-size: 14px;
}

.ui-datepicker-prev,
.ui-datepicker-next {
    width: 25px;
    font-size: 16px;
    height: 25px;
    display: inline-block;
    margin: 0;
    cursor: pointer;
    color: #222222;
}

.ui-datepicker-prev span {
    background-position: -147px -27px;
    overflow: hidden;
    width: 32px;
    height: 22px;
    position: static;
    margin: 5px 0 0;
}

.ui-datepicker-next span {
    background-position: -175px -27px;
    overflow: hidden;
    width: 32px;
    height: 22px;
    position: static;
    margin: 5px 0 0;
}

.ui-datepicker td span,
.ui-datepicker td a {
    text-align: center;
    line-height: 20px;
}

.ui-datepicker-trigger {
    display: none;
    width: 30px;
    height: 30px;
    margin: 8px 0 0 15px;
    vertical-align: top;
    overflow: hidden;
    background: transparent !important;
    padding: 0;
    margin: 0;
    position: absolute;
    right: -45px;
    top: 7px;
}

.ui-datepicker td:first-child,
.ui-datepicker td:first-child {
    width: 32px;
}

.calIcon {
    background-position: -205px -107px;
    width: 30px;
    height: 30px;
    display: block;
}

.ui-datepicker-calendar th:first-child,
.ui-datepicker-calendar .tableData th:first-child,
.ui-datepicker-calendar td:first-child,
.ui-datepicker-calendar .tableData td:first-child {
    width: auto;
}

.container {
    margin: 0 auto;
    width: 100%;
    max-width: 1290px;
}

#content {
    padding: 15px;
    position: relative;
}

.sideBar {
    float: left;
    background: #fff;
    z-index: 999;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: fadeInLeft;
    animation-name: fadeInLeft;
    width: 60px;
    position: fixed;
    top: 60px;
    left: 0px;
    border-top: 1px solid #e5e5e5;
    box-shadow: 5px 15px 21px #e5e5e5;
    margin-bottom: 0px;
    transition: max-width .5s ease;
    height: 100% !important;
}

@-webkit-keyframes fadeInLeft {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

}

@keyframes fadeInLeft {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

}

.rightBar {
    padding-left: 135px;
    -webkit-animation-duration: 1.5s;
    animation-duration: 1.5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
}

@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }

}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }

}

.txtCenter {
    text-align: center;
}

.txtLeft {
    text-align: left !important;
}

.txtRight {
    text-align: right !important;
}

.usrCntNav {
    float: left;
}

.usrCntNav a {
    color: inherit;
}

.syncERP,
.usrSettings,
.notificationBar {
    position: relative;
    float: left;
    padding-left: 35px;
    margin-right: 30px;
    color: #565656;
}

.headerIcon {
    display: block;
    height: 26px;
    width: 35px;
    position: absolute;
    top: 1px;
    left: 2px;
    color: #979797;
}

.syncERP,
.usrSettings {
    margin-top: 8px;
}

.syncIcon {
    background: url('https://cdn.incentx.com/assets/images/sprite.png') 0px -50px no-repeat;
}

.rightDrop {
    float: left;
}

.rightDropBtn {
    display: none;
}

.stngIcon {
    background: url('https://cdn.incentx.com/assets/images/sprite.png') -32px -50px no-repeat;
}

.downArrow {
    background: url('https://cdn.incentx.com/assets/images/sprite.png') -143px -26px no-repeat;
    width: 20px;
    height: 12px;
    display: inline-block;
}

.syncHistory {
    display: block;
    font-size: 12px;
    font-style: italic;
}

.notificationBar {
    padding-left: 0;
    margin-top: 9px;
    margin-right: 47px;
}

.notifyIcon {
    background: url('https://cdn.incentx.com/assets/images/sprite.png') -250px -115px no-repeat;
    position: relative;
    width: 45px;
    height: 45px;
}

.notiCount {
    display: block;
    position: absolute;
    top: -10px;
    right: -5px;
    text-align: center;
    line-height: 10px;
    font-size: 12px;
    background: #fe5059;
    border-radius: 50%;
    color: #fff;
    padding: 9px 4px;
    width: 26px;
    height: 26px;
}

.searchBar {
    float: right;
    margin-top: 1px;
}

.searchControl {
    position: relative;
}

input[type='text'].searchField {
    border: 1px solid #c8c8c8;
    font-size: 16px;
    color: #969696;
    padding: 14px 42px 14px 20px;
    height: auto;
    min-width: 220px;
    box-shadow: none;
}

input[type='button'].searchBtn {
    background: url('https://cdn.incentx.com/assets/images/sprite.png') no-repeat -250px -71px;
    position: absolute;
    top: 0;
    right: 0;
    width: 45px;
    height: 48px;
    box-shadow: none;
}

.contIcon {
    margin-right: 5px;
    width: 24px;
    height: 24px;
    display: inline-block;
    vertical-align: middle;
}

.userDtlToggle {
    width: 170px;
    position: absolute;
    left: 105px;
    top: 0;
    background: #706cce;
    color: #fff;
    border: 1px solid #706cce;
    z-index: 11;
    display: none;
    border-radius: 2px;
}

.userProfile.toggleOpen .userDtlToggle {
    display: block;
}

.userDtlToggle:before {
    right: 100%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.userDtlToggle:before {
    border-color: rgba(112, 108, 206, 0);
    border-bottom-color: #706cce;
    border-width: 10px;
    margin-right: -10px;
}

.userProfile {
    background: #fff;
    padding: 20px;
    font-size: 15px;
    text-align: center;
    position: relative;
    box-shadow: 5px 0px 21px #e5e5e5;
    position: relative;
    z-index: 999;
}

.userProfile h3 {
    font-size: 14px;
    color: #222222;
    font-family: 'proxima_nova semiBold';
    text-align: right;
    padding: 0;
    width: auto;
    margin-top: 2px;
    margin-bottom: 3px;
    line-height: 14px;
}

.userProfile h4 {
    font-size: 12px;
    color: #222;
    text-align: right;
    padding: 0;
    margin-bottom: 0;
    text-transform: capitalize;
    line-height: 12px;
    font-weight: 400;
    font-family: 'proxima_nova semiBold';
}

.editProfile {
    background: url('https://cdn.incentx.com/assets/images/sprite.png') -216px -24px no-repeat;
    width: 25px;
    height: 25px;
    position: absolute;
    top: 15px;
    right: 14px;
    display: block;
}

.editProfile:hover::after {
    content: '';
    width: 35px;
    height: 35px;
    position: absolute;
    left: -5px;
    top: -5px;
    border-radius: 30px;
    background: #5753bf;
    z-index: -1;
}

.userImage {
    width: 65px;
    height: 65px;
    border-radius: 50%;
    border: 3px solid #ebebeb;
    overflow: hidden;
    margin: 0 auto;
    display: block;
    cursor: pointer;
    background: url('https://cdn.incentx.com/assets/images/usr-img-default.jpg') no-repeat;
    background-size: 100%;
}

.userProfile.toggleOpen .userImage {
    border: 3px solid #6c68ac;
}

.userProfile>a {
    display: block;
    padding-bottom: 5px;
}

.userImage img {
    overflow: hidden;
    max-width: 100%;
}

.userDetail {
    float: left;
    margin: 6px 10px;
}

.usrContact {
    list-style: none;
    text-align: left;
    margin: 0;
    padding: 0;
    background: #fff;
}

.usrContact li {
    display: block;
    color: #565656;
    padding: 0px;
    font-size: 16px;
}

.usrContact li a {
    display: block;
    color: inherit;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 9px 8px 7px 8px;
    vertical-align: middle;
    line-height: 28px;
}

.usrContact li a:hover {
    background: #f0f0fa;
    color: #706cce;
}

ul.navigation {
    padding: 0 0 30px;
    margin: 0;
    list-style: none;
    text-align: center;
    position: relative;
    background: #fff;
}

ul.navigation a {
    color: #222222;
    display: block;
    padding: 15px 0px;
    text-align: left;
}

ul.navigation ul li:hover a,
ul.navigation ul.subMenu li.activate a,
ul.navigation ul li:hover a:hover {
    color: #565656;
    background: #f0f0fa;
}

ul.navigation li:last-child a {
    border: none;
}

ul.navigation>li {
    position: relative;
    padding: 20px;
}

ul.navigation>li::after {
    content: '';
    width: 60px;
    left: 50%;
    margin-left: -30px;
    border-bottom: 1px solid #cbcbcb;
    position: absolute;
    height: 1px;
    bottom: 0;
}

ul.navigation>li:first-child::after {
    display: none;
}

ul.navigation>li:hover .subMenu {
    opacity: 1;
    visibility: visible;
}

.subMenu::after {
    width: 0;
    height: 0;
    border-top: 9px solid transparent;
    border-right: 15px solid #706cce;
    border-bottom: 9px solid transparent;
    content: '';
    position: absolute;
    left: -15px;
    top: 16px;
}

ul.navigation ul.subMenu {
    padding: 0;
    margin: 0;
    position: absolute;
    top: 20px;
    left: 100%;
    list-style: none;
    width: auto;
    border: 1px solid #706cce;
    z-index: 11;
    -webkit-transition: opacity 0.8s ease-in-out;
    -moz-transition: opacity 0.8s ease-in-out;
    -ms-transition: opacity 0.8s ease-in-out;
    -o-transition: opacity 0.8s ease-in-out;
    transition: opacity 0.8s ease-in-out;
    opacity: 0;
    visibility: hidden;
}

ul.navigation ul.subMenu>li {
    white-space: nowrap;
}

ul.navigation ul.subMenu li a {
    background: #fff;
    color: #565656;
    padding: 13px 15px;
}

ul.navigation ul.subMenu li:first-child a {
    background: #706cce;
    color: #fff;
    position: relative;
    z-index: 1;
}

ul.navigation ul.subMenu li:first-child a::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 0;
    background: #5753bf;
    z-index: -1;
}

ul.navigation ul.subMenu:hover li:first-child a::before,
ul.navigation li.activate ul.subMenu li:first-child a::before {
    width: 100%;
    -webkit-transition: 600ms ease;
    transition: 600ms ease;
}

.subMenu:hover::after,
li.activate .subMenu::after {
    border-right-color: #5753bf;
    -webkit-transition: 100ms ease;
    transition: 100ms ease;
}

ul.navigation li:hover .navIcon1,
ul.navigation li.activate .navIcon1,
ul.navigation li.activePage .navIcon1,
.navIcon1:hover {
    background-position: -50px 0px;
}

ul.navigation li:hover .navIcon2,
ul.navigation li.activate .navIcon2,
ul.navigation li.activePage .navIcon2,
.navIcon2:hover {
    background-position: -50px -50px;
}

ul.navigation li:hover .navIcon3,
ul.navigation li.activate .navIcon3,
ul.navigation li.activePage .navIcon3,
.navIcon3:hover {
    background-position: -50px -100px;
}

ul.navigation li:hover .navIcon4,
ul.navigation li.activate .navIcon4,
ul.navigation li.activePage .navIcon4,
.navIcon4:hover {
    background-position: -50px -150px;
}

ul.navigation li:hover .navIcon5,
ul.navigation li.activate .navIcon5,
ul.navigation li.activePage .navIcon5,
.navIcon5:hover {
    background-position: -50px -200px;
}

ul.navigation li:hover .navIcon6,
ul.navigation li.activate .navIcon6,
ul.navigation li.activePage .navIcon6,
.navIcon6:hover {
    background-position: -50px -250px;
}

ul.navigation li:hover .navIcon7,
ul.navigation li.activate .navIcon7,
ul.navigation li.activePage .navIcon7,
.navIcon7:hover {
    background-position: -50px -300px;
}

ul.navigation li:hover .navIcon8,
ul.navigation li.activate .navIcon8,
ul.navigation li.activePage .navIcon8,
.navIcon8:hover {
    background-position: -50px -350px;
}

ul.navigation li:hover .navIcon9,
ul.navigation li.activate .navIcon9,
ul.navigation li.activePage .navIcon9,
.navIcon9:hover {
    background-position: -50px -400px;
}

ul.navigation li:hover .navIcon10,
ul.navigation li.activate .navIcon10,
ul.navigation li.activePage .navIcon10,
.navIcon10:hover {
    background-position: -50px -450px;
}

ul.navigation li:hover .navIcon11,
ul.navigation li.activate .navIcon11,
ul.navigation li.activePage .navIcon11,
.navIcon11:hover {
    background-position: -50px -500px;
}

ul.navigation li:hover .navIcon12,
ul.navigation li.activate .navIcon12,
ul.navigation li.activePage .navIcon12,
.navIcon12:hover {
    background-position: -50px -550px;
}

ul.navigation li:hover .navIcon13,
ul.navigation li.activate .navIcon13,
ul.navigation li.activePage .navIcon13,
.navIcon13:hover {
    background-position: -50px -600px;
}

.formList {
    list-style: none;
    padding: 0;
    margin: 0;
}

.formList li {
    padding: 0 0 15px;
    margin: 0;
}

.required,
.formList .error {
    color: #C00;
}

.formList .error {
    display: block;
    font-size: 12px;
}

.formList .textBoxOut {
    margin-bottom: 5px;
}

.formList label,
.formList .label {
    color: #333;
    display: block;
    font-weight: normal;
    padding-bottom: 7px;
}

.radioList input {
    float: left;
    margin: 5px;
    clear: left;
}

.radioList label,
.radioList .label {
    float: left;
    color: #555;
}

.note {
    font-size: 12px;
    font-style: italic;
    padding: 5px 0 0;
}

.captcha {
    position: relative;
}

.captcha .captchaImg {
    border: 1px solid #d7d7d7;
    float: left;
    max-height: 33px;
}

.captcha .refreshCaptcha {
    float: left;
    margin: 5px 0 0 10px;
}

.fixedErrorMsg {
    background-color: rgba(233, 48, 30, 0.92);
    color: #fff;
    text-align: center;
    padding: 20px 20px;
    font-size: 15px;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 99999;
    width: 100%;
    text-transform: uppercase;
    display: none;
}

.fixedSuccessMsg {
    background-color: rgba(20, 155, 65, 0.92);
    color: #fff;
    text-align: center;
    padding: 20px 20px;
    font-size: 15px;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 99999;
    width: 100%;
    text-transform: uppercase;
    display: none;
}
.navigation-trigger {
    float: left;
    margin: 19px 20px;
    padding: 0 0;
    cursor: pointer;
    display: none;
}

.menu-icon {
    cursor: pointer;
    content: "\e968";
    color: #767676;
    font-family: 'IXPORTAL';
    width: 30px;
    height: 30px;
    display: inline-block;
    vertical-align: top;
}

#logo .trialCnt .trialStampImg {
    position: relative;
    right: auto;
    left: auto;
    top: auto;
    bottom: auto;
    margin: 0;
    padding: 0;
    width: 100%;
    height: auto;
}

.trialCnt {
    position: absolute;
    width: 62px;
    right: -80px;
    top: 0px;
}

.rightTop {
    float: right;
}

.titleRow h1 {
    font-size: 22px;
    color: #222222;
    font-weight: 600;
    display: inline-block;
    line-height: 42px;
    margin-bottom: 10px;
}

.titleRow .titleBtnCol {
    float: right;
    margin-bottom: 0px;
}

.sprite {
    background: url('https://cdn.incentx.com/assets/images/sprite.png');
    background-repeat: no-repeat;
    background-position: 0 0;
}

.button,
input[type='submit'],
input[type='button'],
input[type='reset'],
button,
.button {
    background: #21b3c6;
    border: 0;
    font-size: 18px;
    font-family: 'proxima_nova semiBold';
    padding: 9px 16px;
    position: relative;
    line-height: 24px;
    height: 42px;
}

textarea,
select,
input.text,
input[type='text'],
input[type='reset'],
input[type='button'],
input[type='submit'],
.input-checkbox {
    border-radius: 0 !important;
}

.tabbing {
    overflow-x: auto;
    display: inline-block;
    width: 100%;
    margin-top: -15px;
}

.tabbing ul {
    list-style-type: none;
    padding: 0;
    border-bottom: 2px solid #e6e6e6;
    float: left;
    margin-bottom: 15px;
    white-space: nowrap;
    min-width: 100%;
}

.tabbing ul li {
    position: relative;
    display: inline-block;
    text-decoration: none;
    color: #767676;
    padding: 0px;
    outline: 0;
    margin-right: 25px;
    margin-top: 15px;
    font-size: 18px;
    cursor: pointer;
    white-space: nowrap;
}

.tabbing ul li a {
    color: #767676;
    padding: 0 0 10px;
    display: inline-block;
    vertical-align: top;
}

.tabbing ul li.active a {
    color: #706cce !important;
}

.tabbing ul li.active:after {
    content: '';
    background: #706cce !important;
    height: 4px;
    bottom: 0px;
    position: absolute;
    left: 0;
    right: 0;
}

.filter {
    position: relative;
    padding-right: 94px;
    display: inline-block;
    width: 100%;
    margin-bottom: 30px;
    margin-top: 10px;
    vertical-align: top;
}

.filter .button {
    position: absolute;
    right: 0;
    top: 0;
}

.largeCol,
.midiumCol {
    padding-right: 20px;
}

.filter .col {
    float: left;
}

.filter .largeCol {
    width: 56%;
}

.filter .midiumCol {
    width: 22%;
}

.filter .exMidiumCol {
    padding-right: 20px;
    width: 29.3%;
}

.filter .smLargeCol {
    padding-right: 20px;
    width: 41.38%;
}

.datePicker {
    position: relative;
}

.datePicker .iconCal {
    width: 24px;
    height: 24px;
    float: left;
    margin-right: 10px;
    background-position: -32px 0px;
    position: absolute;
    right: 20px;
    top: 9px;
}

input-placeholder {
    color: #8f8f8c;
    opacity: 1;
}

::-webkit-input-placeholder {
    color: #8f8f8c;
    opacity: 1;
}

:-moz-placeholder {
    color: #8f8f8c;
    opacity: 1;
}

::-moz-placeholder {
    color: #8f8f8c;
    opacity: 1;
}

:-ms-input-placeholder {
    color: #8f8f8c;
    opacity: 1;
}

.borderBox {
    float: left;
    width: 100%;
    padding: 23px 25px;
    background: #fff;
    box-shadow: 5px 0px 21px #e5e5e5;
    margin-bottom: 20px;
}

.contentRow>.borderBox:last-child {
    margin-bottom: 0;
}

.filter .feildCol {
    min-height: 55px;
}

h2 {
    font-size: 18px;
    color: #222222;
    font-family: 'proxima_nova semiBold';
    display: inline-block;
}

.tableEntries {
    margin-top: 0px;
}

.tableEntries .totalShow {
    display: inline-block;
    font-size: 15px;
    color: #939393;
}

.tableEntries .pagination {
    padding: 0;
    float: right;
    width: auto;
    border-bottom: 0;
    margin: 0;
    list-style-type: none;
}

.tableEntries .pagination li {
    float: left;
    padding: 0;
    border-right: 1px solid #bbbbbb;
}

.tableEntries .pagination li:first-child,
.tableEntries .pagination li:last-child,
.tableEntries .pagination li:nth-child(4) {
    border: 0;
}

.tableEntries .pagination li:first-child a,
.tableEntries .pagination li:last-child a {
    padding: 0px;
}

.tableEntries .pagination a {
    display: block;
    padding: 0px 10px;
    font-size: 15px;
    color: #43525b;
}

.tableEntries .pagination a:hover,
.tableEntries .pagination a.active {
    font-weight: 600;
    color: #706cce;
}

.iconPre,
.iconNext {
    width: 13px;
    height: 15px;
    float: left;
    margin-right: 0;
    background-position: 0px -26px;
    margin-top: 1px;
}

.iconNext {
    background-position: -12px -26px;
    margin-top: 1px;
}

.listInd {
    border-bottom: 0;
    margin: 0;
    list-style-type: none;
    float: right;
}

.listInd li {
    float: left;
    padding-right: 20px;
}

.listInd li span {
    display: inline-block;
    width: 10px;
    height: 10px;
    background: #38b1eb;
    margin-right: 3px;
    vertical-align: middle;
    margin-bottom: 3px;
    border-radius: 50%;
}

.listInd li.terList span {
    background: #f99300;
}

.listInd li:last-child {
    padding-right: 0px;
}

.listInd .terList span {
    background: #a5a5a5;
}

.widBorder {
    border-bottom: 1px solid #e6e6e6;
    margin-bottom: 40px;
}

.formControls {
    position: relative;
    float: left;
    width: 100%;
    margin-bottom: 15px;
}

.formControls .largeCol {
    width: 56%;
}

.formControls .midiumCol {
    width: 22%;
}

.formControls .largeCol,
.formControls .midiumCol,
.formControls .exlargeCol,
.formControls .smallCol,
.fullCol {
    padding: 0 15px;
    float: left;
}

.inputField {
    position: relative
}

.inputField label {
    position: absolute;
    bottom: 31px;
    left: 8px;
    background: #fff;
    padding: 3px 5px;
    font-size: 14px;
    transition: all 0.2s;
    max-width: calc(100% - 20px);
    line-height: 16px;
}

.formControls .inputContols {
    margin-bottom: 35px;
}

.exlargeCol .smallCol {
    float: left;
}

.formControls .exlargeCol {
    width: 78%;
}

.formControls .smallCol {
    width: 22%;
}

.radioGroup label {
    font-size: 17px;
    color: #222222;
    padding-right: 25px;
    padding-left: 30px;
}

.radioGroup>label {
    line-height: 15px;
    padding: 0;
    font-size: 14px;
    color: #999;
    margin: -7px 0 8px;
    display: block;
    width: 100%;
}

.radioGroup {
    position: relative;
    padding-top: 10px;
}

.fullCol {
    width: 100%;
}

.multiBtnsRow .titleText {
    display: inline-block;
    color: #7c7c7c;
    font-size: 17px;
}

.multiBtnsRow .btnsGroup {
    float: right;
}

.multiBtnsRow .button {
    margin-left: 15px;
    border: 1px solid;
    font-weight: normal;
}

.multiBtnsRow .button:first-child {
    margin-left: 0;
}

.multiBtnsRow {
    padding-bottom: 0;
    margin-top: 10px;
    display: inline-block;
    width: 100%;
}

.inputContols.mrgB0 {
    margin-bottom: 0;
}

.iconInfo,
.iconDelete {
    width: 20px;
    height: 20px;
    display: inline-block;
    margin-right: 0;
    background-position: -25px -25px;
    vertical-align: middle;
}

.iconInfo {
    margin-left: 10px;
    position: absolute;
    left: 45px;
    width: 17px;
    top: 10px;
}

.iconInfo:hover {
    background-position: -118px -25px;
}

.serchBox {
    position: relative
}

.serchBox input[type='text'] {
    padding-right: 45px;
}

.serchBox button {
    position: absolute;
    right: 0;
    top: 0;
    background-color: transparent;
    margin: 0;
    padding: 0;
    box-shadow: none;
    width: 42px;
}

.serchBox .iconSearch {
    width: 24px;
    height: 22px;
    float: right;
    margin-right: 10px;
    background-position: -259px -46px;
    box-shadow: none;
}

.statisticBlock {
    float: left;
    width: 100%;
    margin-bottom: 5px;
}

.statisticBlock .numberBlock {
    background: #fff;
    padding: 15px 0;
    color: #767676;
    box-shadow: 5px 0px 21px #e5e5e5;
    height: 100%;
    min-height: 105px;
}

.numberBlock strong {
    display: block;
    font-size: 26px;
    font-weight: normal;
    line-height: 36px;
    color: #222222;
}

.contractIcon,
.customerIcon,
.salesPersonIcon {
    display: inline-block;
    vertical-align: middle;
    width: 85px;
}

.blockText {
    display: inline-block;
    vertical-align: top;
    margin-top: 8px;
    font-size: 18px;
}

.reportsSection {
    float: left;
    width: 100%;
}

.quarterlyReport,
.revenueProduct {
    box-shadow: 5px 0px 21px #e5e5e5;
}

.quarterlyReport img,
.revenueProduct img {
    display: block;
    width: 100%;
}

.salesPerformanceSection {
    float: left;
    width: 100%;
}

.agreementList tr.terList td:first-child,
.agreementList tr td:first-child {
    padding-left: 0 !important;
}

.agreementList tr td:first-child:before {
    width: 4px;
    height: 30px;
    margin-top: 0;
    background: #38b1eb;
    content: '';
    float: left;
}

.agreementList tr.terList td:first-child:before {
    width: 4px;
    height: 22px;
    margin-top: 0;
    background: #f99300;
    content: '';
}

.agreementList tr td a {
    display: inline-block;
    margin: 0 auto;
}

.viewAllBtn {
    float: right;
    margin-left: 25px;
    color: #706cce;
    text-decoration: underline;
}

.rightArrow {
    background: url('https://cdn.incentx.com/assets/images/sprite.png') -200px -25px no-repeat;
    width: 20px;
    height: 20px;
    display: inline-block;
    vertical-align: middle;
    margin-left: 8px;
}

.latestNews {
    background: #fff;
    padding: 25px 30px;
    color: #767676;
    box-shadow: 5px 0px 21px #e5e5e5;
}

.customerSection {
    background: #fff;
    padding: 25px 30px 5px 30px;
    color: #767676;
    box-shadow: 5px 0px 21px #e5e5e5;
}

.newsDate {
    background: #fff;
    border-radius: 8px;
    width: 55px;
    height: 55px;
    text-align: center;
    font-family: 'proxima_nova semiBold';
    font-size: 18px;
    color: #222222;
    box-shadow: 5px 0px 21px #e5e5e5;
    line-height: 30px;
    float: left;
}

.newsTitle {
    float: right;
    width: calc(100% - 70px);
}

.newsMonth {
    background: #2ABBCE;
    background: -moz-linear-gradient(left, #2ABBCE 0%, #569BE6 52%, #837BFD 100%);
    background: -webkit-linear-gradient(left, #2ABBCE 0%, #569BE6 52%, #837BFD 100%);
    background: linear-gradient(to right, #2ABBCE 0%, #569BE6 52%, #837BFD 100%);
    display: block;
    color: #fff;
    border-radius: 0px 0px 8px 8px;
    line-height: 26px;
}

ul.newsListing {
    list-style: none;
    padding: 0;
    margin: 0;
    height: 338px;
    overflow: auto;
}

ul.newsListing li {
    display: table;
    clear: both;
    padding: 14px 0;
    width: 100%;
}

ul.newsListing li a {
    display: block;
    color: #222222;
}

.customerSection h2 {
    line-height: 38px;
}

.customerSection .dateFilter {
    float: right;
    max-width: 320px;
}

.customerSection .dateFilter .datePicker {
    float: left;
    width: 50%;
    padding-left: 6%;
}

.customerSection .dateFilter .datePicker .iconCal {
    right: 0;
}

.custRight {
    float: right;
}

.customerSection .customerTabbing {
    width: auto;
    float: left;
    margin-top: -10px;
    margin-right: 10px;
}

.customerSection .customerTabbing li:last-child {
    margin-right: 0;
}

.customerSection ul.buyListing {
    list-style: none;
    padding: 0 15px;
    margin: 0;
    height: 310px;
    overflow: auto;
}

.customerSection ul.buyListing h3 {
    font-size: 18px;
    color: #222;
    font-family: 'proxima_nova semiBold';
    margin-bottom: 5px;
}

.customerSection ul.buyListing li {
    padding-top: 12px;
    padding-bottom: 10px;
    border-bottom: 1px solid #e1e1e1;
    display: table;
    clear: both;
    width: 100%;
}

.customerSection .customerTabbing ul {
    border: none;
    color: #222222;
    margin-bottom: 20px;
}

.customerSection .custLabel {
    color: #969696;
    font-size: 15px;
    display: block;
}

.customerSection .custDate {
    color: #21b3c6;
}

.filter input[type='text'],
.filter input[type='email'],
.filter input[type='tel'],
.filter input[type='number'],
.filter input[type='url'],
.filter input[type='password'],
.filter input[type='search'],
.filter select,
.filter textarea,
.filter .mat-form-field-flex {
    background: #fff;
    border: 1px solid #c8c8c8;
}

.borderBox .filter input[type='text'],
.borderBox .filter input[type='email'],
.borderBox .filter input[type='tel'],
.borderBox .filter input[type='number'],
.borderBox .filter input[type='url'],
.borderBox .filter input[type='password'],
.borderBox .filter input[type='search'],
.borderBox .filter select,
.borderBox .filter textarea,
.borderBox .mat-form-field-flex {
    border-color: #e5e5e5;
}

.button.filterSearch {
    background: #969696;
    box-shadow: 5px 0px 21px #e3e3e3;
}

.button.filterSearch:hover {
    background-color: #868686;
}

.highlight {
    color: #706cce !important;
}

.stlmntTime {
    color: #222;
}

.custLogo {
    width: 76px;
    height: 76px;
    box-shadow: 5px 0px 21px #f0f0f0;
    border-radius: 50%;
    padding: 5px;
    text-align: center;
    position: absolute;
    top: 15px;
    left: 5px;
}

.customerListing ul {
    padding: 0;
    margin: 0px 0px 20px 0px;
    list-style: none;
}

.customerListing ul li {
    padding: 15px 40px 15px 90px;
    border-bottom: 1px solid #dadada;
    clear: both;
    display: table;
    width: 100%;
    position: relative;
}

.customerListing ul li a,
.customerListing ul li .custDtl {
    color: inherit;
    position: relative;
    padding-left: 28px;
}

.contListingBox {
    padding: 10px 15px !important;
    margin: 0px 0px 30px 0px;
    list-style: none;
    border: 1px solid transparent;
}

.hoverClr {
    cursor: pointer;
}

.contListing {
    padding: 15px 0 15px 105px;
    clear: both;
    width: 100%;
    position: relative;
    min-height: 105px;
}

.contListing .customerDetail .cols12 {
    padding: 0;
}

.cpListing .icoOut {
    margin: 5px 0 0;
}

.contListing a,
.contDtl .icoOut>span,
.contListing .custDtl {
    color: inherit;
    position: relative;
    padding-left: 28px;
    display: inline-block;
}

.cpListing {
    min-height: 170px;
}

.cols6 .cpListing {
    min-height: 135px;
}

.billAddress .cols6:nth-child(2n+1) {
    clear: both;
}

.cols6 .custCntBx {
    min-height: 170px;
    margin: 0 0 30px;
}

.locationIcon {
    background: url('https://cdn.incentx.com/assets/images/sprite.png') -230px -50px no-repeat;
    width: 25px;
    height: 25px;
    display: inline-block;
    vertical-align: middle;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
}

.customerListing ul li a.actionDlt {
    background: url('https://cdn.incentx.com/assets/images/sprite.png') -52px -27px no-repeat;
    width: 15px;
    height: 17px;
    display: inline-block;
    position: absolute;
    top: 40%;
    right: 0px;
    padding: 0;
}

.customerDetail h4 {
    color: #21b3c6;
    font-size: 16px;
    margin: 0;
}

.customerDetail h2 {
    color: #222222;
    font-family: 'proxima_nova semiBold';
    font-size: 20px;
    margin-bottom: 5px;
    line-height: 21px;
}

.gridSearch {
    position: relative;
}

.serachTable.tableOut {
    max-height: 410px;
    overflow: auto;
    margin: 0;
}

.searchResultSection {
    position: absolute;
    padding: 0px;
    top: 100%;
    width: 100%;
    background: #fff;
    z-index: 11111;
    display: none;
}

.searchResultSection.showIt {
    display: block;
}

.searchResultSection table th,
.searchResultSection table td {
    padding: 8px 15px;
}

.searchResultBox table {
    border: none;
    margin-bottom: 0;
}

.searchResultBox {
    border: 1px solid #21b3c6;
    clear: both;
    width: 100%;
}

.borderBox.padB0 {
    padding-bottom: 0;
}

.inputContols.marB10 {
    margin-bottom: 10px;
}

.form-group {
    display: block;
}

.form-group input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}

.form-group label {
    position: relative;
    cursor: pointer;
    color: #706cce;
    font-size: 17px;
}

.form-group label:before {
    content: '';
    -webkit-appearance: none;
    background-color: transparent;
    border: 1px solid #c8c8c8;
    padding: 10px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 12px;
    border-radius: 2px;
}

.form-group input:checked+label:before {
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 2px;
}

.form-group input:checked+label:after {
    content: '';
    display: block;
    position: absolute;
    top: 4px;
    left: 7px;
    width: 8px;
    height: 14px;
    border: solid #706cce;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.radio input[type='radio'] {
    position: absolute;
    opacity: 0;
}

.radio input[type='radio']+.radio-label:before {
    content: '';
    background: #fff;
    border-radius: 100%;
    border: 1px solid #b4b4b4;
    display: inline-block;
    width: 23px;
    height: 23px;
    position: relative;
    top: 0px;
    margin-right: 8px;
    vertical-align: top;
    cursor: pointer;
    text-align: center;
    margin-left: -30px;
}

.radio input[type='radio']:checked+.radio-label:before {
    background: #706cce;
    box-shadow: inset 0 0 0 7px #fff;
    border: 1px solid #706cce;
}

.radio input[type='radio']:focus+.radio-label:before {
    outline: none;
}

.radio input[type='radio']:disabled+.radio-label:before {
    box-shadow: inset 0 0 0 10px #f4f4f4 !important;
    border-color: #b4b4b4;
    cursor: auto;
}

.radio input[type='radio']:disabled:checked+.radio-label:before {
    box-shadow: inset 0 0 0 6px #f4f4f4 !important;
}

.radio input[type='radio']+.radio-label:empty:before {
    margin-right: 0;
}

.checkboxGroup label {
    font-size: 17px;
    color: #222222;
    padding-right: 25px;
}

.checkboxGroup>label {
    line-height: 15px;
    padding: 0;
    font-size: 14px;
    color: #999;
    margin: -7px 0 8px;
    display: block;
    width: 100%;
}

.checkBox>label:last-child {
    padding-right: 0 !important;
}

.dataTables_length,
.dataTables_filter,
.dataTables_info,
.dataTables_paginate {
    display: none;
}

.dataTables_scrollHeadInner,
.dataTables_wrapper.no-footer div.dataTables_scrollHead table.dataTable {
    width: 100% !important;
}

.signUpForm {
    margin-top: 35px;
}

.signUpContainer {
    position: relative;
}

.signUpContainer:after {
    position: absolute;
    height: 20px;
    width: 90%;
    bottom: -20px;
    left: 5%;
    background: #f4f4f4;
    content: '';
    border-radius: 0px 0px 6px 6px;
}

.loginScreens {
    background: #eeeeee url('https://cdn.incentx.com/assets/images/1_Login_BG.png') no-repeat top center;
    background-attachment: fixed;
    background-size: cover;
}

.loginScreens {
    width: 100%;
    height: 100%;
    position: relative;
    margin: 0;
    padding: 0;
    vertical-align: top;
    display: inline-block;
}

.signUpContainer {
    background: #fff;
    max-width: 670px;
    width: 90%;
    margin: 40px auto;
    padding: 35px 40px;
    border-radius: 6px;
}

.regTitles {
    text-align: center;
}

.regTitles h3 {
    font-family: 'proxima_nova semiBold';
    font-size: 26px;
    color: #706cce;
    margin-bottom: 8px;
}

.regTitles h4 {
    font-family: 'proxima_nova semiBold';
    font-size: 18px;
    color: #767676;
    margin-bottom: 8px;
}

.regNote {
    background: #f0f0fa;
    border: 1px solid #706cce;
    font-size: 15px;
    line-height: 20px;
    color: #706cce;
    padding: 15px 20px 15px 65px;
    position: relative;
    margin: 10px 0px;
}

.regIcon {
    background: url('https://cdn.incentx.com/assets/images/reg-note-icon.png') no-repeat top center;
    width: 34px;
    height: 34px;
    position: absolute;
    left: 20px;
    top: 9px;
}

.signUpForm .formControls>.row {
    margin-bottom: 40px;
}

.signUpForm .widBorder {
    margin-bottom: 20px;
}

.button.payBtn {
    font-size: 22px;
    font-family: 'proxima_nova semiBold';
    padding: 15px 20px;
}

.signUpTextarea {
    height: 130px;
}

.cardImages,
.cardImages .dx-texteditor-input {
    background: url('https://cdn.incentx.com/assets/images/visa-card.jpg') no-repeat 95% 12px;
}

.cardVisaImages,
.cardVisaImages .dx-texteditor-input {
    background: url('https://cdn.incentx.com/assets/images/visa-card.jpg') no-repeat 95% 15px;
}

.cardMasterImages,
.cardMasterImages .dx-texteditor-input {
    background: url('https://cdn.incentx.com/assets/images/mster-card.png') no-repeat 95% 8px;
}

.cardAmericanExpressImages,
.cardAmericanExpressImages .dx-texteditor-input {
    background: url('https://cdn.incentx.com/assets/images/american-express.png') no-repeat 95% 8px;
}

.cardDiscoverImages,
.cardDiscoverImages .dx-texteditor-input {
    background: url('https://cdn.incentx.com/assets/images/discover.png') no-repeat 95% 8px;
}

.cardJCBImages,
.cardJCBImages .dx-texteditor-input {
    background: url('https://cdn.incentx.com/assets/images/jcb.png') no-repeat 95% 8px;
}

.cardDinerClubImages,
.cardDinerClubImages .dx-texteditor-input {
    background: url('https://cdn.incentx.com/assets/images/diners-club.png') no-repeat 95% 8px;
}

.loginContainer {
    max-width: 460px;
    width: 90%;
    background: #fff;
    margin: 0 auto;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    top: 50%;
    position: relative;
    padding: 30px 30px;
    border-radius: 6px;
}

.loginInput {
    padding-left: 50px !important;
}

.loginPasInput {
    padding-left: 50px !important;
    padding-right: 50px !important;
}

.loginForm {
    padding-top: 20px;
}

.loginForm .payBtn {
    margin-top: 20px;
    font-family: proxima_nova semiBold !important;
    font-size: 22px !important;
    padding: 15px 20px !important;
    height: auto !important;
}

.forgotRow .cols6 {
    margin-top: 10px;
    width: auto !important;
}

.forgotRow .cols6.txtRight {
    float: right;
}

.forgotPass {
    font-size: 16px;
    text-decoration: underline;
    color: #706cce;
    line-height: 24px;
}

.loginContainer:after {
    position: absolute;
    height: 20px;
    width: 90%;
    bottom: -20px;
    left: 5%;
    background: #f4f4f4;
    content: '';
    border-radius: 0px 0px 6px 6px;
}

.otpInput {
    position: relative;
}

.otpInput input {
    border: none;
    box-shadow: none !important;
    width: 41px;
    display: inline-block;
    display: inline-block;
    padding: 0 !important;
    border-bottom: 1px solid #d7d7d7;
    text-align: center;
    margin: 2px 5px;
    font-size: 20px;
    height: 36px !important;
}

.optTime {
    font-size: 14px;
    position: absolute;
    top: 18px;
    right: 20px;
    color: #6e6aaf;
    font-family: 'proxima_nova semiBold';
    visibility: hidden;
}

.rsndPin {
    text-decoration: underline;
    color: #565656;
    margin-top: 5px;
    display: inline-block;
}

.otpInput {
    box-shadow: 5px 0px 21px #e5e5e5;
    padding: 10px 20px 10px 20px;
    text-align: center;
}

html,
body {
    height: 100%;
    min-height: 100%;
}

#wrapper {
    height: 100%;
    min-height: 100%;
}

.signupSec {
    height: auto !important;
}

.loginScreens.signUpPage {
    height: auto !important;
    float: left;
    width: 100%;
    min-height: 100%;
}

.navIcon .tooltiptext {
    visibility: hidden;
    width: 140px;
    background-color: #555;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    top: -40px;
    left: 50%;
    margin-left: -70px;
    opacity: 0;
    transition: opacity 0.3s;
}

.navIcon .tooltiptext::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

.navIcon:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}

.timeline-tab .titleRow h1 {
    font-size: 18px;
    color: #767676;
    font-family: 'proxima_nova';
    font-weight: 100;
    margin-top: 15px;
}

.eventTime {
    display: block;
    font-size: 15px;
    color: #767676;
    margin-top: 5px;
}

.timeline-tab .newsTitle {
    font-size: 16px;
    color: #222222;
}

.timeline-tab .newsTitle h3 {
    color: #706cce;
    font-size: 15px;
    margin-bottom: 4px;
    line-height: 16px;
    display: block;
}

.clockIcon::before {
    width: 15px;
    height: 15px;
    display: inline-block;
    vertical-align: top;
    margin-right: 5px;
    content: "\e996";
    color: #9f9f9f;
    font-family: 'IXPORTAL';
}

.tlTime {
    vertical-align: top;
    display: inline-block;
    line-height: 15px;
}

.eventTime .clockIcon {
    margin-left: 5px;
}

.timeline-tab .newsListing {
    margin-bottom: 20px;
}

.timeline-tab .newsListing li {
    padding-top: 8px;
}

.personalInfo {
    position: relative;
}

.contactDetailBlock {
    background: #fff;
    min-height: 230px;
}

.contactDetailBlock .personalInfo {
    clear: both;
    display: table;
    width: 100%;
    margin-bottom: 20px;
    border-bottom: 2px solid #ededee;
    padding-bottom: 20px;
}

.contactDetailBlock .blockTitle {
    font-family: 'proxima_nova semiBold';
    font-size: 20px;
    color: #222222;
    margin: 0px 0px 20px 0px;
    display: block;
}

.contactDetailBlock .blockText {
    float: left;
    max-width: 70%;
    margin-top: 0;
}

.contactDetailBlock .contractIcon {
    float: left;
    margin-right: 15px;
}

.contactDetailBlock h2 {
    font-family: 'proxima_nova semiBold';
    font-size: 18px;
    color: #706cce;
    margin-bottom: 6px;
    display: block;
}

.contactDetailBlock h2 span,
.contactDetailBlock .blockText {
    font-family: 'proxima_nova';
    font-size: 16px;
    color: #565656;
}

.contUrl {
    display: block;
    margin-bottom: 5px;
    position: relative;
}

.blockText a {
    position: relative;
    padding-left: 30px;
    color: inherit;
}

.addressCol {
    padding-left: 25px;
}

.notesListing {
    padding: 0;
    margin: 0;
    list-style: none;
    max-height: 330px;
    overflow: auto;
}

.noteImage {
    max-width: 50px;
    margin-right: 15px;
    float: left;
}

span.notesDetail {
    float: left;
    width: calc(100% - 80px);
    font-size: 16px;
    color: #222;
}

.noteInfo {
    font-size: 15px;
    color: #767676;
}

.noteUser {
    color: #21b3c6;
}

.noteInfor {
    display: block;
}

.notesListing li {
    padding: 15px 5px;
    border-bottom: 2px solid #ededee;
    clear: both;
    display: table;
    width: 100%;
}

.noteControl .editIcon,
.editIcon {
    background: url('https://cdn.incentx.com/assets/images/sprite.png') -128px 2px no-repeat;
    width: 25px;
    height: 25px;
    display: inline-block;
    vertical-align: middle;
}

.noteControl {
    display: inline-block;
    vertical-align: middle;
    float: right;
}

.taskContainer {
    color: #222222;
    width: 100%;
}

.noBdr {
    border: none !important;
}

.itemTask {
    margin-bottom: 30px;
}

.mat-menu-panel {
    border: 1px solid #706cce;
    border-radius: 0 !important;
}

.mat-menu-item {
    padding: 2px 16px;
}

button.mat-menu-item,
.mat-menu-item {
    box-shadow: none;
    color: #565656;
    padding: 2px 35px 2px 18px;
}

.mat-menu-item:hover {
    background: #f0f0fa;
    color: #565656;
}

.mat-menu-content:not(:empty) {
    padding: 0 !important;
}

.actionBtn .svg {
    margin-left: 5px;
    vertical-align: middle;
}

.button.actionBtn {
    padding: 12px 25px;
}

.button.actionBtn .svg path.cls-1 {
    color: #fff !important;
    fill: #fff !important;
}

.mat-form-field-flex {
    border: 1px solid #e5e5e5;
    width: 100%;
    height: 42px;
    padding: 13px 15px;
    font-size: 16px;
    font-family: 'proxima_nova semiBold';
}

.mat-form-field-flex * {
    padding: 0;
    margin: 0;
    border: 0;
    font-size: 15px;
    font-family: 'proxima_nova semiBold';
    line-height: 28px;
}

input.mat-input-element {
    margin-top: 0;
}

.mat-form-field-label-wrapper {
    position: relative;
    padding: 0;
    top: 0 !important;
    margin-top: 0;
    padding-top: 0 !important;
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
    padding-bottom: 0 !important;
}

.mat-form-field-appearance-legacy .mat-form-field-infix {
    padding: 0 !important;
    border-top: 0 !important;
    max-width: 100% !important;
    width: 100%;
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
    display: none;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
    padding-top: 0;
    top: 0;
}

.mat-form-field._mat-animation-noopable .mat-form-field-label {
    display: none !important;
}

.mat-calendar-controls button {
    box-shadow: none;
}

.tableReports tr td:first-child {
    min-width: 200px;
}

.mat-form-field-flex {
    align-items: center !important;
}

.mat-datepicker-toggle ._mat-animation-noopable.mat-icon-button {
    background: url('https://cdn.incentx.com/assets/images/sprite.png') -52px -27px no-repeat;
    width: 24px;
    height: 24px;
    float: left;
    background-position: -32px 0px;
    border-radius: 0;
    box-shadow: none;
}

.mat-datepicker-toggle ._mat-animation-noopable.mat-icon-button * {
    display: none;
}

.mat-form-field {
    line-height: inherit !important;
}

.mat-paginator-container {
    min-height: inherit !important;
    margin-top: 5px;
}

.mat-sort-header-button {
    height: auto;
}

.ytdTotal .mat-sort-header-container {
    float: right;
}

.ytdTotal {
    width: 140px;
}

.searchPanel {
    margin-bottom: 30px;
}

.modalBoxContent h1 {
    margin-bottom: 10px;
}

.modalBoxContent .radio {
    margin: 0;
}

.rightContent {
    height: 1085px;
    overflow: auto;
}

button.mat-menu-item .svg {
    margin-right: 8px !important;
    vertical-align: middle;
}

.noteTitleDoc .svg {
    margin-right: 5px;
    vertical-align: middle;
    display: inline-block;
}

.taskContainer .noteImage {
    margin-right: 8px;
}

.closedTask .tlNm {
    text-decoration: line-through;
    color: #969696;
}

.chartTopRow {
    float: left;
    width: 100%;
    position: relative;
}

.chartTopRow .chartDate {
    float: right;
    width: 130px;
    position: absolute;
    top: -2px;
    right: 0;
    z-index: 9;
}

.chartTopRow .chartDateFirst {
    right: 140px;
}

.chartTopRow .chartDate input[type='text'] {
    padding: 0 30px 0 10px;
}

.chartTopRow .chartDate .iconCal {
    margin-right: 0;
    right: 10px;
    top: 12px;
}

.chartAreaBox {
    padding: 15px;
}

.rfpChart {
    padding-left: 30px;
}

.ypsBox .subTitleRow {
    display: none;
}

.lconListOut {
    float: left;
    width: 100%;
    box-shadow: 5px 0px 21px #e5e5e5;
    display: table;
    table-layout: fixed;
}

.lconList {
    width: 25%;
    border-left: 1px solid #dadada;
    display: table-cell;
    padding-bottom: 65px;
    vertical-align: top;
}

.lconList:first-child {
    border-left: 0;
}

.lconTitle {
    display: table;
    table-layout: fixed;
    height: 110px;
    width: 100%;
    border-bottom: 1px solid #dadada;
    background: #fff;
}

.lconListing {
    list-style: none;
    margin: 0;
    padding: 0;
    float: left;
    width: 100%;
}

.lconListing li {
    display: table;
    table-layout: fixed;
    height: 110px;
    width: 100%;
    border-bottom: 1px solid #dadada;
    position: relative;
    background: #fff;
    padding: 10px 30px;
}

.lconListing li::after {
    background: url('https://cdn.incentx.com/assets/images/dropShadow.png') no-repeat center top;
    content: '';
    position: absolute;
    left: 0;
    bottom: -65px;
    width: 100%;
    height: 65px;
    z-index: -1;
}

.lconTitleBx {
    display: table-cell;
    width: 100%;
    height: 100%;
    vertical-align: middle;
    padding: 10px 30px;
}

.lconTitleBx h3 {
    line-height: 18px;
    font-size: 22px;
    font-family: 'proxima_nova semiBold';
    color: #222222;
    margin: 0 0 15px;
}

.lconTx {
    display: block;
    width: 100%;
    font-size: 16px;
    color: #767676;
    line-height: 18px;
}

.lconTx .price {
    color: #706cce;
}

.lconListing li .lconImg {
    display: table-cell;
    width: 46px;
    text-align: center;
    vertical-align: middle;
    height: 100%;
}

.lconListingBx {
    display: table-cell;
    vertical-align: middle;
    height: 100%;
    padding-left: 15px;
    width: 100%;
}

.lconImg .iconImg {
    display: inline-block;
    width: 42px;
    height: 42px;
    line-height: 43px;
    font-size: 16px;
    color: #fff;
    background: #ffffff;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    vertical-align: middle;
}

.lconImg .iconImg img {
    height: 100%;
    width: 100%;
    float: left;
    position: absolute;
    top: 0;
    left: 0;
}

.lconListingBx h4 {
    display: block;
    line-height: 18px;
    font-size: 16px;
    font-family: 'proxima_nova semiBold';
    color: #222222;
    margin: 0 0 10px;
    width: 100%;
}

.lconListingBx p {
    display: block;
    line-height: 18px;
    font-size: 16px;
    color: #767676;
    margin: 0;
    width: 100%;
}

.icoImg {
    float: right;
}

.lconImg .iconTx,
.iconClr1 {
    background: #21b3c6 !important;
}

.lconListing li:nth-child(2n) .iconTx,.iconClr2 {
    background: #05c1a0 !important;
}

.lconListing li:nth-child(3n) .iconTx,.iconClr3 {
    background: #706cce !important;
}

.lconListing li:nth-child(4n) .iconTx,.iconClr4 {
    background: #f99300 !important;
}

.lconListing li:nth-child(5n) .iconTx,.iconClr5 {
    background: #38b1eb !important;
}

.lagendBtn {
    float: left;
    width: 100%;
    background: #fff;
    padding: 15px;
}

.lagendBtn .btnOut {
    float: left;
    width: 33.33%;
    padding: 15px;
}

.lagendBtn .btnOut a {
    height: 51px;
    font-family: 'proxima_nova semiBold';
    float: left;
    width: 100%;
    text-align: center;
    color: #fff;
    font-size: 16px;
    line-height: 51px;
}

.lagendBtn .btnOut a:hover {
    background: #777777;
}

.grayBtnClr {
    background: #969696;
}

.greenBtnClr {
    background: #05c1a0;
}

.redBtnClr {
    background: #ff4545;
}

.quarterlyReportBox .subTitleRow,
.salesPerformanceSection .subTitleRow {
    display: none;
}

select,
.inputSelect {
    background: #ffffff url('https://cdn.incentx.com/assets/images/dropIcon.png') no-repeat right center !important;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    color: #4a5861;
    padding-right: 32px;
    padding-left: 15px;
}

.tooltip {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 290px;
    color: #fff;
    text-align: left;
    padding: 25px 0 0;
    position: absolute;
    z-index: 9;
    top: 100%;
    right: -30px;
}

.tooltip .tooltiptext::after {
    background: transparent url('https://cdn.incentx.com/assets/images/toolTopAro.png') no-repeat center center;
    content: '';
    position: absolute;
    right: 30px;
    top: 6px;
    height: 20px;
    width: 20px;
}

.tipOut {
    background-color: #fff;
    border: 1px solid #dc4132;
    display: block;
    width: 100%;
    padding: 20px 0 0;
    box-shadow: 0 0 20px rgba(85, 85, 85, 0.2);
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}

.tipTitie {
    float: left;
    font-size: 16px;
    color: #565656;
    margin: 0 0 10px 20px;
}

.tipTitie strong {
    color: #dc4132;
}

.tipOut p {
    float: left;
    width: 100%;
    padding: 0 20px;
    margin: 0 0 15px;
    font-size: 16px;
    color: #565656;
}

.tipOut p a {
    color: #706cce;
}

.tipOut p a:hover,
.tipBtn a:hover {
    color: #222222;
}

.tipOut .sucImg {
    float: right;
    height: 21px;
    width: 21px;
    margin: 0 20px 0 0;
}

.tipBtn {
    display: inline-block;
    width: 100%;
    border-top: 2px solid #ededee;
}

.tipBtn a {
    display: inline-block;
    width: 100%;
    line-height: 41px;
    text-align: center;
    color: #21b3c6;
    font-family: 'proxima_nova semiBold';
    font-size: 16px;
}

.orderBox {
    float: left;
    width: 100%;
    padding-left: 115px;
    position: relative;
    min-height: 90px;
}

.custImg {
    float: left;
    position: absolute;
    left: 0;
    top: 4px;
    height: 87px;
    width: 87px;
    background: #fff;
    text-align: center;
    padding: 0px;
    border-radius: 50px;
}

.custImg img {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    height: 100%;
    border-radius: 100%;
}

.custId {
    display: block;
    font-size: 16px;
    color: #21b3c6;
}

.custDetail {
    float: left;
    width: 75%;
}

.orderStatus {
    float: right;
    max-width: 25%;
}

.custNm {
    font-family: 'proxima_nova semiBold';
    font-size: 20px;
    color: #222;
    margin: 0 0 10px;
    line-height: 20px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.graySubHead {
    font-size: 16px;
    color: #767676;
    margin: 0 0 8px;
}

.contDtl {
    display: inline-block;
    width: 100%;
}

.contTx {
    font-size: 16px;
    line-height: 21px;
    color: #565656;
    margin-right: 15px;
    display: inline-block;
    vertical-align: top;
    margin-bottom: 5px;
    position: relative;
}

.contTx a {
    color: #565656;
}

.contTx a:hover {
    color: #706cce;
}

.contTx.icoDate {
    color: #000;
    padding-left: 0px;
}

.contTx .date-img {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    position: absolute;
    left: 0;
    top: -2px;
}

.contTx .call-img {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    position: absolute;
    left: 0;
    top: 2px;
}

.contTx.icoMail {
    padding-left: 27px;
}

.contTx .mail-img {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    position: absolute;
    left: 0;
    top: 3px;
}

.contTx.pinIcon {
    padding-left: 22px;
}

.contTx .pin-img {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    position: absolute;
    left: 0;
    top: 2px;
}

.orderBox strong {
    font-size: 18px;
    color: #706cce;
}

.lbl {
    color: #767676;
}

.statusBx {
    list-style: none;
    margin: 0;
    padding: 0 0 0 15px;
    display: inline-block;
    width: auto;
}

.stBorderNull {
    border-left: none;
    padding-left: 0;
}

.stBorderNull .stLbl {
    min-width: inherit;
}

.statusBx li {
    display: block;
    width: 100%;
    margin: 0 0 5px;
    font-size: 16px;
    color: #222;
}

.statusBx li:last-child {
    margin-bottom: 0;
}

.stLbl {
    min-width: 110px;
    display: inline-block;
    padding: 3px 8px 3px 0;
}

.orangeLine .stLbl {
    min-width: 135px;
}

.stData {
    color: #f99300;
    display: inline-block;
    vertical-align: top;
}

.stBtn {
    color: #fff;
    line-height: 30px;
    padding: 1px 12px;
    border-radius: 3px;
    font-size: 14px;
    text-transform: uppercase;
    height: 30px;
    display: inline-block;
}

.greenBg {
    background: #05c1a0 !important;
}

.orangeBg {
    background: #f99300 !important;
}

.redBg {
    background: #ff4545 !important;
}

.defaultBg {
    background: #21b3c6 !important;
}

.purpleBg {
    background: #706cce !important;
}

.skyblueBg {
    background: #38b1eb !important;
}

.button.defaultBg:hover {
    background-color: #169bb2 !important;
}

.button.orangeBg:hover {
    background-color: #e08400 !important;
}

.button.purpleBg:hover {
    background-color: #6561b9 !important;
}

.button.skyblueBg:hover {
    background-color: #329fd3 !important;
}

.button.redBg:hover {
    background-color: #e53e3e !important;
}

.button.greenBg:hover {
    background-color: #04ad90 !important;
}

.orderAddress {
    float: left;
    width: 100%;
    margin-top: 15px;
    padding-top: 20px;
    border-top: 1px solid #e5e5e5;
}

.addressBox {
    float: left;
    width: 50%;
    padding-right: 30px;
}

.EQDetails .addressBox .feildCol {
    margin-top: 20px;
    max-width: 250px;
}

.addressBox .contTx {
    width: 100%;
    margin: 0 0 10px;
}

.addNm {
    display: inline-block;
    width: 100%;
    margin: 0 0 8px;
    font-size: 17px;
    color: #222;
    vertical-align: top;
}

.remarkBx {
    width: 75%;
    text-align: left;
    border: 1px solid #706cce;
    color: #706cce;
    padding: 9px 15px;
    background: rgba(112, 108, 206, 0.1);
    vertical-align: top;
    margin: 20px 15px;
    font-size: 15px;
}

.remarkAuto {
    float: left;
    margin: 0 15px 15px 0;
    width: auto;
}

.totalCount {
    vertical-align: middle;
    text-align: right !important;
}

.totalCount .subTotal,
.totalCount .tax {
    font-size: 17px;
    color: #767676;
    margin: 2px 0;
    white-space: nowrap;
}

.totalCount .subTotal span,
.totalCount .tax span {
    color: #222222;
    display: inline-block;
    vertical-align: top;
    text-align: left;
}

.totalCount .total {
    font-size: 18px;
    color: #222222;
    margin: 2px 0;
    white-space: nowrap;
}

.totalCount .total span {
    color: #706cce;
    font-family: 'proxima_nova semiBold';
    display: inline-block;
    vertical-align: top;
    text-align: left;
}

.center {
    text-align: center !important;
}

.right {
    text-align: right !important;
}

.left {
    text-align: left !important;
}

.mrgB0 {
    margin-bottom: 0 !important;
}

.erAmout {
    float: right;
    text-align: left;
    border: 1px solid #706cce;
    color: #706cce;
    padding: 10px 15px 5px;
    background: rgba(112, 108, 206, 0.1);
    vertical-align: top;
    margin: 0;
}

.erAmout span {
    color: #565656;
    font-size: 17px;
    display: block;
}

.erAmout strong {
    color: #706cce;
    font-size: 24px;
    display: block;
    font-family: 'proxima_nova semiBold';
}

.addressBox .formControls .inputContols {
    margin: 10px 0 15px;
}

.colsP0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.mrgB25 {
    margin-bottom: 25px !important;
}

.hrBlock {
    float: left;
    width: 100%;
    border-top: 1px solid #e5e5e5;
    margin-top: 10px;
    padding-top: 15px;
}

.minusAfter,
.equalAfter {
    position: relative;
}

.minusAfter::after {
    content: '-';
    position: absolute;
    font-size: 25px;
    color: #767676;
    right: -4px;
    top: 10px;
    font-weight: 700;
    line-height: 42px;
}

.equalAfter::after {
    content: '=';
    position: absolute;
    font-size: 25px;
    color: #767676;
    left: 0px;
    top: 10px;
    font-weight: 700;
    line-height: 42px;
}

.filterCols .equalAfter {
    position: absolute;
    right: 0;
    min-width: 100px;
    bottom: 18px;
    padding-left: 15px;
}

.filterCols .equalAfter::after {
    top: 0px;
}

.dataView {
    display: inline-block;
    width: 100%;
    height: 42px;
    padding: 9px 20px;
    color: #706cce;
    font-size: 18px;
    vertical-align: top;
    font-family: 'proxima_nova semiBold';
}

.feildCol {
    min-height: 65px;
    margin-top: 10px;
}

.feildCol.serchBox {
    margin-bottom: 0;
}

.filterCols .feildCol {
    min-height: 60px;
}

.cols12 .feildCol.radioGroup {
    min-height: 45px;
}

.highcharts-axis-labels>span {
    width: 47px;
    height: 47px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 10px 15px rgba(85, 85, 85, 0.25);
}

.rfpChart h2 {
    margin-bottom: 15px;
}

.rfpChart .tabbing ul {
    border: none;
    margin: 0 0 10px;
}

.rfpChart .tabbing {
    border: none;
    margin-top: -15px;
}

.equalHight .borderBox {
    min-height: 470px;
}

.filter .smallCol {
    padding-right: 20px;
    width: 14.66%;
}

.pIcon {
    display: inline-block;
    vertical-align: middle;
    height: 45px;
    width: 45px;
}

.pIcon img {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    height: 100%;
}

.switch {
    position: relative;
    display: inline-block;
    width: 46px;
    height: 26px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.switch .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.switch .slider:before {
    background: #ffffff url('https://cdn.incentx.com/assets/images/switchIcon1.png') no-repeat center center;
    position: absolute;
    content: '';
    height: 20px;
    width: 20px;
    left: 3px;
    bottom: 3px;
    -webkit-transition: .4s;
    transition: .4s;
}

.switch input:checked+.slider {
    background-image: -ms-linear-gradient(0deg, rgb(42, 187, 206) 0%, rgb(134, 121, 255) 100%);
    background-image: -moz-linear-gradient(0deg, rgb(42, 187, 206) 0%, rgb(134, 121, 255) 100%);
    background-image: -webkit-linear-gradient(0deg, rgb(42, 187, 206) 0%, rgb(134, 121, 255) 100%);
}

.switch input {
    -webkit-appearance: none;
}

.switch input:focus+.slider {
    box-shadow: 0 0 1px #2196F3;
}

.switch input:checked+.slider:before {
    background: #ffffff url('https://cdn.incentx.com/assets/images/switchIcon2.png') no-repeat center center;
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(22px);
    transform: translateX(20px);
}

.switch .slider.round {
    border-radius: 34px;
}

.switch .slider.round:before {
    border-radius: 50%;
}

.productSec {
    display: inline-block;
    width: 100%;
}

.productDtl {
    display: flow-root;
}

.productSec .productImg {
    float: left;
    width: 100%;
    max-width: 350px;
    margin: 0 25px 10px 0px;
}

.proDtlSlider {
    list-style: none;
    padding: 0;
    margin: 0;
}

.proDtlSlider li {
    display: table;
    table-layout: fixed;
    width: 100%;
    height: 350px;
    padding: 0px !important;
}

.proDtlSlider li .imgOut {
    display: table-cell;
    width: 100%;
    text-align: left;
    vertical-align: middle;
    height: 100%;
    text-align: center;
    margin: 0 auto;
}

.imgOut img {
    display: inline-block !important;
    width: auto !important;
    max-width: 100%;
    max-height: 347px;
}

.autoWidth th:first-child,
.tableData.autoWidth th:first-child,
.autoWidth td:first-child,
.tableData.autoWidth td:first-child {
    text-align: left;
    width: auto;
}

.rangeOut {
    display: inline-block;
    width: calc(100% - 100px);
    margin-right: 70px;
    position: relative;
    height: 5px;
    border-radius: 5px;
    background: #e9ecef;
    min-width: 80px;
}

.rangeOut .range {
    float: left;
    height: 5px;
    border-radius: 5px 0 0 5px;
}

.rangeCount {
    position: absolute;
    left: 100%;
    margin-left: 15px;
    font-size: 15px;
    color: #565656;
    top: -10px;
}

.width150 {
    width: 150px;
    min-width: 150px;
}

.width120 {
    width: 120px;
    min-width: 120px;
}

.width60 {
    width: 60px !important;
    min-width: 60px;
}

.width40 {
    width: 40px !important;
}

.width180 {
    width: 180px !important;
}

.width200 {
    width: 200px;
}

.width100 {
    width: 100px;
}

.width80 {
    width: 80px !important;
}

.width50 {
    width: 50px !important;
}

.borderNull tr td {
    border-bottom: 0;
}

.custIdDtl {
    display: block;
    font-size: 18px;
    color: #706cce;
}

.proName {
    margin: 0px;
    display: block;
    font-size: 32px;
    font-family: 'proxima_nova Bold';
    color: #222;
}

.stDataOut {
    display: block;
    margin: 5px 0 10px;
}

.finalPrice {
    display: block;
    font-size: 17px;
    color: #767676;
    margin: 0 0 20px;
}

.finalPrice span {
    display: block;
    font-size: 30px;
    color: #706cce;
    margin: 0px;
    font-family: 'proxima_nova Bold';
    line-height: 31px;
}

.productDtl .contTx {
    margin-bottom: 20px;
}

.listInd .orangeClr span,
.colorList tr.orangeClr td:first-child::before {
    background: #f99300 !important;
}

.listInd .greenClr span,
.colorList tr.greenClr td:first-child::before {
    background: #05c1a0 !important;
}

.listInd .redClr span,
.colorList tr.redClr td:first-child::before {
    background: #ff4545 !important;
}

.listInd .purpleClr span,
.colorList tr.purpleClr td:first-child::before {
    background: #706cce !important;
}

.listInd .skyblueClr span,
.colorList tr.skyblueClr td:first-child::before {
    background: #38b1eb !important;
}

.black {
    color: #222222 !important;
}

.orange {
    color: #f99300 !important;
}

.green {
    color: #05c1a0 !important;
}

.red {
    color: #ff4545 !important;
}

.purple {
    color: #706cce !important;
}

.skyblue {
    color: #38b1eb !important;
}

.defaultClr {
    color: #21b3c6 !important;
}

.colorList tr td:first-child::before {
    width: 3px;
    height: 30px;
    margin-top: 0;
    content: '';
    float: left;
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -15px;
}

.colorList tr td:first-child {
    position: relative;
}

.tooltip .toolTipTx {
    vertical-align: top;
    margin-right: 8px;
    color: #565656;
    display: inline-block;
    line-height: 19px;
}

table tr:nth-last-child(1) .tooltip .tooltiptext,
table tr:nth-last-child(2) .tooltip .tooltiptext,
table tr:nth-last-child(3) .tooltip .tooltiptext,
table tr:nth-last-child(4) .tooltip .tooltiptext {
    top: auto;
    bottom: 100%;
    padding: 0 0 25px;
}

table tr:nth-last-child(1) .tooltip .tooltiptext::after,
table tr:nth-last-child(2) .tooltip .tooltiptext::after,
table tr:nth-last-child(3) .tooltip .tooltiptext::after,
table tr:nth-last-child(4) .tooltip .tooltiptext::after {
    top: auto;
    transform: rotate(180deg);
    bottom: 6px;
}

.width120 {
    width: 120px !important;
}

.width250 {
    width: 250px !important;
    min-width: 250px;
}

.vlMiddle {
    vertical-align: middle !important;
}

.orderPdetails .orderStatus {
    max-width: 30%;
}

.orderPdetails .custDetail {
    width: 70%;
}

a {
    color: #706cce;
    cursor: pointer;
}

.tabBtns .btn {
    float: left;
    width: 50%;
    text-align: center;
    line-height: 42px;
    color: #767676;
    background-color: #fff;
    font-size: 18px;
    height: 40px;
}

.tabBtns .btn.active {
    background-color: #706cce;
    color: #fff;
}

.tabBtns {
    border: 1px solid #c8c8c8;
    float: left;
    width: 100%;
}

.customerDetail.crmCustomerDetail > div:last-child .contDtl:after {
    display: none;
}

.contDtl strong {
    font-size: 17px;
    color: #222;
}

.contDtl.selectedContact strong {
    color: #706cce;
}

.borderBox.noPaddBorder .subTitleRow a.defaultClr {
    font-family: 'proxima_nova semiBold';
}

.filterCols {
    margin: 0 0 10px;
    padding-right: 105px;
}

.feildCol .iconCal {
    right: 0;
}

.borderTop {
    border-top: 1px solid #e6e6e6;
    padding-top: 20px;
}

.summaryOut {
    display: inline-block;
    width: 100%;
    position: relative;
}

.summaryTx {
    float: left;
    width: 30%;
    margin: 0;
    padding: 0;
    list-style: none;
}

.summaryTx .contTx {
    width: 100%;
    margin: 0 0 20px;
    display: table;
}

.summaryTx .contTx .lbl {
    width: 110px;
    display: table-cell;
    margin-right: 10px;
    white-space: nowrap;
    padding-right: 5px;
}

.summaryOut .btnGroup {
    position: absolute;
    right: 0;
    bottom: 25px;
}

.btnGroup {
    float: right;
}

.btnGroup .button {
    float: left;
    margin-left: 20px;
}

.btnGroup .button img {
    display: inline-block;
    margin-right: 5px;
    vertical-align: top;
    margin-top: 2px;
}

.button.orangeBg {
    box-shadow: 0 8px 15px rgba(249, 147, 0, 0.20);
}

.button.purpleBg {
    box-shadow: 0 8px 15px rgba(112, 108, 206, 0.20);
}

.button.defaultBg {
    box-shadow: 0 8px 15px rgba(33, 179, 198, 0.20);
}

.feildCol button {
    right: 0;
}

.colspanTbl tr td:nth-last-child(2n),
.colspanTbl tr:nth-child(2) th:nth-last-child(2n) {
    border-left: 1px solid #e1e1e1;
}

.colspanTbl tr th {
    padding: 8px 15px;
}

.colspanTbl tr th,
.colspanTbl tr td {
    vertical-align: middle;
}

.filter .button.secRow {
    top: auto;
    bottom: 30px;
}

.tableOut .mat-table {
    background: #fff;
    margin-bottom: 15px;
    font-family: 'proxima_nova' !important;
}

.tableOut .mat-table tr th {
    margin: 0;
    vertical-align: middle;
    padding: 15px 15px;
    font-size: 16px;
    white-space: nowrap;
    color: #222222;
}

.tableOut .mat-table tr td {
    margin: 0;
    vertical-align: middle;
    font-size: 15px;
    color: #444444;
    padding: 8px 15px;
}

.mat-table tr th button {
    box-shadow: none;
    background: none;
    color: #222222 !important;
}

.mat-calendar {
    font-family: 'proxima_nova' !important;
}

.mat-calendar-table {
    border: none;
}

.mat-calendar-table th {
    border-bottom: none;
}

.mat-calendar-table td {
    border-bottom: none;
}

.mat-calendar-body-label {
    text-align: left !important;
    width: auto !important;
}

.mat-spinner-class {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.5);
    text-align: center;
    top: 0;
}

.mat-spinner-class .mat-progress-spinner {
    height: 100px;
    width: 100px;
    position: relative;
    left: 50%;
    top: 50%;
    margin-left: -50px;
    margin-top: -50px;
}

.mat-spinner-class .mat-progress-spinner circle,
.mat-spinner circle {
    stroke: #ffffff;
}

.matcalendar-icon-toggle .mat-icon-button .mat-button-wrapper,
.matcalendar-icon-toggle .mat-icon-button .mat-button-ripple,
.matcalendar-icon-toggle .mat-icon-button .mat-button-focus-overlay,
.mat-calendar .mat-button-focus-overlay {
    display: none;
}

.matcalendar-icon-toggle .mat-icon-button {
    height: 27px !important;
    width: 27px !important;
    background: transparent url('https://cdn.incentx.com/assets/images/sprite.png') no-repeat -31px 2px !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    vertical-align: top !important;
}

.matcalendar-icon-toggle .mat-icon-button:focus {
    background-color: transparent !important;
}

.mat-calendar .mat-calendar-body-selected {
    background-color: #21b3c6;
    color: #fff;
    border-radius: 1px;
    font-weight: 600;
    box-shadow: inset 0 0 0 1px #fff;
}

.mat-calendar .mat-calendar-body-today:not(.mat-calendar-body-selected) {
    border-color: #f99300;
    background-color: #f9ecd8;
    border-radius: 1px;
    border-color: #706cce;
    background-color: #f3f3f3;
}

.mat-calendar-body-cell-content {
    border-radius: 1px !important;
}

.mat-calendar tr:hover td,
.mat-calendar .tableData tr:hover td {
    background: #fff !important;
}

.mat-calendar button:hover {
    background-color: transparent;
}

.mat-calendar button:hover {
    background-color: transparent;
    color: #111 !important;
}

.mat-calendar-table-header th {
    text-align: center !important;
    padding: 0 0 8px !important;
    color: #38b1eb !important;
    font-size: 14px !important;
}

.mat-calendar-controls {
    margin-top: 0 !important;
    background: #f0f0f0;
}

.mat-calendar-body tr:first-child td {
    color: #706cce;
    font-size: 15px;
}

.mat-paginator-range-actions button {
    box-shadow: none;
    width: 30px;
    height: 30px;
    line-height: 24px;
    border-radius: 0;
}

.mat-paginator-range-actions button:hover {
    background: transparent;
    color: #222 !important;
    cursor: pointer;
}

.mat-paginator-container .mat-form-field-flex {
    padding: 5px 10px !important;
    height: 30px;
    box-shadow: none !important;
}

.mat-select-panel-wrap .mat-select-panel {
    border-radius: 0 !important;
    box-shadow: 0 2px 2px #ddd !important;
}

.mat-select-panel-wrap .mat-select-panel .mat-option {
    height: 30px !important;
}

.mat-paginator-range-actions button .mat-button-ripple,
.mat-paginator-range-actions button .mat-button-focus-overlay {
    display: none !important;
}

.mat-paginator-page-size-select {
    margin: 0 10px !important;
    width: 70px !important;
}

.mat-paginator-page-size-label {
    margin: 0 10px !important;
    font-size: 15px !important;
    color: #939393 !important;
}

.mat-paginator-range-label {
    margin: 0 20px 0 10px !important;
    font-size: 15px !important;
    color: #939393 !important;
}

.filter .fullCol {
    padding-left: 0;
}

.cdk-keyboard-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
.cdk-program-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
    background-color: #c3e7f8 !important;
    border: 1px solid #38b1eb !important;
}

.matSelect .mat-form-field-flex {
    background: #ffffff url('https://cdn.incentx.com/assets/images/dropIcon.png') no-repeat right center !important;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    color: #868686;
    padding: 0;
}

.matSelect .mat-form-field-flex input[type='text'] {
    background-color: transparent;
}

.searchBoxIn {
    position: relative;
}

.topBreadcrumb {
    list-style: none;
    overflow: hidden;
    font: 18px Sans-Serif;
    padding: 0;
    margin: 3px 0 15px 0;
}

.topBreadcrumb li {
    float: left;
    font-size: 16px;
    margin: 0 15px 0 0;
    position: relative;
    vertical-align: top;
    padding-right: 15px;
}

.topBreadcrumb li a {
    color: #6e6aaf;
    text-decoration: none;
    padding: 2px;
    display: inline;
    font-family: 'proxima_nova semiBold';
}

.topBreadcrumb li::after {
    content: ' ';
    display: block;
    width: 8px;
    height: 8px;
    border-top: 2px solid #6e6aaf;
    border-right: 2px solid #6e6aaf;
    position: absolute;
    top: 5px;
    margin-top: 0px;
    z-index: 2;
    right: -4px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    left: -20px;
}

.topBreadcrumb li:first-child {
    margin-left: 0;
}

.topBreadcrumb li:last-child a {
    pointer-events: none;
    cursor: default;
    color: #555;
}

.topBreadcrumb li:first-child::after {
    display: none;
}

.topBreadcrumb li:last-child::after {
    border-top: 2px solid #555;
    border-right: 2px solid #555;
}

.topBreadcrumb li a:hover {
    color: #5753bf;
    text-decoration: underline;
}

.eqIcon2 {
    width: 100%;
    display: inline-block;
    text-align: center;
    margin-top: 8px;
}

.actBtn a {
    display: inline-block;
    margin: 0px 1px !important;
    vertical-align: middle;
    min-width: 20px;
    width: 30px;
    height: 30px;
    padding: 4px 5px;
    position: relative;
}

.actBtn a::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 50%;
    top: 0;
    left: 0;
    z-index: -1;
    transition: 350ms ease;
    transform: scale(0);
    -webkit-transform: scale(0);
}

.actBtn a:hover::after,
.actBtn a:focus::after {
    transform: scale(1);
    -webkit-transform: scale(1);
    z-index: 11;
}

.actBtn a span {
    margin: 0;
    position: relative;
    z-index: 111;
}

.proIcon {
    width: 42px;
    height: 42px;
    display: inline-block;
    border-radius: 30px;
    overflow: hidden;
    vertical-align: middle;
}

.proIcon img {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    height: 100%;
}

.newUserForm {
    padding-left: 110px;
    position: relative;
}

.addNewProfile {
    float: left;
    position: absolute;
    left: 0;
    width: 100px;
    text-align: center;
}

.addNewProfile span.iconImg {
    height: 80px;
    width: 80px;
    border-radius: 100%;
    line-height: 80px;
    font-size: 28px;
}

.proImgOut {
    display: inline-block;
    vertical-align: top;
    margin-bottom: 10px;
    width: 110px;
    height: 110px;
    border-radius: 70px;
    overflow: hidden;
}

.editLink {
    display: inline-block;
    width: 100%;
}

.topLbl {
    float: left;
    width: 100%;
    margin-top: -20px;
    margin-bottom: 20px;
    color: #767676;
    font-size: 14px;
}

.topLbl2 {
    float: left;
    width: 100%;
    margin-top: -20px;
    margin-bottom: 20px;
    color: #767676;
    font-size: 15px;
}

.closeIcon {
    width: 24px;
    height: 22px;
    float: left;
    margin-right: 10px;
    background-position: -48px -24px;
}

.closeIcon:hover {
    background-position: -95px -24px;
}

.actBtn a:hover .closeIcon {
    background-position: -95px -24px;
}

.viewIcon {
    width: 24px;
    height: 22px;
    float: left;
    margin-right: 10px;
    background-position: -84px -110px;
}

.viewIcon:hover {
    background-position: -114px -110px;
}

.actBtn a:hover .viewIcon {
    background-position: -114px -110px;
}

.notiImg {
    padding: 10px 0 !important;
    width: 50px !important;
}

.tblNotification {
    border: 0;
}

.tblNotification tr td {
    border-bottom: 1px solid #e1e1e1 !important;
    height: 90px;
}

.lastBorderB0 tr:last-child td {
    border-bottom: 0 !important;
}

.tblNotification .contTx {
    display: block;
    margin: 5px 0 0;
    vertical-align: middle;
    color: #21b3c6;
    font-size: 14px;
    line-height: 16px;
}

.tblNotification .actBtn {
    width: 25px;
    padding: 0;
}

.tblNotification .actBtn a {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
}

.widthAuto {
    width: auto !important;
}

.eqIcon {
    float: right;
    vertical-align: top;
    margin: 0 20px;
}

.cols5-5 {
    float: left;
    padding-left: 15px;
    padding-right: 15px;
    width: 45.82%;
}

.subTitleRow {
    display: inline-block;
    width: 100%;
    vertical-align: top;
}

.subTitleRow h2 {
    margin-bottom: 10px;
}

.addNewBtn {
    padding-right: 140px;
    position: relative;
}

.addNewBtn .addBtn {
    position: absolute;
    right: 15px;
    bottom: 23px;
    min-width: 110px;
}

.cscCol {
    position: relative;
}

.cscCol .iconCsc {
    width: 38px;
    height: 30px;
    float: left;
    margin-right: 10px;
    background-position: 2px -76px;
    position: absolute;
    right: 20px;
    top: 7px;
}

.feildCol .iconCsc {
    right: 0;
}

.customerListing ul li .actionAro {
    background: url('https://cdn.incentx.com/assets/images/sprite.png') -39px -82px no-repeat;
    width: 15px;
    height: 17px;
    display: inline-block;
    position: absolute;
    top: 40%;
    right: 0px;
    padding: 0;
}

.customerListing ul li .actionAro:hover {
    background-position: -59px -82px;
}

.customerDetail h3 {
    color: #706cce;
    font-size: 16px;
    margin: 0;
    font-family: 'proxima_nova semiBold';
}

.customerDetail h5 {
    color: #222222;
    font-size: 16px;
    margin: 0;
}

.icoOut {
    float: left;
    padding-right: 25px;
    margin: -5px 0 0 0;
}

.lstB0 ul li {
    border-bottom: 0 !important;
}

.custLogo img {
    width: 66px;
    height: 66px;
    border-radius: 40px;
}

.custCntBx .icoOut a {
    padding-left: 27px;
    position: relative;
    display: inline-block;
}

.custCntBx {
    box-shadow: 5px 0px 21px #e5e5e5;
    display: inline-block;
    vertical-align: top;
    width: 100%;
    background-color: #fff;
    padding: 25px 30px 10px;
    min-height: 185px;
    margin-top: 30px;
    border: 1px solid transparent;
}

.locationTx {
    display: inline-block;
    width: 100%;
    line-height: 20px;
    font-size: 14px;
    margin: 5px 0;
    color: #565656;
}

.contentRow .row .cols4:nth-child(2) .custCntBx,
.contentRow .row .cols4:nth-child(3) .custCntBx,.contentRow .row .cols4:first-child .custCntBx {
    margin-top: 0;
}

.shipToAddCont .cols4:nth-child(3n+1),
.bilToAddCont .cols4:nth-child(3n+1) {
    clear: both;
}

.icoOut a,
.customerListing ul li a {
    color: #565656;
}

.icoOut a:hover,
.customerListing ul li a:hover {
    color: #222222;
}

.relative {
    position: relative;
}

.filterCols2 {
    padding-right: 155px;
    margin: 0 0 10px;
}

.filterCols2 .button.filterSearch {
    right: 170px;
    width: 100px;
}

.borderBox .filterCols2 .button {
    margin-top: 10px;
}

.button.filterILbtn {
    width: 140px;
    text-align: center;
}

.skyblueToolTip .tooltiptext {
    max-width: 420px;
}

.skyblueToolTip .tooltiptext .tipOut {
    max-height: 205px;
    border-color: #21b3c6;
    padding: 20px 8px;
    overflow-y: auto;
    width: 100%;
}

.skyblueToolTip .tooltiptext::after {
    background-image: url('https://cdn.incentx.com/assets/images/toolTopAro2.png');
}

ol.tipList {
    margin: 0;
    color: #565656;
    padding-left: 20px;
}

.tipList li {
    font-size: 15px;
    margin: 8px 0;
    width: 100%;
    color: #565656;
}

.tipList li a {
    color: #565656;
}

.tipList li a:hover {
    color: #38b1eb;
}

.tooltiptext::after {
    background-image: url('https://cdn.incentx.com/assets/images/toolTopAro2.png');
}

.customerSection.tabContainer {
    padding-bottom: 10px;
}

.loginForm input[type='text'],
.loginForm input[type='password'] {
    height: 49px;
    border: 1px solid #c8c8c8;
    box-shadow: 8px 0 15px rgba(85, 85, 85, 0.05);
    padding-left: 50px;
    font-family: 'proxima_nova semiBold' !important;
    font-size: 15px;
    color: #4a5861;
}

.loginForm input[type='submit'],
.loginForm input[type='button'],
.signUpContainer input[type='submit'],
.signUpContainer input[type='button'] {
    height: 54px;
}

.loginIcon {
    position: relative;
    box-shadow: 8px 0 15px rgba(85, 85, 85, 0.05);
    display: inline-block;
    width: 100%;
    vertical-align: top;
}

.loginIcon>label {
    position: absolute;
    width: 48px;
    height: 48px;
    top: 1px;
    left: 1px;
    z-index: 1;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #ffffff inset !important;
}

input:-webkit-autofill {
    -webkit-text-fill-color: #4a5861 !important;
}

.rightAro {
    width: 24px;
    height: 22px;
    float: left;
    margin-right: 10px;
    background-position: -35px -80px;
}

.actBtn a:hover .rightAro {
    background-position: -55px -80px;
}

.custIcon {
    width: 80px;
    height: 80px;
    display: inline-block;
    box-shadow: 5px 0px 21px #f0f0f0;
    border-radius: 50%;
    text-align: center;
    overflow: hidden;
    vertical-align: middle;
    margin: 0 6px;
    background: #fff;
}

.custIcon img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    display: block;
    vertical-align: middle;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.icoOuter {
    position: relative;
    color: #565656;
    padding-top: 2px;
    display: inline-block;
}

table.tblLstBorder tr:last-child td {
    border-bottom: 1px solid #e1e1e1 !important;
}

.filterCols .button {
    top: auto;
    bottom: 18px;
}

.growthUpDown {
    margin: 0;
    display: inline-block;
    width: 100%;
    position: relative;
    top: -15px;
    padding: 0 0 0 20px;
    font-size: 15px;
    color: #767676;
}

.growthUp {
    display: inline-block;
    line-height: 21px;
    padding-left: 20px;
    position: relative;
    color: #0acf97;
    margin-right: 10px;
}

.growthDown {
    display: inline-block;
    line-height: 21px;
    padding-left: 20px;
    position: relative;
    color: #fa5c7c;
    margin-right: 10px;
}

.growthUp::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 21px;
    width: 15px;
    background: url('https://cdn.incentx.com/assets/images/sprite.png') no-repeat -76px -79px;
}

.growthDown::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 21px;
    width: 15px;
    background: url('https://cdn.incentx.com/assets/images/sprite.png') no-repeat -96px -79px;
}

.adCustRight {
    float: right;
}

.adCustRight .tabbing {
    float: left;
    width: auto;
    overflow: visible;
}

.tabbing.adCustTabbing ul {
    float: left;
    width: auto;
    border-bottom: 0;
    margin: 0;
}

.dasCustTbl {
    height: 350px;
    overflow-y: auto;
}

.dasCustTbl table {
    margin: 0;
    border: 0;
}

.dasTblTitle {
    border-bottom: 0;
    padding-bottom: 0 !important;
    font-size: 18px;
    font-family: 'proxima_nova semiBold';
    color: #222;
    line-height: 16px;
    padding-top: 19px !important;
}

.dasCustTbl td {
    vertical-align: top;
    padding: 10px 15px 20px;
}

.dasCustTbl td .custLabel {
    white-space: nowrap;
}

.dasCustTbl tr:hover td {
    background-color: #fff !important;
}

.dasCustTbl td a {
    color: #222;
}

.dasCustTbl td:first-child {
    padding-left: 0;
}

.dasCustTbl td {
    color: #222;
}

.dashCustTbl {
    height: 335px;
}

.twrDate {
    float: right;
    width: 140px;
    margin-left: 20px;
    margin-top: -8px;
}

.twrDate .iconCal {
    right: 0;
}

.trpImg {
    padding: 0;
    width: 55px;
}

.trpImg .proIcon {
    width: 52px;
    height: 52px;
    padding: 6px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.12);
    margin-left: 5px;
}

.trpCustTbl .tooltip {
    line-height: 18px;
    color: #222222;
}

.trpCustTbl .tooltip .icoImg {
    margin-left: 8px;
}

.trpCustTbl table {
    border: 0;
}

.trpCustTbl table td {
    border: 0;
    padding-top: 15px;
    padding-bottom: 15px;
    background-color: #fff !important;
}

.trpCustTbl .tooltiptext {
    width: 270px;
    left: 0;
}

.trpCustTbl .tooltiptext .tipOut {
    height: auto;
    max-height: 200px;
}

.trpCustTbl .tooltip .tooltiptext::after {
    right: 117px;
}

table tr:nth-child(1) .tooltip .tooltiptext,
table tr:nth-child(2) .tooltip .tooltiptext {
    top: 100%;
    bottom: auto;
    padding: 25px 0 0;
}

table tr:nth-child(1) .tooltip .tooltiptext::after,
table tr:nth-child(2) .tooltip .tooltiptext::after {
    top: 6px;
    transform: rotate(0deg);
    bottom: auto;
}

.twrChart .chartArea {
    float: left;
    width: 60%;
    padding-top: 50px;
}

.adChartRange {
    float: right;
    width: 30%;
    margin-top: 30px;
}

.adCRTitle {
    font-size: 16px;
    margin: 0 0 20px;
    color: #767676;
    line-height: 24px;
}

.adCRTitle .bigTx {
    font-size: 24px;
    margin: 0;
    color: #222;
    display: inline-block;
    vertical-align: top;
}

.adChartRange ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.acrNm {
    float: left;
    vertical-align: top;
    line-height: 18px;
    width: 100%;
    color: #565656;
}

.adChartRange ul li {
    margin-bottom: 17px;
}

.pbsLegend table {
    border: 0;
    margin: 15px 0 0;
}

.pbsLegend table th {
    padding: 0 0 3px;
    font-size: 15px;
    color: #969696;
    border: none;
}

.pbsLegend table td {
    padding: 3px 0;
    border: none;
    color: #222;
    background: #fff !important;
}

.pbsLegend table td:first-child {
    color: #565656;
}

.pbsLegend .clr {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    margin-right: 8px;
    vertical-align: middle;
}

.statusTx {
    color: #fff;
    padding: 3px 7px;
    border-radius: 3px;
    white-space: nowrap;
}

.statusTx.orangeBg {
    background: #feefd9 !important;
    color: #f89300 !important;
}

.statusTx.redBg {
    background: #ffe3e3 !important;
    color: #fe4545 !important;
}

.noteBx {
    width: auto;
    text-align: left;
    border: 1px solid #706cce;
    color: #706cce;
    padding: 9px 15px;
    background: rgba(112, 108, 206, 0.1);
    vertical-align: top;
    margin: 20px 0;
    font-size: 15px;
    display: table;
    clear: both;
    line-height: normal;
}

.noteTx {
    font-size: 16px;
    display: inline-block;
    margin-right: 20px;
}

.noteTx strong {
    font-size: 16px;
}

.orangeLine .statusBx {
    border-left-color: #f99300;
}

.borderBox .btnCol {
    margin-top: 10px;
}

.hrLine {
    display: inline-block;
    width: 100%;
    clear: both;
    border-top: 1px solid #e5e5e5;
    height: 1px;
    margin: 15px 0;
}

.bulletPoint {
    display: inline-block;
    width: 100%;
    list-style: none;
    padding: 0;
    vertical-align: top;
}

.bulletPoint li {
    float: left;
    padding-right: 25px;
    position: relative;
    padding-left: 12px;
    position: relative;
}

.bulletPoint li::after {
    content: '';
    position: absolute;
    left: 0;
    top: 9px;
    width: 6px;
    height: 6px;
    background: #7f7f7f;
    border-radius: 5px;
}

.textareaCols .inputField label {
    bottom: -10px;
}

.textareaCols {
    margin-bottom: 23px;
}

.separator .feildCol {
    width: auto;
    float: left;
    padding-right: 40px;
    margin-right: 40px;
    position: relative;
}

.separator .feildCol:last-child {
    margin-right: 0;
    padding-right: 0;
}

.separator .feildCol::after {
    content: '';
    position: absolute;
    right: 0;
    top: 0px;
    height: 98%;
    width: 1px;
    border-right: 1px solid #dfdfdf;
}

.separator .feildCol:last-child::after {
    display: none;
}

.tipOut table {
    border: 0;
}

.tipOut table th {
    padding-top: 0;
}

.tipOut table td {
    border-bottom: 0;
}

.popOuter {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.7);
    top: 0;
    left: 0;
}

.popCnt {
    position: relative;
    left: 50%;
    top: 10%;
    transform: translateX(-50%);
    overflow-y: auto;
    max-height: 80vh;
    max-width: 100%;
}

.popBox {
    background: #fff;
    padding: 0 30px 20px;
}

.popHeader {
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 99;
    padding: 20px 30px 0;
    margin: 0px -30px 20px;
}

.popTitle {
    display: inline-block;
    width: 100%;
    border-bottom: 1px solid #e1e1e1;
    padding-bottom: 18px;
    margin-bottom: 0;
    padding-right: 50px;
}

.closePopup {
    position: absolute;
    right: 25px;
    top: 25px;
    cursor: pointer;
    width: 25px;
    height: 25px;
    background-position: -94px -23px;
    background-color: #fff;
}

.closePopup2 {
    position: absolute;
    right: 30px;
    top: 20px;
    cursor: pointer;
    width: 25px;
    height: 25px;
    background-position: -220px -110px;
}

.closePopup:hover {
    background-position: -47px -23px;
}

.popSubtitle {
    font-size: 18px;
    color: #706cce;
    margin: 0 0 5px;
    font-family: 'proxima_nova semiBold';
}

.popBox p {
    margin: 0 0 15px;
}

.purpleSubHead {
    color: #706cce;
    font-size: 18px;
    font-family: 'proxima_nova semiBold';
    margin: 0 0 8px
}

.blackSubHead {
    color: #222222;
    font-size: 17px;
    margin: 0 0 8px
}

.barcode {
    float: right;
    margin-top: 25px;
}

.remarkBx.fullNote {
    width: auto;
    max-width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0;
}

.txNote {
    font-size: 14px;
    display: block;
    line-height: 18px;
    color: #767676;
    font-style: italic;
    margin-top: 10px;
    margin-bottom: -10px;
}

.fullBtn .button {
    width: 100%;
}

.feildColAuto {
    margin-bottom: 25px !important;
    min-height: inherit !important;
}

.feildColAuto.mrgB0 {
    margin-bottom: 0px !important;
}

.feildCol .mat-form-field {
    width: 100%;
}

.radio .ng-star-inserted {
    display: inline-block;
}

.customProfile {
    float: left;
    width: 100%;
    position: relative;
    text-align: center;
}

.profileImg {
    display: table;
    table-layout: fixed;
    margin: 0 auto;
    width: 200px;
    height: 130px;
    border: 1px solid #D9D9D9;
}

.profileOut {
    display: table-cell;
    width: 100%;
    height: 100%;
    text-align: center;
    vertical-align: middle;
    position: relative;
    overflow: hidden;
}

.profileOut .uploadLogo {
    display: inline-block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
    opacity: 0;
    cursor: pointer;
}

.removeLogo {
    float: right;
    position: absolute;
    top: 0;
    right: 0;
}

.removeLogo .closeIcon {
    margin: 0;
}

.customProfile>label {
    display: block;
    width: 100%;
    text-align: center;
    margin-top: 10px;
}

.profileOut img {
    max-width: 85%;
    max-height: 85px;
    position: relative;
    z-index: 1;
    margin-bottom: 30px;
    object-fit: contain;
}

.popupAddress {
    background: #f4f4f4;
    padding: 30px;
}

.popupAddress .icoOut {
    position: relative;
}

.popupAddress .icoOut a {
    padding-left: 25px;
}

.popupAddress .icoOut a .callIcon {
    top: 0;
}

.popupAddress .customerDetail {
    display: inline-block;
    width: 100%;
    background: #fff;
    padding: 25px 30px 20px 30px;
    margin-bottom: 30px;
    box-shadow: 0 5px 21px rgba(0, 0, 0, 0.09);
    position: relative;
}

.removeAddress {
    float: right;
    position: absolute;
    top: 10px;
    right: 0;
}

.addHead {
    color: #767676;
    margin-bottom: 15px;
}

.stickyBottom {
    position: sticky;
    bottom: 0;
    left: 0;
}

.whiteBg2 {
    background: #f4f4f4;
}

.radio>label:last-child {
    padding-right: 0 !important;
}

.textareaCols textarea {
    height: 90px;
    padding: 9px 15px;
    font-size: 15px;
}

.datePicker .iconTime {
    width: 24px;
    height: 24px;
    float: left;
    background-position: -29px -110px;
    position: absolute;
    right: 10px;
    top: 9px;
}

.documentUpload {
    display: table;
    table-layout: fixed;
    height: 240px;
    width: 100%;
    border: 1px solid #c8c8c8;
    background: #fafafa;
    margin: 10px 0 20px;
}

.documentUpload .documentOut {
    display: table-cell;
    width: 100%;
    height: 100%;
    text-align: center;
    vertical-align: middle;
    position: relative;
}

.uploadDocument {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 9;
    opacity: 0;
}

.documentOut .udText {
    display: block;
    margin-top: 10px;
    position: relative;
    z-index: 0;
}

.documentOut img {
    position: relative;
    z-index: 0;
}

.upSec {
    position: relative;
    margin-bottom: 30px;
}

.upSec .fileIcon {
    float: left;
    position: absolute;
    left: 15px;
    width: 44px;
    height: 44px;
    top: 5px;
}

.upProcess {
    float: left;
    width: 100%;
    padding-left: 65px;
}

.upFileName {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    color: #222;
    font-size: 16px;
}

.rangeOut.uploadRange {
    width: 100%;
    margin: 5px 0 2px;
    vertical-align: top;
    display: block;
}

.upProcess .rangeSize {
    float: left;
    font-size: 15px;
    color: #767676;
}

.rangeProcess {
    float: right;
    font-size: 15px;
    color: #21b3c6;
}

.skyblueTipout {
    max-height: 205px;
    border-color: #21b3c6;
    padding: 20px 15px;
    overflow-y: auto;
    width: 100%;
}

.greenTipout {
    max-height: 205px;
    border-color: #05c1a0;
    padding: 15px 15px;
    overflow-y: auto;
    width: 100%;
}

.newMenu {
    border: 0 !important;
    box-shadow: none !important;
    padding: 15px !important;
    background: transparent !important;
}

.border0 {
    border: 0 !important;
}

.selectTip {
    display: inline-block;
    width: 100%;
    text-align: left;
    padding: 1px 30px 0 15px;
    line-height: 30px;
    background: #21b3c6;
    color: #fff;
    position: relative;
    border-radius: 3px;
    text-transform: uppercase;
    cursor: pointer;
    height: 30px;
    white-space: nowrap;
    min-width: 85px;
}

.countryFlags .selectTip {
    min-width: 25px;
}

.selectTip::after {
    background: url('https://cdn.incentx.com/assets/images/sprite.png') no-repeat -55px -105px;
    content: '';
    position: absolute;
    width: 30px;
    height: 30px;
    right: 0;
    top: 0;
}

.disabled,
.disabled:hover {
    background-color: #d7d7d7 !important;
    color: #ffffff !important;
    cursor: default !important;
}

.mat-select-panel-wrap .ng-trigger {
    border: 1px solid #dddddd;
}

.fullWidth {
    width: 100%;
}

.lableTx {
    float: left;
    margin: 5px 0;
}

.icoOut.fullWidth {
    padding: 0;
}

.tabbingNew {
    list-style: none;
    overflow: hidden;
    font: 18px Sans-Serif;
    padding: 0;
    margin: 0;
    position: absolute;
    width: calc(100% - 60px);
    left: 30px;
    bottom: 30px;
}

.tabbingNew li {
    float: left;
    font-size: 16px;
    width: 33.33%;
}

.tabbingNew li a {
    color: #e0e0e0;
    text-decoration: none;
    padding: 12px 0 12px 40px;
    background: #969696;
    position: relative;
    display: block;
    float: left;
    font-family: 'proxima_nova semiBold';
    width: 100%;
}

.tabbingNew li a::after {
    content: ' ';
    display: block;
    width: 0;
    height: 0;
    border-top: 50px solid transparent;
    border-bottom: 50px solid transparent;
    border-left: 30px solid #969696;
    position: absolute;
    top: 50%;
    margin-top: -50px;
    left: 100%;
    z-index: 2;
}

.tabbingNew li a::before {
    content: ' ';
    display: block;
    width: 0;
    height: 0;
    border-top: 50px solid transparent;
    border-bottom: 50px solid transparent;
    border-left: 31px solid white;
    position: absolute;
    top: 50%;
    margin-top: -50px;
    margin-left: 1px;
    left: 100%;
    z-index: 1;
}

.tabbingNew li:first-child a {
    padding-left: 20px;
}

.tabbingNew li:last-child a::after,
.tabbingNew li:last-child a::before {
    display: none;
}

.tabbingNew li:last-child a {
    padding-right: 30px;
}

.tabbingNew li a:hover {
    background: #777777;
    color: #fff;
}

.tabbingNew li a:hover:after {
    border-left-color: #777777 !important;
}

.tabbingNew li.active a {
    background: #706cce;
    color: #fff;
}

.tabbingNew li.active a:after {
    border-left-color: #706cce !important;
}

.tabbingNew li:last-child {
    width: 36.33%;
}

.tabbingNew li:first-child {
    width: 30.33%;
}

.perInfo {
    margin-bottom: 30px;
    padding-bottom: 100px !important;
    height: 510px;
    position: relative;
}

.viewMore {
    float: left;
    width: 100%;
    text-align: center;
    position: absolute;
    left: 0;
    bottom: 0;
}

.menuTip,
.menuTip:hover,
.menuTip:focus {
    width: 35px;
    height: 35px;
    line-height: 35px;
    border-radius: 30px;
    overflow: hidden;
    position: relative;
    z-index: 0;
    display: inline-block;
    outline: 0 !important;
    margin: 0 auto;
    vertical-align: top;
}

.menuTip::after {
    content: '';
    position: absolute;
    width: 35px;
    height: 35px;
    background: #fff;
    border-radius: 30px;
    top: 0;
    left: 0;
    z-index: -1;
    display: block;
    opacity: 0;
}

.menuTip:focus::after,
.menuTip:hover::after {
    opacity: 1;
}

.menuTipdxGrid {
    width: 35px;
    height: 35px;
    line-height: 39px;
    border-radius: 30px;
    overflow: hidden;
    position: relative;
    z-index: 0;
    display: inline-block;
    outline: 0 !important;
    margin: 0 auto;
    vertical-align: top;
}

.menuTipdxGrid:hover {
    background-color: #ffffff;
}

.filterCols .button.filterSearch {
    min-width: 90px;
    padding-left: 0;
    padding-right: 0;
}

.timelineNote tr td {
    padding: 10px 0;
    vertical-align: top;
    color: #767676;
}

.timelineNote table {
    border: 0;
    margin: 0;
}

.hoverNone tr:hover>td {
    background: #ffffff !important;
}

.tlNm {
    display: block;
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 2px;
}

.tlBtn a {
    margin: 0 0 0 2px !important;
    display: inline-block;
}

/* .tlBtn {
vertical-align: bottom !important;
}
*/
.timelineNote {
    height: 350px;
    overflow-y: auto;
}

.timelineRight td {
    border: 0;
    padding: 5px 0;
    vertical-align: top;
}

.timelineRight table {
    border: 0;
    margin: 0 0 10px;
}

.calB0x {
    padding-left: 35px !important;
    padding-right: 30px !important;
    width: 120px;
    position: relative;
}

.calendarBx {
    display: inline-block;
    vertical-align: top;
    text-align: center;
    background: #ecf1f7;
    border-radius: 5px;
    overflow: hidden;
    width: 100%;
    min-width: 50px;
    margin-top: 5px;
}

.cDate {
    display: inline-block;
    width: 100%;
    font-size: 18px;
    font-family: 'proxima_nova semiBold';
    line-height: 29px;
    padding: 2px 0 0;
    color: #222;
    vertical-align: top;
}

.cMonth {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    line-height: 21px;
    font-size: 13px;
    text-transform: uppercase;
    color: #fff;
    font-family: 'proxima_nova semiBold';
    border-radius: 0 0 5px 5px;
    background: #21b4c6;
}

.timelineRight .actBtn {
    width: 30px;
    text-align: right;
    padding-top: 13px;
}

.timelineRight {
    height: calc(100% - 120px) !important;
    overflow-y: auto;
    width: 100%;
    max-height: none !important;
}

.timelineRightAction {
    height: 1116px;
}

.trTitle {
    font-size: 18px;
    color: #767676;
    margin: 10px 0;
    font-family: 'proxima_nova';
}

.calB0x::after {
    content: '';
    height: 100%;
    width: 2px;
    background: #ededee;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
}

table tr:first-child .calB0x::after {
    top: 35px;
}

table tr:last-child .calB0x::after {
    height: 50%;
}

.calB0x::before {
    content: '';
    height: 12px;
    width: 12px;
    background: #ededee;
    position: absolute;
    left: -5px;
    top: 34px;
    border: 2px solid #706cce;
    border-radius: 12px;
    background: #fff;
    z-index: 1;
    margin-top: -6px;
}

.timelineRight .tableOut {
    padding-left: 6px;
}

table tr:nth-child(2n) .calB0x::before {
    border-color: #38b1eb;
}

table tr:nth-child(3n) .calB0x::before {
    border-color: #f99300;
}

table tr:nth-child(4n) .calB0x::before {
    border-color: #05c1a0;
}

.actionTip .tipOut {
    border: 1px solid #706cce;
    padding: 0;
}

.actionTip .tooltiptext {
    width: auto;
    min-width: 210px;
    right: 0;
}

.actionTip .tooltiptext::after {
    display: none;
}

.iconList {
    padding: 0;
    margin: 0;
    list-style: none;
}

.iconList li {
    display: block;
    width: 100%;
}

.iconList li a {
    display: block;
    padding: 8px 10px;
    font-size: 16px;
    color: #565656;
}

.iconList li a:hover {
    background-color: #f0f0fa;
}

.iconSvg {
    display: inline-block;
    width: 24px;
    height: 24px;
    vertical-align: top;
    margin-right: 5px;
    max-width: 100%;
    max-height: 100%;
}

.timelineRight .timelineTask td {
    padding: 4px 0;
}

.closedTask .callSvg {
    content: "\e90f";
    color: #565656;
    font-family: 'IXPORTAL';
}

.closedTask .emailSvg {
    content: "\e95f";
    color: #565656;
    font-family: 'IXPORTAL';
}

.closedTask .visitSvg {
    content: "\e98d";
    color: #565656;
    font-family: 'IXPORTAL';
}

.closedTask .appointSvg {
    content: "\e90a";
    color: #565656;
    font-family: 'IXPORTAL';
}

.closedTask .prospectSvg {
    content: "\e97b";
    color: #565656;
    font-family: 'IXPORTAL';
}

.closedTask .customerSvg {
    content: "\e964";
    color: #565656;
    font-family: 'IXPORTAL';
}

.closedTask .pipelineSvg {
    content: "\e974";
    color: #565656;
    font-family: 'IXPORTAL';
}

.closedTask .taskSvg {
    content: "\e902";
    color: #565656;
    font-family: 'IXPORTAL';
}

.closedTask .dealSvg {
    content: "\e926";
    color: #565656;
    font-family: 'IXPORTAL';
}

.closedTask .documentSvg {
    content: "\e92a";
    color: #565656;
    font-family: 'IXPORTAL';
}

.subTitleRow .iconSvg {
    margin-right: 0;
}

.timelineTask tr td {
    vertical-align: top;
}

.timelineDeals table tr td:first-child {
    vertical-align: top;
    width: 100px;
}

.timelineOrder table tr td:first-child {
    width: auto !important;
}

.timelineDeals .tableOut {
    padding-left: 0 !important;
}

.cursorPointer {
    cursor: pointer;
}

.timelineDeals {
    padding-left: 30px;
    margin-left: -30px;
    width: auto;
}

.timelineDeals .tlBtn.actBtn {
    width: 70px;
    float: none;
    display: table-cell;
    vertical-align: middle !important;
}

.dealsView {
    display: block;
    width: 100%;
    margin-top: 8px;
}

.dealsView .proIcon {
    width: 25px;
    height: 25px;
    margin-left: 5px;
}

.dealsView .lconImg {
    display: inline-block;
    margin-left: 5px;
}

.dealsView .lconImg .iconImg {
    width: 25px;
    height: 25px;
    line-height: 25px;
    font-size: 11px;
}

.timelineDeals table tr td:first-child::before {
    top: 32px;
    height: 38px;
}

.timelineMail tr td:first-child {
    width: 65px;
    vertical-align: top;
}

.timelineMail tr .actBtn {
    width: 70px;
}

.actBtn {
    white-space: nowrap;
}

.fileTx {
    display: inline-block;
    margin-right: 12px;
    margin-top: 8px;
}

.dots {
    display: inline-block;
    width: 4px;
    height: 4px;
    background: #767676;
    border-radius: 2px;
    vertical-align: middle;
    margin: 8px;
}

.timelineDocument tr td:first-child {
    width: 65px;
    vertical-align: middle;
}

.timelineDocument tr .actBtn {
    width: 70px;
}

.timelineDocument .dealsView {
    color: #767676;
}

.twrChart {
    padding-left: 30px;
}

.mrgT-7 {
    margin-top: -7px;
}

.mrgT-10 {
    margin-top: -10px;
}

.filterCols .button.addBtn {
    padding-left: 45px !important;
    padding-right: 10px;
    background-position: 15px center !important;
    min-width: 90px;
}

.mat-dialog-container {
    padding: 0 !important;
}

.listText {
    list-style: none;
    padding-left: 0;
    margin: 0 0 20px;
}

.listText li {
    float: left;
    font-size: 15px;
    color: #454545;
    margin-right: 20px;
}

.listText li strong {
    margin-left: 2px;
}

.infoBox {
    padding-top: 0px;
}

.infoIcon {
    text-align: center;
    margin-top: 20px;
    height: 70px;
}

.deleteTopIcon .infoIcon:before {
    content: "\e929";
    color: #ff0100;
    font-size: 55px;
    font-family: 'IXPortal';
}

.addTopIcon .infoIcon:before {
    content: "\e975";
    color: #21b3c6;
    font-family: 'IXPortal';
    font-size: 54px;
}

.infoTitle {
    width: 100%;
    text-align: center;
    font-size: 30px;
    font-family: 'proxima_nova semiBold';
    color: #222222;
    margin: 14px 0;
    line-height: 23px;
}

.infoBox p {
    color: #767676;
    font-size: 18px;
    text-align: center;
    line-height: 24px;
    margin-bottom: 15px;
}

.cancelLink {
    width: 100%;
    text-align: center;
    margin-top: 18px;
    font-size: 18px;
    color: #767676;
    line-height: 15px;
}

.cancel-link {
    font-size: 18px;
    color: #767676;
}

.cancel-link:hover {
    color: #222222;
}

.imgUc {
    display: block;
    width: 100%;
    text-align: center;
    margin: 80px 0 40px;
}

.ucTitle {
    text-align: center;
    width: 100%;
    font-size: 38px;
    font-family: 'proxima_nova semiBold';
    line-height: 28px;
    margin-bottom: 15px;
    padding: 0px;
}

.ucBox p {
    color: #767676;
    font-size: 18px;
    text-align: center;
    line-height: 24px;
    margin-bottom: 30px;
    padding: 0 8px;
}

.toast-warning:after {
    background-color: #fc9700 !important;
    content: '';
    height: 100%;
    width: 47px;
    display: inline-block;
    position: absolute;
    left: 0;
    top: 0;
    outline: 1px solid #fc9700;
}

.toast-warning:before {
    content: "\e905" !important;
    font-family: 'IXPortal' !important;
    color: #fff;
    width: 47px;
    height: max-content;
    display: block;
    position: absolute;
    text-align: center;
    font-size: 45px;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    z-index: 1;
}

.toast-warning {
    background-color: #fff !important;
    padding: 12px 26px 12px 59px !important;
    box-shadow: unset !important;
    border-radius: 0 !important;
    min-width: 290px !important;
    width: 290px !important;
    min-height: 63px;
    border: 1px solid #DADADA;
}

.toast-success:after {
    background-color: #31cc71 !important;
    content: '';
    height: 100%;
    width: 47px;
    display: inline-block;
    position: absolute;
    left: 0;
    top: 0;
    outline: 1px solid #31cc71;
}

.toast-success:before {
    content: "\e92d" !important;
    font-family: 'IXPortal' !important;
    color: #fff;
    width: 47px;
    height: max-content;
    display: block;
    position: absolute;
    text-align: center;
    font-size: 20px;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    z-index: 1;
}

.toast-success {
    background-color: #fff !important;
    padding: 12px 26px 12px 59px !important;
    box-shadow: unset !important;
    border-radius: 0 !important;
    min-width: 290px !important;
    width: 290px !important;
    min-height: 63px;
    border: 1px solid #DADADA;
}

.toast-info {
    background-color: #fff !important;
    padding: 12px 26px 12px 59px !important;
    box-shadow: unset !important;
    border-radius: 0 !important;
    min-width: 290px !important;
    width: 290px !important;
    min-height: 63px;
    border: 1px solid #DADADA;
}

.toast-info:after {
    background-color: #0081c5 !important;
    content: '';
    height: 100%;
    width: 47px;
    display: inline-block;
    position: absolute;
    left: 0;
    top: 0;
    outline: 1px solid #0081c5;
}

.toast-info:before {
    content: "\e955" !important;
    font-family: 'IXPortal' !important;
    color: #fff;
    width: 47px;
    height: max-content;
    display: block;
    position: absolute;
    text-align: center;
    font-size: 22px;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    z-index: 1;
}

.toast-error:after {
    background-color: #ff4342 !important;
    content: '';
    height: 100%;
    width: 47px;
    display: inline-block;
    position: absolute;
    left: 0;
    top: 0;
    outline: 1px solid #ff4342;
}

.toast-error:before {
    content: "\e919" !important;
    font-family: 'IXPortal' !important;
    color: #fff;
    width: 47px;
    height: max-content;
    display: block;
    position: absolute;
    text-align: center;
    font-size: 16px;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    z-index: 1;
}

.toast-error {
    background-color: #fff !important;
    padding: 12px 26px 12px 59px !important;
    box-shadow: unset !important;
    border-radius: 0 !important;
    min-width: 290px !important;
    width: 290px !important;
    min-height: 63px;
    border: 1px solid #DADADA;
}

.toast-top-right {
    top: 70px !important;
    right: 35px !important;
}

.toast-container .ng-trigger {
    overflow: unset !important;
}

.toast-title {
    display: none !important;
}

.toast-message {
    font-size: 14px;
    color: #222;
    line-height: 19px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-height: 38px;
}

.toast-message p {
    font-size: 14px;
    color: #767676;
    margin: 0;
}

.toast-message p strong {
    font-family: 'proxima_nova semiBold';
    color: #222222;
}

.toast-close-button {
    font-size: 26px !important;
    font-weight: 400 !important;
    text-shadow: none !important;
    box-shadow: none !important;
    color: #767676 !important;
    top: -7px !important;
    right: 8px !important;
    position: absolute !important;
}

.custCntBx.newAdd button {
    width: 100%;
    height: 100%;
    display: inline-block;
    position: absolute;
    left: 0;
    top: 0;
    background: #fff;
    color: #21b3c6;
    box-shadow: none;
}

.custCntBx.newAdd button:hover {
    background: #edfdff;
}

.popSubNm {
    font-size: 16px;
    margin: -20px 0 15px;
    line-height: 18px;
    color: #767676;
    background: #f0f0fa;
    padding: 12px 15px;
}

.popSubNm span {
    font-family: 'proxima_nova semiBold';
    color: #706cce;
}

.popAccordion .accTrigger {
    height: 45px;
    padding: 10px 40px 10px 0;
}

.popAccordion {
    border-top: 1px solid #e1e1e1;
}

.popAccordion .accTrigger {
    font-size: 16px;
    color: #222;
    font-family: 'proxima_nova semiBold';
}

.popTotal span {
    color: #706cce;
    font-size: 18px;
    font-family: 'proxima_nova semiBold';
}

.popTotal {
    line-height: 40px;
    position: sticky;
    bottom: 0;
    background: #f0f0fa;
    text-align: right;
    padding: 0 15px;
    margin: 0 0 20px;
    color: #767676;
    font-size: 16px;
}

.tableScroll {
    max-height: 550px;
    width: 100%;
    overflow-y: auto;
    margin-bottom: 25px;
    position: relative;
}

.tableScroll table {
    border-top: 0 !important;
}

.tableScroll table tr:first-child th {
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -ms-sticky;
    position: -o-sticky;
    position: sticky;
    top: 0;
    border-top: 1px solid #e1e1e1 !important;
    z-index: 1111;
}

.inputDisable input,
.inputDisable select,
.inputDisable textarea {
    background-color: #f0f0f0 !important;
    color: #c2c0c0 !important;
    cursor: auto;
    outline: none !important;
}

.inputDisable select {
    background-image: url('https://cdn.incentx.com/assets/images/dropIcon2.png') !important;
}

.inputDisable .inputField label {
    background-image: -webkit-linear-gradient(#ffffff 50%, #f0f0f0 50%);
    background-image: -moz-linear-gradient(#ffffff 50%, #f0f0f0 50%); 
    background-image: -o-linear-gradient(#ffffff 50%, #f0f0f0 50%); 
    background-image: -ms-linear-gradient(#ffffff 50%, #f0f0f0 50%); 
    background-image: linear-gradient(#ffffff 50%, #f0f0f0 50%); 
 }

.searchFilter .inputField label {
    background: rgba(243, 243, 243, 1);
    background: -moz-linear-gradient(top, rgba(243, 243, 243, 1) 0%, rgba(243, 243, 243, 1) 50%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 1) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(243, 243, 243, 1)), color-stop(50%, rgba(243, 243, 243, 1)), color-stop(50%, rgba(255, 255, 255, 1)), color-stop(100%, rgba(255, 255, 255, 1)));
    background: -webkit-linear-gradient(top, rgba(243, 243, 243, 1) 0%, rgba(243, 243, 243, 1) 50%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 1) 100%);
    background: -o-linear-gradient(top, rgba(243, 243, 243, 1) 0%, rgba(243, 243, 243, 1) 50%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 1) 100%);
    background: -ms-linear-gradient(top, rgba(243, 243, 243, 1) 0%, rgba(243, 243, 243, 1) 50%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 1) 100%);
    background: linear-gradient(to bottom, rgba(243, 243, 243, 1) 0%, rgba(243, 243, 243, 1) 50%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f3f3f3', endColorstr='#ffffff', GradientType=0);
}

.borderBox .searchFilter .inputField label {
    background: #ffffff;
}

.shipAddress>.cols4:nth-child(3n+1) {
    clear: left;
}

.radioDisable label {
    opacity: 0.4;
}

.radio.radioDisable input[type='radio']:checked+.radio-label::before {
    background: #999999;
}

.inputDisable .iconSearch {
    opacity: 0.5;
}

.inputDisable .mat-form-field-flex {
    background-color: #f0f0f0 !important;
}

.phoneCall .feildCol.radioGroup {
    min-height: inherit;
    margin-top: 18px;
}

.accTotal {
    float: right;
    margin-right: 15px;
    font-size: 16px;
    color: #767676;
    font-family: 'proxima_nova';
}

.accTotal span {
    color: #706cce;
}

.dropPopup {
    display: none;
    width: 300px;
    background: #ffffff;
    padding: 15px;
    position: absolute;
    top: 56px;
    right: -20px;
    border: 0 none;
    z-index: 9;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
    max-height: 435px;
    overflow-y: auto;
}

.dropPopup ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.dropPopup li {
    font-size: 16px;
    color: #565656;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #cbcbcb;
    margin-right: 0;
    position: relative;
}

.notifiaction li {
    padding-left: 30px;
}

.dropPopup.notifiaction li:before {
    background: url('https://cdn.incentx.com/assets/images/sprite.png') no-repeat -158px -52px;
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    left: 0;
    top: 2px;
    margin-top: 0;
}

.dropPopup li a {
    color: #565656;
    text-decoration: none;
}

.dropPopup li a:hover {
    color: #222222;
}

.dropPopup li span {
    display: block;
}

.dropPopup li span.dropPopupTitle {
    font-weight: 600;
    margin-bottom: 5px;
}

.dropPopup li span.dropPopupText {
    line-height: 21px;
    margin-bottom: 10px;
}

.dropPopup li span.dropPopupDate {
    font-size: 14px;
}

.dropPopup li:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: 0 none;
}

.dropPopup .viewAll {
    display: block;
    text-align: center;
    margin: 15px 0;
    color: #fff;
}

.agreementBox table {
    min-width: 768px !important;
}

.dashCustTbl table {
    min-width: 640px;
}

.twrCustRight {
    float: right;
    width: auto;
}

.twrCustRight .tabbing {
    float: left;
    width: auto;
    overflow: visible;
}

.tableOut.trpCustTbl {
    margin-bottom: 0;
    overflow-x: hidden;
    height: 355px;
    overflow-y: auto;
    padding: 10px 0;
}

#territoryWiseRevenues img {
    max-height: 100%;
}

.signUpForm .cols5 {
    width: 41.66666667%;
}

.signUpForm .cols7 {
    width: 58.33333333%;
}

.signUpForm .cols6,
.signUpForm .cols6.colsP0 {
    width: 50%;
}

.signupLogo {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
}

.perInfo .contListing .icoOut {
    width: 100%;
}

.perInfo .contListing .icoOut.fullWidth {
    width: 100%;
}

.notesFilter {
    margin: 0;
}

.tlFilter {
    padding-right: 0 !important;
    margin: 0;
}

.inBtn .button.filterSearch {
    background-image: url('https://cdn.incentx.com/assets/images/svgs/search.svg');
    background-position: center center;
    background-repeat: no-repeat;
    width: 42px !important;
    min-width: inherit;
    padding: 0;
    font-size: 0;
    position: relative;
    float: right;
    bottom: auto;
    margin-top: 10px;
}

.inBtn .feildCol {
    width: calc(100% - 72px) !important;
    float: left;
}

.perInfo .subTitleRow {
    position: relative;
}

.perInfo .subTitleRow .alignRight {
    position: absolute;
    right: 0;
    top: 0;
}

.filterTogle {
    background: #ffffff url('https://cdn.incentx.com/assets/images/svgs/filter.svg') no-repeat center center;
    display: none;
    float: right;
    width: 33px;
    height: 33px;
    font-size: 0;
    border: 1px solid #c8c8c8;
    border-radius: 2px;
    cursor: pointer;
}

.filterTogle.open {
    background-image: url('https://cdn.incentx.com/assets/images/svgs/close-filter.svg');
}

.filterToggleOut {
    display: inline-block;
    width: 100%;
    vertical-align: top;
}

.inBtnSearch>.feildCol {
    width: calc(100% - 145px);
}

.filterToggleOut.topBtn {
    position: relative;
}

.filterToggleOut.topBtn .filterTogle {
    position: absolute;
    right: 0;
    top: -55px;
}

.button.filterILbtn img {
    margin: -3px 0 0 0;
}

.bilToAddCont {
    margin-bottom: 30px;
}

.spdFilterCols {
    padding-right: 0;
}

.inSearchCol>.feildCol {
    width: calc(100% - 130px);
    float: left;
}


.spdFilterCols .cols5 {
    width: calc(41.66666667% - 120px);
}

.spdFilterCols .cols2 {
    width: 16.66666667%;
}

.spdFilterCols .cols3 {
    width: 25%;
}

.spdFilterCols .cols2.inSearchCol {
    width: calc(16.66666667% + 120px);
}

.filterCols .inSearchCol .button.filterSearch {
    min-width: 90px;
    margin: 0;
    position: relative;
    bottom: auto;
    right: auto;
    float: right;
    top: 10px;
}

.orderId {
    font-size: 16px;
    color: #222;
    margin: -5px 0 0;
}

.orderId span {
    font-family: 'proxima_nova semiBold';
}

.timelineOrder .contTx {
    margin-bottom: 0;
}

.timelineOrder table tr td:first-child::before {
    top: 50%;
    height: 38px;
    margin-top: -19px;
}

.bspFilter .searchFilter {
    padding-right: 0;
}

.bspBorderBox {
    margin-bottom: 0;
    padding-bottom: 0;
    margin-top: 5px;
}

.barcodeCols {
    padding-right: 135px;
    position: relative;
    min-height: 100px;
}

.barcodeCols .barcode {
    position: absolute;
    right: 15px;
    top: 0;
    margin-top: 0;
}

.tableOut .siTable {
    min-width: 1000px;
}

.filterCols .reportBtn .filterSearch {
    position: absolute;
    top: 10px;
    right: 0;
}

.reportFilter .searchFilter {
    margin-bottom: 0;
}

.RIAfilter .filterCols {
    padding-right: 0;
}

.riaTable {
    min-width: 1120px;
}

.prTable {
    min-width: 1050px;
}

.sliderRangeNg .ng5-slider .ng5-slider-pointer {
    background-color: #706cce;
    width: 22px;
    height: 22px;
    top: -9px;
    margin-left: 5px;
}

.sliderRangeNg .ng5-slider .ng5-slider-pointer.ng5-slider-active:after {
    background-color: #ffffff;
}

.sliderRangeNg .ng5-slider .ng5-slider-pointer:after {
    top: 7px;
    left: 7px;
}

.sliderRangeNg {
    margin-bottom: 35px;
}

.sliderRangeNg .ng5-slider .ng5-slider-bubble {
    display: none;
}

.tableOut .anrTable {
    min-width: inherit;
}

.tableOut .nsTable {
    min-width: 400px;
}

.tableOut .olTable {
    min-width: 992px;
}

.tableOut .plTable {
    min-width: 992px;
}

.aspdFilter .filterCols {
    padding-right: 0;
}

.aspdFilter .cols5.colsP0 .cols6 {
    width: calc(50% - 60px);
}

.aspdFilter .cols5.colsP0 .cols6.inSearchCol {
    width: calc(50% + 60px);
}

.tableOut .aspdTable {
    min-width: 1150px;
}

.timelineRight.timelineMail .tableOut table {
    min-width: 640px;
}

.bhTitleRow .cols2 {
    width: 180px !important;
    float: right;
}

.bhTitleRow .cols10 {
    width: calc(100% - 180px) !important;
}

.tableOut .bhTable {
    min-width: 850px;
}

.addNBtn .addBtn {
    width: 100px;
    float: right;
    margin: 10px 0 0;
}

.addNBtn .feildCol {
    width: calc(100% - 130px);
    float: left;
}

.tableOut .sidTable {
    min-width: 1070px;
}

.tableOut .acdTable {
    min-width: 1024px;
}

.umFilter .filterCols {
    padding-right: 0;
}

.tableOut .umTable {
    min-width: 1024px;
}

.tableOut .odTable {
    min-width: 1100px;
}

.tableOut .crmTable {
    min-width: 1160px;
}

.tableOut .crmLeadsTable {
    min-width: 1160px;
}

.tableOut .aaTable {
    min-width: 1100px;
}

.tableOut .abpTable {
    min-width: 1090px;
}

.cancelSearchCol {
    position: relative;
}

.cancelSearchCol input[type='text'] {
    padding-right: 50px;
}

.cancelSearch {
    background: url('https://cdn.incentx.com/assets/images/svgs/close.svg') center center no-repeat;
    position: absolute;
    font-size: 0;
    float: right;
    width: 40px;
    height: 40px;
    right: 1px;
    top: 1px;
    cursor: pointer;
}

.datePicker .cancelSearch {
    top: 12px;
    right: 55px;
}

.spFilter .filterCols {
    padding-right: 0;
}

.tableOut .spTable {
    min-width: 1150px;
}

.pgFilter .filterCols {
    padding-right: 0;
}

.accordDetail .tableOut table {
    min-width: 1024px;
}

.tableOut .arpTable {
    min-width: 1024px;
}

.pvdFilter .filterCols {
    padding-right: 0;
}

.tableOut .pvdTable {
    min-width: 1024px;
}

#yearlyPipeline {
    min-width: 640px !important;
}

.leadProspectsRow .custLogo {
    top: 7px !important;
}

.timelineBorderBox {
    height: 996px;
}

.tableOut .addProTable {
    min-width: 1225px;
}

.multiBtnsRow .btnsGroup .button:last-child {
    float: right;
}

.tableOut .proDtlTable {
    min-width: 900px;
}

.tableOut .proDtlPriceTbl {
    min-width: 400px;
}

.tableTHnone .mat-header-row {
    display: none;
}

.customerDetail .icoOut {
    word-break: break-word;
}

.filter.memberFilter {
    padding-right: 0;
}

.memberFilter .button {
    position: relative;
    float: right;
    width: 190px;
    text-align: center;
    top: 0px;
    right: 0;
}

.memberFilter .searchBoxIn {
    float: left;
    width: calc(100% - 210px);
}

.fixedHeader thead {
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 9;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.serachTable.tableOut table {
    min-width: inherit;
}

.dyReportCols>div:nth-last-child(3) {
    width: 50%;
}

.ecaRemark {
    margin: 10px 0 0;
}

.reportFilter.YTDfilter .filterCols {
    padding-right: 120px !important;
}

.searchResultBox .form-group label {
    white-space: nowrap;
}

.ecaSearch .searchBoxIn {
    float: left;
    width: calc(100% - 250px);
}

.ecaSearch .button {
    float: right;
    margin-top: 10px;
    width: 220px;
    text-align: center;
}

.radioGroup .radio label {
    margin-bottom: 5px;
}

.genPass {
    margin-top: 18px;
}

.appConfigBtn {
    padding-right: 15px;
    margin-top: 15px;
}

.sliderRangeNg .ng5-slider {
    margin-top: 20px;
}

.fcoBorderBox .checkboxGroup label {
    margin-bottom: 10px;
}

.fcoBorderBox .sliderRangeNg {
    margin-top: 10px;
}

.mat-form-field .mat-datepicker-toggle {
    float: right;
    position: relative;
    right: -15px;
    padding: 6px 10px;
    height: 40px;
}

.reportBorderTop {
    margin-top: 15px;
}

.mat-menu-content button.mat-menu-item {
    padding: 0 15px !important;
    height: 35px;
    line-height: 35px;
    min-width: 150px;
}

.mat-menu-panel .mat-menu-content {
    padding: 10px 0 !important;
}

.tableOut .custActiveTbl {
    min-width: 1020px;
}

.subTitleBtnCol {
    float: right;
    margin: -10px 0 20px;
}

.ucsCols {
    margin-top: 25px;
}

.ucsData label {
    float: left;
    width: 50%;
    margin-top: 10px;
    color: #706cce;
}

.ucsCols {
    margin-top: 5px;
}

.ucsCols hr {
    margin-bottom: 23px;
}

.blockDate {
    display: block;
    vertical-align: top;
    font-size: 15px;
    line-height: 12px;
    color: #767676;
}

.newCols4 {
    width: 33.33333333%;
}

.timerBlock {
    display: inline-block;
    width: 100%;
    text-align: center;
}

.timerBox {
    display: inline-block;
    vertical-align: top;
    margin: 0 20px 25px;
    width: 95px;
    box-shadow: 0 10px 10px rgba(100, 100, 100, 0.2);
    border-radius: 10px;
    overflow: hidden;
}

.timerBox .timeCount {
    display: block;
    vertical-align: top;
    width: 100%;
    font-size: 48px;
    color: #fff;
    background: #6460b7;
    line-height: 60px;
    border-radius: 10px 10px 0 0;
    padding-top: 5px;
}

.timerBox .timeTx {
    display: block;
    width: 100%;
    background: #706cce;
    line-height: 30px;
    color: #fff;
    border-radius: 0 0 10px 10px;
    vertical-align: top;
    font-size: 18px;
}

.btnLink {
    display: inline-block;
    width: 100%;
    text-align: center;
    margin-top: 20px;
}

.btnLink a {
    color: #767676;
    font-size: 18px;
}

.btnLink a:hover {
    color: #333;
}

.paymentPopup {
    padding: 20px 60px 40px 60px;
}

.paymentPopup .imgUc {
    margin: 10px;
}

.subscriptionUpg .imgUc {
    margin: 30px 0px 25px 0px;
}

.fullBtn .upgradeBtn.button {
    max-width: 350px;
    margin: 0 auto;
    float: none;
    padding: 12px;
    height: auto;
}

.subscriptionUpg p {
    margin-bottom: 20px;
}

.subscriptionPopup .ucTitle {
    color: #000;
}

.paymentInfo .ucTitle {
    color: #000;
}

.subscriptionUpg .ucTitle {
    color: #000;
}

.planSection {
    position: relative;
    box-shadow: 80px 30px 50px #efeff2;
    margin-bottom: 50px;
}

.planSection:after {
    background: #e5e5ea;
    width: 80%;
    position: absolute;
    left: 10%;
    height: 20px;
    content: '';
    bottom: -20px;
    box-shadow: 80px 0px 50px #efeff2;
}

.popularPlan.planSection:after,
.planSection:hover:after {
    background: #88c9e6;
}

.planDetails {
    background: #fff;
    border: 1px solid #f2f2f2;
    padding: 20px;
    overflow: hidden;
    position: relative;
}

.planTitle {
    text-align: center;
    color: #000;
    border-bottom: 1px solid #e3e3e3;
    margin-bottom: 10px;
}

.planTitle img {
    margin: 15px auto;
}

.planTitle h3 {
    font-size: 28px;
    font-family: 'proxima_nova semiBold';
    margin-bottom: 20px;
    line-height: 32px;
}

.planTitle h4 {
    font-size: 40px;
    font-family: 'proxima_nova Bold';
    line-height: 32px;
    margin-bottom: 15px;
    color: #706cce;
}

.planTitle h4 span {
    display: block;
    font-size: 16px;
    text-align: center;
    color: #6b6b6b;
    font-weight: 100;
    font-family: 'proxima_nova';
}

.planButton .button {
    width: 100%;
}

.popularPlan .planDetails,
.planDetails:hover {
    background: rgba(42, 187, 206, 1);
    background: -moz-linear-gradient(left, rgba(42, 187, 206, 1) 0%, rgba(133, 121, 255, 1) 100%);
    background: -webkit-gradient(left top, right top, color-stop(0%, rgba(42, 187, 206, 1)), color-stop(100%, rgba(133, 121, 255, 1)));
    background: -webkit-linear-gradient(left, rgba(42, 187, 206, 1) 0%, rgba(133, 121, 255, 1) 100%);
    background: -o-linear-gradient(left, rgba(42, 187, 206, 1) 0%, rgba(133, 121, 255, 1) 100%);
    background: -ms-linear-gradient(left, rgba(42, 187, 206, 1) 0%, rgba(133, 121, 255, 1) 100%);
    background: linear-gradient(to right, rgba(42, 187, 206, 1) 0%, rgba(133, 121, 255, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#2abbce', endColorstr='#857afe', GradientType=1);
    color: #fff;
    border: 1px solid #83cce3;
}

.popularPlan .planTitle,
.planDetails:hover .planTitle {
    color: #fff;
    border-bottom: 1px solid #3f94c5;
}

.popularPlan .planTitle h4 span,
.planDetails:hover .planTitle h4 span {
    color: #fff;
}

.popularPlan .planButton .button,
.planDetails:hover .planButton .button {
    background: #fff;
    color: #56a4e3;
}

.planDetails .planTag {
    position: absolute;
    background: rgba(0, 0, 0, 0.2);
    color: #fff;
    padding: 5px 15px;
    transform: rotate(-45deg);
    height: 85px;
    padding-top: 60px;
    top: -40px;
    left: -55px;
    width: 120px;
    text-align: center;
    font-size: 14px;
}

.currentPlan .planButton .button {
    background: #cacaca;
    color: #fff;
    box-shadow: none;
}

.cardImg {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border: 1px solid #ffa813;
    overflow: hidden;
    float: left;
}

.cardImg img {
    max-width: 100%;
}

.cardNumber>span {
    display: block;
    font-size: 16px;
    color: #8491a4;
}

.cardNumber {
    font-size: 18px;
    color: #122752;
    line-height: 20px;
    width: calc(100% - 70px);
    float: left;
    padding: 3px 10px 3px 10px;
}

.cvvNumber {
    color: #057adc;
    font-size: 14px;
    margin-bottom: 20px;
    margin-top: 20px;
}

.cvvInput {
    max-width: 160px;
    position: relative;
    margin-top: 10px;
}

.cvvInput input {
    border: none;
    border-bottom: 1px solid #ccc;
    font-size: 40px;
    padding-left: 0;
}

.cvvStatus {
    position: absolute;
    right: 0;
    top: 0;
}

.paymentInfo p {
    font-size: 15px;
}

.savedCard {
    clear: both;
    display: block;
    width: 100%;
    background: #fff;
    box-shadow: 10px 10px 10px #efeff2;
    padding: 25px 25px 0;
    margin: 25px 0px 5px;
    position: relative;
    min-height: 100px;
}

.savedCard:before,
.savedCard:after {
    clear: both;
    display: table;
    content: '';
}

.savedCard .cardChange {
    float: right;
    position: absolute;
    right: 5px;
    top: 37px;
}

.mstrCard {
    border-color: #cc0001;
}

.defaultCard .cardNumber>span {
    color: #32c680;
}

.newCard {
    border-color: #0fa65f;
}

.savedCards .cols4 {
    float: none;
    display: inline-block;
    vertical-align: top;
}

.popBox .paymentCardDetail {
    border-bottom: 1px solid #d6d6d6;
}

.popBox .paymentCardDetail label {
    width: 100%;
    padding: 14px 8px;
    border-left: 3px solid #fff;
}

.popBox .radio .paymentCardDetail input[type='radio']+.radio-label:before {
    top: 16px;
    right: -35px;
}

.popBox .paymentCardDetail .cardNumber {
    width: calc(100% - 80px);
}

.popBox .paymentCardDetail.activeCard,
.popBox .paymentCardDetail input[type='radio']:checked+.radio-label {
    background: rgba(232, 249, 241, 1);
    background: -moz-linear-gradient(left, rgba(232, 249, 241, 1) 0%, rgba(255, 255, 255, 1) 100%);
    background: -webkit-gradient(left top, right top, color-stop(0%, rgba(232, 249, 241, 1)), color-stop(100%, rgba(255, 255, 255, 1)));
    background: -webkit-linear-gradient(left, rgba(232, 249, 241, 1) 0%, rgba(255, 255, 255, 1) 100%);
    background: -o-linear-gradient(left, rgba(232, 249, 241, 1) 0%, rgba(255, 255, 255, 1) 100%);
    background: -ms-linear-gradient(left, rgba(232, 249, 241, 1) 0%, rgba(255, 255, 255, 1) 100%);
    background: linear-gradient(to right, rgba(232, 249, 241, 1) 0%, rgba(255, 255, 255, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e8f9f1', endColorstr='#ffffff', GradientType=1);
    border-left-color: #32c680;
}

.popCard {
    width: 100%;
    padding: 0px 15px;
    position: relative;
}

.popCard .closeIcon {
    right: 0px;
    position: absolute;
    top: 30px;
}

.fileUploadInput h4 {
    font-size: 18px;
    font-weight: 600;
    color: #4a4a4a;
    line-height: 34px;
    display: block;
    margin-bottom: 5px;
    font-family: proxima_nova semiBold;
}

.supprtInst {
    color: hsl(0, 0%, 64%);
    font-size: 14px;
    font-weight: 600;
    display: block;
    font-family: proxima_nova semiBold;
}

.orStyle {
    position: relative;
    font-size: 16px;
    color: #a3a3a3;
    display: block;
    width: 30%;
    text-align: center;
    margin: 0 auto;
}

.orStyle:before {
    content: '';
    height: 1px;
    background: #a3a3a3;
    width: calc(50% - 20px);
    left: 0;
    top: 12px;
    position: absolute;
    opacity: 0.5;
}

.orStyle:after {
    content: '';
    height: 1px;
    background: #a3a3a3;
    width: calc(50% - 20px);
    right: 0;
    top: 12px;
    position: absolute;
    opacity: 0.5;
}

.fileUploadInput {
    height: 320px;
    padding: 10px;
    border: 1px solid #dddddd;
    display: block;
}

.addFileInput {
    background: #deedef;
    border: 1px solid #21b3c6;
    font-size: 15px;
    line-height: 20px;
    padding: 5px 14px;
    color: #21b3c6;
    margin: 10px auto;
    display: inline-block;
    font-family: 'proxima_nova';
    box-shadow: none;
}

.addFileInput:hover {
    background: #deedff;
    color: #21b3c6;
}

#csvUpload {
    opacity: 0;
    position: absolute;
}

.fileUploadInput>.csvUpload {
    height: 100%;
    width: 100%;
    background: #f4f4f4;
    border: 1px solid #dddddd;
    text-align: center;
    padding-top: 50px;
}

.fileImage,
.fileDetail {
    display: inline-block;
    vertical-align: top;
}

.fileImage {
    width: 30px;
    padding-top: 5px;
}

.fileDetail {
    width: calc(100% - 40px);
    padding: 0px 5px;
    position: relative;
}

.fileDetail h4 {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
    padding: 0;
    width: 85%;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: proxima_nova semiBold;
}

.fileProgText {
    font-size: 14px;
    font-weight: 600;
    font-family: proxima_nova semiBold;
}

.fileProgText span {
    color: #706cce;
}

.fileDetail .closeIcon {
    right: 0px;
    top: 5px;
    position: absolute;
    margin: 0;
}

.fileUploadInput .ngx-file-drop__drop-zone {
    height: 100% !important;
    border: none !important;
    height: 100% !important;
    width: 100% !important;
    background: #f4f4f4 !important;
    border: 1px solid #dddddd !important;
    text-align: center !important;
    padding-top: 0px !important;
}

.fileUploadInput .ngx-file-drop__content .csvUpload {
    width: 100%;
}

.fileUploadInput .ngx-file-drop__content {
    display: block;
    height: 100%;
    color: #000;
}

.fileUploadInput .ngx-file-drop__drop-zone {
    height: 100% !important;
    margin: auto !important;
    border: inherit !important;
    border-radius: 0 !important;
}

/****/
.backBtnPopup {
    width: 24px;
    height: 24px;
    background: url('https://cdn.incentx.com/assets/images/back.png') left top no-repeat;
    float: left;
    cursor: pointer;
    position: absolute;
    left: 30px;
    top: 23px;
}

.backBtnPopup:hover {
    opacity: 0.7;
}

.downloadIcon {
    background: url('https://cdn.incentx.com/assets/images/download.png') no-repeat top right;
    width: 22px;
    height: 21px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
}

.trialStampImg {
    margin-top: 5px;
    margin-left: 20px;
}

.fileUploadInput .ngx-file-drop__content {
    height: 100% !important;
}

.btnCSVcols {
    text-align: right;
    margin-top: 5px;
}

.csvBtn {
    width: auto !important;
    display: inline-block;
    padding: 9px 15px;
    height: 42px;
    margin: 0 30px 0 0 !important;
}

.btnCSVcols .feildCol.alignRight {
    margin: 0;
    min-height: inherit;
}

.fileStatus {
    margin-bottom: 20px;
}

.csvRadioRow .radioGroup {
    margin-top: 20px;
}

.csvFullCol {
    width: 100% !important;
}

.csvFullCol .feildCol {
    min-height: inherit;
    margin-bottom: 20px;
}

.justify {
    text-align: justify !important;
}

.EUPbtn {
    float: right;
    width: 200px !important;
    text-align: right;
    margin-top: 20px;
}

.eupSubscription {
    width: calc(100% - 200px) !important;
}

.orderPdetails .custDetail.custDetailFull {
    width: 100%;
    padding-right: 0;
}

.popCnt.closePopupSticky {
    max-height: 90vh;
}

.ucBox .popHeader p {
    display: inline-block;
    width: 100%;
    margin-bottom: 10px;
}

.ysccRow {
    margin-bottom: 35px;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
    background: #21b3c6 !important;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
    color: rgba(0, 0, 0, .87) !important;
}

.mat-option-text {
    font-size: 15px;
}

.mat-select-panel-wrap .ng-trigger {
    margin-top: 45px;
    margin-left: 25px;
    min-width: calc(100% + 30px) !important;
}

.piCardRadio .radio-label {
    width: calc(100% - 35px) !important;
}

.inputSelectOut .searchResultSection {
    min-width: 280px;
}

.inputSelectOut .serachTable.tableOut {
    max-height: 260px
}

.mat-select-arrow-wrapper {
    background: #fff url('https://cdn.incentx.com/assets/images/dropIcon.png') no-repeat 10px center !important;
    width: 30px;
    height: 30px;
    min-width: 25px;
}

.mat-paginator-page-size .mat-select-arrow-wrapper {
    height: 21px;
}

.mat-select-arrow {
    display: none !important;
}

.sendMailIcon {
    background: url('https://cdn.incentx.com/assets/images/svgs/sent_mail.svg') no-repeat 7px 11px;
    width: 28px;
    height: 30px;
}

a:hover .sendMailIcon {
    background-image: url('https://cdn.incentx.com/assets/images/svgs/sent_mail_hover.svg');
}

.txNote2 {
    font-size: 14px;
    display: block;
    line-height: 18px;
    color: #767676;
    margin-top: -4px;
    margin-bottom: 25px;
}

.inputSelect {
    padding-right: 35px !important;
}

.addQuotPro {
    margin: 0 -10px;
}

.addQuotPro .btnCol {
    float: left;
    width: 100px;
    margin-left: 10px;
    margin-right: 10px;
}

.borderBox .btnCol .button {
    width: 100%;
}

.addQuotPro .cols {
    padding: 0 10px;
    float: left;
}

.cols.colsName {
    width: 36%;
}

.cols.colsQty {
    width: 9%;
}

.cols.colsPrice {
    width: 10%;
}

.cols.colsDiscount {
    width: 10%;
}

.cols.colsTax {
    width: 10%;
}

.cols.colsUom {
    width: 15%;
}

.cols.colsTotal {
    width: calc(20% - 120px);
}

.padL0 {
    padding-left: 0 !important;
}

td.gtOuter {
    padding: 0 !important;
}

.groupingTable {
    border: 0 !important;
}

.tableData .groupingTable td:first-child {
    text-align: left;
    width: 200px;
}

.vTop {
    vertical-align: top !important;
}

tr:hover>td .groupingTable tr td {
    background: #f0f0fa !important;
}

.groupingTable tr td {
    vertical-align: middle !important;
}

.tableOut table.groupingTable {
    min-width: inherit !important;
}

.MnGroupingTable .width100 {
    width: 100px !important;
    min-width: 100px;
}

.MnGroupingTable .width200 {
    width: 200px !important;
    min-width: 200px;
}

.cols4Add .feildCol {
    width: calc(100% - 100px);
    float: left;
}

.cols4Add .button {
    width: 70px;
    float: right;
    margin-top: 10px;
    text-align: center;
}

.cols4Add .searchBoxIn {
    width: calc(100% - 100px);
    float: left;
}

.cols4Add .searchBoxIn .feildCol {
    width: 100%;
}

.groupingTable tr:last-child td {
    border-bottom: 0 !important;
}

.groupingTable tr td {
    border-bottom: 1px solid #e1e1e1 !important;
}

.textWrap {
    word-wrap: break-word;
}

.planDetails:hover .planTitle h4 {
    color: #fff;
}

.fileUpbtn {
    position: relative;
    border: 1px solid #ccc;
    min-height: 42px;
    cursor: pointer;
    padding-right: 97px;
}

.upldBtn:hover {
    background: #f0f0f0;
    min-height: 42px;
}

.fileNamelabel {
    padding: 8px;
    display: block;
    word-break: keep-all;
    overflow: hidden;
    white-space: nowrap;
}

.upldBtn {
    position: absolute;
    width: 90px;
    text-align: center;
    padding: 5px;
    right: 0;
    top: 0;
    border-left: 1px solid #ccc;
    min-height: 42px;
    line-height: 30px;
    background: #ccc;
}

.fileUpbtn input {
    opacity: 0;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 111;
    min-height: 42px;
    position: absolute;
    z-index: 111;
    cursor: pointer;
}

.hideEyeIcon {
    width: 24px;
    height: 22px;
    display: inline-block;
    background-position: -96px 0px;
    position: absolute;
    right: 12px;
    top: 12px;
    cursor: pointer;
}

.showEyeIcon {
    width: 24px;
    height: 22px;
    background-position: -84px -107px;
    position: absolute;
    right: 12px;
    top: 12px;
    cursor: pointer;
}

.passwordIcon {
    position: relative;
}

.passwordIcon .hideEyeIcon,
.passwordIcon .showEyeIcon {
    top: 8px;
}

.mat-form-field-wrapper input:focus {
    outline: none;
}

.userProfileIcon {
    background: url('https://cdn.incentx.com/assets/images/sprite.png') -127px -140px no-repeat;
}

.currentPlanIcon {
    background: url('https://cdn.incentx.com/assets/images/sprite.png') -155px -140px no-repeat;
}

a:hover .billingHistoryIcon {
    background: url('https://cdn.incentx.com/assets/images/sprite.png') -96px -170px no-repeat;
}

a:hover .userProfileIcon {
    background: url('https://cdn.incentx.com/assets/images/sprite.png') -127px -170px no-repeat;
}

a:hover .currentPlanIcon {
    background: url('https://cdn.incentx.com/assets/images/sprite.png') -155px -170px no-repeat;
}

.verifyEmailIcon::after {
    content: "\e92c";
    color: #05c1a0;
    font-family: 'IXPORTAL';
    position: relative;
    line-height: 24px;
    right: 4px;
    font-size: 14px;
}

.emailVerificationPendingBar {
    background: #fff;
    color: black;
}

.emailVerificationPendingBar button.mat-button {
    background: #169bb2;
    display: inline-block;
    color: #fff;
    border-radius: 0;
}

.emailStatus {
    padding: 12px 0px;
    display: inline-block;
    position: absolute;
    left: 40%;
    top: 5px;
}

.quoteDesign .orderBox {
    padding-left: 0;
}

.quoteDesign .orderAddress.cols6 {
    width: 50%;
    margin-top: 0;
    padding-top: 0;
    border: none;
}

.quoteDesign .orderAddress .addressBox {
    position: relative;
}

.quoteDesign .contDtl strong {
    font-size: 16px;
}

.quoteDesign .orderAddress .addressBox:first-child:before,
.quoteDesign .orderAddress .addressBox:first-child:after {
    border-right: 1px solid #e5e5e5;
    content: "";
    height: 100%;
    display: block;
    position: absolute;
    top: -3px;
    left: -5px;
    min-height: 145px
}

.quoteDesign .orderAddress .addressBox:first-child:after {
    left: auto;
    right: 5px;
}

.quoteDesign .feildCol {
    min-height: 55px;
}

.quoteDesign .addressBox .contTx {
    margin: 0 0 2px;
}

.quoteDesign .custDetail {
    width: 100%;
}

.quoteDesign .orderBox strong {
    font-size: 16px;
}

.searchResultBox th {
    font-size: 14px;
    padding: 4px 8px;
}

.searchResultBox td {
    font-size: 12px;
    line-height: 16px;
}

.mat-form-field-wrapper input:focus {
    outline: none;
}

.noPadd {
    padding: 0 !important;
}

.headingPart {
    background: rgba(42, 187, 206, 1);
    background: -moz-linear-gradient(left, rgba(42, 187, 206, 1) 0%, rgba(134, 121, 255, 1) 100%);
    background: -webkit-gradient(left top, right top, color-stop(0%, rgba(42, 187, 206, 1)), color-stop(100%, rgba(134, 121, 255, 1)));
    background: -webkit-linear-gradient(left, rgba(42, 187, 206, 1) 0%, rgba(134, 121, 255, 1) 100%);
    background: -o-linear-gradient(left, rgba(42, 187, 206, 1) 0%, rgba(134, 121, 255, 1) 100%);
    background: -ms-linear-gradient(left, rgba(42, 187, 206, 1) 0%, rgba(134, 121, 255, 1) 100%);
    background: linear-gradient(to right, rgba(42, 187, 206, 1) 0%, rgba(134, 121, 255, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#2abbce', endColorstr='#8679ff', GradientType=1);
    padding: 25px 30px 25px 40px;
    color: #fff;
}

.headingPart h3 {
    font-family: 'proxima_nova semiBold';
    font-size: 38px;
    line-height: 42px;
    margin-bottom: 20px;
}

.headingPart h4 {
    font-size: 58px;
    line-height: 42px;
    margin-bottom: 12px;
}

.headingPart h4 span {
    display: inline-block;
    font-size: 22px;
    opacity: 0.8;
    line-height: 24px;
    padding-left: 8px;
}

.headingPart ul li {
    background: url('https://cdn.incentx.com/assets/images/whiteBullet.png') no-repeat left 8px;
    padding-left: 30px;
    display: inline-block;
    margin-right: 15px;
}

.paddT20 {
    padding-top: 20px;
}

.planForm {
    color: #000;
    margin-bottom: 40px;
}

.addressBox.singleBlock {
    width: 50%;
    padding-left: 25px;
    float: right;
}

.addressBox.singleBlock:after {
    display: none !important;
}

.quoteDesign .subTitleRow h2 {
    margin-bottom: 5px;
    color: #706cce;
}

.verifyEmailInput {
    position: relative;
}

.verifyEmailInput .contIcon {
    position: absolute;
    right: 10px;
    top: 10px;
    text-align:center;
}

.leadProspectsRow .contListing {
    padding: 0px;
    min-height: 70px;
}

.leadProspectsRow .cpListing {
    min-height: 100px;
}

.leadProspectsRow .cols6 .custCntBx {
    min-height: 130px !important;
    min-height: 100px !important;
}

.leadProspectsRow .cols4 .custCntBx {
    margin-top: 0;
    min-height: 100px !important;
    max-height: 100px !important;
}

.leadProspectsRow .custCntBx {
    padding: 10px 15px;
}

.leadProspectsRow .contListingBox {
    margin: 0px 0px 15px 0px;
}

.padR0 {
    padding-right: 0 !important;
}

.nameColumn {
    width: 250px !important;
}

.contDtlBox .borderBox {
    padding: 10px;
}

.contBtn.titleBtnCol {
    position: absolute;
    right: 30px;
    top: 5px;
}

.contBtn.titleBtnCol .button {
    padding: 5px 10px;
    font-size: 16px;
    height: auto;
    margin-top: 5px;
}

.right.secondaryTitle {
    float: right;
}

.subTitleRow .right.secondaryTitle {
    margin-top: -8px;
}

.right.secondaryTitle h4 {
    font-size: 14px;
    margin: 0;
    padding: 0;
    line-height: 12px;
    display: inline-block;
}

.right.secondaryTitle span {
    font-size: 13px;
}

.widthauto {
    width: auto !important;
}

.loginContainer.verificationScreen {
    max-width: 495px;
    box-shadow: 0px 20px 40px #d8d8d9;
}

.verificationScreen h3 {
    margin-bottom: 0;
    color: #222222;
    font-size: 40px;
    font-family: 'proxima_nova semiBold';
    text-align: center;
    margin-top: 20px;
}

.verificationScreen p {
    margin-bottom: 0;
    color: #767676;
    font-size: 18px;
    font-family: 'proxima_nova semiBold';
    text-align: center;
}

.loginContainer.verificationScreen .loginForm .payBtn {
    margin-top: 22px;
}

.signUpContainer {
    max-width: 1090px;
    margin: 40px auto 100px auto;
}

.packageCart {
    background: #fff;
}

.packageCart h3 {
    font-size: 24px;
    font-family: 'proxima_nova semiBold';
    color: #222222;
}

.packageCart ul {
    padding: 0;
    margin: 0;
}

.packageCart ul li {
    list-style: none;
    position: relative;
    border-bottom: 1px solid #d9d9d9;
    margin-bottom: 10px;
}

.packageCart ul li .blueColor {
    color: #706cce;
}

.packageCart ul li h3 {
    font-size: 16px;
    font-family: 'proxima_nova semiBold';
    color: #222222;
    margin-bottom: 1px;
    line-height: 20px;
}

.packageCart ul li h4 {
    font-size: 16px;
    font-family: 'proxima_nova';
    color: #222222;
    text-align: left;
    margin-bottom: 10px;
}

.packageCart ul li .blueColor {
    font-family: 'proxima_nova semiBold';
}

.noBdr {
    border: none !important;
}

.packageSubTotal {
    font-size: 18px;
    padding-bottom: 8px;
}

.packageCart ul li.totalCoast {
    margin-bottom: 25px;
}

.packageCart ul li.totalCoast h3 {
    margin-bottom: 0;
}

.packageCart ul li.totalCoast h4 {
    font-size: 24px;
    margin-bottom: 0;
}

.modifyPackage {
    font-size: 12px;
    font-style: italic;
    clear: both;
    display: block;
    font-family: 'proxima_nova semiBold';
}

.modifyPackage a {
    font-size: 15px;
    font-family: 'proxima_nova semiBold';
    color: #21b3c6;
    text-decoration: underline;
    float: right;
    font-style: normal;
}

.signUpRightSec {
    padding-left: 25px;
    border-left: 1px solid #e0e0e0;
}

.signUpForm .cols8 {
    padding-right: 25px;
}

.signUpRightSec>h4 {
    font-size: 18px;
    margin-bottom: 8px;
    color: #222222;
    font-family: 'proxima_nova semiBold';
}

.planFeatureList {
    border-bottom: 1px solid #d9d9d9;
    padding: 0;
    margin: 0;
    padding-bottom: 25px;
    font-size: 15px;
    margin-bottom: 25px;
}

.planFeatureList li {
    margin-bottom: 5px;
    background: url('https://cdn.incentx.com/assets/images/subscription-plan-green.png') no-repeat 0px 5px;
    padding-left: 25px;
    list-style: none;
}

.packageCart h3 {
    font-size: 18px;
    margin-bottom: 10px;
}

.contQuestion {
    margin: 0px;
    text-align: center;
    font-size: 20px;
    background: #f0f0fa;
    padding: 15px;
}

.contQuestion .callIcon {
    margin-right: 5px;
}

.contQuestion h4 {
    font-size: 16px;
    color: #222;
    padding: 8px 30px;
    margin-bottom: 0px;
    line-height: 20px;
    font-family: 'proxima_nova semiBold';
}

.contQuestion h5 {
    font-size: 16px;
    font-family: 'proxima_nova';
    color: #6b6b6b;
    margin-bottom: 5px;
}

.contQuestion a {
    color: #21b3c6;
    font-size: 16px;
}

.termConditions {
    font-size: 17px;
    color: #5c5c5c;
}

.termConditions .popTitle {
    font-size: 25px;
}

.termConditions h3 {
    font-size: 24px;
    color: #222222;
    font-family: 'proxima_nova semiBold';
    margin-bottom: 10px;
    margin-top: 25px;
}

p.italicText {
    font-style: italic;
    font-size: 15px;
    color: #5c5c5c;
}

.termConditions ul li {
    list-style: upper-alpha;
    padding-left: 5px;
    margin-bottom: 20px;
}

.cvvField {
    position: relative;
}

.cvvInfo {
    width: 455px;
    border: 1px solid #4ebecd;
    background: #fff;
    z-index: 111;
    right: 15px;
    bottom: 50px;
    top: auto;
    padding: 15px 25px;
    text-align: center;
    white-space: break-spaces;
}

.cvvInfo .cols6 {
    padding: 0px 10px;
}

.cvvInfo .cols6:first-child {
    border-right: 1px solid #e5e5e5;
}

.cvvInfo h4 {
    font-size: 15px;
    font-family: 'proxima_nova semiBold';
    color: #222222;
    text-transform: uppercase;
    margin-bottom: 2px;
}

.cvvInfo p {
    font-size: 14px;
    margin-bottom: 0;
    line-height: 22px;
    color: #4a5861;
}

.cvvInfo>p {
    font-size: 16px;
    font-family: 'proxima_nova semiBold';
    margin-bottom: 12px;
    line-height: 22px;
}

.signUpNew#wrapper {
    height: auto;
}

.inlineBlock {
    display: inline-block;
}

.infoTooltip {
    position: absolute;
    right: 8px;
    top: 8px;
}

.cardRow {
    margin: 0px -30px;
    padding: 20px 35px;
    background: #f3f3f3;
}

.popBox .cardRow .paymentCardDetail label,
.popBox .cardRow .paymentCardDetail.activeCard,
.popBox .cardRow .paymentCardDetail input[type='radio']:checked+.radio-label {
    background: none !important;
    border-left: 0 !important;
}

.popBox .paymentCardDetail .cardNumber {
    position: relative;
}

.popBox .cardRow .paymentCardDetail .inputField label {
    padding: 0px 2px !important;
    background-color: #fff !important;
    width: auto !important;
}

.cvv {
    width: 130px;
    position: absolute;
    top: -5px;
    right: 170px;
    min-height: 20px;
}

.cvv input {
    background: #fff url('https://cdn.incentx.com/assets/images/cvv-img.jpg') no-repeat 95% 9px;
}

.incentexAnim {
    height: 115px;
    display: block;
    padding: 15px;
}

.savingData,
.paySuccess,
.payAlert {
    max-width: 85px;
    display: none;
    text-align: center;
    margin: 0 auto;
}

.savingData img {
    margin: 0 auto;
    text-align: center;
}

.currentState {
    display: block;
    text-align: center;
}

.animatedLogo {
    text-align: center;
    padding-top: 15px;
}

.animatedLogo h3 {
    font-size: 24px;
    font-family: 'proxima_nova semiBold';
    color: #fff;
    margin-bottom: 5px;
}

.animatedLogo ul li {
}

.animatedLogo p {
    color: #fff;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 0;
}

.transparentPop .popCnt {
    top: calc(48% - 130px);
}

.transparentPop .popBox {
    background: transparent;
}

.animatedText {
    text-indent: 8px;
    display: block;
    position: relative;
}

.firstStep,
.secondStep,
.thirdStep {
    display: none;
}

.firstStep.activeStep,
.secondStep.activeStep,
.thirdStep.activeStep {
    display: block;
}

.signupSuccess {
    text-align: center;
}

.signupSuccess .incentexAnim {
    padding-top: 45px;
}

.signupSuccess .popCnt {
    top: calc(48% - 148px);
}

.signupSuccess h3 {
    color: #222;
    font-family: 'proxima_nova semiBold';
    margin-bottom: 5px;
}

.popContet {
    position: relative;
}

.popupTermCheck {
    position: sticky;
    bottom: 0;
    padding: 0px 15px 10px 30px;
    margin: 0px -30px;
    overflow: hidden;
    background: #706cce;
}

.popupTermCheck.termUnRead {
    background: #f0f0fa;
}

.popupTermCheck.termUnRead label {
    color: #5c5c5c;
    font-size: 16px;
}

.popupTermCheck label {
    color: #fff;
    font-size: 16px;
}

.termConditions .form-group label:before {
    background-color: #fff;
}

.termCon {
    padding-bottom: 0;
}

.termConditions .popBox {
    padding-bottom: 0;
}

.termCheckContainer {
    position: relative;
}

.termCheckContainer {
    position: sticky;
    bottom: 0px;
    width: calc(100% - 0px);
}

.termUnchecked {
    position: absolute;
    background: #ff4342;
    max-width: 485px;
    bottom: 60px;
    color: #fff;
    font-size: 16px;
    padding: 10px 15px 10px 65px;
    box-shadow: 0px 4px 10px #f3f3f3;
    display: none;
}

.termUnchecked.plsCheckIt {
    display: block;
}

.termUnchecked .termUnchecked .termErrorIcon {
    width: 45px;
    height: 45px;
    position: absolute;
    top: 8px;
    left: 8px;
}

span.termErrorIcon {
    display: block;
    position: absolute;
    left: 10px;
    top: 10px;
}

span.closeTermError {
    display: block;
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
}

.form-group label:before {
    background-color: #fff;
}

.temrGroup {
    position: relative;
}

.temrGroup label:before {
    content: '';
    -webkit-appearance: none;
    background-color: #fff;
    border: 1px solid #c8c8c8;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 10px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 12px;
    border-radius: 2px;
}

.temrGroup input[type="checkbox"] {
    opacity: 0;
    visibility: hidden;
    display: none;
}

.temrGroup input:checked+label:before {
    background: #fff;
    border: 1px solid #706cce;
    border-radius: 2px;
}

.temrGroup input:checked+label:after {
    content: '';
    display: block;
    position: absolute;
    top: 6px;
    left: 8px;
    width: 6px;
    height: 12px;
    border: solid #706cce;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.zipCodeText {
    color: #21b3c6;
    font-size: 14px;
    font-family: 'proxima_nova semiBold';
    font-style: italic;
    letter-spacing: 0px;
}

.priceCheck {
    min-width: 80px;
    min-height: 30px;
    display: inline-block;
}

.loadingPrice {
    display: inline-block;
    vertical-align: middle;
    height: 22px;
    width: 22px;
    background: url('https://cdn.incentx.com/assets/images/price-loader.png') 0px 0px no-repeat;
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(359deg);
    }

}

.custSpinner {
    animation: rotation 2s infinite linear;
}

.rightBar .titleRow h1 {
    margin-bottom: 20px;
    line-height: 30px;
}

.rightBar .titleRow h3 {
    font-size: 16px;
}

.paymentPlans>h3 {
    font-size: 24px;
    line-height: 28px;
    color: #222222;
    font-family: 'proxima_nova semiBold';
    margin-bottom: 8px;
}

.paymentPlans>h4 {
    font-size: 17px;
    font-family: 'proxima_nova';
    color: #6a6a6a;
    text-align: left;
    margin-bottom: 15px;
}

.planBlock {
    border: 1px solid #dadada;
    background: #fff;
    box-shadow: 1px 5px 20px #e1e1e1;
    padding: 24px;
    margin-bottom: 30px;
    min-height: 375px;
}

.planBlock h3 {
    font-size: 22px;
    font-family: 'proxima_nova semiBold';
    color: #222222;
    margin-bottom: 10px;
    border-bottom: 1px solid #d9d9d9;
    padding-bottom: 10px;
    position: relative;
    padding-right: 100px;
}

.planBlock .digitsCount {
    position: absolute;
    right: 0px;
    top: 10px;
}

.planBlock h3 .userPlan {
    color: #787878;
    font-size: 16px;
    display: block;
    font-family: 'proxima_nova';
}

.planBlock h3 .userPlan span {
    font-size: 18px;
    font-family: 'proxima_nova semiBold';
}

.seprator {
    border-top: 1px solid #d8d8d8;
    width: 100%;
    height: 0px;
    margin: 45px 0px;
}

.insideApp.packageCart {
    box-shadow: -1px 6px 40px #e1e1e1;
    background: #fff;
    padding: 25px;
    padding-right: 35px;
    margin-left: 35px;
}

.insideApp.packageCart h3 {
    font-size: 23px;
    font-family: 'proxima_nova semiBold';
    color: #222222;
    margin-bottom: 0;
    line-height: 20px;
}

.packageCart.insideApp h4 {
    font-size: 16px;
    color: #787878;
    margin-bottom: 15px;
}

.insideApp.packageCart ul.sideBdrList li:before {
    content: "";
    height: calc(100% + 1px);
    width: 3px;
    background: #2abbce;
    display: block;
    position: absolute;
    left: -15px;
    top: 0;
}

.insideApp.packageCart ul.sideBdrList li {
    margin-bottom: 0;
    padding: 10px 0px 5px 0px;
}

.insideApp.packageCart ul {
    padding: 0;
    margin: 0px 0px 20px 0px;
    width: calc(100% - 15px);
    float: right;
}

.insideApp.packageCart ul li {
    list-style: none;
    position: relative;
    border-bottom: 1px solid #d9d9d9;
    margin-bottom: 15px;
}

.insideApp.packageCart ul li h3 {
    font-size: 18px;
    font-family: 'proxima_nova semiBold';
    color: #222222;
    margin-bottom: 5px;
    line-height: 20px;
}

.insideApp.packageCart ul li.totalCoast h3 {
    color: #464646;
    font-size: 16px;
}

.insideApp.packageCart ul li.totalCoast h4 {
    line-height: 24px;
}

.insideApp.packageCart ul li h4 {
    font-size: 16px;
    font-family: 'proxima_nova';
    color: #787878;
    text-align: left;
    margin-bottom: 0;
}

.insideApp.packageCart ul li .blueColor {
    font-family: 'proxima_nova semiBold';
}

.noBdr {
    border: none !important;
}

.signUpbtn {
    background: #21b3c6;
    padding: 15px 10px;
    text-align: center;
    color: #fff;
    font-size: 20px;
    font-family: 'proxima_nova semiBold';
    display: block;
    margin-top: 15px;
    box-shadow: 0px 2px 20px #bdf7ff;
}

.signUpbtn:hover {
    color: #fff;
}

.insideApp.packageCart ul li.totalCoast h4 {
    font-size: 26px;
}

.digitsCount {
    border: 1px solid #706cce;
    border-radius: 2px;
    background: #706cce;
    color: #fff;
    font-size: 16px;
}

.digitsCount input[type="button"] {
    background: #fff;
    color: #706cce;
    text-align: center;
    padding: 1px 4px 4px 4px;
    width: 30px;
    height: 30px;
    border: none;
    display: inline-block;
    margin: 0;
    vertical-align: top;
    font-size: 28px;
    font-family: Georgia, sans-serif;
    line-height: 20px;
    box-shadow: none !important;
}

.digitsCount input[type="button"]:focus,
.digitsCount input[type="button"]:hover {
    outline: none;
    background: #eee;
}

.digitsCount .count {
    padding: 4px 8px;
    height: 30px;
    display: inline-block;
    margin: 0;
    vertical-align: top;
    width: 35px;
    text-align: center;
}

.insideApp.packageCart.currentPack {
    margin-left: 0;
}

.insideApp.packageCart.currentPack li {
    padding-right: 25px;
    margin-right: 15px;
    border-bottom: none;
    border-right: 1px solid #d9d9d9;
}

.insideApp.packageCart.currentPack li.totalCoast {
    background: none;
    padding-left: 10px;
}

.insideApp.packageCart.currentPack ul li.totalCoast h3 {
    margin-bottom: 5px;
    color: #767676;
    font-family: 'proxima_nova';
    font-size: 14px;
}

.insideApp.packageCart.currentPack ul li.totalCoast h4 {
    font-size: 16px;
}

.noBgr {
    background: none;
}

.digitsCount.noBgr {
    box-shadow: none;
}

.planBlock.activePlan {
    border-color: #706cce;
}

span.relativeRadio {
    position: absolute;
    right: 5px;
    top: 0px;
}

.recomSeries .planBlock {
    min-height: 100px;
}

.paymentPlans .planBlock .rightText.planBullets {
    overflow: auto;
}

.relativeRadio input[type="checkbox"] {
    opacity: 0;
}

.relativeRadio input[type="checkbox"]+label.digitsCount.radio {
    background: url(https://cdn.incentx.com/wp-content/2020/02/28015935/select-plan.png) no-repeat 0px 0px;
    box-shadow: none;
    width: 27px;
    height: 27px;
    padding-bottom: 0;
    display: block;
}

.relativeRadio input[type="checkbox"]:checked+label.digitsCount.radio {
    background: url(https://cdn.incentx.com/wp-content/2020/02/28015935/selected-plan.png) no-repeat 0px 0px;
    box-shadow: none;
    width: 27px;
    height: 27px;
    padding-bottom: 0;
    display: block;
}

.termConditions,
.termnService {
    min-height: 250px;
}

.upLeftBtn {
    max-width: 200px !important;
    text-align: right;
    float: right;
    margin: 0;
}

.panelWithBtn {
    width: calc(100% - 160px);
    float: left;
}

.disabled-forcefuly {
    pointer-events: none !important;
    opacity: 0.8;
}

.popAccordionlist {
    margin-left: 70%;
    position: absolute;
}

.width130 {
    width: 130px !important;
    min-width: 130px;
}

.iconDone,
.iconDone:hover {
    background-position: -214px -171px;
}

.absBtnTop {
    float: right;
    margin-top: -70px;
    margin-right: 0;
    padding-right: 0;
}

.absBtnTop .remarkBx.fullNote {
    padding: 10px 15px;
    float: right;
}

.newCustomerForm .custIcon {
    float: left;
}

.newCustomerForm .companyDetails {
    width: calc(100% - 85px);
    float: right;
}

.newCustomerForm .companyDetails h3 {
    font-size: 14px;
    color: #767676;
    margin-bottom: 0px;
}

.newCustomerForm .companyDetails p {
    font-size: 13px;
    color: #767676;
    letter-spacing: 0;
    margin-bottom: 0;
}

.newCustomerForm .custIcon {
    position: relative;
    overflow: visible;
}

.newCustomerForm .custIcon {
    background: url('https://cdn.incentx.com/assets/images/upload-logo.png') center center no-repeat;
    background-size: 45%;
}

.custLogoEdit {
    width: 20px;
    height: 20px;
    background: #706cce url('https://cdn.incentx.com/assets/images/edit-icon.png') center center no-repeat;
    position: absolute;
    right: 2px;
    bottom: 8px;
    display: block;
    border-radius: 50%;
}

.custLogoEdit.addLogo {
    background: #706cce url('https://cdn.incentx.com/assets/images/plus-icon.png') center center no-repeat;
}

.genPassUrl {
    font-size: 14px;
    color: #706cce;
    border-bottom: 1px solid rgba(112, 108, 206, 0.5)
}

.trialCheck.form-group label {
    color: #999;
    font-size: 18px;
    color: #767676;
}

.addTextArea {
    height: 42px;
    resize: none;
    min-height: 0;
}

.newCustomerForm .button.payBtn {
    height: auto;
}

.newCustomerPage .insideApp.packageCart {
    margin: 0;
}

.newCustomerPage .digitsCount {
    position: absolute;
    display: inline-block;
    top: 12px;
    right: 0;
}

.form-group.custCheckbox {
    position: absolute;
    right: 0px;
    top: 15px;
}

.newCustomerPage .sideBdrList {
    margin-top: 10px !important;
}

.newCustomerPage .insideApp.packageCart {
    position: relative;
}

.packageDisabledOverlay {
    background: rgba(255, 255, 255, 0.6);
    position: absolute;
    z-index: 111;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.contentRow .signUpForm .cols8 {
    padding-left: 0px;
}

.custDetailFull .button {
    margin-top: -20px;
}

.contentRow .signUpForm .cols8 .packageCart.currentPack .panelWithBtn {
    width: 100%;
}

.contentRow .signUpForm .cols8 .insideApp.packageCart.currentPack li {
    width: 33%;
    display: inline-block;
    margin-right: 0;
    padding: 15px 5px 15px 25px;
}

.contentRow .signUpForm .cols8 .insideApp.packageCart ul li .blueColor {
    display: block;
}

.contentRow .signUpForm {
    margin-top: 0;
}

.borderBox .insideApp.packageCart {
    box-shadow: none !important;
    padding: 0 !important;
}

.borderBox .insideApp.packageCart .panelWithBtn {
    border-bottom: 1px solid #d9d9d9;
    width: 100%;
}

.planBlock {
    padding: 24px 24px 15px 24px;
    min-height: 0;
}

.featureLink {
    text-align: center;
    font-size: 16px;
    margin-top: 10px;
}

.featurePopup h2 {
    text-align: center;
    font-size: 28px;
    line-height: 28px;
}

.featurePopup h2 div {
    color: #787878;
    font-size: 16px;
}

.featurePopup h2 .highlight {
    font-size: 20px;
}

.featurePopup h4 {
    font-size: 18px;
    font-family: 'proxima_nova Bold';
    margin-bottom: 0;
    color: #000;
}

.cartInfoIcon {
    position: absolute;
    right: 10px;
    top: 0;
}

.iconCartInfo {
    height: 20px;
    width: 20px;
    display: inline-block;
    vertical-align: top;
    background-position: right bottom;
}

.loaderParent {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    background: rgba(0, 0, 0, .5);
    height: 100%;
    z-index: 11;
    text-align: center;
}

.loaderParent .mat-progress-spinner {
    left: calc(50% - 50px);
    top: calc(50% - 50px);
}

.posRelativeAndWidth {
    position: relative;
    min-height: 200px
}

.numberBlock .contractIcon img {
    max-width: 60px;
}

.numberBlock .blockText {
    margin-top: 0;
    vertical-align: middle;
}

.statisticBlock.newStBlock .row {
    margin: 0px -8px !important;
}

.statisticBlock.newStBlock .row>[class^=cols] {
    margin-bottom: 15px;
    padding: 0px 7px;
    transition: all .25s ease;
}

.statisticBlock .numberBlock {
    border: 1px solid #fff;
}

.statisticBlock .numberBlock:hover {
    transform: translateY(-4px) scale(1.02);
    box-shadow: 0 14px 24px rgba(62, 57, 107, .2);
    z-index: 30;
    transition: all .25s ease;
    background-color: #fff !important;
}

.appSettingTabs {
    padding: 0;
    background: #fff;
}

.appSettingTabs .cols2,
.appSettingTabs .cols8 {
    padding: 0;
}

.vertTabTitle {
    padding: 13px 20px;
    border-bottom: 1px solid #e1e1e1;
    background: #fff;
}

.vertTabTitle h3 {
    margin-bottom: 0;
    font-size: 18px;
    color: #000;
    line-height: 42px;
    font-family: 'proxima_nova Bold';
}

.contentFilter {
    padding: 13px 14px;
}

.contentFilter .cols2 {
    padding-left: 0;
}

.contentFilter label {
    margin-bottom: 0;
    font-size: 18px;
    color: #000;
    line-height: 42px;
}

.appSettingTabs .cols2 {
    height: 100%;
}

.verticleTab {
    height: 100%;
}

.appTabFirst {
    background: #f8f8f8;
}

.appTabFirst,
.appTabSecond {
    border-right: 1px solid #dfdfdf;
}

.verticleTab ul {
    padding: 0;
    margin: 0;
}

.verticleTab ul li {
    list-style: none;
}

.verticleTab ul .tabLink {
    color: #565656;
    font-size: 18px;
    padding: 10px 20px;
    display: block;
    border-left: 3px solid transparent;
    font-family: 'proxima_nova semiBold';
    position: relative;
}

.verticleTab ul .tabLink:hover,
.tabLink.currAppTab {
    border-left: 3px solid #6e6ab2 !important;
    background: #eaeaf4;
    color: #000 !important;
}

.appSettingTabs .tableOut table {
    border-top: 1px solid #e1e1e1;
    margin: 0 auto;
    width: 98%;
}

.appSettingTabs .tableOut {
    margin: 0;
    overflow: auto;
    height: 100%;
}

.activeArrow {
    position: relative;
    background: #ffffff;
}

.activeArrow:after {
    right: -1px;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(255, 255, 255, 0);
    border-right-color: #ffffff;
    border-width: 10px;
    margin-top: -10px;
}

.appSettingTabs .tableOut table td {
    padding: 15px;
    font-size: 16px;
}

.appSetSection {
    height: 400px;
    overflow: hidden;
}

.iconDown {
    width: 24px;
    height: 22px;
    float: left;
    background-position: -212px -218px;
}

.iconUp {
    width: 24px;
    height: 22px;
    float: left;
    background-position: -240px -218px;
}

.customSelectColorsListPanel .mat-option {
    padding-left: 30px;
}

.customSelectColorsListPanel .mat-option .colorCode {
    width: 9px;
    height: 9px;
    display: block;
    background: #000;
    position: absolute;
    top: 40%;
    left: 12px;
    border-radius: 50% !important;
}

.customSelectColorsList .mat-select .mat-select-trigger .mat-select-arrow-wrapper {
    line-height: 38px;
    background: transparent url('https://cdn.incentx.com/assets/images/dropIcon.png') no-repeat 12px center !important;
}

.mat-select-panel-wrap .customSelectColorsListPanel .mat-select-panel .mat-option {
    height: auto !important;
}

.customSelectColorsList .mat-form-field {
    width: 100%;
}

.customSelectColorsList .mat-select .mat-select-trigger .mat-select-value {
    line-height: 38px !important;
}

.appSettingHead {
    padding: 13px 20px;
    border-bottom: 1px solid #e1e1e1;
    background: #fff;
}

.appSettingHead h3 {
    margin-bottom: 0;
    font-size: 18px;
    color: #000;
    line-height: 42px;
    font-family: 'proxima_nova semiBold';
}

.appTabFirst .vertTabTitle {
    background: #f8f8f8;
}

.mat-select-panel-wrap .customSelectColorsListPanel.ng-trigger {
    margin-left: 0 !important;
    border: 1px solid #706cce;
}

.mat-select-panel-wrap .customSelectColorsListPanel.ng-trigger .mat-option {
    height: auto !important;
}

.customSelectColorsList .mat-form-field.mat-focused.mat-primary .mat-select .mat-select-trigger .mat-select-arrow-wrapper {
    line-height: 38px;
    background: transparent url('https://cdn.incentx.com/assets/images/dropIcon.png') no-repeat 10px center !important;
    transform: rotate(180deg);
}

.customSelectColorsList .mat-form-field.mat-primary .mat-select .mat-select-trigger {
    position: relative;
    padding-left: 15px;
}

.customSelectColorsList .mat-form-field.mat-primary .mat-select .mat-select-trigger .colorCode {
    width: 9px;
    height: 9px;
    display: block;
    background: #000;
    position: absolute;
    top: 40%;
    left: 0px;
    border-radius: 50% !important;
    content: "";
}

.packageStatus {
    margin-bottom: 5px;
}

.packageStatus h4 {
    font-size: 16px;
    color: #787878;
    padding: 0;
    margin: 0px 0px 8px 0px;
    text-align: left;
}

.packageStatus h4 .salerName {
    color: #706cce;
}

.tabTitles,
.tabDetails {
    height: 100%;
    overflow: auto;
    max-height: 840px;
}

.notificationMsgTab .custDis {
    position: relative;
    padding: 20px 35px 20px 25px;
    width: 100%;
    text-align: left;
    cursor: pointer;
}

.notificationMsgTab .custDis h4 {
    color: #222222;
    font-size: 17px;
    font-family: 'proxima_nova semiBold';
    margin-bottom: 5px;
    line-height: 20px;
}

.notificationMsgTab .custDis h5 {
    color: #222222;
    font-size: 15px;
    font-family: 'proxima_nova';
    margin-bottom: 5px;
    line-height: 18px;
}

.notificationMsgTab .custDis h5 span {
    color: #6e6aaf;
}

.notificationMsgTab .custDis .contTx.icoDate {
    color: #565656;
    margin-bottom: 0;
}

.notificationMsgTab .custDis .contTx .date-img {
    top: 1px;
}

.notificationMsgTab .custDis .actBtn {
    position: absolute;
    right: 8px;
    top: 40%;
}

.notificationMsgTab {
    background: #fff;
    clear: both;
    display: block;
    width: 100%;
}

.notificationMsgTab .tabTitles {
    width: 28%;
    overflow: auto;
    height: 100%;
    display: inline-block;
    vertical-align: top;
    border-right: 1px solid #dadee0;
}

.notificationMsgTab .tabTitles[hidden] {
    display: none;
}

.notificationMsgTab .tabDetails {
    padding: 22px;
    width: calc(100% - 29.5%);
    display: inline-block;
    vertical-align: top;
}

.notificationTabContainer .notiMessageTitle h4 {
    color: #222222;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 5px;
}

.notificationTabContainer .notiMessageTitle h5 {
    font-size: 17px;
    color: #222;
    margin-bottom: 5px;
}

.notificationTabContainer .notiMessageTitle h5 span {
    color: #6e6aaf;
}

.notificationMsgTab .notiMessageTitle {
    border-bottom: 1px solid #e5e5e5;
    margin-bottom: 15px;
    padding-bottom: 10px;
}

.notificationMsgTab .notiMessageTitle .contTx .date-img {
    top: 1px;
}

.notificationMsgTab .custDis.unreadNtfc:before {
    height: 100%;
    width: 3px;
    background: #6e6aaf;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
}

.notificationMsgTab .activeNtfc {
    border: 1px solid #2abbce;
    box-shadow: 0px 0px 20px #d3d3d3;
    z-index: 111;
    overflow: hidden;
    cursor: pointer;
}

.activeNtfc {
    position: relative;
    background: #ffffff;
    border: 3px solid #2abbce;
}

.ntfcTable td {
    padding: 0 !important;
    text-align: left;
}

.ntfcTable.tableData {
    margin-bottom: 0;
}

.ntfcTable.tableData td:first-child {
    width: 100% !important;
}

.tableData.ntfcTable.mat-table tr th {
    padding: 0;
    margin: 0;
    height: 0;
    border: 0;
    line-height: 0;
}

.tableData.ntfcTable tr.mat-header-row {
    height: auto !important;
}

.notificationMsgTab .tabTitles .mat-paginator-range-label {
    margin: 0 5px 0 5px !important
}

.notificationMsgTab .mat-paginator-page-size {
    margin: 5px auto !important;
}

.notificationMsgTab .mat-paginator-range-actions {
    margin: 5px auto !important;
}

.loginContainer.verificationScreen {
    max-width: 495px;
    box-shadow: 0px 20px 40px #d8d8d9;
}

.verificationScreen h3 {
    margin-bottom: 0;
    color: #222222;
    font-size: 40px;
    font-family: 'proxima_nova semiBold';
    text-align: center;
    margin-top: 20px;
}

.verificationScreen p {
    margin-bottom: 0;
    color: #767676;
    font-size: 18px;
    font-family: 'proxima_nova semiBold';
    text-align: center;
}

.loginContainer.verificationScreen .loginForm .payBtn {
    margin-top: 22px;
}

.authyCode .verificationCode {
    border: 1px solid #c8c8c8;
    width: 99%;
}

.authyCode {
    color: #222222;
    font-size: 16px;
}

.authyCode .button {
    margin-bottom: 10px;
}

a.downAppy {
    color: #ec1c24;
    font-family: 'proxima_nova semiBold';
}

a.downAppy span.underLine {
    text-decoration: underline;
}

.authyCode .regTitles h3 {
    font-size: 28px;
    font-family: 'proxima_nova';
    font-weight: normal;
    color: #222222;
    line-height: 34px;
}

.authyCode .regTitles h4 {
    font-size: 18px;
    font-family: 'proxima_nova semiBold';
    color: #767676;
}

.authyCode .otpInput {
    border: 1px solid #c8c8c8;
}

a.rsndPing {
    text-decoration: underline;
    line-height: 28px;
}

.anotherMethod {
    color: #767676;
    font-size: 16px;
    text-align: center;
    position: relative;
    margin-bottom: 10px;
    margin-top: 5px;
}

.anotherMethod span {
    background: #fff;
    padding: 4px 10px;
    position: relative;
    z-index: 111;
    line-height: 26px;
}

.anotherMethod:after {
    content: "";
    width: 100%;
    height: 1px;
    background: #bababa;
    display: block;
    position: absolute;
    left: 0;
    top: 12px;
}

.authyAppIconRed {
    background: url('https://cdn.incentx.com/assets/images/authy-icon-sm.png') no-repeat center center;
    height: 47px;
    width: 47px;
    display: block;
    margin: 0px auto;
}

.authyAppIcon,
.rcvCallIcon,
.rcvrCode {
    height: 47px;
    width: 47px;
    display: block;
    background: url('https://cdn.incentx.com/assets/images/verification-icons.png') no-repeat;
    margin: 0px auto;
}

.rcvCallIcon {
    background-position: -47px 0px;
}

.rcvrCode {
    background-position: -94px 0px;
}

.methodIcons {
    width: 33.33%;
    padding: 2px 10px;
    float: left;
    text-align: center;
    color: #696868;
    text-decoration: underline;
    left: 34%;
}

.methodIcons a {
    color: #696868;
    position: relative;
    left: 120%;
}

.methodIcons a:hover {
    color: #0077b5;
}

.authyCode .verificationCode {
    border: 1px solid #c8c8c8;
    width: 100%;
    letter-spacing: 18px;
    text-align: center;
    font-size: 24px;
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper,
.customSelectColorsList .mat-select .mat-select-trigger .mat-select-value,
.customSelectColorsList .mat-select .mat-select-trigger .mat-select-arrow-wrapper {
    cursor: pointer !important;
}

.newCustDetail .contDtl strong {
    color: #000;
}

.newCustDetail .customerDetail .icoOut {
    margin: 0px 0px 8px 0px;
}

.newContactListing .hrLine {
    margin: 5px 0px;
    float: left
}

.newContactListing .contDtl,
.newBillAddress .newBillingAdd {
    position: relative;
}

.newContactListing .contDtl .editIco,
.newBillAddress .editIco {
    position: absolute;
    right: 2px;
    top: 12px;
}

.newContactListing .contDtl .icoOut {
    margin: 2px 0px;
}

.newContactListing {
    height: 305px;
    overflow: auto;
}

.newBillingAdd p {
    margin-bottom: 0;
}

.newBillAddress .tabbing {
    position: relative;
    float: left;
}

.newBillAddress .tabbing .addBtn {
    position: absolute;
    right: 0;
    top: 7px;
    padding-left: 0 !important;
    background: none !important;
}

.newBillAddress .tabContainer {
    height: 420px;
    overflow: auto;
    float: left;
    width: 100%;
    min-height: 80px;
}

.invoiceTable .tableOut {
    margin: 0;
}

.timelineRight .invoiceTable table {
    border: 1px solid #e1e1e1;
}

.assignTo {
    clear: both;
    display: block;
    width: 100%;
}

.assignTo h5 {
    font-size: 13px;
    margin-top: 8px;
    margin-bottom: 3px;
}

.borderBox.noPaddBorder {
    padding: 0;
}

.borderBox.noPaddBorder .subTitleRow {
    width: calc(100% - 50px);
    margin-left: 25px;
    margin-top: 15px;
}

.crmCustomerDetail .contDtl {
    position: relative;
    width: 100%;
    float: left;
    padding: 10px 105px 10px 10px;
    border: 1px solid #fff;
    border-left: 4px solid #fff;
}

.crmCustomerDetail .contDtl:after {
    width: calc(100% - 32px);
    position: absolute;
    bottom: 0px;
    left: 16px;
    content: "";
    border-bottom: 1px solid #e5e5e5;
}

.crmCustomerDetail .contDtl.hoverClr:hover {
    border-color: #706cce;
}

.crmCustomerDetail .contDtl.selectedContact,
.crmCustomerDetail .contDtl.selectedContact.hoverClr:hover {
    border-color: #706cce;
    min-height: 50px;
}

.crmCustomerDetail .contDtl.selectedContact .icoOut.fullWidth {
    color: #21b3c6;
}

.selectPersonImg {
    display: block;
    text-align: center;
}

.selectPersonImg img {
    text-align: center;
}

.selectPersonImg h4 {
    font-size: 22px;
    margin: 20px 0px 5px 0px;
    color: #000;
    font-weight: 600;
}

.custActionGrid .tableOut {
    max-height: 965px;
    overflow: auto;
}

.tooltipAssignTo {
    position: relative;
    display: inline-block;
    cursor: pointer;
    left: 120px;
    top: -10px;
}

.pointerMark {
    cursor: pointer;
}

.gridTitle {
    position: relative;
}

.gridTitle h3 {
    margin-bottom: 10px;
    font-size: 20px;
    color: #222222;
    line-height: 24px;
    font-family: 'proxima_nova semiBold';
}

.addSupportusr {
    color: #21b3c6;
    font-size: 15px;
    position: absolute;
    right: 0;
    top: 0px;
}

.addSupportusr:hover {
    color: #21b3c6;
}

table.devExTable tr th {
    border-bottom: 1px solid #dadada;
    border-right: 1px solid #dadada;
    font-size: 15px;
    color: #222222;
    padding: 12px 10px;
}

table.devExTable tr td {
    border-right: 1px solid #dadada;
}

table.devExTable tr td:last-child {
    border-right: none;
}

table.devExTable tr th:last-child {
    border-right: none;
}

table.devExTable tr td {
    padding: 16px 10px;
    font-size: 14px;
}

.addSupportusr .addIcon {
    background: url('https://cdn.incentx.com/assets/images/add-icon.png') no-repeat center center;
    width: 20px;
    height: 20px;
    background-size: 20px 20px;
}

.lconImg .iconImgmenu {
    display: inline-block;
    width: 58px;
    height: 58px;
    line-height: 55px;
    font-size: 20px;
    color: #fff;
    background: #ffffff;
    border: 3px solid #ebebeb;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    vertical-align: middle;
    padding: 1px 0;
}

td .documentStatusColor.orangeClrBefore::after {
    background: #f99300 !important;
}

td .documentStatusColor.greenClrBefore::after {
    background: #05c1a0 !important;
}

td .documentStatusColor.redClrBefore::after {
    background: #ff4545 !important;
}

td .documentStatusColor.purpleClrBefore::after {
    background: #706cce !important;
}

td .documentStatusColor.skyblueClrBefore:first-child::after {
    background: #38b1eb !important;
}

.orangeClrBefore:after,
.greenClrBefore:after,
.redClrBefore:after,
.purpleClrBefore:after,
.skyblueClrBefore:after {
    width: 2px;
    height: 30px;
    margin-top: 0;
    content: '';
    float: left;
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -15px;
}

.cellColor td {
    position: relative;
}

.mCustomScrollbar {
    -ms-touch-action: pinch-zoom;
    touch-action: pinch-zoom;
}

.mCustomScrollbar.mCS_no_scrollbar,
.mCustomScrollbar.mCS_touch_action {
    -ms-touch-action: auto;
    touch-action: auto;
}

.mCustomScrollBox {
    position: relative;
    overflow: hidden;
    height: 100%;
    max-width: 100%;
    outline: 0;
    direction: ltr;
}

.mCSB_container {
    overflow: hidden;
    width: auto;
    height: auto;
}

.mCSB_inside>.mCSB_container {
    margin-right: 30px;
}

.mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden {
    margin-right: 0;
}

.mCS-dir-rtl>.mCSB_inside>.mCSB_container {
    margin-right: 0;
    margin-left: 30px;
}

.mCS-dir-rtl>.mCSB_inside>.mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden {
    margin-left: 0;
}

.mCSB_scrollTools {
    position: absolute;
    width: 16px;
    height: auto;
    left: auto;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: .75;
}

.mCSB_outside+.mCSB_scrollTools {
    right: -26px;
}

.mCS-dir-rtl>.mCSB_inside>.mCSB_scrollTools,
.mCS-dir-rtl>.mCSB_outside+.mCSB_scrollTools {
    right: auto;
    left: 0;
}

.mCS-dir-rtl>.mCSB_outside+.mCSB_scrollTools {
    left: -26px;
}

.mCSB_scrollTools .mCSB_draggerContainer {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: auto;
}

.mCSB_scrollTools a+.mCSB_draggerContainer {
    margin: 20px 0;
}

.mCSB_scrollTools .mCSB_draggerRail {
    width: 2px;
    height: 100%;
    margin: 0 auto;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    border-radius: 16px;
}

.mCSB_scrollTools .mCSB_dragger {
    cursor: pointer;
    width: 100%;
    height: 30px;
    z-index: 1;
}

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    position: relative;
    width: 4px;
    height: 100%;
    margin: 0 auto;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    border-radius: 16px;
    text-align: center;
}

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
    width: 12px;
}

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded+.mCSB_draggerRail,
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
    width: 8px;
}

.mCSB_scrollTools .mCSB_buttonDown,
.mCSB_scrollTools .mCSB_buttonUp {
    display: block;
    position: absolute;
    height: 20px;
    width: 100%;
    overflow: hidden;
    margin: 0 auto;
    cursor: pointer;
}

.mCSB_scrollTools .mCSB_buttonDown {
    bottom: 0;
}

.mCSB_horizontal.mCSB_inside>.mCSB_container {
    margin-right: 0;
    margin-bottom: 30px;
}

.mCSB_horizontal.mCSB_outside>.mCSB_container {
    min-height: 100%;
}

.mCSB_horizontal>.mCSB_container.mCS_no_scrollbar_x.mCS_x_hidden {
    margin-bottom: 0;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal {
    width: auto;
    height: 16px;
    top: auto;
    right: 0;
    bottom: 0;
    left: 0;
}

.mCustomScrollBox+.mCSB_scrollTools+.mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox+.mCSB_scrollTools.mCSB_scrollTools_horizontal {
    bottom: -26px;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal a+.mCSB_draggerContainer {
    margin: 0 20px;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_draggerRail {
    width: 100%;
    height: 2px;
    margin: 7px 0;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger {
    width: 30px;
    height: 100%;
    left: 0;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
    width: 100%;
    height: 4px;
    margin: 6px auto;
}

.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
    height: 12px;
    margin: 2px auto;
}

.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded+.mCSB_draggerRail,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
    height: 8px;
    margin: 4px 0;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonLeft,
.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonRight {
    display: block;
    position: absolute;
    width: 20px;
    height: 100%;
    overflow: hidden;
    margin: 0 auto;
    cursor: pointer;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonLeft {
    left: 0;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonRight {
    right: 0;
}

.mCSB_container_wrapper {
    position: absolute;
    height: auto;
    width: auto;
    overflow: hidden;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin-right: 30px;
    margin-bottom: 30px;
}

.mCSB_container_wrapper>.mCSB_container {
    padding-right: 30px;
    padding-bottom: 30px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.mCSB_vertical_horizontal>.mCSB_scrollTools.mCSB_scrollTools_vertical {
    bottom: 20px;
}

.mCSB_vertical_horizontal>.mCSB_scrollTools.mCSB_scrollTools_horizontal {
    right: 20px;
}

.mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden+.mCSB_scrollTools.mCSB_scrollTools_vertical {
    bottom: 0;
}

.mCS-dir-rtl>.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside>.mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden+.mCSB_scrollTools~.mCSB_scrollTools.mCSB_scrollTools_horizontal {
    right: 0;
}

.mCS-dir-rtl>.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside>.mCSB_scrollTools.mCSB_scrollTools_horizontal {
    left: 20px;
}

.mCS-dir-rtl>.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside>.mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden+.mCSB_scrollTools~.mCSB_scrollTools.mCSB_scrollTools_horizontal {
    left: 0;
}

.mCS-dir-rtl>.mCSB_inside>.mCSB_container_wrapper {
    margin-right: 0;
    margin-left: 30px;
}

.mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden>.mCSB_container {
    padding-right: 0;
}

.mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden>.mCSB_container {
    padding-bottom: 0;
}

.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside>.mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden {
    margin-right: 0;
    margin-left: 0;
}

.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside>.mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden {
    margin-bottom: 0;
}

.mCSB_scrollTools,
.mCSB_scrollTools .mCSB_buttonDown,
.mCSB_scrollTools .mCSB_buttonLeft,
.mCSB_scrollTools .mCSB_buttonRight,
.mCSB_scrollTools .mCSB_buttonUp,
.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    -webkit-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
    -moz-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
    -o-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
    transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
}

.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerRail,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger_bar,
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerRail,
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger_bar {
    -webkit-transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
    -moz-transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
    -o-transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
    transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
}

.mCS-autoHide>.mCustomScrollBox>.mCSB_scrollTools,
.mCS-autoHide>.mCustomScrollBox~.mCSB_scrollTools {
    opacity: 0;
}

.mCS-autoHide:hover>.mCustomScrollBox>.mCSB_scrollTools,
.mCS-autoHide:hover>.mCustomScrollBox~.mCSB_scrollTools,
.mCustomScrollBox:hover>.mCSB_scrollTools,
.mCustomScrollBox:hover~.mCSB_scrollTools,
.mCustomScrollbar>.mCustomScrollBox>.mCSB_scrollTools.mCSB_scrollTools_onDrag,
.mCustomScrollbar>.mCustomScrollBox~.mCSB_scrollTools.mCSB_scrollTools_onDrag {
    opacity: 1;
}

.mCSB_scrollTools .mCSB_draggerRail {
    background-color: #000;
    background-color: rgba(0, 0, 0, .4);
}

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    background-color: #fff;
    background-color: rgba(255, 255, 255, .75);
}

.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
    background-color: #fff;
    background-color: rgba(255, 255, 255, .85);
}

.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
    background-color: #fff;
    background-color: rgba(255, 255, 255, .9);
}

.mCSB_scrollTools .mCSB_buttonDown,
.mCSB_scrollTools .mCSB_buttonLeft,
.mCSB_scrollTools .mCSB_buttonRight,
.mCSB_scrollTools .mCSB_buttonUp {
    background-repeat: no-repeat;
    opacity: .4;
}

.mCSB_scrollTools .mCSB_buttonUp {
    background-position: 0 0;
}

.mCSB_scrollTools .mCSB_buttonDown {
    background-position: 0 -20px;
}

.mCSB_scrollTools .mCSB_buttonLeft {
    background-position: 0 -40px;
}

.mCSB_scrollTools .mCSB_buttonRight {
    background-position: 0 -56px;
}

.mCSB_scrollTools .mCSB_buttonDown:hover,
.mCSB_scrollTools .mCSB_buttonLeft:hover,
.mCSB_scrollTools .mCSB_buttonRight:hover,
.mCSB_scrollTools .mCSB_buttonUp:hover {
    opacity: .75;
}

.mCSB_scrollTools .mCSB_buttonDown:active,
.mCSB_scrollTools .mCSB_buttonLeft:active,
.mCSB_scrollTools .mCSB_buttonRight:active,
.mCSB_scrollTools .mCSB_buttonUp:active {
    opacity: .9;
}

.mCS-dark.mCSB_scrollTools .mCSB_draggerRail {
    background-color: #000;
    background-color: rgba(0, 0, 0, .15);
}

.mCS-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    background-color: #000;
    background-color: rgba(0, 0, 0, .75);
}

.mCS-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
    background-color: rgba(0, 0, 0, .85);
}

.mCS-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
    background-color: rgba(0, 0, 0, .9);
}

.mCS-dark.mCSB_scrollTools .mCSB_buttonUp {
    background-position: -80px 0;
}

.mCS-dark.mCSB_scrollTools .mCSB_buttonDown {
    background-position: -80px -20px;
}

.mCS-dark.mCSB_scrollTools .mCSB_buttonLeft {
    background-position: -80px -40px;
}

.mCS-dark.mCSB_scrollTools .mCSB_buttonRight {
    background-position: -80px -56px;
}

.mCS-dark-2.mCSB_scrollTools .mCSB_draggerRail,
.mCS-light-2.mCSB_scrollTools .mCSB_draggerRail {
    width: 4px;
    background-color: #fff;
    background-color: rgba(255, 255, 255, .1);
    -webkit-border-radius: 1px;
    -moz-border-radius: 1px;
    border-radius: 1px;
}

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-light-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    width: 4px;
    background-color: #fff;
    background-color: rgba(255, 255, 255, .75);
    -webkit-border-radius: 1px;
    -moz-border-radius: 1px;
    border-radius: 1px;
}

.mCS-dark-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-2.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-light-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-light-2.mCSB_scrollTools_horizontal .mCSB_draggerRail {
    width: 100%;
    height: 4px;
    margin: 6px auto;
}

.mCS-light-2.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
    background-color: #fff;
    background-color: rgba(255, 255, 255, .85);
}

.mCS-light-2.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-light-2.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
    background-color: #fff;
    background-color: rgba(255, 255, 255, .9);
}

.mCS-light-2.mCSB_scrollTools .mCSB_buttonUp {
    background-position: -32px 0;
}

.mCS-light-2.mCSB_scrollTools .mCSB_buttonDown {
    background-position: -32px -20px;
}

.mCS-light-2.mCSB_scrollTools .mCSB_buttonLeft {
    background-position: -40px -40px;
}

.mCS-light-2.mCSB_scrollTools .mCSB_buttonRight {
    background-position: -40px -56px;
}

.mCS-dark-2.mCSB_scrollTools .mCSB_draggerRail {
    background-color: #000;
    background-color: rgba(0, 0, 0, .1);
    -webkit-border-radius: 1px;
    -moz-border-radius: 1px;
    border-radius: 1px;
}

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    background-color: #000;
    background-color: rgba(0, 0, 0, .75);
    -webkit-border-radius: 1px;
    -moz-border-radius: 1px;
    border-radius: 1px;
}

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
    background-color: #000;
    background-color: rgba(0, 0, 0, .85);
}

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-dark-2.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
    background-color: #000;
    background-color: rgba(0, 0, 0, .9);
}

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonUp {
    background-position: -112px 0;
}

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonDown {
    background-position: -112px -20px;
}

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonLeft {
    background-position: -120px -40px;
}

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonRight {
    background-position: -120px -56px;
}

.mCS-dark-thick.mCSB_scrollTools .mCSB_draggerRail,
.mCS-light-thick.mCSB_scrollTools .mCSB_draggerRail {
    width: 4px;
    background-color: #fff;
    background-color: rgba(255, 255, 255, .1);
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
}

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-light-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    width: 6px;
    background-color: #fff;
    background-color: rgba(255, 255, 255, .75);
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
}

.mCS-dark-thick.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-light-thick.mCSB_scrollTools_horizontal .mCSB_draggerRail {
    width: 100%;
    height: 4px;
    margin: 6px 0;
}

.mCS-dark-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-light-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
    width: 100%;
    height: 6px;
    margin: 5px auto;
}

.mCS-light-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
    background-color: #fff;
    background-color: rgba(255, 255, 255, .85);
}

.mCS-light-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-light-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
    background-color: #fff;
    background-color: rgba(255, 255, 255, .9);
}

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonUp {
    background-position: -16px 0;
}

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonDown {
    background-position: -16px -20px;
}

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonLeft {
    background-position: -20px -40px;
}

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonRight {
    background-position: -20px -56px;
}

.mCS-dark-thick.mCSB_scrollTools .mCSB_draggerRail {
    background-color: #000;
    background-color: rgba(0, 0, 0, .1);
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
}

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    background-color: #000;
    background-color: rgba(0, 0, 0, .75);
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
}

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
    background-color: #000;
    background-color: rgba(0, 0, 0, .85);
}

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
    background-color: #000;
    background-color: rgba(0, 0, 0, .9);
}

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonUp {
    background-position: -96px 0;
}

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonDown {
    background-position: -96px -20px;
}

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonLeft {
    background-position: -100px -40px;
}

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonRight {
    background-position: -100px -56px;
}

.mCS-light-thin.mCSB_scrollTools .mCSB_draggerRail {
    background-color: #fff;
    background-color: rgba(255, 255, 255, .1);
}

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-light-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    width: 2px;
}

.mCS-dark-thin.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-light-thin.mCSB_scrollTools_horizontal .mCSB_draggerRail {
    width: 100%;
}

.mCS-dark-thin.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-light-thin.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
    width: 100%;
    height: 2px;
    margin: 7px auto;
}

.mCS-dark-thin.mCSB_scrollTools .mCSB_draggerRail {
    background-color: #000;
    background-color: rgba(0, 0, 0, .15);
}

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    background-color: #000;
    background-color: rgba(0, 0, 0, .75);
}

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
    background-color: #000;
    background-color: rgba(0, 0, 0, .85);
}

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
    background-color: #000;
    background-color: rgba(0, 0, 0, .9);
}

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonUp {
    background-position: -80px 0;
}

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonDown {
    background-position: -80px -20px;
}

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonLeft {
    background-position: -80px -40px;
}

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonRight {
    background-position: -80px -56px;
}

.mCS-rounded.mCSB_scrollTools .mCSB_draggerRail {
    background-color: #fff;
    background-color: rgba(255, 255, 255, .15);
}

.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger,
.mCS-rounded-dots.mCSB_scrollTools .mCSB_dragger,
.mCS-rounded.mCSB_scrollTools .mCSB_dragger {
    height: 14px;
}

.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    width: 14px;
    margin: 0 1px;
}

.mCS-rounded-dark.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-rounded.mCSB_scrollTools_horizontal .mCSB_dragger {
    width: 14px;
}

.mCS-rounded-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
    height: 14px;
    margin: 1px 0;
}

.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
    width: 16px;
    height: 16px;
    margin: -1px 0;
}

.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded+.mCSB_draggerRail,
.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail,
.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded+.mCSB_draggerRail,
.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
    width: 4px;
}

.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
    height: 16px;
    width: 16px;
    margin: 0 -1px;
}

.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded+.mCSB_draggerRail,
.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail,
.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded+.mCSB_draggerRail,
.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
    height: 4px;
    margin: 6px 0;
}

.mCS-rounded.mCSB_scrollTools .mCSB_buttonUp {
    background-position: 0 -72px;
}

.mCS-rounded.mCSB_scrollTools .mCSB_buttonDown {
    background-position: 0 -92px;
}

.mCS-rounded.mCSB_scrollTools .mCSB_buttonLeft {
    background-position: 0 -112px;
}

.mCS-rounded.mCSB_scrollTools .mCSB_buttonRight {
    background-position: 0 -128px;
}

.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    background-color: #000;
    background-color: rgba(0, 0, 0, .75);
}

.mCS-rounded-dark.mCSB_scrollTools .mCSB_draggerRail {
    background-color: #000;
    background-color: rgba(0, 0, 0, .15);
}

.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
    background-color: #000;
    background-color: rgba(0, 0, 0, .85);
}

.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
    background-color: #000;
    background-color: rgba(0, 0, 0, .9);
}

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonUp {
    background-position: -80px -72px;
}

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonDown {
    background-position: -80px -92px;
}

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonLeft {
    background-position: -80px -112px;
}

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonRight {
    background-position: -80px -128px;
}

.mCS-rounded-dots-dark.mCSB_scrollTools_vertical .mCSB_draggerRail,
.mCS-rounded-dots.mCSB_scrollTools_vertical .mCSB_draggerRail {
    width: 4px;
}

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-rounded-dots.mCSB_scrollTools .mCSB_draggerRail,
.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_draggerRail {
    background-color: transparent;
    background-position: center;
}

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-rounded-dots.mCSB_scrollTools .mCSB_draggerRail {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAANElEQVQYV2NkIAAYiVbw//9/Y6DiM1ANJoyMjGdBbLgJQAX/kU0DKgDLkaQAvxW4HEvQFwCRcxIJK1XznAAAAABJRU5ErkJggg==');
    background-repeat: repeat-y;
    opacity: .3;
}

.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_draggerRail {
    height: 4px;
    margin: 6px 0;
    background-repeat: repeat-x;
}

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonUp {
    background-position: -16px -72px;
}

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonDown {
    background-position: -16px -92px;
}

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonLeft {
    background-position: -20px -112px;
}

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonRight {
    background-position: -20px -128px;
}

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAALElEQVQYV2NkIAAYSVFgDFR8BqrBBEifBbGRTfiPZhpYjiQFBK3A6l6CvgAAE9kGCd1mvgEAAAAASUVORK5CYII=');
}

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonUp {
    background-position: -96px -72px;
}

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonDown {
    background-position: -96px -92px;
}

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonLeft {
    background-position: -100px -112px;
}

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonRight {
    background-position: -100px -128px;
}

.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    background-repeat: repeat-y;
    background-image: -moz-linear-gradient(left, rgba(255, 255, 255, .5) 0, rgba(255, 255, 255, 0) 100%);
    background-image: -webkit-gradient(linear, left top, right top, color-stop(0, rgba(255, 255, 255, .5)), color-stop(100%, rgba(255, 255, 255, 0)));
    background-image: -webkit-linear-gradient(left, rgba(255, 255, 255, .5) 0, rgba(255, 255, 255, 0) 100%);
    background-image: -o-linear-gradient(left, rgba(255, 255, 255, .5) 0, rgba(255, 255, 255, 0) 100%);
    background-image: -ms-linear-gradient(left, rgba(255, 255, 255, .5) 0, rgba(255, 255, 255, 0) 100%);
    background-image: linear-gradient(to right, rgba(255, 255, 255, .5) 0, rgba(255, 255, 255, 0) 100%);
}

.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
    background-repeat: repeat-x;
    background-image: -moz-linear-gradient(top, rgba(255, 255, 255, .5) 0, rgba(255, 255, 255, 0) 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, rgba(255, 255, 255, .5)), color-stop(100%, rgba(255, 255, 255, 0)));
    background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, .5) 0, rgba(255, 255, 255, 0) 100%);
    background-image: -o-linear-gradient(top, rgba(255, 255, 255, .5) 0, rgba(255, 255, 255, 0) 100%);
    background-image: -ms-linear-gradient(top, rgba(255, 255, 255, .5) 0, rgba(255, 255, 255, 0) 100%);
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, .5) 0, rgba(255, 255, 255, 0) 100%);
}

.mCS-3d-dark.mCSB_scrollTools_vertical .mCSB_dragger,
.mCS-3d.mCSB_scrollTools_vertical .mCSB_dragger {
    height: 70px;
}

.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger {
    width: 70px;
}

.mCS-3d-dark.mCSB_scrollTools,
.mCS-3d.mCSB_scrollTools {
    opacity: 1;
}

.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d.mCSB_scrollTools .mCSB_draggerRail {
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    border-radius: 16px;
}

.mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-3d.mCSB_scrollTools .mCSB_draggerRail {
    width: 8px;
    background-color: #000;
    background-color: rgba(0, 0, 0, .2);
    box-shadow: inset 1px 0 1px rgba(0, 0, 0, .5), inset -1px 0 1px rgba(255, 255, 255, .2);
}

.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-3d.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-3d.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
    background-color: #555;
}

.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    width: 8px;
}

.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-3d.mCSB_scrollTools_horizontal .mCSB_draggerRail {
    width: 100%;
    height: 8px;
    margin: 4px 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .5), inset 0 -1px 1px rgba(255, 255, 255, .2);
}

.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
    width: 100%;
    height: 8px;
    margin: 4px auto;
}

.mCS-3d.mCSB_scrollTools .mCSB_buttonUp {
    background-position: -32px -72px;
}

.mCS-3d.mCSB_scrollTools .mCSB_buttonDown {
    background-position: -32px -92px;
}

.mCS-3d.mCSB_scrollTools .mCSB_buttonLeft {
    background-position: -40px -112px;
}

.mCS-3d.mCSB_scrollTools .mCSB_buttonRight {
    background-position: -40px -128px;
}

.mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail {
    background-color: #000;
    background-color: rgba(0, 0, 0, .1);
    box-shadow: inset 1px 0 1px rgba(0, 0, 0, .1);
}

.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .1);
}

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonUp {
    background-position: -112px -72px;
}

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonDown {
    background-position: -112px -92px;
}

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonLeft {
    background-position: -120px -112px;
}

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonRight {
    background-position: -120px -128px;
}

.mCS-3d-thick-dark.mCSB_scrollTools,
.mCS-3d-thick.mCSB_scrollTools {
    opacity: 1;
}

.mCS-3d-thick-dark.mCSB_scrollTools,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerContainer,
.mCS-3d-thick.mCSB_scrollTools,
.mCS-3d-thick.mCSB_scrollTools .mCSB_draggerContainer {
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    border-radius: 7px;
}

.mCSB_inside+.mCS-3d-thick-dark.mCSB_scrollTools_vertical,
.mCSB_inside+.mCS-3d-thick.mCSB_scrollTools_vertical {
    right: 1px;
}

.mCS-3d-thick-dark.mCSB_scrollTools_vertical,
.mCS-3d-thick.mCSB_scrollTools_vertical {
    box-shadow: inset 1px 0 1px rgba(0, 0, 0, .1), inset 0 0 14px rgba(0, 0, 0, .5);
}

.mCS-3d-thick-dark.mCSB_scrollTools_horizontal,
.mCS-3d-thick.mCSB_scrollTools_horizontal {
    bottom: 1px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .1), inset 0 0 14px rgba(0, 0, 0, .5);
}

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    box-shadow: inset 1px 0 0 rgba(255, 255, 255, .4);
    width: 12px;
    margin: 2px;
    position: absolute;
    height: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .4);
    height: 12px;
    width: auto;
}

.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
    background-color: #555;
}

.mCS-3d-thick.mCSB_scrollTools .mCSB_draggerContainer {
    background-color: #000;
    background-color: rgba(0, 0, 0, .05);
    box-shadow: inset 1px 1px 16px rgba(0, 0, 0, .1);
}

.mCS-3d-thick.mCSB_scrollTools .mCSB_draggerRail {
    background-color: transparent;
}

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonUp {
    background-position: -32px -72px;
}

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonDown {
    background-position: -32px -92px;
}

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonLeft {
    background-position: -40px -112px;
}

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonRight {
    background-position: -40px -128px;
}

.mCS-3d-thick-dark.mCSB_scrollTools {
    box-shadow: inset 0 0 14px rgba(0, 0, 0, .2);
}

.mCS-3d-thick-dark.mCSB_scrollTools_horizontal {
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .1), inset 0 0 14px rgba(0, 0, 0, .2);
}

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    box-shadow: inset 1px 0 0 rgba(255, 255, 255, .4), inset -1px 0 0 rgba(0, 0, 0, .2);
}

.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .4), inset 0 -1px 0 rgba(0, 0, 0, .2);
}

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
    background-color: #777;
}

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerContainer {
    background-color: #fff;
    background-color: rgba(0, 0, 0, .05);
    box-shadow: inset 1px 1px 16px rgba(0, 0, 0, .1);
}

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-minimal-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-minimal.mCSB_scrollTools .mCSB_draggerRail {
    background-color: transparent;
}

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonUp {
    background-position: -112px -72px;
}

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonDown {
    background-position: -112px -92px;
}

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonLeft {
    background-position: -120px -112px;
}

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonRight {
    background-position: -120px -128px;
}

.mCSB_outside+.mCS-minimal-dark.mCSB_scrollTools_vertical,
.mCSB_outside+.mCS-minimal.mCSB_scrollTools_vertical {
    right: 0;
    margin: 12px 0;
}

.mCustomScrollBox.mCS-minimal+.mCSB_scrollTools+.mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox.mCS-minimal+.mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox.mCS-minimal-dark+.mCSB_scrollTools+.mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox.mCS-minimal-dark+.mCSB_scrollTools.mCSB_scrollTools_horizontal {
    bottom: 0;
    margin: 0 12px;
}

.mCS-dir-rtl>.mCSB_outside+.mCS-minimal-dark.mCSB_scrollTools_vertical,
.mCS-dir-rtl>.mCSB_outside+.mCS-minimal.mCSB_scrollTools_vertical {
    left: 0;
    right: auto;
}

.mCS-minimal-dark.mCSB_scrollTools_vertical .mCSB_dragger,
.mCS-minimal.mCSB_scrollTools_vertical .mCSB_dragger {
    height: 50px;
}

.mCS-minimal-dark.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-minimal.mCSB_scrollTools_horizontal .mCSB_dragger {
    width: 50px;
}

.mCS-minimal.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    background-color: #fff;
    background-color: rgba(255, 255, 255, .2);
}

.mCS-minimal.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-minimal.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
    background-color: #fff;
    background-color: rgba(255, 255, 255, .5);
}

.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    background-color: #000;
    background-color: rgba(0, 0, 0, .2);
}

.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
    background-color: #000;
    background-color: rgba(0, 0, 0, .5);
}

.mCS-dark-3.mCSB_scrollTools .mCSB_draggerRail,
.mCS-light-3.mCSB_scrollTools .mCSB_draggerRail {
    width: 6px;
    background-color: #000;
    background-color: rgba(0, 0, 0, .2);
}

.mCS-dark-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-light-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    width: 6px;
}

.mCS-dark-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-3.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-light-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-light-3.mCSB_scrollTools_horizontal .mCSB_draggerRail {
    width: 100%;
    height: 6px;
    margin: 5px 0;
}

.mCS-dark-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded+.mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail,
.mCS-light-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded+.mCSB_draggerRail,
.mCS-light-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
    width: 12px;
}

.mCS-dark-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded+.mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail,
.mCS-light-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded+.mCSB_draggerRail,
.mCS-light-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
    height: 12px;
    margin: 2px 0;
}

.mCS-light-3.mCSB_scrollTools .mCSB_buttonUp {
    background-position: -32px -72px;
}

.mCS-light-3.mCSB_scrollTools .mCSB_buttonDown {
    background-position: -32px -92px;
}

.mCS-light-3.mCSB_scrollTools .mCSB_buttonLeft {
    background-position: -40px -112px;
}

.mCS-light-3.mCSB_scrollTools .mCSB_buttonRight {
    background-position: -40px -128px;
}

.mCS-dark-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    background-color: #000;
    background-color: rgba(0, 0, 0, .75);
}

.mCS-dark-3.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
    background-color: #000;
    background-color: rgba(0, 0, 0, .85);
}

.mCS-dark-3.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-dark-3.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
    background-color: #000;
    background-color: rgba(0, 0, 0, .9);
}

.mCS-dark-3.mCSB_scrollTools .mCSB_draggerRail {
    background-color: #000;
    background-color: rgba(0, 0, 0, .1);
}

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonUp {
    background-position: -112px -72px;
}

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonDown {
    background-position: -112px -92px;
}

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonLeft {
    background-position: -120px -112px;
}

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonRight {
    background-position: -120px -128px;
}

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-2.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-3.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset.mCSB_scrollTools .mCSB_draggerRail {
    width: 12px;
    background-color: #000;
    background-color: rgba(0, 0, 0, .2);
}

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    width: 6px;
    margin: 3px 5px;
    position: absolute;
    height: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.mCS-inset-2-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
    height: 6px;
    margin: 5px 3px;
    position: absolute;
    width: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.mCS-inset-2-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-2.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-3-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-3.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset.mCSB_scrollTools_horizontal .mCSB_draggerRail {
    width: 100%;
    height: 12px;
    margin: 2px 0;
}

.mCS-inset-2.mCSB_scrollTools .mCSB_buttonUp,
.mCS-inset-3.mCSB_scrollTools .mCSB_buttonUp,
.mCS-inset.mCSB_scrollTools .mCSB_buttonUp {
    background-position: -32px -72px;
}

.mCS-inset-2.mCSB_scrollTools .mCSB_buttonDown,
.mCS-inset-3.mCSB_scrollTools .mCSB_buttonDown,
.mCS-inset.mCSB_scrollTools .mCSB_buttonDown {
    background-position: -32px -92px;
}

.mCS-inset-2.mCSB_scrollTools .mCSB_buttonLeft,
.mCS-inset-3.mCSB_scrollTools .mCSB_buttonLeft,
.mCS-inset.mCSB_scrollTools .mCSB_buttonLeft {
    background-position: -40px -112px;
}

.mCS-inset-2.mCSB_scrollTools .mCSB_buttonRight,
.mCS-inset-3.mCSB_scrollTools .mCSB_buttonRight,
.mCS-inset.mCSB_scrollTools .mCSB_buttonRight {
    background-position: -40px -128px;
}

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    background-color: #000;
    background-color: rgba(0, 0, 0, .75);
}

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
    background-color: #000;
    background-color: rgba(0, 0, 0, .85);
}

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
    background-color: #000;
    background-color: rgba(0, 0, 0, .9);
}

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-dark.mCSB_scrollTools .mCSB_draggerRail {
    background-color: #000;
    background-color: rgba(0, 0, 0, .1);
}

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonUp,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonUp,
.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonUp {
    background-position: -112px -72px;
}

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonDown,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonDown,
.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonDown {
    background-position: -112px -92px;
}

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonLeft,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonLeft,
.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonLeft {
    background-position: -120px -112px;
}

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonRight,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonRight,
.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonRight {
    background-position: -120px -128px;
}

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-2.mCSB_scrollTools .mCSB_draggerRail {
    background-color: transparent;
    border-width: 1px;
    border-style: solid;
    border-color: #fff;
    border-color: rgba(255, 255, 255, .2);
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail {
    border-color: #000;
    border-color: rgba(0, 0, 0, .2);
}

.mCS-inset-3.mCSB_scrollTools .mCSB_draggerRail {
    background-color: #fff;
    background-color: rgba(255, 255, 255, .6);
}

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail {
    background-color: #000;
    background-color: rgba(0, 0, 0, .6);
}

.mCS-inset-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    background-color: #000;
    background-color: rgba(0, 0, 0, .75);
}

.mCS-inset-3.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
    background-color: #000;
    background-color: rgba(0, 0, 0, .85);
}

.mCS-inset-3.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-inset-3.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
    background-color: #000;
    background-color: rgba(0, 0, 0, .9);
}

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    background-color: #fff;
    background-color: rgba(255, 255, 255, .75);
}

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
    background-color: #fff;
    background-color: rgba(255, 255, 255, .85);
}

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
    background-color: #fff;
    background-color: rgba(255, 255, 255, .9);
}

.sideBar .mCSB_inside>.mCSB_container {
    margin-right: 0;
}

.sideBar .mCSB_scrollTools {
    width: 4px;
    z-index: 1111;
}

.sideBar .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCSB_scrollTools .mCSB_draggerRail {
    background-color: transparent;
}

.sideBar.sideBarExpand .mCSB_scrollTools .mCSB_dragger {
    background-color: rgba(0, 0, 0, 0.20) !important;
}

.signOut {
    float: left;
    margin: 11px 10px 4px 0px;
}

ul.navigation ul.subMenu li a {
    padding: 15px 20px;
}

#header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 111;
    box-shadow: 5px 0 21px #e5e5e5;
    background: #fff;
}

.menuExpandTrigger {
    float: left;
    padding: 15px;
    border-right: 1px solid #e5e5e5;
}

.menuExpandTrigger .menu-icon::before {
    cursor: pointer;
    content: "\e968";
    color: #767676;
    font-family: 'IXPORTAL';
}

.menuExpandTrigger .menu-icon.active::before,
.menuExpandTrigger .menu-icon:hover::before {
    cursor: pointer;
    content: "\e968";
    color: #706cce;
    font-family: 'IXPORTAL';
}

#content {
    height: calc(100% - 92px);
    width: calc(100% - 60px);
    overflow: auto;
    left: 60px;
    top: 92px;
    position: relative;
}

#content .sideBar .dx-scrollable-scrollbar {
    right: -2px;
    z-index: 11;
    opacity: 0;
}

#content .sideBar.sideBarExpand .dx-scrollable-scrollbar {
    opacity: 1;
}

ul.navigation {
    padding: 0;
    padding-bottom: 130px;
}

.rightBar {
    padding-left: 0px;
}

ul.navigation>li {
    padding: 0px;
    border-left: 2px solid #fff;
}

.sideBarExpand ul.navigation>li {
    padding: 0px;
}

ul.navigation>li::after {
    width: calc(100% - 20px);
    margin: 0;
    left: 9px;
    border-bottom: 1px solid #dadada;
    top: 0;
    z-index: 9999;
}

ul.navigation>li {
    margin-top: -1px;
}

ul.navigation > li.activate + li::after {
    display: none;
}

ul.navigation > li:first-child {
    margin-top: 0;
}

ul.navigation > li.activate::after,
.userProfile:after {
    display: none;
}

ul.navigation ul.subMenu {
    top: 0;
    border: 0;
    box-shadow: 0px 0px 20px rgba(85, 85, 85, 0.2);
    transition: 5s display ease-in-out;
}

.subMenu::after {
    border-width: 11px;
    top: 13px;
    left: -10px;
}

ul.navigation>li.activate {
    border-left: 2px solid #706cce;
}

ul.navigation>li.activate {
    background-color: #f4f4f4;
}

ul.navigation>li.activate>a,
ul.navigation>li:hover>a,
ul.navigation>li.openMenu>a {
    color: #706cce;
}

ul.navigation>li.activate>a {
    font-family: 'proxima_nova semiBold';
}

.dashIcon {
    position: relative;
    top: -7px;
    left: -15px;
}

.dashIcon:before {
    content: "\e9af";
    font-size: 2px;
    line-height: 28px;
    display: inline-block;
    width: 5px;
    height: 2px;
    position: absolute;
    color: #9f9f9f;
    font-family: 'IXPORTAL';
}

.currentPage .dashIcon:before {
    content: "\e9af";
    color: #706cce;
    font-family: 'IXPORTAL';
}

.sideBarExpand {
    min-width: 227px;
    overflow: auto;
    overflow-x: hidden;
    width: auto;
    max-width: 330px;
    transition: max-width .5s ease;
}

.menu-title {
    display: none;
}

.sideBarExpand .menu-title {
    display: inline-block;
    height: auto;
    line-height: 16px;
    vertical-align: middle;
    font-size: 15px;
    width: calc(100% - 60px);
    white-space: normal;
}

.sideBarExpand ul.navigation {
    padding-bottom: 10px;
}

ul.navigation>li>a {
    padding: 8px 0px;
}

.sideBarExpand ul.navigation ul.subMenu {
    position: static;
    visibility: visible;
    opacity: 1;
    display: none;
    border: 0;
    box-shadow: none;
}

.sideBarExpand ul.navigation ul.subMenu li:first-child {
    display: none;
}

.sideBarExpand .subMenu::after {
    display: none;
}

.userDtlToggle {
    left: auto;
    right: 0;
    top: 52px;
    box-shadow: 0px 0px 20px rgba(85, 85, 85, 0.15);
}

ul.navigation ul.subMenu>li a {
    padding-left: 35px;
}

ul.navigation ul.subMenu>li:first-child a {
    padding-left: 15px;
    font-size: 16px;
    font-family: 'proxima_nova semiBold';
}

ul.navigation ul.subMenu>li:first-child a .dashIcon {
    display: none;
}

.sideBarExpand .usrContact {
    display: block;
    margin-top: 4px;
    padding: 3px 0px 0px 0px;
    border-top: 1px solid #e9e9e9;
}

.sideBarExpand .userDetail {
    width: calc(100% - 50px);
    display: inline-block;
    visibility: visible;
}

.sideBarExpand .userDetail h3 {
    color: #706cce;
    padding: 5px 5px 2px 5px;
    margin: 0px;
    line-height: 18px;
    display: block;
    font-size: 16px;
}

.sideBarExpand .userDetail h4 {
    color: #767676;
    padding: 0px 5px;
    line-height: 14px;
    margin: 0px 0px 5px;
    font-size: 14px;
}

.sideBarExpand .usrContact li {
    color: #565656;
    padding: 2px 5px 3px 8px;
    font-size: 14px;
}

ul.navigation ul.subMenu li:not(:first-child) a {
    padding: 13px 20px 13px 30px;
    font-size: 14px;
    line-height: normal;
}

.sideBarExpand ul.navigation ul.subMenu li a {
    padding: 13px 5px 13px 70px;
    font-size: 14px;
    white-space: normal;
}

.sideBarExpand ul.navigation ul.subMenu li a:hover {
    color: #565656;
}

.sideBarExpand ul.navigation ul.subMenu .dashIcon:before {
    border-color: #706cce;
}

ul.navigation li.currentPage>a {
    font-family: 'proxima_nova semiBold';
    color: #706cce !important;
}

.userImage {
    width: 42px;
    height: 42px;
    display: inline-block;
    background: #21b3c6 !important;
    vertical-align: top;
    margin: 0px;
}

.userProfile {
    text-align: left;
    padding: 0px;
    line-height: 0;
    box-shadow: none;
    float: left;
    position: relative;
    margin-right: 35px;
}

.userProfile.toggleOpen .userDtlToggle {
    display: block;
}

.userDtlToggle:after,
.userDtlToggle:before {
    bottom: 100%;
    right: 20px;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.userProfile:after {
    height: 28px;
    border-right: 1px solid #d9d9d9;
    width: 1px;
    display: block;
    position: absolute;
    right: -18px;
    content: "";
    top: 7px;
}

.userDtlToggle:after {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #fff;
    border-width: 8px;
    margin-right: -8px;
}

.userDtlToggle:before {
    border-color: rgba(112, 108, 206, 0);
    border-bottom-color: #706cce;
    border-width: 10px;
    margin-right: -10px;
}

.toggleSub {
    z-index: 11;
    display: block;
    height: 60px;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    cursor: pointer;
}

.toggleSub:before {
    position: absolute;
    right: 10px;
    top: 26px;
    cursor: pointer;
    z-index: 11;
    display: none;
    content: "\e997";
    color: #9f9f9f;
    font-size: 8px;
}

.sideBarExpand .toggleSub:before {
    display: block;
}

.toggleSub.active:before {
    content: "\e998";
    color: #706cce;
    font-size: 8px;
}

.notiCount {
    padding: 0px;
    width: 8px;
    height: 8px;
    line-height: 20px;
    top: 2px;
    right: 15px;
}

.rightTop {
    padding-top: 9px;
    padding-right: 5px;
}

.sideBarExpand .editProfile {
    display: none;
}

.contIcon.mailIcon {
    background: url("https://cdn.incentx.com/assets/images/mail-icon.png") no-repeat center center;
    width: 22px;
    height: 22px;
}

a:hover .contIcon.mailIcon {
    background: url("https://cdn.incentx.com/assets/images/mail-icon-active.png") no-repeat center center;
}

.contIcon.mobileIcon {
    background: url("https://cdn.incentx.com/assets/images/mobile-icon.png") no-repeat center center;
    width: 22px;
    height: 22px;
}

.userImage span {
    width: 100%;
    height: 100%;
    line-height: 39px;
    text-align: center;
    display: block;
    font-size: 16px;
    color: #fff;
}

a:hover .contIcon.mobileIcon {
    background: url("https://cdn.incentx.com/assets/images/mobile-icon-active.png") no-repeat center center;
}

.sideBarExpand .usrContact .contIcon {
    zoom: 0.9;
}

.dx-scrollable-content {
    transform: none !important;
}

.sideBar .dx-scrollable-wrapper .dx-scrollable-container,
.sideBar .dx-scrollable-vertical,
.sideBar.dx-scrollable-native.dx-scrollable-vertical {
    overflow-y: scroll !important;
    overflow-x: hidden !important;
}

.chartArea .dx-field.txtcenter {
    min-height: 20px;
    margin-bottom: 10px;
}

h2.chartTitle {
    text-align: center;
    font-size: 32px;
    display: block;
    font-weight: 400;
    font-family: 'proxima_nova';
}

.chartArea .dx-field.alignRight ~ .cf {
    display: none;
}

.chartAreaBox h2.chartTitle {
    position: absolute;
    width: 100%;
    top: 0px;
    z-index: 11;
}

.chartAreaBox .dx-field.alignRight {
    position: relative;
    z-index: 111;
}

.loginForm .formControls {
    margin-bottom: 0;
}

.loginContainer.authyCode {
    padding-bottom: 25px;
}

.loginContainer.authyCode .authyIcon {
    margin-right: 5px;
}

.agreementBox .colorList.tableData th:first-child {
    text-align: left;
}

.cdk-overlay-connected-position-bounding-box {
    margin-left: -10px;
}

.currentPage .dashIcon {
    top: -7px;
}

.featurePopup .featurePopup .popHeader h2 {
    text-align: left;
    font-size: 18px;
    line-height: 22px;
}

.featurePopup .featurePopup .popHeader h2 div {
    font-size: 14px;
}

.featurePopup h2 .highlight {
    font-size: 16px;
}

.popTitle {
    padding-bottom: 12px;
}

.grayedBlock {
    background: #f0f0fa;
    box-shadow: 0px 5px 21px rgba(0, 0, 0, 0.2);
    border-bottom: 1px solid #d3d3dd;
}

.disabledBlock input[type="button"] {
    background-color: #c3c2c7;
    box-shadow: 0 8px 15px rgba(195, 194, 199, 0.20);
}

.ownerTypeBlock label {
    display: block;
    line-height: 15px;
    margin-top: 3px;
    font-size: 14px;
}

.ownerTypeBlock .ownerType {
    display: block;
    color: #000;
}

.ownerTypeBlock {
    border-right: 1px solid #d3d3d3;
    padding-right: 25px;
    width: auto;
    display: table;
}

.assignDropdown {
    background-color: #fff;
    width: 100%;
}

.productDtl h3 {
    margin-bottom: 6px;
    line-height: 34px;
    font-size: 16px;
}

.productDtl .contTx {
    margin-bottom: 6px;
    color: #222;
}

.productDtl .lbl {
    color: #222222;
    font-size: 20px;
    font-family: 'proxima_nova semiBold';
}

.productDtl .contTx .lbl {
    color: #767676;
    font-size: 17px;
    font-family: 'proxima_nova';
}

.ucsData label strong {
    color: #706cce;
}

.ucsData label {
    color: #565656;
}

.multiBtnsRow .button {
    border: 0;
}

.leadProspectsRow .contListingBox .contListing {
    min-height: 60px;
}

.uploadLogoContainer {
    position: relative;
}

.uploadLogoContainer input {
    display: block;
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 11;
    cursor: pointer;
}

.uploadLogoContainer .sprite {
    position: absolute;
    right: 0px;
    top: -25px;
}

.requirementUp {
    width: 24px;
    height: 22px;
    float: left;
    background-position: -186px -220px;
}

.compImgContainer {
    clear: both;
    display: block;
    margin: 10px auto;
    text-align: center;
    height: 280px;
    width: 320px;
}

.userProPhoto {
    width: 80px;
    float: left;
}

.userProPhoto .uploadLogo {
    font-size: 12px;
    display: block;
    text-align: center;
    color: #565656;
    font-family: 'proxima_nova';
}

.userProfileEdit .customerDetail {
    width: calc(100% - 120px);
    padding-left: 10px;
    float: left;
}

.userProfileEdit .customerDetail.companyInfoTab {
    width: calc(100% - 80px)
}

.userAddressEdit {
    border-top: 1px solid #eeeeee;
    position: relative;
    margin-top: 10px;
    padding-top: 10px;
}

.addressDetails {
    float: left;
    width: calc(100% - 35px);
}

.addressDetails strong {
    color: #222222;
    font-size: 18px;
    font-family: 'proxima_nova semiBold';
}

.addressDetails .userAddName {
    display: block;
    font-size: 17px;
    font-family: 'proxima_nova';
    color: #222222;
}

.sectionTitle {
    font-size: 20px;
    font-family: 'proxima_nova semiBold';
    color: #222;
    margin-bottom: 10px;
}

.addressDetails p {
    margin-bottom: 0;
}

.listofCards .paymentCardDetail {
    width: 240px;
    display: block;
}

.listofCards .cardNumber,
.listofCards .cardNumber>span {
    font-size: 15px;
    color: #565656;
}

.primaryCardIco {
    position: relative;
}

.defCard {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 54px;
    height: 19px;
    background: url('https://cdn.incentx.com/assets/images/svgs/default-card.svg');
}

.primaryCard td:first-child {
    border-left: 3px solid #05c1a0;
}

.profileBlocks h3 {
    font-size: 20px;
    font-family: 'proxima_nova semiBold';
    line-height: 36px;
    margin-bottom: 10px;
    border-bottom: 1px solid #ededee;
}

.profileBlocks .newBillingAdd strong {
    line-height: 30px;
    font-size: 17px;
    color: #222222;
    font-family: 'proxima_nova';
    font-weight: normal;
}

.profileBlocks .newBillingAdd p {
    color: #565656;
    font-size: 16px;
}

.downloadSvIcon {
    background: url('https://cdn.incentx.com/assets/images/download-action.jpg') no-repeat center;
    width: 18px;
    height: 24px;
}

.noteDate {
    width: auto !important;
    display: inline-block;
}

.planWithDiscount .currentPackage {
    position: relative;
}

.planWithDiscount .currentPackage .panelWithBtn {
    padding-right: 130px;
    border: none !important;
}

.planWithDiscount .upLeftBtn {
    position: absolute;
    top: 25px;
    right: 0px;
}

.planWithDiscount .priceSpan,
.priceSpan {
    min-width: 50px;
    text-align: right;
    display: inline-block;
}

.discountPercent {
    font-family: 'proxima_nova semiBold';
    color: #32c680;
    font-size: 14px;
}

.planWithDiscount h2 {
    color: #565656;
    float: left;
    width: 100%;
}

.cancelLink,
.saveLink {
    font-size: 16px;
    font-family: 'proxima_nova';
}

input.discountInput,
input.discountInput:hover,
input.discountInput:focus {
    padding: 3px;
    color: #000 !important;
    border: 1px solid #d9d9d9;
    border-radius: 3px !important;
    background: #fff !important;
    width: 60px;
    height: auto;
    display: inline-block;
    font-size: 14px;
    color: #000;
    outline: none;
}

input.discountInput.disabled {
    color: #32c680 !important;
    border: 1px solid #fff;
}

.noteDate span.labelDate {
    color: #565656;
    font-weight: normal;
}

.insideApp.packageCart ul {
    width: 100%;
}

.insideApp.packageCart ul.sideBdrList li:before {
    display: none;
}

.orderDetail.withActionBtn .custDetail {
    width: 88%;
}

.orderDetail.withActionBtn .titleBtnCol {
    float: right;
}

.tableData.invoiceTbl th {
    color: #000;
}

.tableData.invoiceTbl th:first-child,
.tableData.invoiceTbl td:first-child {
    width: auto;
}

.customerPlanDiscount {
    position: relative;
    padding-right: 100px;
}

.customerPlanDiscount .discountInput {
    width: 98px;
    display: block;
    position: absolute;
    right: 0;
    top: 5px;
}

.discountInput .inputField label {
    padding: 1px 2px;
    font-size: 12px;
    left: 5px;
    bottom: 22px;
    line-height: 10px;
}

.discountInput input {
    padding: 2px 4px;
    border-radius: 2px !important;
    height: 30px;
    width: 74px;
    outline: 0;
}

.discountInput .iconDelete {
    right: 3px;
    top: 5px;
    position: absolute;
}

.addDiscount {
    font-size: 14px;
    font-family: 'proxima_nova bold';
    color: #787878;
    cursor: pointer;
}

.addDiscount .plusIcon {
    font-size: 20px;
    vertical-align: middle;
    line-height: 14px;
}

.grayedBlock {
    background: #f0f0fa;
    box-shadow: 0px 5px 21px rgba(0, 0, 0, 0.2);
    border-bottom: 1px solid #d3d3dd;
}

.disabledBlock input[type="button"] {
    background-color: #c3c2c7;
    box-shadow: 0 8px 15px rgba(195, 194, 199, 0.20);
}

.ownerTypeBlock label {
    display: block;
    line-height: 15px;
    margin-top: 3px;
    font-size: 14px;
}

.ownerTypeBlock .ownerType {
    display: block;
    color: #000;
}

.ownerTypeBlock {
    border-right: 1px solid #d3d3d3;
    padding-right: 25px;
    width: auto;
    display: table;
}

.shiftTime {
    max-width: 275px;
    border: 1px solid #c8c8c8;
    padding: 14px 7px;
}

.timeZone {
    font-size: 14px;
    color: #565656;
    font-family: 'proxima_nova';
}

.timeZone strong {
    font-size: 15px;
    display: block;
    font-weight: 400;
    color: #222;
}

.shiftTime .inputField label {
    bottom: 28px;
    line-height: 10px;
}

.passwordInput,
input[type="password"].passwordInput {
    font-size: 22px;
    letter-spacing: 1.5px;
    padding: 0;
    border: none;
    height: auto;
    outline: none;
}

.designation {
    font-size: 16px;
    color: #565656;
    font-weight: 400;
    font-family: 'Proxima_Nova'
}

.addressDetails strong.subTitle {
    font-size: 15px;
    font-weight: 400;
    color: #565656;
    font-family: 'Proxima_Nova';
}

.addressDetails .userAddName {
    font-family: 'Proxima_Nova semiBold';
}

/*07/12/2020*/
.userProfileBg {
    background: #1b8693;
    width: 60px;
    height: 60px;
    border-radius: 50%;
}

.passwordInfo h3 {
    font-size: 20px;
    color: #000;
    margin-bottom: 5px;
}

.passwordInfo ul {
    padding: 0;
    font-size: 14px;
}

.passwordInfo ul li {
    list-style: none;
    position: relative;
}

.passwordInfo ul li.comply {
    color: #39c970;
}

.passwordInfo ul li.comply {
    padding-left: 18px;
}

.passwordInfo ul li .complyIcon {
    display: none;
}

.passwordInfo ul li.comply .complyIcon {
    display: block;
}

.generatePass {
    font-size: 14px;
    color: #706cce;
    text-decoration: underline;
    position: absolute;
    bottom: -4px;
    left: 0;
}

.generatePass:hover {
    cursor: pointer;
}

span.errorLabel {
    position: absolute;
    top: -15px;
    font-size: 12px;
    right: 0;
    color: #ff4342;
}

.pswdNotMatch input {
    border-color: #ff4342;
    background: url('https://cdn.incentx.com/assets/images/error-input.png') 97% 8px no-repeat;
}

.pswdMatch input {
    border-color: #31cc71;
    background: url('https://cdn.incentx.com/assets/images/checked-icon.png') 97% 8px no-repeat;
}

.componentList .dx-list-item-content {
    padding: 15px 0px;
}

.componentList .dx-scrollview-content {
    padding-right: 18px;
}

.componentList .componentBtn {
    padding-right: 33px;
}

.configIcon.sprite {
    background-position: -154px -216px !important;
}

.center.addNew {
    position: absolute;
    right: 5px;
    top: 2px;
}

.userProPhoto .userImage span,
.popContent .userImage span {
    line-height: 74px;
    font-size: 28px;
}

.userProPhoto .userImage {
    width: 100%;
    height: 100%;
}

.userProPhoto {
    width: 80px;
    float: left;
    height: 80px;
}

.popContent .userImage {
    width: 80px;
    height: 80px;
    margin-top: -10px;
}

/* Dashboard Tile New Design */
.statisticBlock.newStBlock.newTileBlock .row>[class^=cols] {
    width: 12.5%;
}

.newTileBlock .contractIcon,
.newTileBlock .customerIcon,
.newTileBlock .salesPersonIcon {
    text-align: center;
    display: block;
    margin: 8px auto;
    height: 32px;
}

.newTileBlock .numberBlock .blockText {
    text-align: center;
    display: block;
    font-size: 15px;
    color: #767676;
}

.newTileBlock .numberBlock strong {
    font-size: 24px;
}

.adminStatisticBlock.statisticBlock.newStBlock.newTileBlock .row>[class^=cols] {
    width: 16.6%;
}

.mat-select-panel-wrap .ng-trigger {
    min-width: calc(100% - 31px) !important;
    margin-left: 35px;
}

.mat-datepicker-content {
    margin-left: 15px;
}

.fcoBorderBox .appConfigBtn {
    margin-top: 0;
}

.tableOut .mat-table tr td {
    border-right: 0;
}

.lconImg {
    text-align: left;
}

.matBtn .makePayIcon {
    vertical-align: middle;
}

.matBtn .iconDelete {
    vertical-align: middle;
}

.popContent .cvvInfo {
    top: 50px;
    bottom: auto !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none !important;
}

.customerDetail.custdtlTop {
    position: relative;
}

.customerDetail.custdtlTop .editSvg {
    position: absolute;
    right: 0;
    top: 0;
    margin-right: 0;
}

a.sentMail {
    width: 34px;
    height: 34px;
    padding: 4px 0px 0px 0px;
}

table.devExTable tr td {
    padding: 10px;
    font-size: 14px;
}

a.purchaseNow {
    color: #21b3c6;
}

.purchaseNow span {
    vertical-align: middle;
    display: inline-block;
    height: 20px;
}

.planTotalSection {
    font-size: 14px;
    color: #767676;
}

.planTotalSection .totalValue {
    color: #222222;
    margin-left: 5px;
}

.planTotalSection .savingNote {
    color: #32c680;
    font-size: 13px;
}

.planTotalSection .extraNote {
    color: #767676;
    font-size: 13px;
}

.planTotalSection .finalTotal .totalValue {
    font-size: 16px;
    color: #706cce;
    font-family: 'Proxima_Nova semiBold';
}

.planTotalSection .cf {
    margin: 5px 0px 0px 0px;
}

.planAmount {
    font-size: 13px;
    font-family: 'Proxima_Nova';
    color: #787878;
}

.planAmount strong {
    font-size: 14px;
    font-family: 'Proxima_Nova';
    color: #565656;
    font-weight: 400;
}

.noteSeprator {
    position: relative;
    width: 10px;
    display: inline-block;
    vertical-align: top;
}

span.noteSeprator:before {
    content: "";
    display: block;
    vertical-align: middle;
    position: absolute;
    top: -2px;
    right: 4px;
    border-left: 1px solid #866fd4;
    width: 1px;
    height: 24px;
    border-right: 1px solid #fff;
}

span.noteSeprator ~ strong {
    border-bottom: 1px solid #706cce;
}

.modifiedCell {
    border-color: rgba(92, 184, 92, .8);
    border-width: 1px;
    border-style: solid;
}

#gridContainer.uniqueClass .dx-datagrid-content .dx-datagrid-table .dx-row td:last-child {
    padding: 0;
    height: 42px;
}

#gridContainer.uniqueClass .dx-datagrid-content .dx-datagrid-table .dx-row td:last-child>div {
    height: 100%;
    line-height: 36px;
    padding: 0px 4px;
}

.cols6.shiftTimeViewOnly {
    max-width: 240px;
    border: 1px solid #c8c8c8;
}

.inlineRadio .notificationRadio {
    display: inline-block;
    margin-right: 20px;
}

.notificationRadio .mainLabel {
    font-size: 17px;
    color: #222222;
    font-family: 'proxima_nova';
    margin-right: 10px;
}

.notifyBy>label,
.notifiactionDuration>label,
.timeBetween>label,
.notifyDays>label {
    font-size: 15px;
    color: #565656;
    margin-right: 15px;
    margin-bottom: 10px;
}

.notifiactionDuration>label,
.timeBetween>label,
.notifyDays>label {
    display: block;
}

.notifyBy {
    width: 100%;
    margin: 20px 0px;
}

.notifiactionDuration,
.timeBetween,
.notifyDays {
    display: inline-block;
    margin-bottom: 20px;
    margin-right: 15px;
    vertical-align: top;
}

.timeBetween {
    margin-right: 30px;
}

.notifiactionDuration .notifyDurationTime {
    width: 210px;
}

.notifiactionDuration .notifyDurationTime input {
    max-width: 80px;
    display: inline-block;
    height: 38px;
    padding: 3px 15px;
    margin-right: 10px;
    font-family: 'proxima_nova';
    color: #222222;
    font-size: 16px;
}

.notifiactionDuration .notifyDurationTime select {
    max-width: 110px;
    display: inline-block;
    height: 38px;
    padding: 3px 35px 3px 15px;
    font-family: 'proxima_nova';
    color: #222222;
    font-size: 16px;
}

.timeBetween .durationTime .timeInput {
    padding: 3px 30px 3px 15px;
    font-family: 'proxima_nova';
    color: #222222;
    font-size: 16px;
    box-shadow: none;
}

.timeBetween .durationTime {
    width: auto;
}

.timeBetween .durationTime .row {
    margin: 0px -10px;
}

.timeBetween .durationTime .row .cols6 {
    padding: 0px 10px;
}

.notifyBy .form-group,
.notifyDays .form-group,
.notifyDays .form-group {
    display: inline-block;
    margin-right: 15px;
}

.notifyBy .form-group label,
.notifyDays .form-group label {
    color: #222222;
    font-size: 16px;
}

.notifyDays {
    max-width: calc(100% - 460px);
}

.saveBtn.notificationSettings {
    float: right;
    margin-top: 30px;
}

.notifyDays .form-group,
.notifyDays .form-group {
    margin-top: 2px;
}

.form-group input:disabled+label:before {
    background-color: #f0f0f0;
}

.notificationDisabled,
.notificationDisabled .notifyBy .form-group input:disabled+label,
.notificationDisabled .notifyDays .form-group input:disabled+label,
.notificationDisabled .notifyBy>label,
.notificationDisabled .notifiactionDuration>label,
.notificationDisabled .timeBetween>label,
.notificationDisabled .notifyDays>label {
    color: #999;
}

input[type="button"].disabled {
    box-shadow: 0 8px 15px rgba(175, 175, 175, 0.20);
}

.notifyDurationTime input[type=number]::-webkit-inner-spin-button,
.notifyDurationTime input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
}

.logoConnection {
    clear: both;
    display: table;
    width: 100%;
    text-align: center;
}

.sourceLogo,
.connectedArrow,
.destinationLogo {
    float: left;
    padding: 0px 10px;
}

.connectedArrow {
    background: url('https://cdn.incentx.com/assets/images/arrow-image.jpg') no-repeat top left;
    margin: 30px 0px;
    width: 170px;
    display: block;
    height: 21px;
}

.connectedTitle h4 {
    font-size: 32px;
    font-weight: 600;
    margin: 20px 0px 5px 0px;
    color: #000;
    font-family: Helvetica, sans-serif;
}

.connectedTitle p {
    color: #767676;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    margin-top: 12px;
    margin-bottom: 5px;
    font-family: Helvetica, sans-serif;
}

.custIcon {
    padding: 5px;
    box-shadow: none;
    background: #fafafa;
    border: 2px solid #fafafa;
}

strong.timeInput {
    padding: 3px 0px;
    height: auto;
    line-height: 30px;
    background: url(https://cdn.incentx.com/assets/images/svgs/timing-icon.svg) no-repeat 70px 8px;
}

.poShipping .cols6 {
    display: block;
    font-size: 16px;
    color: #000;
    font-weight: 500;
}

.poShipping .cols6:first-child {
    width: 205px;
}

.refundIcon {
    width: 24px;
    height: 24px;
    display: inline-block;
    background-position: -114px -216px;
}

.additionalDetails {
    clear: both;
    display: block;
    width: 100%;
    padding: 5px 0px;
    margin: 0px;
}

.additionalDetails li {
    list-style: none;
    display: inline-block;
    margin-right: 13px;
    padding-right: 13px;
    font-size: 15px;
    color: #222222;
    position: relative;
    line-height: 25px;
}

.additionalDetails li:after {
    content: "";
    height: 38px;
    top: 3px;
    right: 0;
    position: absolute;
    width: 1px;
    border-right: 1px solid #dadada;
}

.additionalDetails li:last-child:after {
    display: none;
}

.additionalDetails li span {
    display: block;
    font-size: 15px;
    color: #565656;
}

.additionalDetails li strong {
    font-weight: 400;
    color: #565656;
    font-family: 'proxima_nova';
    font-size: 15px;
}

.additionalDetails li strong:last-child {
    margin-left: 10px;
}

.notifyDays.fullWidth {
    width: 100% !important;
    max-width: 100% !important;
}

.grandTotalAction {
    background-color: #f6f6f6;
    padding: 15px;
}

.grandTotalAction h4 {
    font-size: 18px;
}

.customTooltipCLass {
    padding: 0;
    margin: 0;
    border-color: #21b3c6 !important;
}

.customTooltipCLass .dx-popup-content {
    padding: 0 !important;
    border-color: #21b3c6 !important;
}

ul.tooltipList {
    padding: 0;
    margin: 0;
    min-width: 210px;
    text-align: left;
}

ul.tooltipList li {
    padding: 10px 12px;
    border-bottom: 1px solid #ccc;
    list-style: none;
}

.contentRow {
    position: relative;
    float: left;
    width: 100%;
}

.adminuser {
    position: absolute;
    top: 0;
    right: 0;
    width: 54px;
    height: 19px;
    background: url(https://cdn.incentx.com/assets/images/admin-user.png);
}

.adminUsrTag {
    padding-right: 70px;
    position: relative;
    display: inline-block;
}

.adminUsrTag span.adminuser {
    right: 0;
    top: 2px;
    left: auto;
}

.genInvoiceIcon {
    width: 24px;
    height: 24px;
    display: inline-block;
    background-position: -74px -216px;
}

.confirmationIcon .ItemsIgnore {
    text-align: center;
    margin-top: 30px;
    height: 70px;
    background: url('https://cdn.incentx.com/assets/images/Items_Lost.png') no-repeat center center;
}

.confirmationIcon .changeContract {
    text-align: center;
    margin-top: 30px;
    height: 70px;
    background: url('https://cdn.incentx.com/assets/images/Change_Contract.png') no-repeat center center;
}

.viewAllBtn .rightArrow {
    width: 10px;
}

#crmCustomerDetail dx-tag-box .dx-tag {
    display: table;
    max-width: 100% !important;
}

#crmCustomerDetail dx-tag-box .dx-tag .dx-dropdowneditor-input-wrapper {
    display: table;
}

#crmCustomerDetail dx-tag-box .dx-tag {
    display: block;
    word-break: keep-all;
}

#crmCustomerDetail .dx-tag-content span {
    word-break: keep-all;
}

#crmCustomerDetail .assignLabel~div {
    clear: both;
    display: table;
}

.chartArea>.row>.cols2.pull-left {
    display: none;
}

.tableScroll {
    margin-bottom: 10px;
}

input::-webkit-contacts-auto-fill-button {
    visibility: hidden;
    display: none !important;
    pointer-events: none;
    height: 0;
    width: 0;
    margin: 0;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent !important;
    width: 34px;
    height: 100%;
    position: relative;
    z-index: 1111;
    font-size: 0;
}

.contDtl span.adminuser {
    right: 50px !important;
    top: 15px !important;
}

.paddL0.quotesRadio {
    padding-left: 0px;
}

.centeredIcon {
    float: none !important;
}

.userCheckbox {
    margin-bottom: 5px;
    display: inline-block;
}

.ntfcTable .actBtn a::after {
    display: none;
}

.relativeRadio input[type="checkbox"]+label.digitsCount.radio {
    background: transparent !important;
}

.relativeRadio input[type="checkbox"]+label.digitsCount.radio:before {
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 2px;
    padding: 10px;
    display: inline-block !important;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    content: "";
}

.relativeRadio input[type="checkbox"]:checked+label.digitsCount.radio:after {
    content: "";
    display: block;
    position: absolute;
    top: 4px;
    left: 7px;
    width: 8px;
    height: 14px;
    border: solid #706cce;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.statusBx span.txtRight {
    float: right;
    line-height: 34px;
}

.poShipping .cols6 span {
    display: block;
    color: #565656;
    font-weight: 400;
}

span.passwordInput {
    font-size: 20px;
    letter-spacing: 0px;
    cursor: default;
}

.productSearch .feildCol.radioGroup {
    margin-top: 5px;
    min-height: 20px;
}

.productSearch .feildCol.radioGroup .inlineBlock {
    padding-left: 0;
    vertical-align: middle;
    display: inline-block;
}

.productSearch .feildCol.radioGroup .inlineBlock > label {
    padding-left: 0;
    font-weight: 600;
    margin-right: 25px;
    padding-right: 0;
}

.cols2.reportYear {
    width: 33%;
}

.srTitle > label {
    font-size: 18px;
    color: #000;
    margin-bottom: 10px;
}

.productSearch ~ .appConfigBtn {
    margin-top: 10px;
}

.productSearch .feildCol.radioGroup:last-child {
    margin-bottom: 5px;
}

.fcoBorderBox .radioGroup>label {
    margin-top: 0px !important;
}

.remarkBx.ecaRemark.alignRight {
    float: right !important;
}

.colSFT .form-group {
    display: inline-block;
    margin-right: 10px;
}

.colSFT .form-group label {
    color: #999;
}

.serchBox input[type='text']#twofaPassPhrase {
    padding-right: 15px;
}

.notifyBy .dx-checkbox-text,
.notifyDays .dx-checkbox-text {
    word-wrap: normal !important;
}

.customerDetail.crmCustomerDetail .editIco {
    right: 12px;
}

.forCsv h2 {
    margin-bottom: 10px;
}

.addQuotPro .configBtn ~ .btnCol {
    width: 265px;
    margin-right: 0;
}

.addQuotPro .configBtn ~ .btnCol .button {
    margin-right: 10px;
    width: auto;
}

.addQuotPro .configBtn ~ .btnCol .button:nth-child(2) {
    margin-right: 0;
}

.cols.colsName.configBtn {
    width: 24%;
}

.ecaCols .feildCol.radioGroup {
    min-height: 45px;
}

.reportSearchFrm.cols10 {
    width: calc(100% - 120px);
}

.cols2.reportSearchBtn {
    width: 120px;
}

.cols2reportBtn {
    padding-right: 15px;
    width: 120px;
    float: right;
}

.reportDate .mat-form-field-infix,
.reportDate .mat-form-field-infix input {
    line-height: 20px;
}

.tblNotification .actBtn.profileEditBtn {
    right: 30px;
    position: absolute;
}

.claimAmount {
    width: calc(27% - 120px);
    float: left;
}

.chargeBackAdd {
    float: left;
    padding-left: 15px;
}

.claimNote p {
    margin-bottom: 0;
}

.prodSearchRadio {
    width: 50%;
    padding-right: 25px;
    display: inline-block;
}

.dx-datagrid-table .menuTip .iconThree.sprite {
    margin: 6px 0px 0px 7px !important;
}

.fileUpbtn {
    border: 1px solid #e5e5e5;
}

.mat-pseudo-checkbox {
    content: '';
    -webkit-appearance: none;
    background-color: #fff !important;
    border: 1px solid #c8c8c8 !important;
    padding: 10px;
    display: inline-block !important;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 12px !important;
    border-radius: 2px !important;
    opacity: 1 !important;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
    background-color: #fff !important;
}

.mat-pseudo-checkbox-checked::after {
    content: '';
    display: block;
    position: absolute !important;
    top: 0px !important;
    left: 6px !important;
    width: 6px !important;
    height: 14px !important;
    border: solid #337ab7 !important;
    border-width: 0 2px 2px 0 !important;
    transform: rotate(45deg) !important;
}

.pointer {
    cursor: pointer;
}

.signleTag {
    right: 50px !important;
    top: 8px !important;
}

.dayCount input.dx-texteditor-input {
    padding: 2px 0px 2px 5px;
    height: auto;
    min-height: auto;
    line-height: 26px;
}

.container {
    padding: 15px;
}

.customer360 {
    width: 100%;
}

.customer360 .chartBox {
    border: 1px solid #dfdfdf;
    margin-bottom: 16px;
}

.customer360 .chartBox h2 {
    font-size: 30px;
    color: #222222;
    margin-bottom: 8px;
    line-height: 50px;
    text-align: center;
}

.customer360 .chartBox img {
    margin: 0 auto;
    display: block;
}

.customeIco {
    background: #f1f3fc;
    border-radius: 50%;
    width: 45px;
    height: 45px;
}

.iconBlocks {
    padding: 15px;
    border: 1px solid #dfdfdf;
    text-align: center;
    margin-bottom: 20px;
}

.dayCount {
    color: #222;
    font-size: 30px;
    line-height: 34px;
    text-align: left;
    display: table;
    clear: both;
    margin-bottom: 0;
    width: 100%;
}

.dayCount span {
    font-size: 16px;
    color: #767676;
    display: block;
    line-height: 18px;
}

.dayCount select {
    display: block;
    margin: 3px auto;
    border: 1px solid #dfdfdf;
    padding: 5px 22px 5px 5px;
    background: url('https://cdn.incentx.com/assets/images/tiles-dropdown-arrow.png') 94% 7px no-repeat;
    font-size: 13px;
    color: #4a4a4a;
    -webkit-appearance: none;
}

span.upIcon {
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 12px solid #3ea10e;
    display: inline-block;
    margin-bottom: 3px;
}

span.downIcon {
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 12px solid #fe5059;
    display: inline-block;
    margin-bottom: 3px;
}

.bgrev {
    background: -moz-linear-gradient(-45deg, rgb(57, 39, 203) 0%, rgb(164, 95, 215) 100%);
    background: -webkit-linear-gradient(-45deg, rgb(57, 39, 203) 0%, rgb(164, 95, 215) 100%);
    background: linear-gradient(-45deg, rgb(57, 39, 203) 0%, rgb(164, 95, 215) 100%);
}

.bgvol {
    background: -moz-linear-gradient(-45deg, rgb(28, 117, 200) 0%, rgb(98, 95, 216) 100%);
    background: -webkit-linear-gradient(-45deg, rgb(28, 117, 200) 0%, rgb(98, 95, 216) 100%);
    background: linear-gradient(-45deg, rgb(28, 117, 200) 0%, rgb(98, 95, 216) 100%);
}

.bgVariety {
    background: -moz-linear-gradient(-45deg, rgb(33, 187, 187) 0%, rgb(17, 155, 210) 100%);
    background: -webkit-linear-gradient(-45deg, rgb(33, 187, 187) 0%, rgb(17, 155, 210) 100%);
    background: linear-gradient(-45deg, rgb(33, 187, 187) 0%, rgb(17, 155, 210) 100%);
}

.bgProf {
    background: -moz-linear-gradient(145deg, rgb(143, 104, 250) 0%, rgb(239, 108, 177) 100%);
    background: -webkit-linear-gradient(145deg, rgb(143, 104, 250) 0%, rgb(239, 108, 177) 100%);
    background: linear-gradient(145deg, rgb(143, 104, 250) 0%, rgb(239, 108, 177) 100%);
}

.bgPay {
    background: -moz-linear-gradient(-45deg, rgb(32, 152, 185) 0%, rgb(59, 115, 187) 100%);
    background: -webkit-linear-gradient(-45deg, rgb(32, 152, 185) 0%, rgb(59, 115, 187) 100%);
    background: linear-gradient(-45deg, rgb(32, 152, 185) 0%, rgb(59, 115, 187) 100%);
}

.bgOrder {
    background: -moz-linear-gradient(-45deg, rgb(36, 202, 137) 0%, rgb(97, 207, 216) 100%);
    background: -webkit-linear-gradient(-45deg, rgb(36, 202, 137) 0%, rgb(97, 207, 216) 100%);
    background: linear-gradient(-45deg, rgb(36, 202, 137) 0%, rgb(97, 207, 216) 100%);
}

.bgDelivery {
    background: -moz-linear-gradient(-45deg, rgb(214, 88, 119) 0%, rgb(202, 39, 140) 100%);
    background: -webkit-linear-gradient(-45deg, rgb(214, 88, 119) 0%, rgb(202, 39, 140) 100%);
    background: linear-gradient(-45deg, rgb(214, 88, 119) 0%, rgb(202, 39, 140) 100%);
}

.bgMargin {
    background: #fdefec;
    background: -moz-linear-gradient(-45deg, rgb(230, 167, 45) 0%, rgb(237, 58, 89) 100%);
    background: -webkit-linear-gradient(-45deg, rgb(230, 167, 45) 0%, rgb(237, 58, 89) 100%);
    background: linear-gradient(-45deg, rgb(230, 167, 45) 0%, rgb(237, 58, 89) 100%);
}

.dayCount .dx-dropdowneditor {
    width: auto !important;
    display: inline-block;
    margin: 2px auto;
    max-width: 150px;
    font-size: 14px;
}

.dayCount input.dx-texteditor-input {
    padding: 2px 0px 2px 5px;
    height: auto;
    min-height: auto;
    line-height: 26px;
}

.countValue .loadingPrice.custSpinner {
    width: 22px;
    margin: 5px auto;
    clear: both;
    line-height: 24px;
}

.ddwithTitle {
    width: auto;
    float: right;
    max-width: 220px;
}

.iconBlocks {
    position: relative;
}

.chartFullScreen:before {
    content: "\e941";
    color: #838383;
    font-size: 14px;
    font-family: 'IXPortal';
    top: -2px;
    position: absolute;
    right: 0;
}

.chartFullScreen {
    width: 15px;
    height: 15px;
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
}

.customeIco img {
    max-width: 47px;
    position: relative;
    top: -1px;
    left: -1px;
}

.dayCount span.highlight {
    color: #706cce;
    margin-top: 2px;
    display: block;
    text-align: center;
}

.dayCount .dx-texteditor-input {
    padding-right: 0;
}

.dayCount .dx-dropdowneditor-button {
    width: 24px;
    min-width: 24px;
}

.crmTileDetails .noBorderStyle {
    border-style: none !important;
    background-color: transparent !important;
    border-color: transparent !important;
}

.crmTileDetails .dx-state-hover {
    border-style: none !important;
    background-color: transparent !important;
    border-color: transparent !important;
}

.countValue {
    min-height: 52px;
    display: table;
    width: 100%;
    vertical-align: middle;
}

.countValue>* {
    display: table-cell;
    vertical-align: middle;
}

.customer360 .cols6 dx-radio-group {
    margin-top: 5px;
}

.iconBlockTool {
    position: absolute;
    right: 15px;
    top: 15px;
}

.iconBlockTool .chartFullScreen {
    position: relative;
    display: inline-block;
    top: 0;
    right: 0;
    margin-left: 10px;
    float: right;
}

.amountRow {
    font-size: 27px;
    font-family: 'proxima_nova semiBold';
    line-height: 50px;
}

.amountRow .changeIcon {
    display: inline-block;
    font-size: 14px;
    font-family: 'proxima_nova';
    text-align: center;
    padding-bottom: 5px;
    vertical-align: text-top;
}

.amountRow .changeIcon span {
    display: block;
    margin: 0 auto;
    font-size: 14px;
}

.iconBlockTool .dx-dropdowneditor .dx-texteditor-input {
    min-height: 0;
    line-height: 24px;
    padding: 3px 5px !important;
    height: auto !important;
}

.iconBlockTool .dx-texteditor {
    display: inline-block !important;
}

.iconBlockTool .dx-dropdowneditor-button {
    min-width: 22px;
    width: 22px;
}

.amountRow > span {
    display: inline-block;
    vertical-align: middle;
}

.downTrend {
    color: #fe5059 !important;
}

.upTrend {
    color: #3ea10e !important;
}

.chartTitle~.ddwithTitle {
    position: absolute;
    right: 25px;
    top: 20px;
}

.dayCount span.loadingPrice.custSpinner {
    margin: 14px 0px;
    display: block;
}

.downTrend,
.downTrend * {
    color: #fe5059 !important;
}

.upTrend,
.upTrend * {
    color: #3ea10e !important;
}

.customer360 dx-radio-group {
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
}

.row.customerTiles {
    margin: 0px -7px;
}

.customerTiles .cols3 {
    padding: 0px 8px;
}

.customer360 .cols9 label {
    vertical-align: middle !important;
}

.customer360 .row .cols12 label {
    font-size: 13px;
}

.amountRow .changeIcon.upTrend {
    padding-bottom: 5px;
    vertical-align: middle;
}

a.gridEye {
    padding: 4px 3px;
}

.actBtn .downloadIcon {
    background-size: 100% !important;
    width: 16px !important;
    height: 18px !important;
}

.tabwithAction {
    position: relative;
    width: calc(100% - 115px);
    overflow: hidden;
}

.multiLineTab {
    border-bottom: 2px solid #e6e6e6;
}

.multiLineTab .tabwithAction > ul {
    border-bottom: none;
    white-space: normal !important;
    margin-bottom: 0;
}

#crmCustomerDetail .cols7 .tabbing ul {
    margin-bottom: 0px;
}

.noteBx.payTotal {
    position: absolute;
    right: -15px;
    top: -25px;
    padding: 10px 20px 10px 10px;
}

.noteBx.payTotal .stLbl {
    display: block;
    font-size: 16px;
    color: #565656;
    padding: 0;
    line-height: 26px;
    width: auto;
    min-width: auto;
}

.noteBx.payTotal .totalAmount {
    font-size: 22px;
    font-family: 'proxima_nova semiBold';
    color: #706cce;
    display: block;
    line-height: 22px;
}

.paySubotal,
.payFinalTotal {
    text-align: right;
}

.paySubTotal {
    color: #767676;
    font-size: 15px;
    margin: 2px 0px;
    text-align: right;
}

.paySubTotal span {
    color: #222;
    font-size: 15px;
}

.payFinalTotal {
    font-size: 16px;
    color: #222222;
    margin: 2px 0px;
}

.payFinalTotal span {
    color: #706cce;
    font-family: 'proxima_nova semiBold';
}

.paySubTotal span,
.payFinalTotal span {
    text-align: right;
    min-width: 110px;
    display: inline-block;
    margin-right: -5px;
}

.cols12.paymentBtmRow {
    border-left: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    border-right: 1px solid #ddd;
}

.listOfCards .cardNumber>div {
    display: inline-block;
}

.listOfCards .listofCards .paymentCardDetail {
    width: 100%;
    display: table;
    clear: both;
    padding-right: 60px;
}

.listOfCards .defCard {
    top: 4px;
}

.listOfCards dx-radio-group {
    margin: 10px auto;
    display: inline-block;
    width: 22px;
}

span.cardExpiry {
    position: absolute;
    top: 25px;
    font-size: 15px;
    color: #565656;
    right: 0;
}

.creditMemo {
    color: #ff4545 !important;
}

.quoteDesign .orderAddress .addressBox {
    min-height: 140px;
}

.orderDetail.cols4 {
    width: 28%;
}

.quoteDesign .orderAddress.cols6 {
    width: 44%;
}

.orderStatus {
    width: 27%;
    max-width: 28%;
}

.trackingTable {
    max-height: 140px;
    overflow: auto;
    width: 100%;
}

.trackingTable th,
.trackingTable td {
    padding: 3px 5px;
}

.trackingTable th {
    color: #222222;
    font-size: 16px;
    border-bottom-width: 2px;
}

.trackingTable td {
    font-size: 15px;
}

.orderStatus.odStatus.cols2:after {
    content: "";
    height: 95%;
    display: block;
    position: absolute;
    top: 6%;
    left: -5px;
}

.inStatusLabel {
    border-radius: 3px;
    padding: 5px;
    font-size: 13px;
    cursor: default;
    vertical-align: middle;
    line-height: normal;
}

.inStatusLabel.openInvoice {
    color: #02C19F;
    background: #E5F8F5;
    border: 1px solid #02C19F;
}

.inStatusLabel.orangeInvoice {
    color: #F99301;
    background: #FEF4E5;
    border: 1px solid #F99301;
}

.inStatusLabel.redInvoice {
    color: #FE5059;
    background: #FEEDEE;
    border: 1px solid #FE5059;
}

.orderStatus.odStatus.cols2.noBdr:after {
    border: none !important;
}

.orderAddress.cols8 {
    width: 72%;
    padding-top: 0 !important;
    border-top: 0 !important;
}

.sepConfigField .feildCol {
    width: calc(100% - 120px);
    float: left;
}

.sepConfigField .feildCol {
    width: calc(100% - 120px);
    float: left;
}

.sepConfigField .fieldBtn {
    width: 100px;
    float: left;
    border: 1px solid #e5e5e5;
    border-left: none;
    height: 42px;
    margin-top: 10px;
    text-align: center;
}

.sepConfigField .fieldBtn:hover {
    background: #f0f0f0;
}

.sepConfigField .fieldBtn .dx-link {
    vertical-align: top;
    margin-top: 3px;
}

.track_numbers_tagview {
    position: relative;
    display: inline-block;
    min-width: 30px;
    text-align: center;
    cursor: pointer;
    margin: 4px 0 0 4px;
    padding: 2px 6px 2px 6px;
    min-width: 40px;
    background-color: #EDEDF3;
    border-radius: 2px;
    color: #2b6ca3 !important;
    text-decoration: none !important;
    border: 2px solid #DBDBDB;
}

span.carrier {
    color: black;
    font-weight: 500;
}

.notesHeight {
    height: calc(100% - 200px) !important;
}

#crmCustomerDetail .crmTable {
    min-height: 440px;
    height: max-content;
}

.newCustDetail {
    height: 310px;
}

.crmTable > div.ng-star-inserted {
    min-height: 90%;
}

.contBtn.titleBtnCol.vndrActionBtn {
    right: 0 !important;
    color: #fff;
    top: 0px !important;
}

.newCustDetail.vendorDtl {
    height: 120px;
}

#crmCustomerDetail .crmTable.vendoreTable {
    height: 685px;
}

.mrgR50 {
    margin-right: 50px !important;
}

.remarkBx {
    width: calc(100% - 170px);
    display: inline-block;
}

.withAddBtn dx-button {
    display: inline-block;
}

.paymentBtmRow .alignRight {
    float: right !important;
}

.minHeightDataGrid150 {
    min-height: 150px !important;
}

.totalRowCal {
    width: calc(91% - 365px);
}

.totalRowCal.noBatchClm {
    width: calc(100% - 5px);
    float: left;
}

.totalRowCal.noActClm {
    width: 81%;
    float: left;
}

.totalRowCal.noActClm.noBatchClm {
    width: 100%;
}

ul.tableOut.trackingTable {
    padding: 0;
}

app-incoming-payments-details-view .orderStatus.odStatus.cols2 {
    width: 25%;
    float: right;
    position: relative;
}

app-delivery-details .orderStatus.odStatus.cols2 {
    position: relative;
}

.adminCRM .newContactListing {
    height: 260px;
}

.adminCRM .newBillAddress .tabContainer {
    height: 360px;
}

.tlNm .iconSvg {
    vertical-align: middle;
}

.activate .toggleSub:before {
    color: #706cce;
}

.activate .toggleSub.active:before {
    transform: none;
}

.invoiceListIcon .refundIcon {
    background-position: -115px -219px;
}

#crmCustomerDetail .cols7 .contBtn.titleBtnCol a img {
    margin-top: -1px;
}

.checkboxGroup .form-group.checkBox > span {
    vertical-align: middle;
    line-height: 16px;
}

.form-group.checkBox.editCustomer > span {
    margin-bottom: 8px;
    display: block;
}

/* DocuSign Rebate Stage Changed */
.rebateStatusTab ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    overflow-y: hidden;
    white-space: nowrap;
    display: flex;
}

.rebateStatusTab li+li {
    padding: 13px 30px 13px 45px;
    color: #fff;
}

.rebateStatusTab li {
    padding: 13px 30px;
    position: relative;
    float: left;
    vertical-align: top;
    background: #706cce;
    color: #fff;
    min-width: 150px;
    font-size: 15px;
    font-family: 'proxima_nova semiBold';
    flex-grow: 1;
}

.rebateStatusTab li.currentStat,
.rebateStatusTab li.currentStat+li:before {
    background: #21b3c6;
}

.rebateStatusTab li+li:before {
    content: '';
    position: absolute;
    top: -0.2em;
    bottom: 0;
    border-right: solid;
    border-top: solid;
    pointer-events: none;
    box-sizing: border-box;
    width: 3em;
    transform: rotate(30deg) skewy(30deg);
    z-index: 1;
    border-color: #fff;
    border-width: 2px;
}

.rebateStatusTab li:last-child:after {
    right: -1.5em;
    background: inherit;
}

.rebateStatusTab li+li:before {
    left: -2em;
}

.rebateStatusTab li.currentStat:before {
    background: #706cce;
}

.rebateStatusTab li span {
    z-index: 2;
    position: relative;
}

.rebateStatusTab li.disabledStat {
    background: #969696;
}

.submitDDBtn .dx-button-content {
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
}

.submitDDBtn .dx-button-has-text .dx-button-content {
    padding: 10px 20px !important;
    font-size: 16px;
    color: #fff;
}

.submitDDBtn .dx-button-has-icon .dx-button-content {
    padding: 10px 6px !important;
}

.submitDDBtn .dx-icon-spindown::before {
    font-size: 16px;
    color: #fff;
    background: url('https://cdn.incentx.com/assets/images/sprite.png') no-repeat -60px -112px;
    content: "" !important;
    width: 18px;
    height: 18px;
    display: block;
}

.submitDDBtn.dxSubmitBtn {
    background-color: #21b3c6 !important;
}

.submitDDBtn .dx-dropdownbutton-toggle {
    max-width: 40px;
    background-color: #1ea1b2 !important;
    min-width: 38px !important;
}

.submitDDBtn * {
    border-radius: 0 !important;
}

.rebateStatusTab {
    margin-bottom: 15px;
}

.gen2Pass {
    padding: 10px;
    height: 44px !important;
}

.userProfile h3 span,
.userProfile h4 span {
    font-family: 'proxima_nova';
    font-size: 12px;
    color: #767676;
}

.cssPop {
    top: 22px;
    right: 0;
    position: absolute;
}

.popContent .cvvField .dx-invalid.dx-texteditor.dx-editor-outlined.dx-show-invalid-badge .dx-texteditor-input-container::before {
    width: 45px;
    height: 44px;
    background: #fff;
    content: "";
    display: block;
    z-index: 11;
    position: absolute;
    right: 0;
}

.cvvField.usaEpay .dx-invalid.dx-texteditor.dx-editor-outlined.dx-show-invalid-badge .dx-texteditor-input-container::after {
    right: 48px;
}

.totalRowCal.quoteGrid {
    width: calc(100% - 150px);
}

.mrgR25 {
    margin-right: 25px !important;
}

.mrgR35 {
    margin-right: 35px !important;
}

.filterBtn {
    width: 110px;
    display: inline-block;
    margin-left: 10px;
}

.dynamicInput {
    width: calc(100% - 120px);
    display: inline-block;
    vertical-align: top;
}

.dynamicInputColor {
    width: 50%;
    margin-right: 20px;
    display: inline-block;
    vertical-align: top;
}

.colorDD {
    width: calc(50% - 140px);
    display: inline-block;
    vertical-align: top;
}

.selectContract {
    width: 430px;
    position: relative;
    right: 0px;
    z-index: 111;
    float: right;
    top: 8px;
}

.chartAreaBox .contractDetail h2.chartTitle {
    font-size: 28px;
    text-align: left;
    line-height: 36px;
    padding-top: 8px;
}

.contractAddDtl {
    display: block;
    font-size: 20px;
    color: #222;
    margin-top: 8px;
}

.contractAddDtl label {
    font-size: 18px;
    color: #767676;
}

.rebateChart {
    margin-bottom: 25px;
    position: relative;
}

.contractAddDtl>span:first-child label {
    padding-left: 0;
}

.contractAddDtl span label {
    padding-left: 15px;
}

.userProfileEdit  .editIcon {
    right: -7px;
}

/* DocuSign - Systm Config Changes */
.revHighlight label {
    color: #969696;
    font-weight: 500 !important;
    font-size: 15px;
}

span.revValue {
    color: #222222;
    font-weight: bold;
    font-size: 16px;
}

span.divPipe {
    padding: 0px 10px;
    font-size: 22px;
    vertical-align: middle;
    color: #969696;
}

.colorIndicator {
    background: #666666;
    width: 15px;
    height: 15px;
    vertical-align: top;
    margin: 1px 5px;
    line-height: 0;
    display: inline-block;
}

.chartAverage {
    color: #666;
    font-size: 15px;
    line-height: 16px;
    position: absolute;
    right: 0;
    bottom: -25px;
}

.chartAverage .value {
    font-weight: bold;
}

.remarkBx .dx-texteditor.dx-editor-outlined {
    border: 0;
}

.documentStatusColor {
    min-height: 36px;
    vertical-align: middle;
    line-height: 38px;
}

.countryFlags .selectTip::after {
    right: -5px;
    top: 4px;
}

.vendoreTable .dx-datagrid-content .dx-datagrid-table .dx-row > td {
    vertical-align: middle !important;
}

.appConfigformRow .feildCol.radioGroup {
    margin: 0;
}

.menuTip .makePayIcon {
    margin: 7px 5px 0px 6px;
}

.appSettingTabs .tileBoxDetail .dayBox {
    margin-bottom: 20px;
}

.appSettingTabs .tileBoxDetail .dayBox > label {
    margin-top: 10px;
}

.formDxBtn {
    box-shadow: none !important;
}

.formDxBtn input {
    display: none;
}

.remarkBx textarea {
    padding: 13px 10px;
}

/* Custom Loader Class */
.posRelative {
    position: relative;
}

.custLoader {
    width: 222px;
    height: 90px;
    z-index: 1502;
    margin: 0px;
    left: calc(50% - 111px);
    top: calc(50% - 45px);
    transition: all 0s ease 0s;
    padding: 10px;
    text-align: center;
    user-select: none;
    border: 1px solid #ddd;
    background: #fff;
    border-radius: 6px;
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .25);
    box-shadow: 0 6px 12px rgba(0, 0, 0, .25);
    position: absolute;
}

.custLoader .loaderGif {
    position: relative;
    width: 100%;
    height: 100%;
}

.custLoader .dx-loadindicator-icon {
    direction: ltr;
    position: absolute;
    top: 10%;
    left: calc(50% - 16px);
    width: 32px;
    height: 32px;
}

.custLoader .loaderText {
    position: relative;
    top: -23px;
}

.loaderOverlay {
    background-color: transparent;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
}

.loaderOverlay.blackOverlay {
    background-color: rgba(0, 0, 0, 0.4);
}

/* New LOGIN Screen CSS */
.loginScreenno .loginScreenslogo {
    width: 150px;
}

.loginScreenno .loginScreenemail {
    margin-top: 15px;
    margin-bottom: 12px;
    background: #f0f0f7;
    padding: 8px 10px;
    line-height: 14px;
    font-size: 17px;
    font-weight: 400;
    color: #6e6aaf;
}

.loginScreenno .authyCode .regTitles h3 {
    font-size: 20px;
    line-height: 18px;
}

.loginScreenno .authyCode .regTitles h4 {
    font-size: 16px;
    line-height: 20px;
}

.loginScreenno .regTitles {
    position: relative;
}

.loginScreenno .loginForm .payBtn {
    margin-top: 2px;
}

.loginScreenno .authyAppIcon {
    float: left;
}

.loginScreenno .methodIcons a {
    line-height: 50px;
    left: 0;
}

.loginScreenno .loginForm {
    padding-top: 10px;
}

.loginBack {
    width: 20px;
    height: 16px;
    background: url('https://cdn.incentx.com/assets/images/svgs/login-back-arrow.svg') no-repeat;
    display: block;
    position: absolute;
    left: 40px;
    top: 45px;
    cursor: pointer;
    font-family: 'proxima_nova';
}

.loginScreenno .anotherMethod {
    margin-bottom: 5px;
}

.loginScreenno input[type='text']::placeholder {
    letter-spacing: 0px;
    font-size: 16px;
    line-height: 18px;
    text-align: left;
    font-family: 'proxima_nova';
}

.loginForm .dx-show-clear-button .dx-clear-button-area {
    background: #fff;
}

.verificationCode .dx-texteditor-input-container:after {
    display: none;
}

.verificationCode .dx-texteditor-input {
    letter-spacing: 10px;
}

.verificationCode .dx-invalid-message-content {
    letter-spacing: 0px;
}

.verificationCode .dx-invalid-message-content {
    display: none !important;
}

.loginForm .dx-placeholder {
    padding-left: 34px !important;
    width: 100%;
    border: 0 !important;
    font-family: 'proxima_nova semiBold';
    font-size: 15px;
    opacity: 0.8;
}

.loginScreenno .methodIcons {
    float: none;
    margin: 0 auto;
}

.verificationCode input[type="text"] {
    font-weight: 800;
    font-size: 20px;
    text-align: center;
    padding-left: 0 !important;
    padding-right: 0 !important;
    outline: 0 !important;
    font-family: 'proxima_nova Bold' !important;
    color: #4a5861;
    letter-spacing: 30px;
    box-shadow: unset;
}

.verificationCode.dx-invalid.dx-texteditor {
    border: 1px solid #c8c8c8 !important;
}

.loginForm .payBtn input[type="submit"] {
    display: none;
}

.verificationCodeInput input {
    letter-spacing: 30px;
    text-align: left;
    font-weight: 600;
    font-size: 20px;
    padding: 9px 15px !important;
}

.verificationCodeInput input::placeholder {
    letter-spacing: 0px;
}

/*** Add new css for new profile page ***/
.border-box .user-details {
    width: 20%;
    float: left;
    padding-right: 27px;
    padding-left: 15px;
}

.border-box .user-details .userImage {
    width: 120px;
    height: 120px;
    text-align: center;
    background-color: #21B3C6 !important;
    margin: auto;
    display: block;
    margin-top: 25px;
}

.border-box .user-details .userImage span {
    font-size: 55px;
    font-style: normal;
    font-weight: 400;
    line-height: 61px;
    letter-spacing: 0em;
    text-align: center;
    padding-top: 30px;
}

.border-box .user-details h2 {
    font-size: 23px;
    font-style: normal;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: -0.01em;
    text-align: center;
    display: block;
    padding-top: 18px;
    margin-bottom: 10px;
    word-break: break-word;
}

.border-box .user-details h3 {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    display: block;
    margin-bottom: 15px;
    word-break: break-word;
}

.border-box .user-details h4 {
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    display: block;
    margin-bottom: 17px;
    word-break: break-all;
}

.border-box .user-details h5 {
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    display: block;
    margin-bottom: 25px;
}

.border-box {
    padding: 0;
}

.border-box .user-detailsright {
    width: 80%;
    float: left;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 25px;
    border-left: 1px solid #DADADA;
    min-height: 315px;
}

.border-box .user-detailsright .user-content .cols6 {
    padding-left: 0;
}

.containerbox {
    position: relative;
    padding-left: 30px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #565656;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0em;
    text-align: left;
    padding-top: 5px;
    padding-right: 5px;
    margin-top: 18px;
}

/*check box start*/
.containerbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.containerbox .checkmark {
    position: absolute;
    top: 4px;
    left: 0;
    height: 23px;
    width: 23px;
    background-color: #fff;
    border: 1px solid #e5e5e5;
    border-radius: 3px;
}

.containerbox:hover input~.checkmark {
    background-color: #f2f2f2;
}

.containerbox input:checked~.checkmark {
    background-color: #fff;
    border-radius: 3px;
    border: 1px solid #706CCE;
}

.containerbox .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.containerbox input:checked~.checkmark:after {
    display: block;
}

.containerbox .checkmark:after {
    left: 7px;
    top: 3px;
    width: 7px;
    height: 13px;
    border: solid #706CCE;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.containerradio {
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #222222;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    padding-top: 4px;
    padding-right: 15px;
}

.containerradio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.checkmarkr {
    position: absolute;
    top: 0px;
    left: 0;
    height: 22px;
    width: 22px;
    background-color: #fff;
    border: 1px solid #e5e5e5;
    border-radius: 50%;
}

.containerradio:hover input~.checkmarkr {
    background-color: #f2f2f2;
}

.containerradio input:checked~.checkmarkr {
    background-color: #fff;
    border: 1px solid #706CCE;
}

.checkmarkr:after {
    content: "";
    position: absolute;
    display: none;
}

.containerradio input:checked~.checkmarkr:after {
    display: block;
}

.containerradio .checkmarkr:after {
    top: 6px;
    left: 6px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #706CCE;
}

/*radio end*/
.border-box .radiotitle {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    color: #767676;
    padding-right: 5px;
    text-align: left;
}

.user-content .emailverification {
    position: relative;
    top: -5px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    display: block;
}

.user-content .modescription img {
    width: 15px;
    position: relative;
    top: -2px;
}

.user-content .modescription {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.01em;
    text-align: left;
    color: #767676;
    position: relative;
    top: -5px;
}

.tab-sections .notifications-check .containerbox {
    margin-top: 0;
}

.tab-sections .notifications-check h6 {
    color: #767676;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 10px;
    margin-top: 18px;
}

.tab-sections .notifications-time {
    padding-top: 25px;
}

.tab-sections .notifications-time .modescription {
    padding-left: 15px;
    padding-top: 10px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.01em;
    text-align: left;
    color: #767676;
    float: left;
}

.tab-sections .notifications-time .feildCol {
    min-height: unset;
    margin-top: 10px;
}

.tab-sections .notifications-time .cols6 {
    padding: 0 8px;
}

.tab-sections .appConfigBtn {
    padding-right: 0px;
    margin-top: 0px;
    position: relative;
    top: -25px;
}

.tab-sections .notificationRadio .mainLabel {
    font-size: 16px;
    color: #222222;
}

.top-title {
    width: 100%;
    float: left;
}

.user-detailsright .user-content .feildCol {
    min-height: 57px;
    margin-top: 10px;
}

.user-detailsright .user-content .countryFlags .selectTip {
    min-width: 25px;
    position: relative;
    top: -8px;
}

.borderBox.border-box {
    padding: 0;
}

.borderBox.border-box .user-content .user-content-checkbox {
    font-size: 14px;
    color: #222222;
    padding-right: 10px;
}

.user-content-radio {
    margin-top: -14px;
}

.user-content-radio .dx-item-content {
    font-size: 14px !important;
}

.borderBox.border-box .user-content-mobile {
    height: 67px;
}

.padT40 {
    padding-top: 40px !important;
}

.padB30 {
    padding-bottom: 30px !important;
}

.tabBox {
    padding: 30px 25px 10px 25px;
    float: left;
    width: 100%;
    border-top: 1px solid #DADADA;
}

.paidIcon {
    background: url(https://cdn.incentx.com/assets/images/svgs/paid.svg);
    width: 28px;
    height: 30px;
    display: block;
    background-repeat: no-repeat;
    background-position-y: 4px;
}

.crmCustomerDetail .contDtl.selectedContact,
.crmCustomerDetail .contDtl.selectedContact.hoverClr:hover {
    min-height: 50px;
}

.mapaverage {
    position: relative;
}

.mapaverage .chartAverage {
    right: 12px;
    top: 5px;
}

.chartArea.contractDetail .cols4.rebateChart .selectContract {
    width: 100% !important;
    top: 9px !important;
}

.chartArea.contractDetail .mapaverage .chartAveragerefresh {
    float: right;
    position: absolute;
    right: 200px;
    top: -5px;
}

/*DocuSign Steps Feature CSS*/
.externalsysection .externalstepinput .docuSignID {
    width: 43%;
    float: left;
    padding-right: 30px;
}

.externalsysection .externalstepinput .accountBase {
    width: 43%;
    float: left;
    padding-right: 30px;
}

.externalsysection .externalstepinput .dx-field .dxSaveBtn {
    height: 45px;
    width: 100%;
}

.externalsysection .externalsycon .externalstepinput .txNote2 {
    padding-left: 0;
}

.externalsysection .externalsycon .externalstepbtnstep2 {
    width: max-content;
    border-right: 1px solid #ddd;
    float: left;
    padding-right: 25px;
}

.externalsysection .externalsycon .externalstepbtnstep2 .secondaryBtn .dx-button-content {
    border: none;
    padding-left: 0px !important;
}

.externalsysection .externalsycon .externalstepbtnstep2 .secondaryBtn:hover {
    background: unset !important;
}

.externalsysection .externalsycon .externalstepbtnstep2 .secondaryBtn .dx-button-content .dx-button-text {
    border-bottom: 1px solid #21b3c6;
    padding-bottom: 5px;
}

.externalsysection .externalsycon .externalstepinput2 {
    float: left;
    margin-left: 20px;
}

.externalsysection .externalsycon .externalstepinput2 .docuSigCopynUrl #docuSignUrlRef {
    border: none;
}

.externalsysection .externalsycon .connectionlable p {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #222222;
    padding-top: 11px;
    float: left;
    padding-left: 15px;
}

.externalsysection .externalsycon.noBdr .externalstepbtn {
    width: unset;
    margin-right: 0;
}

.externalsysection .externalstepbtn .primaryBtn {
    width: 100%;
}

.externalsysection .externalstepinput .informationimg {
    position: absolute;
    z-index: 9;
    right: 45px;
    top: 13px;
}

.externalsysection .externalsycon .externalstepinput2 .docuSigCopynUrl #docuSignUrlRef input {
    font-size: 16px;
    font-weight: 400;
    padding-top: 10px;
}

.externalsysection .externalsycon .externalstepinput2 .docuSigCopynUrl {
    margin-top: 10px;
}

.externalsysection .externalsycon .externalstepbtnstep2 .secondaryBtn {
    padding-left: 0;
}

.externalsysection .externalstepinput .step1savebtn {
    width: 14%;
}

.externalsysection .externalstepinput .docuSignID input,
.externalsysection .externalstepinput .accountBase input {
    padding-right: 35px;
}

.externalsysection .externalsycon .externalstepinput2 .docuSigCopynUrl dx-text-box {
    border: none !important;
}

.externalsysection .externalstepinput .docuSignID .dx-show-clear-button .dx-clear-button-area,
.externalsysection .externalstepinput .accountBase .dx-show-clear-button .dx-clear-button-area {
    right: 32px;
}

.externalsysection .externalsycon .connectionlable p img {
    width: 16px;
    margin-right: 7px;
}

.externalsysection .externalsycon.noBdr {
    border-top: 1px solid #DDDDDD !important;
}

.externalsycon .txNote2.step3Lbl {
    padding-left: 6%;
    padding-top: 5px;
    margin-bottom: -5px;
}

.connectionverifyIcon {
    background-image: url(https://cdn.incentx.com/assets/images/Group+40.png);
    width: 17px;
    height: 17px;
    display: inline-block;
    background-repeat: no-repeat;
    object-fit: contain;
    background-position-y: 2px;
    position: relative;
    top: 3px;
}

.externalsysection .externalsycon .externalstepinput2 .docuSigCopynUrl .copyinputtxt {
    float: left;
    width: max-content;
    margin-right: 85px;
    font-size: 16px;
    font-weight: 400;
    margin-top: 11px;
    max-width: 800px;
    padding-left: 14px;
    min-width: 175px;
}

.externalsysection .externalsycon {
    float: left;
    width: 100%;
    padding-top: 25px;
}

.externalsysection .externalstepinput {
    width: 69%;
    float: left;
    margin-left: 0;
}

.docuSigCopynUrl .dxCopyBtn {
    right: 1px;
    padding-left: 16px;
    padding-right: 16px;
}

.externalsysection .externalstepbtn {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    width: 23%;
    float: left;
    margin-right: 2%;
}

.externalsysection .externalstep {
    width: 6%;
    float: left;
}

.primaryBtn,
.primaryBtn:hover {
    border-radius: 0px !important;
    border: none !important;
}

.primaryBtn:hover,
.primaryBtn:hover .dx-button-content,
.primaryBtn.dx-state-hover:hover {
    color: #fff;
    background: #1EA1B2 !important;
    border-radius: 0px !important;
}

.primaryBtn input {
    display: none;
}

.primaryBtn .dx-button-content {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 12px 11px !important;
    background: #21b3c6;
    border: 1px solid #21b3c6;
    color: #fff;
    font-size: 16px;
}

.secondaryBtn,
.secondaryBtn:hover {
    border-radius: 0px !important;
    border: none !important;
}

.secondaryBtn:hover,
.secondaryBtn:hover .dx-button-content,
.secondaryBtn.dx-state-hover:hover {
    color: #21b3c6;
    background: #E8F7F9 !important;
    border-radius: 0px !important;
}

.secondaryBtn input {
    display: none;
}

.secondaryBtn .dx-button-content {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 12px 11px !important;
    background: #fff;
    border: 1px solid #21b3c6;
    color: #21b3c6;
    font-size: 16px;
}

.externalsysection .externalstep p img {
    padding-bottom: 3px;
    padding-left: 12px;
}

.externalsysection .externalstep .externalsteptxt {
    padding-top: 12px;
    color: #767676;
}

.externalsysection .externalstep p {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #21B3C6;
    padding-top: 7px;
    margin-bottom: 19px;
}

.externalsysection .modescriptionb {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #222222;
    padding-top: 7px;
    margin-bottom: 18px;
}

.externalsysection .modescription {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #767676;
    padding-left: 0px;
    padding-top: 13px;
    margin-bottom: 18px;
}

.externalsysection .externalstepbtn .contIcon {
    margin-right: 0px;
}

.docuSignID {
    position: relative;
}

.docuSignID .dxResetBtn,
.docuSignID .dxSaveBtn,
.docuSignID .dxEditBtn,
.docuSigCopynUrl .dxCopyBtn {
    position: absolute;
    top: 1px;
    right: 1px;
}

.docuSignID .dxResetBtn .dx-icon-refresh::before {
    background: url(https://cdn.incentx.com/assets/images/svgs/revert-red.svg) no-repeat !important;
    width: 17px !important;
    height: 18px !important;
    content: "" !important;
    display: block;
    margin: 0px auto;
}

.docuSignID .dxSaveBtn .dx-icon-save::before {
    background: url(https://cdn.incentx.com/assets/images/svgs/save-blue.svg) no-repeat !important;
    width: 18px !important;
    height: 18px !important;
    content: "" !important;
    display: block;
    float: left;
    margin: 0px auto;
}

.docuSigCopynUrl .dxCopyBtn .dx-icon-copy::before {
    background: url(https://cdn.incentx.com/assets/images/svgs/copy_svg.svg) no-repeat !important;
    width: 18px !important;
    height: 18px !important;
    content: "" !important;
    display: block;
    float: left;
    margin: 0px auto;
}

.docuSignID .dxSaveBtn {
    right: 39px;
    border-left: 1px solid #e5e5e5 !important;
}

.docuSignID .dxEditBtn {
    right: 1px;
    border-left: 1px solid #e5e5e5 !important;
}

.dxResetBtn.secondaryBtn .dx-button-content,
.dxSaveBtn.secondaryBtn .dx-button-content,
.dxEditBtn.secondaryBtn .dx-button-content,
.dxCopyBtn.secondaryBtn .dx-button-content {
    border: unset;
}

.externalsysection .externalstep.active p {
    color: #21B3C6 !important;
}

.grayedBlock .inputField label {
    background: rgba(240 240 250);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(240 240 250)), color-stop(50%, rgba(240 240 250)), color-stop(50%, rgba(255, 255, 255, 1)), color-stop(100%, rgba(255, 255, 255, 1)));
    background: linear-gradient(to bottom, rgba(240 240 250) 0%, rgba(240 240 250) 50%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f3f3f3', endColorstr='#ffffff', GradientType=0);
}

.morecontent span {
    display: none;
}

.morecontent a {
    color: #706cce;
    text-decoration: underline;
    display: inline-block;
}

.morelink {
    display: block;
}

.ng-untouched.ng-pristine .checkBox {
    margin-top: 7px;
}

.productSearch.ng-star-inserted .srTitle label {
    margin-bottom: 0;
}

.formControls.editAgreeDetail .cols2.datePicker .dx-texteditor-input-container {
    height: 40px;
}

.formControls.editAgreeDetail .cols12 .dx-field.mrgT10 {
    margin-bottom: 10px;
}

.borderBox.cf .row.proSearch .cols1 .button.center {
    width: 100%;
}

.numberBlock {
    position: relative;
}

.numberBlock .settingIcon img {
    width: 18px;
    position: absolute;
    right: 13px;
    top: 13px;
}

.phoneNumberField input[type='text'] {
    padding-left: 60px;
}

.hovercolor.dx-state-hover {
    background: #21b3c6 !important;
}

.inputnone .dx-button-content input.dx-button-submit-input {
    display: none;
}

.addEditTask .popContent .button {
    box-shadow: none;
}

.addEditTask .popContent .button:hover {
    background-color: #169bb2 !important;
}

.addEditTask .popContent .dx-radiogroup.dx-radiogroup-horizontal {
    margin-top: 15px;
}

.addEditTask .taskstatus {
    left: -4px;
}

.buttonblue {
    color: #fff;
    background-color: #169bb2;
    border: none;
}

/* ----- NEW BREADCRUMB CSS ---------- */
.ix-dynamic-breadcrumb {
    float: left;
}

.ix-dynamic-breadcrumb .custom-bread-crumb {
    margin-bottom: 0;
    padding: 0 0 13px 0 !important;
}

.ix-dynamic-breadcrumb .custom-bread-crumb span li a {
    color: #6e6aaf;
    text-decoration: none;
    padding: 2px;
    display: inline;
    font-family: 'proxima_nova semiBold';
}

#crmCustomerDetail {
    float: left;
    width: 100%;
}

#reportslist .titleBtnCol {
    margin-bottom: 10px;
}

#crmList .tabbing,
#saleslist .tabbing,
#usermanagementlist .tabbing,
#businessplanlist .tabbing {
    margin-top: 0;
}

#crmList .tabbing ul li,
#saleslist .tabbing ul li,
#usermanagementlist .tabbing ul li,
#businessplanlist .tabbing ul li {
    margin-top: 0;
}

#rebatelist .titleRow .titleBtnCol,
#royaltylists .titleRow .titleBtnCol,
#billbackchargebacklist .titleRow .titleBtnCol {
    margin-bottom: 10px;
}

#rebatecontract .rightBlock,
#commissioncontract .rightBlock,
#royaltycontract .rightBlock {
    float: left;
    width: 100%;
}

.formControls.editAgreeDetail .datePicker {
    height: 45px;
}

#adminportalCommissionlist .titleRow .titleBtnCol {
    margin-bottom: 10px;
}

/* ---- UDF Integration -----*/
.spanTitle {
    font-size: 14px;
    font-weight: normal;
}

.dialogResync {
    width: 500px;
}

.dialogsync {
    width: 606px;
}

.udfloadPanel {
    left: calc(50%);
    bottom: calc(50% - 250px);
    position: absolute;
}

.popCnt.popConfirmBox {
    min-width: 435px;
}

.accodiansubtitle {
    padding: 17px 0 5px 0;
    display: block;
    color: #999;
}

/* Button Style CSS */
.primaryBtn,
.secondaryBtn,
.tertiaryBtn {
    box-shadow: none !important;
}

/* tertiary Button */
.cols12addbtn {
    width: calc(100% - 25px);
}

.cols11addbtn {
    width: calc(91.66666667% - 25px);
}

.cols7addbtn {
    width: calc(58.33333333% - 25px);
}

.cols6addbtn {
    width: calc(50% - 25px);
}

.cols5addbtn {
    width: calc(41.66666667% - 25px);
}

.cols3addbtn {
    width: calc(25% - 25px);
}

.cols2addbtn {
    width: calc(16.66666667% - 25px);
}

.cols1addbtn {
    width: calc(8.33% + 25px);
}

.supportAddusr {
    position: absolute;
    right: 0;
    top: -10px;
}

.addusrbtn {
    position: absolute;
    right: 0;
    top: -70px;
}

.addpaymntbtn {
    position: absolute;
    right: 0;
    top: -70px;
}

.apcustId {
    font-size: 16px;
    color: #21b3c6;
}

.cols11searchbtn {
    width: calc(91.67% - 45px);
}

.cols1searchbtn {
    width: calc(8.33% + 45px);
}

.sliderRangeNg .dx-slider-range.dx-slider-range-visible {
    border: 1px solid #21b3c6;
    background: #21b3c6;
}

.sliderRangeNg .dx-slider-handle {
    background-color: #21b3c6;
}

.sliderRangeNg .dx-slider .dx-state-focused.dx-slider-handle:not(.dx-state-active),
.sliderRangeNg .dx-slider .dx-state-hover.dx-slider-handle:not(.dx-state-active) {
    background: #21b3c6;
}

.sliderRangeNg .dx-slider .dx-state-active.dx-slider-handle {
    background: #21b3c6;
}

.cntlist {
    padding: 10px 25px 0 25px;
}

a.secondaryBtn:hover {
    color: #21b3c6;
    background: #E8F7F9 !important;
    border-radius: 0px !important;
    border: 1px solid #21b3c6 !important;
}

a.secondaryBtn {
    overflow: hidden;
    border-radius: 0px !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 10px 12px !important;
    background: #fff;
    border: 1px solid #21b3c6 !important;
    color: #21b3c6;
    font-size: 16px;
    box-shadow: unset;    /* font-family: Proxima Nova; */
    height: 41px;
    font-style: normal;
    font-weight: 400;
    display: inline-block;
}

a.secondaryBtn.grayButton {
    background: #fff !important;
    color: #767676;
    border: 1px solid #767676;
    cursor: none !important;
    box-shadow: unset !important;
    font-weight: 400;
    height: 41px;
}

a.secondaryBtn.grayButton:hover {
    background: #fff;
    cursor: none !important;
}

a.primaryBtn:hover {
    color: #fff;
    background: #1EA1B2 !important;
    border-radius: 0px !important;
}

a.primaryBtn {
    overflow: hidden;
    border-radius: 0px !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 10px 12px !important;
    background: #21b3c6;
    border: 1px solid #21b3c6;
    color: #fff;
    font-size: 16px;
    box-shadow: unset;    /* font-family: Proxima Nova; */
    height: 41px;
    font-style: normal;
    font-weight: 400;
}

a.primaryBtn.grayButton {
    color: #767676 !important;
    background: #ffffff;
    cursor: none !important;
    border: 1px solid #767676;
    box-shadow: unset !important;
    font-weight: 400;
    height: 41px;
}

a.primaryBtn.grayButton:hover {
    background: #fff;
    cursor: none !important;
}

a.actionBtn {
    display: inline-block;
    padding: 8px 12px !important;
}

.customer360icon img {
    float: right;
    width: 20px;
    position: relative;
    top: -2px;
}

#usermanagementlist .titleBtnCol .button {
    float: left;
}

#crmCustomerDetail .chartTitle~.ddwithTitle {
    max-width: 100%;
    text-align: right;
}

.data-calendar-gray,
.data-calendar-blue {
    height: 35px;
}

.data-calendar-gray.dx-state-focused {
    width: 36px;
}

.customer-360-tile-setting .srTitle label {
    color: #767676;
    font-size: 16px;
    margin-bottom: 5px;
}

.blockDate {
    height: 15px;
}

#dateRangeSetting label {
    color: #767676;
    font-size: 16px;
    margin-bottom: 0;
}

#dateRangeSetting .dx-radiobutton {
    margin-bottom: 10px;
    margin-top: 10px;
}

#dateRangeSetting .radioGroup {
    margin-bottom: 0;
}

.icon360crm {
    position: relative;
    top: -3px;
}

.chartSorts {
    width: max-content;
    float: right;
    margin-right: 25px;
    margin-top: 10px;
}

.show-data-from {
    position: relative;
    top: -2px;
    color: #222;
}

#dateRangeSetting .dx-radiobutton .dx-item-content {
    font-size: 16px;
    color: #222;
}

.date-range-setting-popup-title {
    font-size: 16px;
    color: #767676;
}

dx-button#dateRangeGlobalSetingPopover {
    border-width: 0px !important;
}

.dateiconset .dx-datebox-date.dx-texteditor-empty .dx-button-normal {
    right: 2px !important;
}

.borderoutline {
    border: 1px solid #e5e5e5;
    padding: 10px;
    width: 450px;
    margin-left: 0px;
    position: relative;
}

.borderoutline .borderoutline-label {
    color: #999;
    position: absolute;
    top: -9px;
    background-color: #fff;
}

.docuSigCopynUrl .dxCopyBtn {
    padding: 0;
    margin-right: 15px !important;
}

#endUserDetails .crmTable {
    min-height: 380px;
    height: 860px;
}

/* --- FONT ICON NEW CSS START  */
.navallIcon {
    width: 54px;
    height: 44px;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    line-height: 42px;
}

.navallIcon:before {
    color: #767676;
}

ul.navigation li:hover .navallIcon:before,
ul.navigation li:hover .icon-toggleDown:before,
ul.navigation li.activate .navallIcon:before {
    color: #706cce;
}

.icon-icon-1:before {
    content: "\e948";
    font-size: 23px;
}

.icon-icon-2:before {
    content: "\e949";
    font-size: 21px;
}

.icon-icon-3:before {
    content: "\e94a";
    font-size: 28px;
}

.icon-icon-4:before {
    content: "\e94b";
    font-size: 28px;
}

.icon-icon-5:before {
    content: "\e94c";
    font-size: 27px;
}

.icon-icon-6:before {
    content: "\e94d";
    font-size: 25px;
}

.icon-icon-7:before {
    content: "\e94e";
    font-size: 23px;
}

.icon-icon-8:before {
    content: "\e94f";
    font-size: 25px;
}

.icon-icon-9:before {
    content: "\e950";
    font-size: 23px;
}

.icon-verification:before {
    content: "\e9a4";
    color: #f99300;
    font-size: 14px;
    line-height: 24px;
}

.verificationcontIcon {
    width: 24px;
    height: 24px;
    display: inline-block;
    vertical-align: middle;
}

.icon-email_id:before {
    content: "\e936";
}

.userDtlToggle .usrContact .contIcon {
    text-align: center;
    line-height: 21px !important;
}

.icon-companyinfo-icon-new:before {
    content: "\e91c";
    font-size: 19px;
}

.icon-current-plan-new:before {
    content: "\e922";
    font-size: 18px;
}

.icon-billing-history-new:before {
    content: "\e90d";
    font-size: 15px;
}

.icon-notification-icon-new:before {
    content: "\e96b";
    font-size: 22px;
}

.headerIcon:hover {
    color: #706cce;
}

.icon-logout-new:before {
    font-size: 20px;
    content: "\e95e";
}

.icon-leads_1:before {
    content: "\e959";
    color: #28b4c7;
    font-size: 33px;
}

.icon-customer:before {
    content: "\e923";
    color: #683fd0;
    font-size: 31px;
}

.icon-prospects:before {
    content: "\e97b";
    font-size: 31px;
    color: #bc6cd8;
}

.icon-tasks_1:before {
    content: "\e993";
    font-size: 28px;
    color: #683fd0;
}

.icon-quotes_1:before {
    content: "\e97d";
    font-size: 31px;
    color: #2a8bba;
}

.icon-icondx-6:before {
    content: "\e94d";
    color: #61d6b9;
    font-size: 30px;
    line-height: 30px;
}

.icon-bill_pay:before {
    content: "\e90c";
    color: #d34a7d;
    font-size: 29px;
}

.icon-commission:before {
    content: "\e91a";
    font-size: 35px;
    color: #ec4455;
}

.icon-menu_se:before {
    content: "\e967";
    font-size: 18px;
    color: #706cce;
    line-height: 35px;
}

.icon-default-allcompany:before {
    content: "\e927";
    color: #fff;
    font-size: 24px;
    background-color: lightgrey;
    padding: 20px 10px;
    text-align: center;
    line-height: 38px;
}

.icon-plusbtn:before {
    content: "\e975";
    color: #ffffff;
    font-size: 19px;
    position: relative;
    top: 2px;
    left: -3px;
}

.icon-calldxtable:before {
    content: "\e90f";
    color: #706cce;
    font-size: 19px;
    position: relative;
    top: 5px;
}

.icon-glob:before {
    content: "\e942";
    color: #706cce;
    font-size: 16px;
    position: relative;
    top: 2px;
    margin-right: 4px;
}

.icon-edit:before {
    content: "\e932";
    color: #706cce;
    font-size: 16px;
}

.customerDetail.custdtlTop .icon-edit {
    position: absolute;
    right: 0;
    top: 0;
    margin-right: 0;
}

.defaultclr::before {
    color: #21b3c6;
}

.icon-editdxtable:before {
    content: "\e932";
    color: #706cce;
    font-size: 16px;
    line-height: 20px;
}

.icon-deletedxtable:before {
    content: "\e929";
    color: #ff0100;
    font-size: 16px;
    line-height: 20px;
}

.icon-tooltipdxtable:before {
    content: "\e999";
    color: #3fcfb6;
    font-size: 16px;
}

.icon-rebates1:before {
    content: "\e97f";
    font-size: 23px;
    color: #ffffff;
    line-height: 43px;
    text-align: center;
    position: relative;
    right: 1px;
}

.icon-margin:before {
    content: "\e961";
    color: #ffffff;
    font-size: 27px;
    line-height: 46px;
}

.icon-profitability:before {
    content: "\e97a";
    color: #ffffff;
    font-size: 25px;
    line-height: 44px;
}

.icon-revenue:before {
    content: "\e982";
    color: #ffffff;
    font-size: 23px;
    line-height: 43px;
}

.icon-variety:before {
    content: "\e9a3";
    color: #ffffff;
    font-size: 29px;
    line-height: 45px;
    position: relative;
    right: 1px;
}

.icon-volume:before {
    content: "\e9a6";
    color: #ffffff;
    font-size: 28px;
    line-height: 42px;
}

.icon-orders_completed:before {
    content: "\e96d";
    color: #ffffff;
    font-size: 23px;
    line-height: 43px;
}

.icon-chargebacks1:before {
    content: "\e916";
    color: #ffffff;
    font-size: 26px;
    line-height: 46px;
    position: relative;
    right: 1px;
}

.icon-copydxtable:before {
    content: "\e91e";
    color: #736fcf;
    font-size: 17px;
    line-height: 22px;
}

.icon-addtask-dp:before {
    content: "\e902";
    color: #706cce;
    font-size: 17px;
    position: relative;
    top: 2px;
}

.icon-Userlogin:before {
    content: "\e936";
    color: #706cce;
    font-size: 19px;
    top: 14px;
    position: relative;
    left: 14px;
}

.icon-passwordlogin:before {
    content: "\e96f";
    color: #706cce;
    font-size: 23px;
    top: 13px;
    position: relative;
    left: 16px;
}

.icon-logos_authy:before {
    content: "\e95d";
    color: #ec1c24;
    font-size: 24px;
    position: relative;
    left: 1px;
    top: -3px;
}

.icon-actionsblue:before {
    content: "\e900";
    color: #21b3c6;
    font-size: 5px;
    position: relative;
    top: -3px;
}

.icon-setting:before {
    content: "\e98a";
}

.icon-setting-purple:before {
    content: "\e9b0";
    color: #706cce !important;
}

.icon-sms:before {
    content: "\e98e";
    color: #0077b5;
    border: none;
    text-decoration: unset;
    font-size: 27px;
    top: 6px;
    position: relative;
    right: 5px;
}

.loginScreenno .methodIcons {
    text-decoration: none;
}

span.settingIcon {
    width: 18px;
    position: absolute;
    right: 13px;
    top: 13px;
}

span.customer360icon {
    float: right;
    width: 20px;
    position: relative;
    top: -1px;
}

span.customer360icon:before {
    font-size: 19px;
}

a.downAppy span.underLine {
    position: relative;
    top: -9px;
}

.icon-call-1:before {
    content: "\e90f";
    color: #706cce;
    font-size: 17px;
}

.icon-map_pin:before {
    content: "\e960";
    color: #706cce;
    font-size: 17px;
}

.icon-appointment_date:before {
    content: "\e90a";
    color: #706cce;
    font-size: 17px;
}

.icon-done_2:before {
    content: "\e92d";
    color: #05c1a0;
    font-size: 17px;
}

.icon-toolTip:before {
    content: "\e999";
    color: #3fcfb6;
}

.icon-add_task:before {
    content: "\e902";
    color: #706cce;
    font-size: 15px;
}

.icon-mark_as_customer:before {
    content: "\e964";
    color: #706cce;
    font-size: 15px;
}

.icon-mark_as_buying_group:before {
    content: "\e962";
    color: #706cce;
    font-size: 15px;
}

.icon-delete:before {
    content: "\e929";
    color: #ff4342;
    font-size: 15px;
}

.icon-pipeline:before {
    content: "\e974";
    color: #706cce;
    font-size: 15px;
}

.icon-mail:before {
    content: "\e95f";
    color: #706cce;
    font-size: 15px;
}

.icon-call:before {
    content: "\e90f";
    color: #706cce;
    font-size: 15px;
}

.icon-doc-se:before {
    content: "\e92a";
    color: #706cce;
    font-size: 15px;
}

.icon-site_visit_se:before {
    content: "\e98d";
    color: #706cce;
    font-size: 15px;
}

.icon-appointment_se:before {
    content: "\e90a";
    color: #706cce;
    font-size: 15px;
}

.icon-deal_se:before {
    content: "\e926";
    color: #706cce;
    font-size: 15px;
}

.icon-actions:before {
    content: "\e900";
    color: #fff;
    font-size: 15px;
}

.icon-plus:before {
    content: "\e975";
    color: #706cce;
    font-size: 15px;
}

.icon-mark_as_closed:before {
    content: "\e963";
    color: #706cce;
    font-size: 15px;
}

.icon-mark_as_open:before {
    content: "\e965";
    color: #706cce;
    font-size: 15px;
}

.icon-dollar:before {
    content: "\e92b";
    color: #706cce;
    font-size: 15px;
}

.icon-Rebates:before {
    content: "\e97e";
    color: #706cce;
    font-size: 15px;
}

.icon-clipboard_2:before {
    content: "\e918";
    color: #706cce;
    font-size: 15px;
}

.icon-edit_payment:before {
    content: "\e933";
    color: #706cce;
    font-size: 15px;
}

.icon-edit_menu:before {
    content: "\e932";
    color: #706cce;
    font-size: 15px;
}

.icon-prospects_menu:before {
    content: "\e97b";
    font-size: 15px;
    color: #bc6cd8;
}

.icon-close:before {
    content: "\e919";
    color: #ff4545;
    font-size: 15px;
}

.icon-close-popup:before {
    content: "\e919";
    color: #9b9b9b;
    font-size: 15px;
}

.icon-hide:before {
    content: "\e947";
    color: #706cce;
    font-size: 15px;
}

.icon-full-screen-purple:before {
    content: "\e941";
    color: #706cce;
    font-size: 15px;
}

.icon-upload:before {
    content: "\e99d";
    color: #d2d2d2;
}

.csvUpload .icon-upload {
    font-size: 60px;
}

.icon-csv_file:before {
    content: "\e921";
    color: #706cce;
}

.fileImage .icon-csv_file {
    font-size: 35px;
}

.icon-pdf:before {
    content: "\e970";
    color: #f00;
}

.icon-pdf-1 .path1:before {
    content: "\e971";
    color: rgb(250, 52, 72);
}

.icon-pdf-1 .path2:before {
    content: "\e972";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}

.icon-word .path1:before {
    content: "\e9a7";
    color: rgb(21, 101, 192);
}

.icon-word .path2:before {
    content: "\e9a8";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}

.icon-jpeg .path1:before {
    content: "\e957";
    color: rgb(18, 169, 189);
}

.icon-jpeg .path2:before {
    content: "\e958";
    margin-left: -1.06640625em;
    color: rgb(255, 255, 255);
}

.icon-ppt .path1:before {
    content: "\e976";
    color: rgb(245, 142, 19);
}

.icon-ppt .path2:before {
    content: "\e977";
    margin-left: -1.06640625em;
    color: rgb(255, 255, 255);
}

.icon-xls .path1:before {
    content: "\e9aa";
    color: rgb(39, 173, 95);
}

.icon-xls .path2:before {
    content: "\e9ab";
    margin-left: -1.06640625em;
    color: rgb(255, 255, 255);
}

.icon-zip .path1:before {
    content: "\e9ac";
    color: rgb(244, 25, 125);
}

.icon-zip .path2:before {
    content: "\e9ad";
    margin-left: -1.06640625em;
    color: rgb(255, 255, 255);
}

.icon-successfully_signed .path1:before {
    content: "\e98f";
    color: rgb(49, 204, 113);
}

.icon-successfully_signed .path2:before {
    content: "\e990";
    margin-left: -1.06640625em;
    color: rgb(255, 255, 255);
}

.icon-alert_popup .path1:before {
    content: "\e904";
    color: rgb(252, 151, 0);
}

.icon-alert_popup .path2:before {
    content: "\e905";
    margin-left: -1.06640625em;
    color: rgb(255, 255, 255);
}

.icon-download:before {
    content: "\e930";
    color: #706cce;
    font-size: 15px;
}

.icon-setting-link:before {
    content: "\e98a" !important;
    color: #706cce;
    font-size: 17px;
    line-height: 35px !important;
}

.icon-delete-link:before {
    content: "\e929" !important;
    color: #ff4342;
    font-size: 17px;
    line-height: 35px !important;
}

.icon-logout-new:hover {
    color: #ff4342;
}

.icon-view:before {
    content: "\e9a5";
    color: #706cce;
    font-size: 18px;
    line-height: 22px;
}

.feildCol dx-date-box.dx-datebox-calendar .dx-texteditor-input-container::after {
    right: 35px;
}

.appConfigformRow .cols5.twoFA .fieldBtn.gen2Pass a {
    line-height: 22px;
}

.custDetail.custDetailFull .contDtl .contTx.icoCall,
.custDetail.custDetailFull .contDtl .contTx.pinIcon {
    padding-left: 0;
}

.custCompIcon {
    background-color: lightgrey;
    overflow: hidden;
}

.custCompIcon .icon-default-allcompany:before {
    font-size: 40px;
    line-height: 76px;
}

.icon-paid:before {
    content: "\e96e";
    color: #706cce;
}

.navIcon10::before {
    content: "\e951";
}

.navIcon11::before {
    content: "\e952";
}

.navIcon12::before {
    content: "\e981";
}

.navIcon13::before {
    content: "\e991";
}

.icon-icon-10:before {
    content: "\e951";
    font-size: 23px;
}

.icon-icon-11:before {
    content: "\e991";
    font-size: 24px;
}

.icon-icon-12:before {
    content: "\e981";
    font-size: 24px;
}

.icon-icon-13:before {
    content: "\e952";
    font-size: 21px;
}

.icon-icon-15:before {
    content: "\e98c";
    font-size: 25px;
}

span.discountInput .icon-deletedxtable {
    position: absolute;
    right: 3px;
    top: 4px;
    cursor: pointer;
}

span.discountInput .icon-deletedxtable:hover::before {
    color: #565656;
}

.labelSet .inputField {
    top: -5px;
}

.paySuccess .icon-successfully_signed {
    font-size: 85px;
}

.custIcon.center.companylogo {
    color: #fff;
    background-color: lightgrey;
}

.companylogo .icon-default-allcompany:before {
    font-size: 32px;
    line-height: 64px;
}

.vtSeparator {
    font-size: 16px;
    font-weight: 400;
    color: #767676;
    width: max-content;
    display: table-cell;
}

.vtSeparator span {
    color: #222222;
}

.statusitems {
    font-size: 16px;
    color: #767676;
    font-weight: 400;
    float: right;
    width: 100%;
    text-align: right;
}

.statusitemsbg {
    background-color: #05C1A0;
    padding: 6px 16px;
    color: #fff;
    border-radius: 3px;
}

.disabledContent {
    pointer-events: none;
}

.icon-credit-card:before {
    content: "\e920";
    color: #706cce;
}

#endUserDetails .icoOut>span {
    padding-left: 0;
}

.padT5 {
    padding-top: 5px !important;
}

.fintecContact {
    border-top: 1px solid #e6e6e6;
    float: left;
    width: 100%;
    display: table;
}

.fintecContact .userAddressEdit {
    border-top: 0;
}

.fintecContact .userAddressEditBorder {
    border-left: 1px solid #e6e6e6;
    padding-bottom: 5px;
}

.cols12.paymentBtmRow .cols8 .dx-field.remarkBx {
    display: block;
}

.labelBackground {
    background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 40%, rgb(247 247 252) 50%, rgb(247 247 252) 100%) !important;
}

.paymentinformation p {
    margin-top: 15px;
    margin-bottom: 20px;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    color: #222;
    padding: 10px 0;
}

.paymentBtmRow .paySubTotal.totalWithAct {
    float: right;
    display: inline-flex;
}

.jurisdictionTaxicon {
    width: max-content !important;
}

.jurisdictionTaxicon:before {
    position: relative;
    top: 2px;
    right: 2px;
}

.paymentBtmRow .paySubTotal.totalWithAct .jurisdictionTaxicon:before {
    right: 8px;
}

.icon-view-pwd:before {
    content: "\e9a5";
    color: #706cce;
    font-size: 18px;
    font-weight: bold;
}

.icon-hide-pwd:before {
    content: "\e947";
    color: #706cce;
    font-size: 18px;
    font-weight: bold;
}

.orderDetail.orderPdetails .custDetail.custDetailFull .noteBx {
    padding: 9px 15px !important;
    vertical-align: middle !important;
    display: flex;
    align-items: center;
}

.orderDetail.orderPdetails .custDetail.custDetailFull .contDtl .icon-call-1:before {
    position: relative;
    top: 3px;
    margin-right: 5px;
}

.heightSet {
    height: 75px;
}

.user-detailsright .cols6 .verifyEmailInput .icon-verification:before {
    content: "\e9a4";
    color: #f99300;
    font-size: 17px;
    right: 0px;
    position: relative;
    top: 3px;
}

.user-detailsright .cols6 .verifyEmailInput .contIcon {
    position: absolute;
    right: 28px;
    top: 10px;
}

.hideFilterPanelInx.listofCards.devExTable.responsiveTable .dx-scrollable-wrapper {
    padding: 0;
}

.labelRadio .dx-field-label {
    float: left;
    position: unset;
}

.fintecContact .cols {
    display: table-cell;
    padding-left: 15px;
    padding-right: 15px;
}

.actionBtnBorderNone.actionBtnIx .dx-item {
    border-radius: 50% !important;
    border: none !important;
}

.orderDetail.orderPdetails .fintecContact .addressDetails .icon-emaildxtable:before {
    position: relative;
    top: 1px;
}

.icon-creditmemos:before {
    content: "\e9b1";
    color: #96B113;
    font-size: 29px;
}

.creditmemosbg {
    background: linear-gradient(139deg, #899E1F 0%, #ADD100 70.88%), #FFFFFF;
    line-height: 57px !important;
}

.icon-creditmemosbg:before {
    content: "\e9b9";
    color: #fff;
    font-size: 23px;
}

.icon-dashboard:before {
    content: "\e9b3";
    color: #706cce;
}

.ix-borderbgnone {
    background-color: transparent !important;
    border-color: unset !important;
    color: transparent !important;
}

.ix-borderbgnone.dx-state-hover {
    background-color: rgba(0, 0, 0, .04) !important;
    border-color: unset !important;
}

.loginForm .verificationCode input:focus {
    border: 1px solid #706cce;
}

.titleBtnCol .show-data-from:first-child {
    color: #999;
}

.adminCRM .newContactListing .customerDetail.crmCustomerDetail .selectedContact .icon-edit:before {
    padding: 13px;
    position: relative;
    top: 2px;
}

.contentRow .borderBox.agreementBox.posRelativeAndWidth {
    margin-bottom: 20px;
}

.popCnt .popBox .feildCol input[type=password] {
    border-radius: 0;
}

.planTotalSection .icon-toolTip.tooltipIcon:before {
    position: relative;
    top: 2px;
}

.orderAddress .addressBox .contTx.icoCall .icon-call-1:before {
    position: relative;
    top: 3px;
    padding-right: 6px;
}

.signOut .icon-logout-new:before {
    color: #767676;
}

.headerIcon.icon-notification-icon-new:before {
    color: #767676;
}

#dateRangeGlobalSetingPopover {
    position: relative;
}

.formControls.editAgreeDetail dx-number-box .dx-texteditor-input-container input[type='text'] {
    border-top: none;
    border-bottom: none;
    border-left: none;
}

#rebatecontract .rightBlock .borderBox .cols11.cols11addbtn {
    padding-right: 0;
}

.orderBox .orderDetail .contTx.icoMail {
    padding-left: 0;
}

.orderBox .orderDetail .contTx.icoMail .icon-mail:before {
    position: relative;
    top: 3px;
}

.paymentBtmRow .cols12 .dx-field.remarkBx {
    width: 100%;
}

.dragdropfullwidth .popContent .remarkBx.fullNote.ecaRemark {
    width: 100%;
}

.dragdropfullwidth .uploadLeft {
    width: 45%;
    float: left;
    justify-content: center;
    display: flex;
    position: relative;
    top: 8px;
}

.dragdropfullwidth .uploadLeft .icon-upload:before {
    font-size: 45px;
}

.dragdropfullwidth .uploadLeft h4 {
    padding-top: 17px;
    padding-left: 12px;
}

.dragdropfullwidth .orStyle {
    width: 10%;
    float: left;
    top: 29px;
}

.dragdropfullwidth .orStyle:after {
    content: '';
    height: 1px;
    background: #a3a3a3;
    width: calc(50% - 10px);
    left: 29%;
    top: 41px;
    transform: rotate(90deg);
    position: absolute;
    opacity: 0.5;
}

.dragdropfullwidth .orStyle:before {
    content: '';
    height: 1px;
    background: #a3a3a3;
    width: calc(50% - 10px);
    left: 29%;
    top: -20px;
    transform: rotate(90deg);
    position: absolute;
    opacity: 0.5;
}

.dragdropfullwidth .uploadRight {
    width: 45%;
    float: left;
    position: relative;
    bottom: 4px;
}

.dragdropfullwidth .uploadRight .addFileInput .addIcon:before {
    content: "\e975" !important;
    font-size: 18px;
    font-family: 'IXPortal' !important;
    line-height: 16px;
    position: relative;
    top: 3px;
    right: 3px;
}

.dragdropfullwidth .fileUploadInput {
    height: 130px;
}

.fileshow {
    padding-top: 10px;
}

.fileshow .fileImage {
    padding-top: 5px;
}

.fileshow .fileDetail {
    padding-left: 10px;
}

.fileshow .fileDetail h4 {
    width: max-content !important;
    float: left;
}

.fileshow .fileDetail .icon-deletedxtable {
    float: left;
}

.fileshow .fileDetail .fileProgText {
    width: max-content !important;
}

.fileshow .fileDetail .icon-deletedxtable:before {
    font-size: 20px;
    line-height: 20px;
    position: relative;
    top: 8px;
    left: 20px;
    cursor: pointer;
}

.ix-dashboardtile {
    position: relative;
    top: 2px;
}

.icon-rebate:before {
    content: "\e979";
    font-size: 31px;
    color: #706cce;
    line-height: 36px;
}

.ix-selectdashboardicon:before {
    font-size: 18px;
    margin-right: 5px;
    float: right;
    position: relative;
    top: -1px;
    line-height: normal;
}

.ix-virtualTag {
    background-color: #d7f5f0;
    color: #24bb8c;
    font-size: 14px;
    padding: 3px 10px;
    border-radius: 3px;
    line-height: normal;
}

.mrgT8 {
    margin-top: 8px !important;
}

.chartTitlesmall {
    line-height: 18px;
    margin-bottom: 0;
}

.dashboardamount {
    position: relative;
    display: inline-block;
}

.dashboardamount .dashboardamounttext {
    visibility: hidden;
    width: 120px;
    background-color: #222222;
    opacity: 0.7 !important;
    color: #fff;
    text-align: center;
    border-radius: 4px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: 100%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
    font-size: 15px;
    line-height: 15px;
}

.dashboardamount .dashboardamounttext::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -7px;
    border-width: 7px;
    border-style: solid;
    border-color: transparent transparent #222 transparent;
}

.dashboardamount:hover .dashboardamounttext {
    visibility: visible;
    opacity: 1;
}

.fullamount {
    background-color: #F7F7F7;
    border-radius: 4px;
    font-size: 17px;
}

.shortamount {
    padding: 0px 15px;
}

.shortamount:hover {
    background-color: rgba(0, 0, 0, .04) !important;
}

.fullamount.trillionamount {
    font-size: 12px;
}

.fullamount.quadrillionamount {
    font-size: 10px;
}

.statusBx #showTotalPaidAmount {
    padding-top: 7px;
    line-height: normal;
}

.iconwebfevicon {
    margin-right: 5px;
}

.iconwebfevicon img {
    width: 20px;
}

.iconBorder {
    border: 1px solid lightgrey;
}

.iconBorder img {
    width: 100%;
    height: 100%;
    padding: 6px;
}

.alignRightBottom {
    position: absolute;
    right: 37px;
    bottom: 55px;
}

/*new add css*/
.crmAddEdit .popContent .contTx strong {
    font-weight: normal;
    color: #222;
}

.ix-chart-sales-performance text tspan:nth-child(2) {
    font-weight: 500 !important;
    fill: #222 !important;
}

td.right #jurisdictionTax:before {
    top: 2px;
    position: relative;
}

.dynamicBgColors.secondaryBtn .dx-button-content {
    padding: 7px 11px !important;
    font-size: 15px;
}

.dynamicBgColors.secondaryBtn .dx-button-content .dx-icon-spindown {
    height: 15px;
    line-height: 15px;
}

.dynamicBgColors.secondaryBtn .dx-button-content .dx-icon-spindown:before {
    font-size: 8px;
}

.icon-verifyEmailIcon:before {
    content: "\e92c";
    color: #05c1a0;
    font-size: 14px;
}

.contIcon.icon-verifyEmailIcon {
    width: 18px;
    height: 18px;
    margin-left: 5px;
}

.customer360 .customerDateLabel,
.customer360 .customerDate {
    position: relative;
    top: 2px;
}

.customer360 .customerDate {
    color: #222;
}

.orderBox .odStatus.cols2 {
    width: 28%;
    position: relative;
    margin-top: 17px;
}

.orderBox .odStatus.cols2:before {
    border-right: 1px solid #e5e5e5;
    content: "";
    height: 100%;
    display: inline-block;
    position: absolute;
    top: 0%;
    left: -5px;
    min-height: 100%;
}

.statusBxLnremove {
    padding-left: 0;
    margin-bottom: 0;
}

.statusBxLnremove li {
    list-style: none;
}

.orderBox .orderDetailLastrow.cols3 {
    width: max-content;
}

.inStatusLabel.greenInvoice {
    color: #02C19F;
    background: #E5F8F5;
    border: 1px solid #02C19F;
}

.incentive-commission-report .incentive-commission-report-content .dx-scrollable-wrapper {
    padding: 0;
}

.incentive-commission-report .incentive-commission-report-content .borderBox {
    padding: 0;
    box-shadow: unset;
}

.custNm .lbl {
    font-family: 'proxima_nova';
}

.quoteDesign .orderAddress #editBillingAddress {
    position: relative;
    top: 5px;
    left: 8px;
}

.quoteDesign .orderAddress #editShippingAddress {
    position: relative;
    top: 5px;
    left: 23px;
}

/*new css*/
.inputDisable .dx-field-value {
    background-color: #f0f0f0;
}

.edit-Company-information-icon:before {
    content: "\e927";
    color: #999;
    font-size: 38px;
    line-height: normal;
    font-family: 'IXPortal';
    top: 48%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
}

.icon-close-input:before {
    content: "\e919";
    color: #9b9b9b;
    font-size: 15px;
    position: absolute;
    right: 12px;
    top: 13px;
    cursor: pointer;
}

.top1px {
    position: relative;
    top: 1px;
}

.crmAddEdit .form-group label:before {
    top: -1px;
}

.box-shadow-none {
    box-shadow: none !important;
}

.shiftTimeViewOnly .timeZone span {
    position: relative;
    top: 5px;
}

.alignLeftBottom {
    position: absolute;
    bottom: 55px;
}

.payAlert .icon-alert_popup {
    font-size: 85px;
}

.icon-removecart:before {
    content: "\e929";
    font-size: 15px;
    position: absolute;
    right: 0px;
    top: 8px;
    color: #ff4545;
    width: 20px;
    height: 20px;
}

.bluebg {
    background: #06b4fe !important;
}

.darkgreenBg {
    background: #77ce44 !important;
}

ul.icon-planBullets {
    padding: 10px 0px;
    margin: 0;
}

ul.icon-planBullets li:before {
    content: "\e92d";
    font-size: 16px;
    list-style: none;
    line-height: 20px;
    color: #32c680;
    font-family: 'IXPortal';
    position: absolute;
    left: 0;
}

ul.icon-planBullets li {
    list-style: none;
    font-family: 'proxima_nova';
    margin-bottom: 10px;
    padding-left: 25px;
    position: relative;
    line-height: 20px;
}

.featurePopup ul.icon-planBullets li ul li {
    background: none;
    width: 49%;
    display: inline-block;
    position: relative;
    padding-right: 15px;
    vertical-align: top;
}

.featurePopup ul.icon-planBullets li ul {
    padding: 0;
}

.featurePopup ul.icon-planBullets li ul li:before {
    content: "\e9b4";
    font-family: 'IXPORTAL';
    color: #21b3c6;
    position: absolute;
    left: 10px;
    top: 0px;
    font-size: 7px;
}

.planWithDiscount ul.icon-planBullets.inlineBullet {
    border: none;
    margin-bottom: 0;
}

.planWithDiscount ul.icon-planBullets.inlineBullet li {
    min-height: 95px;
}

.icon-planBullets.inlineBullet {
    padding-top: 0;
}

.icon-planBullets.inlineBullet li {
    display: inline-block;
    padding-right: 20px;
    vertical-align: top;
}

.popularPlan ul.icon-planBullets li,
.planDetails:hover ul.icon-planBullets li {
    content: "\e92d";
    font-size: 16px;
    color: #fff;
    font-family: 'IXPortal';
}

.paymentPlans .planBlock .rightText.icon-planBullets {
    overflow: auto;
}

.agreementtemplates .dx-field,
.agreementtemplates .feildCol.radioGroup,
.agreementtemplates .feildCol,
.agreementtemplates .cols4 {
    min-height: 65px;
    margin-bottom: 0;
}

.ix-dynamic-breadcrumb .custom-bread-crumb .line {
    font-size: 15px !important;
    color: #565656;
}

chargecontent .cols2 {
    width: 215px;
}

.chargecontent .cols10addbtn {
    width: calc(100% - 215px);
}

input:hover {
    border: 1px solid #B7B5E6 !important;
}

input:focus,
textarea:focus,
select:focus,
.mat-form-field-wrapper:focus-within {
    border: 1px solid #706cce !important;
}

input[type=button]:focus {
    outline: unset;
}

.top3px {
    position: relative;
    top: 3px;
}

.royaltyDetailsFooterBlock1 label {
    display: contents;
}

.icon-cvvcodeicon {
    font-size: 24px;
}

.icon-cvvcodeicon .path1:before {
    content: "\e911";
    color: rgb(118, 118, 118);
}

.icon-cvvcodeicon .path2:before {
    content: "\e912";
    margin-left: -1.400390625em;
    color: rgb(112, 108, 206);
}

.icon-cvvcodeicon .path3:before {
    content: "\e913";
    margin-left: -1.400390625em;
    color: rgb(255, 255, 255);
    opacity: 0.2;
}

.icon-cvvcodeicon .path4:before {
    content: "\e914";
    margin-left: -1.400390625em;
    color: rgb(255, 255, 255);
}

.icon-cvvcodeicon .path5:before {
    content: "\e915";
    margin-left: -1.400390625em;
    color: rgb(255, 255, 255);
    opacity: 0.2;
}

.icon-cvvcodeicon.cvvinputadd {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 11px;
    right: 0px;
    cursor: pointer;
}

.checkboxGroup .checkBox span {
    width: max-content;
    display: inline-block;
}

.ssoBtn .dx-button-text {
    font-size: 22px;
    position: relative;
    top: 2px;
    font-family: proxima_nova semiBold;
}

.ssoBtn {
    height: 53px !important;
    margin-top: 5px;
}

.loginForm .methodIcons a {
    font-size: 16px;
    color: #696868;
}

.loginForm .methodIcons {
    text-decoration: unset;
    margin-top: 15px;
}

.alignRightBtn dx-button {
    float: right;
}

.ix-virtual-invoice-content {
    background-color: #ececec;
}

/* #region for Logout Popup  */
.logOutPopup .logOutPopupLeftContent {
    width: 70px;
    float: left;
}

.logOutPopup .logOutPopupRightContent {
    width: calc(100% - 70px);
    float: right;
}

.logOutPopup .logOutPopupuserImage {
    width: 57px;
    height: 57px;
    display: inline-block;
    background: #21b3c6;
    vertical-align: top;
    margin: 0px;
    border-radius: 50%;
    border: 3px solid #ebebeb;
    overflow: hidden;
}

.logOutPopup .logOutPopupuserImage span {
    width: 100%;
    height: 100%;
    line-height: 52px;
    text-align: center;
    display: block;
    font-size: 26px;
    color: #fff;
}

.logOutPopup .logOutPopupRightContent h3 {
    color: #222222;
    font-family: 'proxima_nova semiBold';
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 0;
    padding-top: 9px;
}

.logOutPopup .logOutPopupRightContent h4 {
    color: #767676;
    font-family: 'proxima_nova';
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 0;
}

.logOutPopup dx-button.secondaryBtn {
    width: 47%;
    float: left;
    margin-right: 3%;
}

.logOutPopup dx-button.primaryBtn {
    width: 47%;
    float: left;
    margin-left: 3%;
}

.logOutPopup dx-button {
    font-family: 'proxima_nova semiBold' !important;
}

.logOutPopupTitle {
    font-size: 18px;
    font-family: 'proxima_nova semiBold';
    margin-bottom: 4px;
    text-align: center;
    margin-top: 7px;
}

.logOutPopupContent {
    padding-top: 17px !important;
}

/* #endregion */
.connectedTitle .successfullyConnectionTitle {
    font-size: 28px;
}

.cols1updatebtn {
    width: calc(8.33% + 40px);
}

.cols2updatebtn {
    width: calc(16.66666667% - 40px);
}

.cols1updatebtn dx-button {
    width: 100%;
}

.ix-dashboardtileContent .dx-list-item .dx-checkbox,
.ix-dashboardtileContent .dx-list-item-content {
    margin-left: 0 !important;
    padding-left: 0 !important;
}

.ix-dashboardtileContent {
    padding-top: 5px !important;
    padding-bottom: 10px !important;
}

.greenTag {
    line-height: normal;
    color: #2fbe92;
    background-color: #daf6f1;
    padding: 3px 8px;
    font-size: 13px;
    letter-spacing: 0.3px;
    font-family: 'proxima_nova semiBold';
    border-radius: 2px;
}

.royaltyDetailsFooterBlock1 .ucsData .cols4 {
    padding-left: 0px !important;
}

.importexcelbtn {
    width: 215px;
    padding-right: 15px;
    padding-left: 15px;
    float: right;
    margin: 0;
}

.importexcelbtn dx-button {
    width: 100%;
}

.editBillingAddress {
    padding-left: 0 !important;
}

.editBillingAddress .dx-list-select-radiobutton {
    margin-left: 0 !important;
}

.editBillingAddress .textWrap {
    margin-bottom: 0;
}

.greenTagInTd {
    position: absolute;
    right: 0;
    top: 2px;
}

.secondaryBtn.linkset {
    margin: 0;
    width: max-content;
    float: left;
    height: unset;
    margin-right: 10px;
}

.primaryBtn.linkset {
    float: right;
    height: unset;
    line-height: normal;
    margin-bottom: 12px;
}

.allErpVerifyEmailIcon:after {
    top: -2px !important;
    left: 5px;
}

.allErpVerificationfailed:before {
    top: -2px !important;
    left: 5px;
    position: relative;
}

.infoIconAddInput {
    position: relative;
}

.icon-royalty:before {
    content: "\e9b6";
    color: #DF9BD5;
    font-size: 31px;
}

.ix-selectdashboardicon.icon-royalty:before {
    position: relative;
    left: 2px;
    font-size: 18px !important;
}

.infoDescription {
    color: #767676;
    font-size: 18px;
    text-align: center;
    line-height: 24px;
    margin-bottom: 0;
    max-width: 620px;
}

.deletePopupIcon {
    text-align: center;
}

.deletePopupIcon:before {
    content: "\e929";
    color: #ff0100;
    font-size: 55px;
    font-family: IXPortal;
}

.configurationsFieldChooser .dx-treelist-rowsview .dx-treelist-expanded span::before {
    content: "\e998";
    color: #959595;
    font-size: 6px;
    font-family: 'IXPortal';
}

.configurationsFieldChooser .dx-treelist-rowsview .dx-treelist-collapsed span::before {
    content: "\e984";
    color: #959595;
    font-size: 10px;
    font-family: 'IXPortal';
    padding-top: 2px;
}

.configurationsFieldChooser .dx-checkbox.dx-checkbox-checked .dx-checkbox-icon {
    border: 1px solid #706cce !important;
}

.configurationsFieldChooser .dx-treelist-table a {
    font-family: 'proxima_nova semiBold';
    margin-left: 8px;
}

.configurationsFieldChooser .dx-header-row .dx-treelist-text-content {
    margin-left: 8px;
}

.inputMandatorySign {
    font-size: 16px;
    font-family: 'proxima_nova semiBold';
    color: red;
}

.height0p {
    height: 0px !important;
}

.customerDetail.custdtlTop .customerdetailsettingiconix {
    position: absolute;
    right: 0;
    top: -9px;
}

.customerDetail.custdtlTop .customerdetailsettingiconix .dx-button-content {
    border: none !important;
}

.newCustDetail.vendorDtl .customerdetailsettingiconix {
    position: absolute;
    right: 0;
    top: -9px;
}

.newCustDetail.vendorDtl .customerdetailsettingiconix .dx-button-content {
    border: none !important;
}

.approvaltimelinelistix .dx-item.dx-list-item:nth-child(2n) .approvaltimelineitemix:before {
    border-color: #38b1eb;
}

.approvaltimelinelistix .dx-item.dx-list-item:nth-child(3n) .approvaltimelineitemix:before {
    border-color: #f99300;
}

.approvaltimelinelistix .dx-item.dx-list-item:nth-child(4n) .approvaltimelineitemix:before {
    border-color: #05c1a0;
}

/* message trall style */
.approvaltimelinelistix .dx-scrollview-content .dx-item:first-child .approvaltimelineitemix:after {
    top: 33px;
}

.approvaltimelineitemix {
    position: relative;
    padding: 0 0 0 36px !important;
    width: 100% !important;
    float: left;
    white-space: normal !important;
}

.approvaltimelineitemix:before {
    content: "" !important;
    height: 12px;
    width: 12px !important;
    position: absolute;
    border: 2px solid #706cce;
    border-radius: 12px;
    background: #fff;
    z-index: 1;
    top: 30px;
    left: 0;
}

.approvaltimelineitemix:after {
    content: "";
    height: 100%;
    width: 2px;
    background: #ededee;
    position: absolute;
    left: 5px;
    top: 0;
    z-index: 0;
}

.approvaltimelineboxix {
    padding: 0 0 0 30px;
}

.approvaltimelineinnerboxix {
    border: 1px solid #DADADA;
    position: relative;
    width: calc(100% - 20px);
    float: left;
    margin-top: 15px;
}

.approvaltimelineinnerboxix:after,
.approvaltimelineinnerboxix:before {
    content: '';
    display: block;
    position: absolute;
    right: 100%;
    width: 0;
    height: 0;
    border-style: solid;
}

.approvaltimelineinnerboxix:after {
    top: 10px;
    border-color: transparent transparent transparent #fff;
    border-width: 10px;
    transform: rotate(180deg);
}

.approvaltimelineinnerboxix:before {
    top: 9px;
    border-color: transparent transparent transparent #DADADA;
    border-width: 11px;
    transform: rotate(180deg);
}

.approvaltimelinecalandtxt .approvaltimelinecal {
    width: 55px;
    float: left;
}

.approvaltimelinecalandtxt .approvaltimelinecal .cMonth {
    float: left;
    border-radius: unset;
}

.approvaltimelinecalandtxt .approvaltimelinetxt {
    width: calc(100% - 55px);
    float: left;
    padding-left: 10px;
}

.approvaltimelinecalandtxt .approvaltimelinetxt h3 {
    font-size: 15px;
    font-family: 'proxima_nova semiBold';
    color: #222;
    margin-bottom: 0;
    padding-top: 7px;
}

.approvaltimelinecalandtxt .approvaltimelinetxt h4 {
    font-size: 14px;
    font-family: 'proxima_nova';
    color: #767676;
    margin-bottom: 0;
}

.approvaltimelinecalandtxt .approvaltimelinecal .calendarBx {
    border-radius: 3px;
}

.approvaltimelinecalandtxt .approvaltimelinetxt h4 span {
    font-size: 14px;
    font-family: 'proxima_nova semiBold';
    color: #706CCE;
}

.approvaltimelinecalandtxt {
    width: 100%;
    float: left;
    padding: 10px 10px 0 10px;
}

.approvaltimelineinnerboxix p {
    width: 100%;
    float: left;
    margin-bottom: 0;
    padding: 10px;
    border-top: 1px solid #DADADA;
    margin-top: 10px;
    color: #767676;
    font-size: 16px;
    line-height: 20px;
}

.approvaltimelinetextareaix {
    width: calc(100% + 15px);
    float: left;
    background: #F4F4F4;
    padding: 15px 20px;
    position: relative;
    left: -15px;
}

.approvaltimelinetextareaix textarea {
    color: #767676;
    font-size: 16px;
    padding-right: 125px;
}

.approvaltimelinetextareaix .approvaltimelinetextareaixbtn {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    right: 40px;
}

.approvaltimelinelistix .dx-list-item {
    border-top: none !important;
    cursor: default;
}

.approvaltimelinelistix .dx-list-item.dx-state-hover {
    background-color: unset !important;
}

.approvaltimelinetopix {
    border-left: 1px solid #EDEDEE;
    height: 100%;
}

.approvaltimelinetopix h2 {
    margin-bottom: 0 !important;
}

.approvaltimelinetopix hr {
    border-top: 1px solid #EDEDEE !important;
    margin: 15px 0 !important;
    width: 100%;
}

.approvaltimelinelistix .dx-scrollview-content .dx-item:last-child .approvaltimelineitemix {
    padding-bottom: 30px !important;
}

.borderradiusnone {
    border-radius: 0 !important;
}

.approvaltimelinelistix {
    max-height: 100vh !important;
    height: calc(100vh - 257px) !important;
}

.nocommentavailableix {
    text-align: center;
    padding-top: 100px;
}

.nocommentavailableix .nocommentavailableimgix {
    margin: auto;
}

.nocommentavailabletextix {
    font-size: 15px;
    font-family: 'proxima_nova';
    color: #767676;
    padding-top: 15px;
}

.approvaltimelineinnerboxix .generatedmessageix {
    background-color: #E2E2F5;
    color: #706CCE !important;
    border-top: unset !important;
}

/* end message trall style */
.notDefaultItemView .dx-datagrid-header-panel .dx-searchbox .dx-texteditor-input {
    border: none;
    height: 34px;
}

.notDefaultItemView .dx-datagrid-header-panel .dx-searchbox .dx-placeholder::before {
    left: 18px;
    position: relative;
}

.notDefaultItemView .dx-datagrid-header-panel .dx-searchbox {
    width: 250px !important;
    margin-right: 5px;
}

/* Price List History  */
.priceListHistoryix {
    border-left: 2px solid #EDEDEE;
    height: 100%;
    padding: 0;
    position: relative;
    width: 100%;
}

.priceListHistoryix h2 {
    border-bottom: 1px solid #E1E1E1;
    display: block;
    padding-bottom: 10px;
    padding-top: 15px;
    margin: 0px 15px 10px 15px;
}

.priceListHistoryix .priceListHistoryinnerix {
    position: relative;
    padding-left: 25px;
}

.priceListHistoryix .priceListHistoryinnerix h3 span {
    font-family: proxima_nova semiBold;
    color: #706cce;
}

.priceListHistoryix .priceListHistoryinnerix h3 {
    font-family: proxima_nova;
    color: #767676;
    font-size: 16px;
    padding-bottom: 2px;
    width: max-content;
    float: left;
}

.priceListHistoryix .priceListHistoryinnerix .cols6 {
    padding-right: 0;
    padding-left: 0;
}

.priceListHistoryix .priceListHistoryinnerix .row {
    margin: 0;
}

.priceListHistoryix .priceListHistoryinnerix h4 {
    color: #767676;
    font-size: 14px;
}

.priceListHistoryix .priceListHistoryinnerix h5 {
    color: #222;
    font-size: 14px;
}

.priceListHistoryix .priceListHistoryinnerix:after {
    content: "";
    height: calc(100% + 26px);
    width: 2px;
    background: #EDEDEE;
    position: absolute;
    left: 5px;
    top: -13px;
    z-index: 0;
}

.priceListHistoryix .priceListHistoryinnerix:before {
    content: "" !important;
    height: 12px;
    width: 12px !important;
    position: absolute;
    border: 2px solid #706cce;
    border-radius: 12px;
    background: #fff;
    z-index: 1;
    top: 4px;
    left: 0;
}

.priceListHistoryix .priceListHistoryallix .priceListHistoryinnertopix {
    padding: 12px 15px 12px 15px;
    border: 1px solid transparent;
}

.priceListHistoryix .dx-item:nth-child(2n) .priceListHistoryinnerix:before {
    border-color: #38b1eb;
}

.priceListHistoryix .dx-item:nth-child(3n) .priceListHistoryinnerix:before {
    border-color: #f99300;
}

.priceListHistoryix .dx-item:nth-child(4n) .priceListHistoryinnerix:before {
    border-color: #05c1a0;
}

.priceListHistoryix .priceListHistorycloseix {
    padding: 0 !important;
    border-color: transparent;
    position: absolute;
    right: 5px;
    top: 9px;
}

.priceListHistoryix .dx-item.dx-list-item-selected,
.priceListHistoryix .dx-item.dx-list-item-selected .priceListHistoryinnertopix:hover {
    background-color: #F0F0FA !important;
}

.priceListHistoryix .dx-item.dx-list-item .dx-list-item-content {
    padding: 0 !important;
}

.priceListHistoryix .dx-item:first-child .priceListHistoryinnerix:after {
    top: 10px;
}

.priceListHistoryix .priceListHistoryallix .priceListHistoryinnertopix:hover {
    background: #F0F0FA;
}

.priceListHistoryix .dx-item.dx-list-item {
    border: none !important;
}

.priceListHistoryix .priceListHistorycloseix.dx-state-hover,
.priceListHistoryix .priceListHistorycloseix.dx-state-focused {
    background-color: transparent !important;
    border-color: transparent !important;
}

.fixheightix {
    min-height: 60px;
}

.priceListHistoryix .dx-item.dx-list-item-selected .priceListHistoryinnertopix {
    border: 1.5px solid #706cce !important;
}

.pad0 {
    padding: 0 !important;
}

.priceListDetailsDrawerPadIx .dx-drawer-content {
    padding: 15px 25px !important;
}

.priceListDetailsDrawerPadIx .inlineRadio .notificationRadio .mainLabel {
    position: relative;
    top: 2px;
}

.dataGridHeightSetIx .dx-datagrid-table tr td {
    padding-top: 12px;
    padding-bottom: 12px;
}

#gridContainerHeightIx {
    max-height: 642px;
}

.toolbarbtnbgchangePopupWrapper .dx-datagrid-header-panel .dx-toolbar-item.dx-toolbar-button .dx-button-normal .dx-button-content {
    background: #ffffff !important;
    color: #565656 !important;
}

.textandlableix {
    font-size: 20px;
    color: #222222;
    margin-bottom: 0;
    font-family: 'proxima_nova semiBold';
}

.textlabelix {
    font-size: 16px;
    color: #767676;
    font-family: 'proxima_nova';
}

.mrgB5 {
    margin-bottom: 5px !important;
}

/* close multiple settlment css */
.listofSettelmentix h2 {
    font-size: 15px;
    font-family: 'proxima_nova semiBold';
    color: #21B3C6;
    margin-bottom: 10px;
}

.listofSettelmentix p {
    color: #767676;
    margin-bottom: 0;
    font-size: 14px;
}

.listofSettelmentix p .purple {
    font-family: 'proxima_nova semiBold';
}

.listofSettelmentix h3 span {
    text-align: right;
    font-weight: normal;
    font-family: 'proxima_nova semiBold';
}

.listofSettelmentix h3 {
    color: #767676;
    margin-bottom: 0;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
}

.listofSettelmentix h4 {
    color: #222222;
    margin-bottom: 3px;
    font-size: 16px;
    margin-top: -1px;
}

.listofSettelmentix h5 {
    color: #222222;
    margin-bottom: 5px;
    font-size: 14px;
}

.listofSettelmentix > .dx-overlay-content {
    border: 1px solid #E1E1E1 !important;
    box-shadow: none !important;
}

.listofSettelmentix .settlement-open-ix,
.listofSettelmentix .settlement-close-ix {
    border: none !important;
    position: relative;
    right: -10px;
}

.listofSettelmentix .settlement-open-ix .dx-button-content,
.listofSettelmentix .settlement-close-ix .dx-button-content {
    background-color: transparent !important;
}

.listofSettelmentix .settlement-open-ix .dx-icon-chevrondown::before {
    content: "\e941";
    font-family: 'IXPortal';
    color: #767676;
    font-size: 14px;
}

.listofSettelmentix .settlement-close-ix .dx-icon-chevronup::before {
    content: "\e9bd";
    font-family: 'IXPortal';
    color: #767676;
    font-size: 15px;
}

.listofSettelmentix .dx-toolbar .dx-toolbar-after .dx-toolbar-item {
    padding-right: 0 !important;
}

.listofSettelmentix .dx-toolbar .dx-icon-close {
    color: #767676 !important;
}

.listofSettelmentix .loadingPrice {
    height: 21px;
}

.listofSettelmentix .dx-popup-content {
    padding-top: 13px !important;
}

.listofSettelmentix .listofSettelmentinnerix {
    white-space: break-spaces;
}

.infoTxtIconIx .dx-icon-info {
    margin-right: 5px;
    position: relative;
    top: 1px;
    color: #3fcfb6;
}

.infoTxtIconIx .demo-info {
    font-size: 14px;
    font-family: 'proxima_nova';
    line-height: 16px;
    color: #767676;
}

/*End close multiple settlment css */
.popupDescriptionix {
    font-family: proxima_nova;
    color: #000000de;
    font-size: 16px;
}

.inputLeftBorderCutix {
    width: calc(100% - 1px);
    margin-left: 1px;
}

.resetButtonTextix .dx-button-text {
    font-size: 18px;
    font-family: proxima_nova semiBold;
}

.resetButtonTextix .dx-button-content {
    background: #21b3c6;
}

.iconColorWhiteIx .dx-button-content .dx-icon:before {
    color: #fff;
}

.cdk-overlay-container {
    z-index: 1505;
}

/* Is Sync on header process css */
.synceProcessIsRanning {
    float: left;
    position: relative;
    padding-right: 15px;
    margin-right: 5px;
}

.synceProcessIsRanning #medium-indicator {
    float: left;
    margin-right: 8px;
    margin-top: 6px;
}

.synceProcessIsRanning #medium-indicator .dx-loadindicator-icon .dx-loadindicator-segment {
    background: #706cce !important;
}

.synceProcessIsRanning:after {
    height: 28px;
    border-right: 1px solid #d9d9d9;
    width: 1px;
    display: block;
    position: absolute;
    right: 0px;
    content: "";
    top: 7px;
}

.synceProcessIsRanning span {
    width: 77px;
    display: inline-block;
    font-size: 13px;
    line-height: 18px;
    padding-top: 4px;
    color: #767676;
}

/* End is Sync on header process css */
.closePopup.dx-icon-close:before {
    font-size: 18px;
    color: #333;
}

.vendoreTable #gridContainer {
    max-height: 545px !important;
}

.d-block {
    display: block !important;
}

.colorWhite {
    color: #fff !important;
}

.actBtnBigTooltip dx-tooltip {
    display: none;
}

.appSettingTabs.mainContentIncentxConfig .appTabFirst .dx-treeview-expander-icon-stub {
    display: none;
}

.appSettingTabs.mainContentIncentxConfig .appTabFirst .dx-scrollable-content {
    padding-right: 0 !important;
}

.invoiceLabel {
    font-size: 18px !important;
    margin-bottom: 5px !important;
    color: #706cce !important;
}

.customerDetail .custNm {
    font-family: 'proxima_nova semiBold' !important;
    font-size: 20px !important;
    color: #222 !important;
    margin: 0 0 10px !important;
    line-height: 20px !important;
    margin-top: 5px !important;
    margin-bottom: 5px !important;
}

.mrgT15 {
    margin-top: 15px !important;
}

/*------ Currency Change ------*/
.d-flex {
    display: flex !important;
}

.flex-grow-1 {
    flex-grow: 1 !important;
    flex-basis: min-content;
}

.line-height-normal {
    line-height: normal !important;
}

.mrgT5 {
    margin-top: 5px !important;
}

.currencyDropdownCustom-item h5 {
    font-size: 16px;
    color: #222;
}

.currencyDropdownCustom-item p {
    color: #565656;
    font-size: 14px;
}

.currencyDropdownCustom-item .currencySymbol {
    color: #222;
    font-size: 17px;
}

.currencyDropdownIx {
    height: 44px;
}

.currencyDropdownIx .dx-lookup-arrow::before {
    content: "\e997" !important;
    font-family: 'IXPORTAL';
    color: #9f9f9f;
    font-size: 9px;
}

.currencyDropdownIx .custom-field p {
    color: #222;
    font-size: 16px;
}

.ix-currencyContent .dx-lookup-search-wrapper {
    padding: 0 !important;
}

.ix-currencyContent .dx-lookup-search-wrapper .dx-texteditor-input {
    border: none !important;
}

.ix-currencyContent .dx-lookup-search-wrapper .dx-texteditor.dx-editor-outlined {
    border-bottom: 1px solid #DADADA;
    border-right: unset;
    border-left: unset;
    border-top: unset;
}

.ix-currencyContent .dx-item.dx-list-item,
.dx-dropdown-currency-format-item-ix .dx-item.dx-list-item {
    border-top: 1px solid #DADADA;
}

.ix-currencyContent .dx-lookup-search-wrapper .dx-searchbox .dx-icon-search::before {
    margin-top: 2px;
}

.ix-currencyContent .dx-list {
    height: calc(100% - 45px) !important;
}

.dx-dropdown-currency-format-item-ix .dx-list-item-content,
.dx-dropdown-currency-format-ix .dx-texteditor-input,
.currencyDropdownIx .dx-lookup-field {
    font-size: 16px !important;
}

.dx-dropdown-currency-format-item-ix .dx-list-item-content {
    padding: 9.5px 9px !important;
}

.currency-flag.us {
    background: url('https://cdn.incentx.com/assets/images/svgs/currency/us.svg');
}

.currency-flag.ca {
    background: url('https://cdn.incentx.com/assets/images/svgs/currency/ca.svg');
}

.currency-flag.eu {
    background: url('https://cdn.incentx.com/assets/images/svgs/currency/eu.svg');
}

.currency-flag.gb {
    background: url('https://cdn.incentx.com/assets/images/svgs/currency/gb.svg');
}

.currency-flag.au {
    background: url('https://cdn.incentx.com/assets/images/svgs/currency/au.svg');
}

.currency-flag.nz {
    background: url('https://cdn.incentx.com/assets/images/svgs/currency/nz.svg');
}

.currency-flag.in {
    background: url('https://cdn.incentx.com/assets/images/svgs/currency/in.svg');
}

.currency-flag {
    width: 23px;
    height: 17px;
    object-fit: contain;
    background-repeat: no-repeat;
    margin-right: 12px;
}

.mrgT3 {
    margin-top: 3px !important;
}

.cols-auto {
    width: auto;
    display: inline-block;
    float: left;
    padding-left: 15px;
}

/*----------Currency Change End --------------------*/
.twoPopupUpLoaderIx {
    z-index: 1503 !important;
}

.m-auto {
    margin: auto !important;
}

.popupToolbarPadding .dx-popup-bottom {
    padding-top: 0px !important;
}

.popupContentHauto .dx-popup-content {
    height: max-content !important;
}

.closeSettlementIconPopupIx .infoIcon:before {
    content: "\e926";
    color: #B854B2;
    font-size: 50px;
    font-family: 'IXPortal';
}

.infoTopIcon .infoIcon:before {
    content: "\e955";
    color: #0081c5;
    font-size: 51px;
    font-family: 'IXPortal';
}

.confirmationIcon .infoIcon:before {
    content: "\e91d";
    color: #0081c5;
    font-size: 51px;
    font-family: 'IXPortal';
}

.successTopIcon .infoIcon:before {
    content: "\e92d";
    color: #31cc71;
    font-size: 54px;
    font-family: 'IXPortal';
}

.timerTopIcon .infoIcon:before {
    content: "\e953";
    color: #0081c5;
    font-size: 52px;
    font-family: 'IXPortal';
}

.customPrimaryBtn,
.customSecondaryBtn {
    min-width: 145px !important;
    border-radius: 0 !important;
}

.icon-all_file:before {
    content: "\e9be";
    color: #706cce;
    font-size: 35px;
}

.externalFieldBgix {
    background-color: #F0F0FA;
    padding: 12px 15px 11px 15px;
    display: flow-root;
}

.externalFieldBgix .externalFieldTitleix {
    font-size: 14px;
    color: #767676;
    display: block;
    font-family: 'proxima_nova semiBold';
    margin-bottom: 0;
    line-height: 19px;
}

.externalFieldDescriptionix {
    color: #222;
    font-family: 'proxima_nova';
    font-size: 14px;
    line-height: 19px;
    float: left;
    margin-bottom: 0;
    padding-right: 15px;
}

.externalFieldLabelix {
    color: #767676;
    font-family: 'proxima_nova';
    font-size: 14px;
    line-height: 19px;
}

#listOfRecipientsGridContainer {
    height: calc(100vh - 335px);
}

.font-weight-normal {
    font-family: 'proxima_nova' !important;
}

.font-weight-bold {
    font-family: 'proxima_nova semiBold' !important;
}

.font-weight-bolder {
    font-family: 'proxima_nova Bold' !important;
}

.line-height-unset {
    line-height: unset !important;
}

.pricelistapprovaltrailstab h5 {
    color: #767676;
    font-size: 15px;
    margin-bottom: 0;
}

.pricelistapprovaltrailstab h6 {
    color: #767676;
    font-size: 12px;
    margin-bottom: 0;
    text-align: center;
    margin-top: 5px;
}

.pricelistapprovaltrailstab .approvaltrailsdescription {
    border-top: 1px solid #DADADA;
    width: 100%;
    float: left;
    display: block;
    margin-top: 10px;
    padding-top: 10px;
}

.mrgR15 {
    margin-right: 15px !important;
}

.sendIcon .infoIcon:before {
    content: "\e9bb";
    color: #21b3c6;
    font-size: 53px;
    font-family: 'IXPortal';
}

.sendIcon {
    text-align: center;
}

.pricelistapprovaltrailstab .dx-item.dx-list-item {
    border-top: unset !important;
}

.pricelistapprovaltrailstab .dx-list-item-content {
    padding: 0px !important;
}

.pricelistapprovaltrailstab .dx-item.dx-list-item.dx-state-hover {
    background-color: unset !important;
}

/* Customer Detail UI change */
.w-flex {
    flex-wrap: wrap !important;
}

.customerTiles .customerTilesBox {
    padding: 0px 8px;
    width: 290px;
}

.customerCompanyIcon {
    width: 50px;
    height: 50px;
    display: inline-block;
    border-radius: 100%;
    overflow: hidden;
    line-height: 57px;
    text-align: center;
    background-color: lightgrey;
    margin-right: 14px;
}

.newBoxCustDetail.vendorDtl {
    height: 120px;
}

.newBoxCustDetail .contDtl strong {
    color: #000;
}

.newBoxCustDetail .customerDetail .icoOut {
    margin: 0px 0px 8px 0px;
}

.newBoxCustDetail.vendorDtl .customerdetailsettingiconix {
    position: absolute;
    right: 0;
    top: -9px;
}

.newBoxCustDetail.vendorDtl .customerdetailsettingiconix .dx-button-content {
    border: none !important;
}

.newBoxCustDetail .dx-tag-content {
    padding: 4px 6px 4px 6px !important;
    border-radius: 0px !important;
}

.newTabContactListing .hrLine {
    margin: 5px 0px;
    float: left
}

.newTabContactListing .contDtl,
.newBillAddress .newBillingAdd {
    position: relative;
}

.newTabContactListing .contDtl .editIco,
.newBillAddress .editIco {
    position: absolute;
    right: 2px;
    top: 12px;
}

.newTabContactListing .contDtl .icoOut {
    margin: 2px 0px;
}

/**********/
.radioGroupTemplate .dx-radio-value-container {
    position: relative;
    top: -20px;
}

.radioGroupTemplate .updateRecordsRadio .updateRecordsRadioTitle {
    color: #222222;
    font-family: 'proxima_nova semiBold';
}

.labelRadio .dx-field-label {
    padding-left: 0 !important;
}

.mrgT7 {
    margin-top: 7px !important;
}

.chargebackbasisWidth {
    width: 380px;
}

.dx-datagrid .dx-datagrid-header-panel .inputFieldFilterLbl label {
    bottom: 24px !important;
}

.chargebackbasisWidth .dx-radio-value-container {
    padding-inline-end: 7px !important;
}

.mrgL5 {
    margin-left: 5px !important;
}

/* new add css for notification pop-up on header*/
.notificationPopupWrapper .dx-popup-content {
    padding: 0 !important;
}

.notificationPopup .taskNotificationPopup {
    background-color: #F0F0FA !important;
    padding: 24px 15px;
    height: 98px;
}

.notificationPopup h2 {
    font-size: 16px;
    font-family: proxima_nova semiBold;
    line-height: 24px;
    margin-bottom: 0;
}

.notificationPopup h3 {
    font-size: 15px;
    margin-bottom: 0;
}

.notificationPopup .taskNotificationPopup dx-button,
.notificationPopup .taskNotificationPopup dx-button:hover {
    height: 30px;
    margin-top: 6px;
}

.notificationPopup .pendingApprovalsNotificationPopup ul li a {
    font-size: 16px;
    font-family: proxima_nova semiBold;
    color: #222222;
    display: block;
    padding: 20px 0 20px 13px;
    border-bottom: 1px solid #D9D9D9;
}

.notificationPopup .pendingApprovalsNotificationPopup ul li:before {
    background-color: #21B3C6;
    font-size: 20px;
    content: "";
    width: 6px;
    height: 6px;
    display: inline-block;
    border-radius: 50%;
    margin-bottom: 2px;
    margin-right: 7px;
    float: left;
    top: 26px;
    position: absolute;
}

.notificationPopup .pendingApprovalsNotificationPopup ul li a span {
    background-color: #E2E2F5;
    height: 30px;
    width: 30px;
    display: inline-block;
    text-align: center;
    float: right;
    line-height: 30px;
    color: #706CCE;
    font-size: 16px;
    border-radius: 3px;
    position: absolute;
    right: 15px;
    top: 16px;
}

.notificationPopup .pendingApprovalsNotificationPopup ul li {
    position: relative;
    list-style-position: inside;
    list-style: none;
    padding-right: 15px;
    padding-left: 15px;
}

.notificationPopup .pendingApprovalsNotificationPopup ul {
    padding-left: 0px;
    margin-bottom: 0px;
    padding-bottom: 5px;
}

.notificationPopup .pendingApprovalsNotificationPopup ul li:last-child {
    border-bottom: unset;
}

.notificationPopup .icon-Triangle:before {
    content: "\e9bf";
    position: absolute;
    color: #F0F0FA;
    font-size: 14px;
    top: -14px;
    left: 409px;
    font-family: 'IXPortal';
}

.notificationPopup .icon-Triangle.colorWhite:before {
    color: #fff !important;
}

.notificationPopup .pendingApprovalsNotificationPopup ul li:last-child a {
    padding-bottom: 20px;
}

.notificationPopup .pendingApprovalsNotificationPopup h2 {
    padding-top: 17px;
    padding-right: 15px;
    padding-left: 15px;
}

.notificationPopup .pendingApprovalsNotificationPopup h3 {
    padding-right: 15px;
    padding-left: 15px;
}

.notificationPopup .pendingApprovalsNotificationPopup ul li:hover {
    background: #f0f0fa;
}

.notificationPopup .pendingApprovalsNotificationPopup ul li:hover a {
    color: #706cce;
}

.notificationPopup .pendingApprovalsNotificationPopup .icon-close-popup:before {
    cursor: pointer;
}

.notificationPopup .pendingApprovalsNotificationPopup .icon-close-popup {
    position: absolute;
    right: 15px;
    top: 12px;
}

.notificationPopup .pendingApprovalsNotificationPopup .icon-close-popup:hover {
    color: #767676;
}

.notificationPopupZindex {
    z-index: 1504 !important;
}

/*end new add css for notification pop-up on header*/
.tabbing .notificationAlert {
    width: 10px;
    height: 10px;
    display: inline-block;
    border-radius: 50px;
    background: #FE5059;
    margin-left: 4px;
}

.trialModeZindex {
    z-index: 1505 !important;
}

/* ============== Company Info Lgoo  ============== */
.customerRectangularCompanyIcon {
    width: 200px;
    height: 100px;
    border: 1px solid #D9D9D9;
    display: inline-block;
    position: relative;
}

.icon-default-company:before {
    content: "\e927";
    color: #D3D3D3;
    font-size: 28px;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.customerRectangularCompanyIcon img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 150px;
    height: 85px;
    object-fit: contain;
}

.customProfile .removeLogo {
    right: -30px;
}

.uploadLogoSetinbox {
    position: absolute;
    display: block;
    width: 100%;
    text-align: left;
    color: #767676;
    font-size: 13px;
    font-family: 'proxima_nova';
    padding: 6px 10px;
    border-top: 1px solid #D9D9D9;
    line-height: 20px;
    bottom: 0;
}

.uploadLogoSetinbox .icon-delete {
    text-align: right;
    float: right;
    margin-top: 2px;
}

.uploadLogoDescription {
    color: #767676;
    font-size: 13px;
    font-family: 'proxima_nova';
    margin: 47px 0 0 10px;
}

.customProfileicon-delete {
    position: absolute;
    bottom: 4px;
    width: 30px;
    z-index: 20;
    right: 3px;
}

.customProfile .profileImg .edit-Company-information-icon:before {
    height: 68px;
}

/* New password policy css */
.newPasswordPolicy h3 {
    color: #222;
    font-size: 16px;
    font-family: 'proxima_nova semiBold';
    margin-bottom: 4px;
}

.newPasswordPolicy ul li {
    color: #767676;
    font-size: 15px;
    font-family: 'proxima_nova semiBold';
    list-style: none;
    margin-bottom: 4px;
}

.rightFontIcon:before {
    content: "\e92d" !important;
    font-family: 'IXPortal' !important;
    font-size: 12px;
    margin-right: 5px;
}

.closeFontIcon:before {
    content: "\e919" !important;
    font-family: 'IXPortal' !important;
    font-size: 10px;
    margin-right: 5px;
}

.bulletPointIcon:before {
    content: "\e9b4";
    font-family: 'IXPORTAL';
    font-size: 5px;
    width: 12px;
    display: inline-block;
    margin-right: 5px;
    top: -3px;
    left: 3px;
    position: relative;
}

.newPasswordPolicy .newPasswordPolicyTwoColumn li {
    width: 50%;
    display: inline-block;
}

.resetPasswordInput input {
    padding: 11px 13px 12px 13px !important;
}

.newPasswordPolicy .newPasswordPolicyTwoColumn li:nth-child(2),
.newPasswordPolicy .newPasswordPolicyTwoColumn li:nth-child(4) {
    padding-left: 10px;
}

.newPasswordPolicy.newPasswordPolicyFixWidth {
    width: 480px;
    padding: 0 15px;
    position: relative;
    top: -18px;
}

.forgotPasswordContainer {
    max-width: 510px;
}

.textUnderline {
    text-decoration: underline !important;
}

/*End new password policy css */
dx-data-grid .dx-footer-row:hover>td {
    background: transparent !important;
}

.inStatusLabel.purpleInvoice {
    color: #706cce;
    background: #F0F0FA;
    border: 1px solid #706cce;
}

.noteSepratorTop span.noteSeprator:before {
    top: -4px;
}

.greyandwhiteLabel {
    background: linear-gradient(#f0f0fa 50%, #fff 50%) !important;
}

#apiVersionToolTip {
    position: absolute;
    right: 30px;
    top: 9px;
    cursor: pointer;
    text-align: center;
    line-height: 26px;
}

/*File Upload */
.tableStatusLabel {
    font-size: 15px !important;
    display: block;
    text-align: center;
}

.tableCsvIcon:before {
    color: #969696;
    font-size: 31px;
    margin-right: 10px;
    line-height: 40px;
    display: inline-block;
    vertical-align: middle;
}

.sendboxCnt {
    background-image: url('https://cdn.incentx.com/assets/images/svgs/sandbox.svg') !important;
    width: 55px;
    height: 16px;
    background-repeat: no-repeat;
    background-size: contain;
    float: left;
    margin-top: 23px;
 }


.salesforceSetIcon::before {
    top: 3px;
    right: -4px;
    position: relative;
}

.inputDisable .dx-texteditor-buttons-container {
    background-color: #f0f0f0 !important;
}

/* ZOHO Billing */
.semiBold {
    font-family: 'proxima_nova semiBold' !important;
}

.paymentInfoAdmin .paymentInfoAdminRight .paymentInfoTopBox {
    border: 1px solid #D9D9D9;
    background-color: #CEF2EC;
    padding: 20px;
    width: 240px;
}

.paymentInfoAdmin .paymentInfoAdminRight .paymentInfoTopBox .paymentInfoborder {
    border-left: 3px solid #05C1A0;
    padding-left: 8px;
}

.paymentInfoAdmin .paymentInfoAdminRight .paymentInfoTopBox .paymentInfoborder h2 {
    font-family: 'proxima_nova semiBold';
    font-size: 22px;
    color: #222222;
    margin-bottom: 0;
    display: block;
    line-height: normal;
}

.paymentInfoAdmin .paymentInfoAdminRight .paymentInfoBottomBox {
    border: 1px solid #D9D9D9;
    border-top: transparent !important;
    padding: 20px;
    width: 240px;
}

.paymentInfoAdmin .paymentInfoAdminRight .paymentInfoBottomBox p {
    font-size: 14px;
}

.invoiceTitle {
    font-size: 16px;
    color: #000000;
    font-family: 'proxima_nova Bold';
    display: block;
    line-height: 19px;
}

.invoiceDescription {
    font-size: 15px;
    color: #000000;
    font-family: 'proxima_nova';
    display: block;
    line-height: 18px;
}

.invoiceTopTitle {
    font-size: 38px;
    color: #000000;
    font-family: 'proxima_nova';
    display: block;
    line-height: 45px;
}

.bold {
    font-family: 'proxima_nova Bold' !important;
}

.invoiceDescriptionFixWidth {
    text-align: right;
    display: block;
}

.invoiceDescriptionFixWidth span {
    width: 150px;
    display: inline-block;
}

.grey {
    color: #565656 !important;
}

.invoiceTotalFixWidth {
    width: 300px;
    display: block;
    text-align: right;
    padding-right: 9px;
}

.lightGrey {
    background-color: #F5F4F3 !important;
}

.invoiceTableBgBlack .dx-header-row td,
.invoiceTableBgBlack dx-header-row:hover>td {
    background: #3C3D3A !important;
    color: #fff;
}

.invoiceTableBgBlack .dx-header-row td {
    background-color: #3C3D3A;
    color: #fff;
}

.invoiceTableBgBlack .dx-datagrid-table {
    border: none !important;
}

.invoiceTableBgBlack .dx-datagrid-rowsview tr:hover>td,
.invoiceTableBgBlack .dx-datagrid-filter-row:hover>td {
    background: transparent !important;
}

.icon-emaildxtable:before,
.dx-icon-emaildxtable:before {
    content: "\e95f";
    color: #706cce;
    font-size: 13px;
    margin-right: 5px;
}

.mrgB3 {
    margin-bottom: 3px !important;
}

.pageCenter {
    max-width: 870px;
    margin: auto;
    background-color: #fff;
    padding: 20px;
}

.inStatusLabel.blueInvoice {
    color: #21B3C6;
    background: #E8F7F9;
    border: 1px solid #21B3C6;
}

.inStatusLabel.pinkInvoice {
    color: #DB5383;
    background: #FBEDF2;
    border: 1px solid #DB5383;
}

.icon-sendMail:before {
    content: "\e95f";
    color: #706cce;
    font-size: 13px;
    line-height: 23px;
}

.icon-downloadAction:before {
    content: "\e92f";
    color: #706cce;
    font-size: 15px;
    line-height: 21px;
}

.icon-creditcardAction:before {
    content: "\e920";
    color: #706cce;
    font-size: 17px;
    line-height: 21px;
    position: relative;
    left: -1px;
}

.statusTableLabel {
    font-size: 15px;
    width: 100%;
    display: block;
    border-radius: 0;
    text-transform: uppercase;
    padding: 7px 10px;
}

.labelTextIx {
    font-family: proxima_nova;
    color: #767676;
    font-size: 15px;
}

.d-flex-center {
    align-items: center !important;
}

/*customerPaymentOptions css*/
.row.customerPaymentOptions {
    margin: 0 -7px;
}

.customerPaymentOptions .customerPaymentOptionsTilesBox {
    padding: 0 8px;
    width: 296px;
}

.customerPaymentOptions .customerPaymentOptionsTilesBox .cardImg {
    float: unset !important;
}

.customerPaymentOptions .customerPaymentOptionsTilesBox .icon-menu_se:before {
    color: #767676;
    position: relative;
    top: 6px;
    cursor: pointer;
}

.customerPaymentOptions .customerPaymentOptionsTilesBox h2 span {
    font-family: proxima_nova semiBold;
    padding-right: 2px;
}

.customerPaymentOptions .customerPaymentOptionsTilesBox h2 {
    font-size: 20px;
    font-family: proxima_nova;
    line-height: normal;
    margin-bottom: 0;
    display: block;
    text-align: left;
    padding-top: 10px;
    color: #000;
}

.customerPaymentOptions .customerPaymentOptionsTilesBox p {
    color: #767676;
    font-size: 15px;
    font-family: proxima_nova;
    line-height: normal;
    margin-bottom: 0;
    display: block;
    text-align: left;
    padding-top: 10px;
    padding-bottom: 2px;
}

.visaCardBgImg {
    background-image: url('https://cdn.incentx.com/assets/images/visa-card-img.jpg') !important;
    width: 46px;
    height: 46px;
    border-radius: 50%;
    border: 1px solid #ffa813;
    float: left;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.bankAccountBgImg {
    background-image: url('https://cdn.incentx.com/assets/images/bankAccount.jpg') !important;
    width: 46px;
    height: 46px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.masterCardBgImg {
    background-image: url('https://cdn.incentx.com/assets/images/masterCard.jpg') !important;
    width: 46px;
    height: 46px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.actionBtnHoverNone:hover,
.actionBtnHoverNone.dx-state-hover:hover {
    background: unset !important;
}

.iconColorGray .dx-icon:before {
    color: #767676 !important;
}

.secondaryBtn .dx-icon:before {
    color: #21b3c6;
}

/* start distibution channal style */
.distributionChannelHeader {
	width: 455px;
	float: right;
	margin-top: 13px;
}

.distributionChannelHeader .distributionChannelLable {
	color: #767676;
	font-size: 13px;
	width: 70px;
	text-align: right;
	line-height: normal;
}

.distributionChannelHeader .distributionChannelInput {
	padding-left: 10px;
	display: inline-block;
	position: relative;
}

.d-flex-center {
	align-items: center !important;
}

.distributionChannelHeader .dx-tag-content {
	border-radius: 0px !important;
    padding-inline-end: 6px !important;
}

.distributionChannelHeader .dc-tag-box {
	padding: 7px 5px !important;
}

.distributionChannelHeader .dx-tagbox-multi-tag .dx-tag-content, 
.distributionChannel .dx-tagbox-multi-tag .dx-tag-content{
	background-color: #21B3C6 !important;
	color: #fff !important;
	font-family: 'proxima_nova semiBold';
}

.distributionChannelHeader .dx-tagbox-multi-tag .dx-tag-remove-button::after,
.distributionChannelHeader .dx-tagbox-multi-tag .dx-tag-remove-button::before, 
.distributionChannel .dx-tagbox-multi-tag .dx-tag-remove-button::after,
.distributionChannel .dx-tagbox-multi-tag .dx-tag-remove-button::before {
	background: #fff !important;
}

.distributionChannelHeader .distributionChannelInput:before,
.distributionChannel .dx-field-value:before {
    content: "\e997" !important;
    font-family: IXPORTAL;
    color: #9f9f9f;
    font-size: 9px;
    position: absolute;
    right: 10px;
    z-index: 1;
    top: 53%;
    pointer-events: none;
    transform: translateY(-50%);
}

.distributionChannelHeader .distributionChannelInput .dx-texteditor-input-container, 
.distributionChannel .dx-field-value .dx-texteditor-input-container {
	padding-right: 35px;
}

.distributionChannel .dx-field-value{
	position: relative;
}

.distributionChannel dx-tag-box {
	height: 44px;
	padding: 5px 5px !important;
}

.dx-tag-content {
	background-color: #F3F3F3 !important;
	border-radius: 0 !important;
	line-height: 18px !important;
	font-size: 14px !important;
}

#distributionChannelHeader dx-tag-box.removedCloseIcon .dx-tag-remove-button {
   display: none;
}
/* end distibution channal style */

.borderIx {
    border: 1px solid #DFDFDF;
}
.invoiceSummaryToolbar.dx-toolbar .dx-toolbar-items-container {
    height: 27px !important;
}
.border-left-separator {
    border-left: 1px solid #e5e5e5 !important;
}

/*email-varified-toast css*/
.email-varified-toast {
    color: #F99301;
    background: #FEF4E5;
    border-top: 1px solid #F99301;
    border-bottom: 1px solid #F99301;
    padding: 3px 15px;
    margin-bottom: 0;
    position: relative;
    width: calc(100% - 60px);
    left: 60px;
}

.email-varified-toast p {
    font-size: 14px;
    margin-bottom: 0;
    line-height: normal;
    padding-left: 5px;
}

.email-varified-toast .icon-verification:before {
    font-size: 14px;
    top: -1px;
    position: relative;
}

.email-varified-toast .icon-close:before {
    font-size: 12px;
    color: #F99301 !important;
}

.d-none {
    display: none !important;
}

.d-block {
    display: block !important;
}

.removeEmailVarified {
    height: calc(100% - 60px) !important;
    top: 60px !important;
}

dx-date-box input:focus{
    border: none !important;
}

.checkBox label {
    margin-right: 20px;
    margin-left: 10px;
    position: relative;
    top: 1px;
}

.inputDisable .dx-state-disabled {
    opacity: 1 !important;
}

.primaryBtn.grayButton .dx-icon::before {
    color: #767676;
}

.zohoBillingCompliance {
    float: left;
    margin-right: 15px;
}

.zohoBillingCompliance img {
    height: 50px !important;
}

.btnHeightSmall {
    height: 35px !important;
}
.emailStatus .icon-verification:before{
    line-height: normal !important;
}

.heightUnset{
    height: unset !important;
}

.checkBox label{
    font-size: 15px;
    color: #565656;
    margin-left: 10px;
    position: relative;
    top: 2px;
}

#logo .xlogo img {
    height: 32px;
 }
 
 #logo .xlogo {
    border-right: 1px solid #E5E5E5;
    text-align: center;
    height: 60px;
    padding: 14px 22px;
    float: left;
 }
 
 #logo .headercustomerlogo {
    float: left;
    padding: 14px 20px;
 }
 
 #logo .headercustomerlogo img {
    height: 32px;
 }
 
 .dashedBorderInput dx-text-box {
    border: 1px dashed #ddd !important;
 }
 
 .dashedBorderInput .dx-texteditor.dx-state-focused.dx-editor-outlined {
    border-style: dashed !important;
 }
 .user-content {
    min-height: 65px;
}

.inputDisable dx-radio-group.dx-state-disabled {
    opacity: .5 !important;
}

.greenboxix {
    min-width: max-content;
    border: 1px solid #05C1A0;
    background-color: #CEF2EC;
    padding: 5px 10px;
    position: relative;
    height: 48px;
}

.greenboxix .greenboxinnerix:after {
    content: '';
    background: #05C1A0;
    width: 3px;
    height: 32px;
    display: inline-block;
    position: absolute;
    float: left;
    top: 2px;
    left: 0;
}

.greenboxix span {
    padding-left: 8px;
}

.greenboxix .greenboxinnerix {
    float: left;
    position: relative;
}

.padR30 {
    padding-right: 30px !important;
}

.mrgL30 {
    margin-left: 30px !important;
}

.hrborderix {
    border-top: 1px solid #ddd !important;
}

.btnHeightBig {
    height: 40px !important;
}

.pad5 {
    padding: 5px !important;
}


.inputDisable .dx-lookup-field-wrapper {
    background-color: #f0f0f0 !important;
}

.inputDisable .dx-lookup-field-wrapper p {
    color: #c2c0c0 !important;
}

.d-in-block {
    display: inline-block !important;
 }
 
 .couponix {
    border: 1px dashed #05C1A0;
    background-color: #CEF2EC;
    height: 50px;
    padding: 7px 8px 7px 17px;
    position: relative;
 }
 
 
 .couponix:after {
    content: '';
    background: #05C1A0;
    width: 3px;
    height: 34px;
    margin-right: 5px;
    display: block;
    position: absolute;
    top: 7px;
    left: 9px;
 }
 
 .padT3 {
    padding-top: 3px !important;
 }
 
 .padB3 {
    padding-bottom: 3px !important;
 }
 
 .top-1px {
    position: relative !important;
    top: -1px !important;
 }
 
 .invoiceSummaryToolbar .invoiceTotalFixWidth {
    width: 150px !important;
 }
 
 .padB2 {
    padding-bottom: 2px !important;
 }
 
 .padB5 {
    padding-bottom: 5px !important;
 }
 

 .mailTagBoxIx dx-tag-box {
    padding: 3px 10px;
}

.mailTagBoxIx .dx-field-label {
    top: -8px;
    padding: 0px 5px !important;
}

.mailTagBoxIx .dx-tag-content {
    padding-top: 4px !important;
    border-radius: 0 !important;
}

.mailTagBoxIx dx-tag-box .dx-texteditor-input:focus {
    border: none !important;
    outline: unset !important;
}


.mailTagBoxIx .dx-tag-remove-button::after,
.mailTagBoxIx .dx-tag-remove-button::before {
    top: 48% !important;
}
/* end-user claim pop-up css */
.popup-content-bgchange .dx-popup-content{
	background-color:#f3f3f3;
}

.headerPanelInx .dx-datagrid-header-panel{
	padding: 10px !important;
}
/*end end-user claim pop-up css */

.icon-change-status:before {
	content: "\e9bc";
	color: #706cce;
	font-size: 14px;
    line-height: 23px;
}

.ix-dynamic-breadcrumb .custom-bread-crumb span li span {
    font-family: 'proxima_nova semiBold';
    font-size: 16px;
}

.bgRebates {
    background: -moz-linear-gradient(-45deg, rgb(185 139 186) 0%, rgb(123 94 169) 100%);
    background: -webkit-linear-gradient(-45deg, rgb(185 139 186) 0%, rgb(123 94 169) 100%);
    background: linear-gradient(-45deg, rgb(185 139 186) 0%, rgb(123 94 169) 100%);
}

.bgChargebacks {
    background: -moz-linear-gradient(-45deg, rgb(22 152 245) 0%, rgb(123 224 217) 100%);
    background: -webkit-linear-gradient(-45deg, rgb(22 152 245) 0%, rgb(123 224 217) 100%);
    background: linear-gradient(-45deg, rgb(22 152 245) 0%, rgb(123 224 217) 100%);
}

.tabbing ul li:hover::after {
    content: '';
    background: #c7c7c7;
    height: 4px;
    bottom: 0px;
    position: absolute;
    left: 0;
    right: 0;
}
.icon-support-ticket:before {
    content: "\e991";
    font-size: 16px;
    font-weight: bold;
}
.inStatusLabel.draftInvoice {
    color: #21b3c6;
   background: #21b3c614;
   border: 1px solid #21b3c6;
}

.icon-support-ticket-header:before {
    content: "\e991";
    font-size: 20px;
    font-weight: bold;
    line-height: 42px;
    margin-right: 10px;
}
/* ADD */

.icon-Financial:before {
    content: "\e9c0";
    font-size: 25px;
}

.dataRowHeight .dx-row.dx-data-row {
    height: 52px;
}