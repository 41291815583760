/*   Font Icon Change for DeveExtrem Controls*/

.dx-icon-filter:before {
   content: "\e93a";
   color: #565656;
   font-size: 17px;
   font-family: 'IXPortal' !important;
}

.dx-icon-add:before {
   content: "\e975" !important;
   font-size: 17px;
   font-family: 'IXPortal' !important;
}

.dx-icon-upload::before {
   color: #ffffff;
}
dx-button.primaryBtn .dx-icon-save::before {
   content: "\e987" !important;
   color: #ffffff;
   font-family: 'IXPortal' !important;
   font-size: 14px;
}


dx-button.secondaryBtn .dx-icon-save::before {
   content: "\e987" !important;
   color: #54c4d3;
   font-family: 'IXPortal' !important;
   font-size: 14px;
}
/**** END *****/

#gridContainer {
   max-height: 715px;
}

#gridContainer .dx-datagrid-content .dx-datagrid-table .dx-row>td,
#gridContainer .dx-datagrid-content .dx-datagrid-table .dx-row>tr>td {
   vertical-align: middle !important;
}

.dx-popup-content .greenTipout {
   padding: 0;
}

.dx-popup-content ol.tipList li {
   text-align: left;
}

.dx-popup-content ol.tipList li a {
   text-align: left;
}

#crmCustomerDetail dx-tag-box.dx-ix.removedCloseIcon .dx-tag-remove-button {
   display: none;
}

#crmCustomerDetail .assignLabel {
   font-size: 13px;
   color: #767676;
   font-family: 'proxima_nova';
   font-weight: 400;
   margin-top: 8px;
   display: block;
   margin-bottom:5px;
}

#crmCustomerDetail .dx-tag-content span {
   color: #4a4a4a;
}

#crmCustomerDetail dx-tag-box.dx-ix.vscrollbar {
   border-radius: 0;
   border-style: solid !important;
   padding-right: 4px;
	overflow-y: auto;
   max-height: 100px;
}

#crmCustomerDetail .tableOut .dx-widget.olTable {
   min-width: fit-content !important;
}

#crmCustomerDetail .crmTable {
   /*height: 1098px;*/
}

.hideFilterPanelInx .dx-datagrid-filter-panel {
   display: none !important;
}

.dx-checkbox-indeterminate .dx-checkbox-icon:before {
   display: none !important;
}

.dx-checkbox.dx-state-readonly .dx-checkbox-icon {
   border-color: #ccc !important;
   background-color: #fff;
}

.dx-checkbox-icon {
   border: 1px solid #ccc !important;
}

.dx-field-label {
   position: absolute;
   top: -7px;
   left: 10px;
   background: #fff;
   padding: 0 5px !important;
   font-size: 14px;
   transition: all 0.2s;
   max-width: calc(100% - 20px);
   line-height: 16px;
   z-index: 11;
}

.dx-field-value-static,
.dx-field-value:not(.dx-switch):not(.dx-checkbox):not(.dx-button) {
   width: 100% !important;
}

.dx-field {
   position: relative !important;
}

.dx-field-value {
   width: 100%;
}

.dx-pager .dx-page-sizes .dx-page-size.dx-selection,
.dx-pager .dx-pages .dx-selection {
   color: #706cce !important;
   font-family: 'Proxima_Nova semiBold';
   background: #e2e2f5 !important;
   outline: 0 !important;
}

.dx-pager .dx-page-sizes .dx-page-size {
   color: #767676 !important;
   outline: 0 !important;
   background: transparent !important;
}

.dx-field .dx-field-label {
   width: auto;
   color: #999;
   z-index: 1;
}

.dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-state-focused {
   background-color: #fff !important;
   color: #fff;
}

.dx-popup-content .dx-tag-box.dx-ix.removedCloseIcon .dx-tag-remove-button {
   display: none;
}

/** dev extreme control 28/09/2020 **/

/*** Filter ***/
.dx-datagrid .dx-header-filter.dx-header-filter-empty:before, .filterIcon:before {
   width: 15px;
   height: 15px;
   display: inline-block;
   vertical-align: middle;
   cursor: pointer;
   content: "\e93a" !important;
   color: #565656;
   font-family: 'IXPortal';
}
.dx-datagrid .dx-header-filter:before, .filterIcon:before {
	width: 15px;
   height: 15px;
   display: inline-block;
   vertical-align: middle;
   cursor: pointer;
   content: "\e93b" !important;
   color: #706cce;
   font-family: 'IXPORTAL';
}

.dx-pager .dx-page-sizes .dx-selection, .dx-pager .dx-pages .dx-selection {
   color: #706cce;
   border-color: transparent;
   background-color: #e2e2f5;
   font-family: 'proxima_nova semiBold';
}

.dx-pager .dx-page, .dx-pager .dx-page-size {
   font-size: 15px;
   font-family: 'proxima_nova';
   color: #767676;
}

.dx-datagrid-pager {
   border-top: 0;
}

/** dev extreme control 15/12/2020 **/
.dxSubmitBtn,.dxSubmitBtn.dx-button-mode-contained.dx-button{
   color: #fff;
   background-color: #21b3c6;
   width:100%;
   border: 0;
   font-size: 18px;
   font-family: 'proxima_nova semiBold';
   padding: 9px 16px;
   position: relative;
   line-height: 24px;
   height: 42px;
   border-radius:0;
}
.dxSubmitBtn *,.dxSubmitBtn.dx-button-mode-contained.dx-button *{
   padding:0;
}
.dxSubmitBtn *, .dxSubmitBtn.dx-button-mode-contained.dx-button .dx-button-text {
   display: block;
   line-height: 24px;
}
.dx-datagrid-container.dx-filter-menu .dx-menu-item-has-icon .dx-icon,
.dx-datagrid.dx-filter-menu .dx-menu-item-has-icon .dx-icon {
   color: #565656;
}
.dx-datagrid-container.dx-filter-menu .dx-menu-item-has-icon .dx-icon,
.dx-datagrid.dx-filter-menu .dx-menu-item-has-icon:hover .dx-icon {
   color: #706cce;
}
.dx-datagrid-filter-row .dx-menu {
   margin: 0;
}
.dx-dropdowneditor-button-visible .dx-texteditor-input {
   border: 0;  
}
.dx-texteditor-container {
   border-radius: 0px;
}
.dx-datagrid-filter-row .dx-editor-cell .dx-editor-with-menu .dx-placeholder:before,
.dx-datagrid-filter-row .dx-editor-cell .dx-editor-with-menu .dx-texteditor-input {
   border: 0;
}
.dx-field .dx-field-label {
   width: auto;
   color: #999;
}

.dx-popup-wrapper .dx-toolbar-items-container .dx-toolbar-item.dx-toolbar-button .dx-button-normal.dx-closebutton .dx-button-content,
.dx-popup-wrapper .dx-toolbar-items-container .dx-toolbar-item.dx-toolbar-button .dx-button-normal.dx-closebutton:hover .dx-button-content {
   background: transparent;
}
.dx-editor-cell .dx-texteditor,
.dx-editor-cell .dx-texteditor .dx-texteditor-input {
   border: 0;
}
div#exportContainer {
   margin: 10px 0px;
}
.dx-datagrid-borders>.dx-datagrid-headers {
   border-bottom: 0;
}
.dx-datagrid-borders .dx-datagrid-rowsview,
.dx-datagrid-headers .dx-datagrid-rowsview {
   border-top: 0;
   z-index: 2;
}

.dx-datagrid .dx-header-filter-empty {
   color: #959595;
}
.dx-datagrid.dx-datagrid-borders>.dx-datagrid-pager {
   border: 0;
}

.dx-tooltip-wrapper .dx-overlay-content .dx-popup-content {
   padding: 6px 10px;
}
.dx-pager {
   padding-bottom: 0;
}
.dx-widget,
.dx-datagrid {
   color: #565656;
}
.dx-datagrid-save-button .dx-button-content {
   padding: 0 !important;
}

.dx-datagrid-save-button .dx-button-content {
   padding: 0 !important;
   border: 0 !important;
}  
.dx-datagrid-rowsview .dx-data-row .dx-cell-modified.dx-cell-modified::after,
.dx-datagrid-rowsview .dx-data-row .dx-cell-modified.dx-datagrid-invalid::after {
   border-color: rgba(33, 180, 198, 1) !important;
   border-width:1px;
}
.dx-datagrid-table .dx-datagrid-rowsview .dx-editor-cell .dx-texteditor-input {
   margin: 0;
   min-height: 24px !important;
}
.dx-datagrid-table .dx-datagrid-rowsview .dx-edit-button.dx-button,
.dx-datagrid-table .dx-datagrid-rowsview .dx-edit-button.dx-datagrid-cancel-button.dx-button {
   border: 0 !important;
}
.dx-datagrid-save-button .dx-icon-edit-button-save,
.dx-toolbar-text-auto-hide .dx-button .dx-icon {
   width: 22px !important;
   height: 23px !important;
}
.dx-datagrid-table .dx-datagrid-rowsview .dx-editor-cell .dx-texteditor,
.dx-datagrid-table .dx-datagrid-rowsview .dx-editor-cell .dx-texteditor .dx-texteditor-input {
   height: auto !important;
   min-height: 51px !important;
}
.dx-icon-edit-button-cancel .dx-button-content,
.dx-edit-button.dx-datagrid-cancel-button,
.dx-edit-button.dx-datagrid-save-button {
   border: 0 !important;
   padding: 0 !important;
}
.dx-edit-button.dx-datagrid-cancel-button.dx-button-mode-contained.dx-state-hover,
.dx-edit-button.dx-button.dx-button-mode-contained.dx-state-hover {
   background-color: transparent !important;
}
.newCustDetail .dx-tag-content {
    padding: 3px 6px 4px 6px !important;
}
.dx-datagrid-filter-row .dx-menu{
    margin:0 !important;
}
.dx-filter-menu.dx-menu .dx-menu-item .dx-menu-item-content{
    padding: 11px 5px 10px !important;
}

.dx-toolbar-button .dx-button .dx-icon-filter:before {
   content: "\e93a" !important;
   width: 15px;
   height: 15px;
   display: inline-block;
}
.dx-toolbar-button .dx-button .dx-icon-xlsxfile:before{
   content: "\e938" !important;
   width: 19px;
   height: 19px;
   display: inline-block;
   font-family: 'IXPortal';
}
.dx-datagrid-rowsview .dx-select-checkboxes-hidden > tbody > tr > td > .dx-select-checkbox {
    display: inline-block !important;
}    
.dx-datagrid td .tlNm{margin-bottom:0 !important;}

.technicalContact .dx-datebox.dx-auto-width .dx-texteditor-input,.technicalContact .dx-datebox:not(.dx-texteditor-empty).dx-auto-width .dx-texteditor-input{padding-right:0 !important;padding-top:14px;}
.technicalContact .timeBetween{max-width: 100%;margin-right: 0;}

.dx-datebox-date .dx-dropdowneditor-icon::before {
   content: "\e90a" !important;
   font-size: 20px;
   color: #9f9f9f;
}

.dx-datagrid.dx-datagrid-borders>.dx-datagrid-pager {
   padding-bottom: 0;
}

.dx-dropdowneditor-icon::before {
   content: "\e997" !important;
   font-family: 'IXPORTAL';
   color: #9f9f9f;
   font-size: 9px;
}

.dx-texteditor.dx-editor-outlined {
   border-radius: 0 !important;
}

.dx-dropdowneditor-button.dx-state-active .dx-dropdowneditor-icon,.dx-dropdowneditor.dx-dropdowneditor-active .dx-dropdowneditor-icon {
   background-color: transparent !important;
}

.dx-popup-wrapper>.dx-overlay-content {
   border-radius: 0 !important;
}

.dx-tag-container .dx-placeholder {
   display: none;
}

.dx-group-row.dx-cell-focus-disabled td {
   background-color: #f0f0fa;
}

.dx-dropdowneditor .dx-texteditor-input {
   border: none !important;
}

.dx-toolbar-item .dx-selectbox.dx-textbox .dx-texteditor-input {
   padding: 5px 0px 5px 15px;
   height: auto;
}

.dx-selectbox:hover .dx-texteditor-buttons-container {
   background: #fff !important;
}

dx-tag-box .dx-texteditor {
   display: inline-block;
}

dx-tag-box .dx-tag-container.dx-texteditor-input-container {
   padding-bottom: 2px !important;
}

.dx-dropdowneditor.dx-state-active .dx-dropdowneditor-icon,.dx-dropdowneditor.dx-state-hover:not(.dx-custom-button-hovered) .dx-dropdowneditor-icon {
   background-color: transparent !important;
   border-color: transparent !important;
}

.dx-datagrid-save-button .dx-icon-edit-button-save, .dx-toolbar-text-auto-hide .dx-button .dx-icon{
   width: 22px !important;
   height: 20px !important;
}
.dx-datagrid-content .dx-datagrid-table {
   border-collapse : separate !important ;
}
.dx-datagrid-content .dx-datagrid-table.dx-datagrid-table-fixed {
   border-bottom : 0;
}
.dx-datagrid-content .dx-datagrid-table.dx-datagrid-table-fixed tr:last-child td {
   border-bottom : 0;
}
.dx-link.menuTip, .dx-link.dx-link-save, .dx-link.dx-link-cancel {
   width: 35px !important;
   height: 35px !important;
   line-height: 32px !important;
}
.dx-grid-edit.dx-icon-edit,.dx-grid-edit.dx-icon-edit:hover{
   vertical-align: middle;
    min-width: 20px;
    width: 30px;
    height: 30px;
    position:relative;
}
.dx-grid-edit.dx-icon-edit::before {
   content: "\e932" !important;
   color: #706cce;
   font-family: 'IXPORTAL';
   font-size: 16px; 
}

.dx-grid-delete.dx-icon-trash::before {
   content: "\e929" !important;
   font-family: 'IXPORTAL';
   color: #ff4342;
   font-size: 16px; 
}
.dx-link.dx-link-save:before {
   content: "\e987" !important;
   color: #7874d0;
   font-family: 'IXPORTAL';
   font-size: 14px;
}

.dx-link.dx-link-cancel:before {
   content: "\e99c" !important;
   color: #ff4342;
   font-family: 'IXPORTAL';
   font-size: 15px;
}

 .dx-link.dx-link-save,.dx-link.dx-link-cancel{
   width: 35px !important;
   height: 35px !important;
   border-radius: 30px;
   overflow: hidden;
   position: relative;
   z-index: 0;
   display: inline-block;
   margin: 9px 0px 0px 9px;
}
.dx-link.dx-link-save:after, .dx-link.dx-link-cancel:after,.dx-grid-delete.dx-icon-trash:after,.dx-grid-edit.dx-icon-edit:after,.dx-link.dx-link-cancel:after{
     content: '';
     position: absolute;
     width: 35px;
     height: 35px;
     background: #fff;
     border-radius: 30px;
     top: 0;
     left: 0;
     z-index: -1;
    display:block;
    opacity:0;
}
.dx-link.dx-link-save:focus::after,.dx-link.dx-link-save:hover::after,.dx-grid-delete.dx-icon-trash:focus::after,.dx-grid-edit.dx-icon-edit:focus::after,.dx-grid-delete.dx-icon-trash:hover::after,.dx-grid-edit.dx-icon-edit:hover::after,.dx-link.dx-link-cancel:focus:after,.dx-link.dx-link-cancel:hover:after {
    opacity:1;
}

.dx-button-mode-contained .dx-icon.dx-icon-refresh{
   color:#565656;
}

.dx-field-value:not(.dx-widget)>.dx-checkbox {
   float: none!important;
}

.dx-popup-content {
   padding: 10px 15px;
}

#dx-popup-content .dx-list .dx-empty-message,
#dx-popup-content .dx-list-item-content {
   padding: 5px;
}

.dx-radiobutton-icon::before {
   display: block;
   width: 21px!important;
   height: 21px!important;
   border: 1px solid #b4b4b4 !important;
   background-color: #fff;
   content: "";
   border-radius:50% !important;
   -webkit-box-sizing: content-box;
   box-sizing: content-box;
}
.dx-radiobutton-icon-checked:before{
   border: 1px solid #706cce !important;
}

/* ---  */

.dx-accordion-item-title h2 {
    margin-bottom: 0;
}
.dx-accordion-item{
   margin-bottom:25px;
   border-color:#ddd !important;
}
.dx-accordion-item.dx-state-focused{
   border-color:#ddd !important;
}
.dx-checkbox-icon{
   font-size:16px !important;
   height: 20px !important;
    width: 20px !important;
}
.dx-widget{
   font-family:'proxima_nova' !important;
}
.dx-widget input,.dx-widget input.button, .dx-widget textarea{
   font-family:'proxima_nova';
}
.dx-widget input.button{
   font-family:'proxima_nova semiBold' !important;
}
.dx-field-value:not(.dx-widget) > .dx-checkbox {
    float: none !important;
}
.dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row .dx-command-edit-with-icons .dx-link{
   margin:0 !important;
}
.dx-datagrid-table .dx-row .dx-command-expand.dx-datagrid-group-space{
   padding-top:0;
}
 .hideAccordion .dx-datagrid-table .dx-row .dx-command-expand.dx-datagrid-group-space {
    padding-top: 0px !important;
}
.popContent.componentList .dx-list{
   height: auto !important;
}
.dx-popup-content .dx-datagrid-rowsview .dx-datagrid-content:not(.dx-datagrid-content-fixed),.dx-popup-content .dx-scrollable-content {
    min-height: auto !important;
}
.dx-texteditor-container .dx-texteditor-buttons-container .dx-button.dx-button-mode-contained.dx-widget{
   border:none !important;
}
dx-text-box .dx-texteditor-input{
   border:none !important;
   outline:none;
}
dx-text-box .dx-texteditor-input .dx-button.dx-state-hover{
   background:transparent !important;
}

.popBox .dx-button .dx-button-content .dx-icon{
   width:24px !important;
   height:auto !important;
}
.popBox .btnCols .dxSubmitBtn .dx-button-submit-input{
   opacity:0;
}
.dx-treelist-text-content {
    line-height: 24px !important;
}
.inputDisable .dx-field-label{
   background-image: -webkit-linear-gradient(#ffffff 50%, #f0f0f0 50%);
   background-image: -moz-linear-gradient(#ffffff 50%, #f0f0f0 50%); 
   background-image: -o-linear-gradient(#ffffff 50%, #f0f0f0 50%); 
   background-image: -ms-linear-gradient(#ffffff 50%, #f0f0f0 50%); 
   background-image: linear-gradient(#ffffff 50%, #f0f0f0 50%); 
}

.dx-field.inputDisable .dx-dropdownbox,.dx-field-value.inputDisable .dx-dropdownbox{
   border:1px solid #e5e5e5;
}

.dx-field.inputDisable .dx-dropdowneditor-button,.dx-field.inputDisable  .dx-dropdowneditor-button .dx-dropdowneditor-icon::before{
       background-color:#f0f0f0 !important;
}

.grandTotalAction .dx-checkbox{
   display:block;
}

.dx-tag-remove-button::after, .dx-tag-remove-button::before {
    right: 11px !important;
    margin-top: -7px !important;
    width: 1px !important;
    height: 15px !important;
    background: #aaa !important;
}
.dx-tag-remove-button::after {
    right: 4px !important;
    margin-top: 0px !important;
    width: 15px !important;
    height: 1px !important;
 }
 
 
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused)>td,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused)>tr>td,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover>td,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover>tr>td {
   background-color: #f0f0f9 !important;
}


#shiptrackContainer .dx-datagrid-headers{
    border:none !important;
 }
#shiptrackContainer .dx-datagrid-rowsview{
    border-left: 0 !important;
    border-right: 0 !important;
}

/*.dx-command-expand.dx-datagrid-group-space.dx-datagrid-expand .dx-datagrid-group-closed:before, .dx-command-expand.dx-datagrid-group-space.dx-datagrid-expand .dx-datagrid-group-opened:before {
   top: 7px !important;
}*/
td.dx-command-expand.dx-datagrid-group-space.dx-datagrid-expand ~ td.dx-group-cell {
   font-family: 'proxima_nova semiBold';
}

/*.rightBlock .dx-command-expand.dx-datagrid-group-space.dx-datagrid-expand .dx-datagrid-group-closed:before, .rightBlock .dx-command-expand.dx-datagrid-group-space.dx-datagrid-expand .dx-datagrid-group-opened:before
{
	top:0px !important;
}*/

.dx-popup-content .btnCols .dxSubmitBtn {box-shadow: none !important;}

.dx-checkbox-text{
   white-space: inherit !important;
}

/*  Inline Add/Edit Quote & Order dx-CSS   */

#shiptrackContainer .dx-datagrid-headers {
   border: none !important;
}

#shiptrackContainer .dx-datagrid-rowsview {
   border-left: 0 !important;
   border-right: 0 !important;
}


.dx-datagrid-revert-tooltip .dx-overlay-content {
   background-color: transparent !important;
}

.dx-datagrid-revert-tooltip .dx-revert-button {
   background-color:#fff !important;
   border: 1px solid #2abbce !important;
   margin: 1px 1px !important;
} 

dx-button.transperentBtn {
    border: 0;
    padding: 0;
    width: auto !important;
}
dx-button.transperentBtn.dx-button-mode-contained{
    background-color:transparent !important;
}
.transperentBtn.dx-button-has-icon .dx-button-content{
    padding:0px !important;
}
.transperentBtn.dx-button-mode-contained .dx-icon {
    color: #565656;
    font-size: 11px;
    font-weight: 600;
    width:auto !important;
    font-family: proxima_nova semiBold;
}
.hideAccordion .dx-command-expand.dx-datagrid-group-space {
    width: 0 !important;
    min-width: 0 !important;
    opacity: 0;
}

/*.dx-checkbox-text {
   white-space: pre-wrap;
}*/

.dx-datagrid-table .dx-row .dx-command-expand.dx-datagrid-group-space {
   padding-top: 0px !important;
   vertical-align: middle !important;
}

.radioGroup .dx-radiobutton .dx-item-content, dx-radio-group .dx-radiobutton .dx-item-content {
   vertical-align: middle;
   font-size: 16px;
   display: table-cell;
   line-height: 21px;
}


.dx-slider-handle
{
   margin-top: -10px !important;
   width: 20px !important;
   height: 20px !important;   
   border-radius: 10px !important;  
   position: absolute;
   top: 0;
   right: 0;
   pointer-events: auto;
   -webkit-user-drag: none;
    margin-right: -7px;
    border: 1px solid #fff;
   background-color: #337ab7;
    -webkit-box-sizing: content-box;
   box-sizing: content-box;
}

.fcoBorderBox  .serchBox input[type='text']{
  border:0;
}

dx-data-grid .dx-row td{
   min-height:50px;
}

.dx-texteditor.dx-show-clear-button .dx-texteditor-input,.dx-texteditor.dx-show-clear-button .dx-texteditor-input:focus{
   outline:none;
}

input.dx-texteditor-input::placeholder{
   text-indent:15px;
}

.dx-placeholder::before{
   padding:7px 16px 8px !important;
}

td.dx-focused .dx-texteditor-input{
   outline:none !important;
   border:0 !important;
}

.dx-datagrid-content .dx-datagrid-table .dx-row > td, .dx-datagrid-content .dx-datagrid-table .dx-row > tr > td{
   vertical-align:middle !important;
}

.dx-button.settingbtnicon .dx-icon::before {
   content: "\e98a" !important;
   font-family: 'IXPortal';
   color: #565656;
}
dx-button.settingbtnblueicon .dx-icon::before {
   content: "\e98a" !important;
   font-family: 'IXPortal';
   color: #706cce;
}

dx-data-grid.datagridHeight {
  max-height: 400px !important;
}

dx-datagrid.dx-gridbase-container .dx-datagrid-headers+.dx-datagrid-rowsview {
   border-bottom: 1px solid #ddd;
}

/*  Button Style Change CSS */


dx-button.primaryBtn.grayButton,
dx-button.primaryBtn.grayButton .dx-button-content {
   background: #fff !important;
   color: #767676;
   border: 1px solid #767676;
   cursor: none !important;
   box-shadow: unset !important;
    font-weight: 400;
    height: 41px;
    opacity: 9 !important;
}
dx-button.primaryBtn.grayButton .dx-button-text {
    opacity: 9 !important;
}
dx-button.secondaryBtn.grayButton,
dx-button.secondaryBtn.grayButton .dx-button-content {
   background: #fff !important;
   color: #767676;
   border: 1px solid #767676;
   box-shadow: unset !important;
    font-weight: 400;
    height: 41px;
    opacity: 9 !important;
}
dx-button.secondaryBtn.grayButton .dx-button-text {
    opacity: 9 !important;
}

/* tertiary Button */
dx-button.tertiaryBtn,
dx-button.tertiaryBtn:hover {
   border-radius: 0px !important;
   border: none !important;
    height: 41px;
}

dx-button.tertiaryBtn:hover,
dx-button.tertiaryBtn:hover .dx-button-content,
dx-button.tertiaryBtn.dx-state-hover:hover {
   color: #21b3c6;
   background: #fff !important;
   border-radius: 0px !important;
}

dx-button.tertiaryBtn .dx-button-content {
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: nowrap;
   padding: 10px 12px !important;
   background: #fff;
   border: 1px solid #fff;
   color: #21b3c6;
   font-size: 16px;
   /* font-family: Proxima Nova; */
   font-style: normal;
   font-weight: 400;
}

dx-button.tertiaryBtn input {
   display: none;
}

dx-button.tertiaryBtn.grayButton,
dx-button.tertiaryBtn.grayButton .dx-button-content {
   background: #fff !important;
   color: #767676;
   border: 1px solid #767676;
   box-shadow: unset !important;
    font-weight: 400;
    height: 41px;
}

dx-button.tertiaryBtn.grayButton:hover {
   background: #fff;
   cursor: none !important;
}

.dx-button.secondaryBtn.dx-button-mode-contained .dx-icon{
    color: unset;
}
.dx-button.secondaryBtn input {
   display: none;
}
.dx-button.secondaryBtn .dx-icon-docfile {
    font-size: 22px;
}

.dx-button.secondaryBtn,
.dx-button.secondaryBtn:hover {
   border-radius: 0px !important;
   border: none !important;
   height: 42px;
}
.dx-button.secondaryBtn:hover,
.dx-button.secondaryBtn:hover .dx-button-content,
.dx-button.secondaryBtn.dx-state-hover:hover {
   color: #21b3c6;
   background: #E8F7F9 !important;
   border-radius: 0px !important;
}
.dx-button.secondaryBtn .dx-button-content {
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: nowrap;
   padding: 7px 16px !important;
   background: #fff;
   border: 1px solid #21b3c6;
   color: #21b3c6;
   font-size: 16px;
   /* font-family: Proxima Nova; */
   font-style: normal;
   font-weight: 400;
    /* height: 46px !important;
    max-height: 46px !important;
    line-height: 0; */
}
dx-button.plusebtntop{
    position: relative;
    top: -5px;
}

dx-button.primaryBtn,
dx-button.primaryBtn:hover {
    border-radius: 0px !important;
    border: none !important;
    height: 41px;
}
dx-button.primaryBtn:hover,
dx-button.primaryBtn:hover .dx-button-content,
dx-button.primaryBtn.dx-state-hover:hover {
    color: #fff;
    background: #1EA1B2 !important;
    border-radius: 0px !important;
}
dx-button.primaryBtn .dx-button-content {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 10px 12px !important;
    background: #21b3c6;
    border: 1px solid #21b3c6;
    color: #fff;
    font-size: 16px;
    /* font-family: Proxima Nova; */
    font-style: normal;
    font-weight: 400;
}
dx-button.primaryBtn input {
    display: none;
}

dx-button.primaryBtn.grayButton:hover {
    background: #fff;
}

dx-button.secondaryBtn.grayButton .dx-icon-save::before {
   content: "\e987" !important;
   color: #767676;
   font-family: 'IXPORTAL';
}
dx-button.secondaryBtn.grayButton:hover {
    background: #fff;
}
dx-button.addNewBtn.secondaryBtn{
    height: 33px;
    /* top: 9px; */
}
dx-button.addNewBtn.secondaryBtn .dx-button-content{
    padding: 7px 8px !important;
}

.zIndexDxLoadPanel {
   z-index: 999999 !important;
}

.dx-icon-xlswhite:before {
   content: "\e938";
   width: 26px;
   height: 17px;
   display: inline-block;
   position: relative;
   top: 5px;
}


.zIndexDxLoadPanel {
   z-index: 999999 !important;
}

.data-calendar-gray,
.data-calendar-gray.dx-state-focused {
   background-color: transparent;
   border: unset;
}

.data-calendar-gray i:before {
   content: "\e9ae" !important;
   color: #565656;
}

.data-calendar-blue,
.data-calendar-blue.dx-state-focused {
   background-color: transparent;
   border: unset;
}
.data-calendar-blue i:before {
   content: "\e9ae" !important;
   color: #706cce;
}

.data-calendar-grayborder i:before {
   content: "\e9ae" !important;
   color: #565656;
}
.data-calendar-blueborder i:before {
   content: "\e9ae" !important;
   color: #706cce;
}

.dx-popup-normal .dx-toolbar.dx-popup-title .dx-toolbar-items-container .dx-item-content.dx-toolbar-item-content div {
   font-size: 16px;
   color: #767676;
}
.dx-icon-import:before {
   content: "\e938";
   color: #21b3c6;
}
dx-data-grid .icon-download::before{
   font-size: 19px; 
}
dx-data-grid .icon-edit_payment::before{
   font-size: 18px; 
}

.primaryBtn .dx-icon-add:before {
   color: #ffffff;
}
.dx-item.dx-list-item.dx-state-focused {
   color: #333 !important;
   font-weight: bold;
}
#gridContainer #gridContainer .dx-datagrid.dx-gridbase-container.dx-datagrid-borders,
#tiersItemDetailssDataGridRef .dx-datagrid.dx-gridbase-container.dx-datagrid-borders,
#customersInvoiceDataGrid .dx-datagrid.dx-gridbase-container.dx-datagrid-borders,
#tiersItemDetailssDataGridRef_10000-1170368953 .dx-datagrid.dx-gridbase-container.dx-datagrid-borders,
#tiersItemDetailssDataGridRef_80000317-1482873093 .dx-datagrid.dx-gridbase-container.dx-datagrid-borders,
#tiersItemDetailssDataGridRef_80000316-1482873093 .dx-datagrid.dx-gridbase-container.dx-datagrid-borders,
#invoicesDataGrid .dx-datagrid.dx-gridbase-container.dx-datagrid-borders {
   padding: 20px;
}
#gridContainer #gridContainer2 .dx-datagrid-content table.dx-datagrid-table.dx-datagrid-table-fixed {
   border: none;
}

.tableheight table tr td.dx-editor-cell {
   padding: 0 !important;
}
.dx-field-value dx-select-box.dx-state-focused .dx-texteditor-buttons-container .dx-dropdowneditor-button {
   outline: unset !important;
   border-radius: 0px;
}
.dx-field-value dx-select-box.dx-state-focused {
   border: 1px solid #21b3c6 !important;
}
.dx-field-value dx-text-box.dx-state-focused {
   border: 1px solid #21b3c6 !important;
}
.dx-checkbox-checked .dx-checkbox-icon::before {
   color: #706cce;
   font-size: 15px !important;
}

.dx-datebox-calendar .dx-dropdowneditor-icon::before {
   margin-left: -16px !important;
}

#applicationconfiguration .dx-button.secondaryBtn .dx-button-content {
   line-height: 23px;
}
dx-data-grid .icon-dollar::before{
   font-size: 17px; 
}
dx-data-grid .icon-paid::before{
   font-size: 15px; 
}
dx-data-grid .icon-bill_pay::before{
   font-size: 17px; 
   color: #706cce;
}
.dx-lablenone .dx-field-label {
    display: none;
}
.dx-popup-title .dx-toolbar-items-container .dx-toolbar-before .dx-toolbar-label .dx-item-content.dx-toolbar-item-content {
   width: max-content;
}
.dx-popup-title .dx-toolbar-items-container .dx-toolbar-before .dx-toolbar-label .dx-item-content.dx-toolbar-item-content div {
   font-weight: bold;
   color: #222222;
   font-size: 18px;
   font-family: proxima_nova semiBold;
}
.scrollViewPopupClassIx .dx-scrollable-wrapper {
   padding: 0 10px;
}
.dx-overlay-wrapper.dx-loadpanel-wrapper.dx-overlay-modal.dx-overlay-shader {
   z-index: 111111 !important;
}

.dx-popup-title .dx-toolbar-items-container .dx-toolbar-before .dx-toolbar-label .dx-item-content.dx-toolbar-item-content {
   width: max-content;
}

.scrollViewPopupClassIx .dx-scrollable-wrapper {
   padding: 0 10px;
}

.dx-overlay-wrapper.dx-loadpanel-wrapper.dx-overlay-modal.dx-overlay-shader {
   z-index: 111111 !important;
}

.dx-lableset .dx-field-label {
   display: contents;
}

.dx-lablenone .dx-field-label {
   display: none;
}

dx-button.primaryBtn .dx-icon-fieldchooser::before {
   color: #ffffff;
}
dx-button.secondaryBtn .dx-icon-close::before {
   color: #54c4d3;
}
.actionBtnIx .dx-button-content i.dx-icon-spindown::before {
   content: "\e900" !important;
   color: #706cce;
   font-size: 4px;
   position: relative;
   top: -4px;
   font-family: 'IXPortal' !important;
}

.actionBtnIx .dx-button-content .dx-button-text {
   padding-right: 15px;
}

.actionBtnIx .dx-button-content {
   padding: 10px 11px !important;
}
.dx-treelist-content .dx-treelist-table .dx-row > td, .dx-treelist-content .dx-treelist-table .dx-row > tr > td {
    vertical-align: middle !important;
}

/* Add New Style */
.dx-dropdownbutton-content .dx-item.dx-list-item.dx-state-focused,
.dx-dropdownbutton-content .dx-item.dx-list-item.dx-state-active {
   font-weight: normal !important;
   background-color: unset !important;
   color: unset !important;
}
.dynamicBgColors.dynamic_0374ff .dx-state-hover .dx-button-content {
   background-color: #E8F7F9 !important;
}
.dynamicBgColors.dynamic_30b7d1 .dx-state-hover .dx-button-content {
   background-color: #eaf8fa !important;
}
.dynamicBgColors.dynamic_706cce .dx-state-hover .dx-button-content {
   background-color: #f0f0fa !important;
}
.dynamicBgColors.dynamic_e37655 .dx-state-hover .dx-button-content {
   background-color: #fcf1ee !important;
}
.dynamicBgColors.dynamic_03b86c .dx-state-hover .dx-button-content {
   background-color: #e5f8f0 !important;
}
.dynamicBgColors.dynamic_ff752a .dx-state-hover .dx-button-content {
   background-color: #fff1e9 !important;
}
.dynamicBgColors.dynamic_05c1a0 .dx-state-hover .dx-button-content {
   background-color: #e6f9f5 !important;
}
.dynamicBgColors.dynamic_f278ab .dx-state-hover .dx-button-content {
   background-color: #fef1f6 !important;
}
.dynamicBgColors.dynamic_f2b80c .dx-state-hover .dx-button-content {
   background-color: #fef8e6 !important;
}

/* ----  */

.dynamicBgColors.dynamic_21b3c6 .dx-state-hover .dx-button-content {
   background-color: #e2f3f5 !important;
}
.dynamicBgColors.dynamic_77ce44 .dx-state-hover .dx-button-content {
   background-color: #F1FAEC !important;
}
.dynamicBgColors.dynamic_21b3c6 .dx-button-content {
   background: #e2f3f5;
   border: 1px solid #21b3c6;
   color: #21b3c6;
}
.dynamicBgColors.dynamic_77ce44 .dx-button-content {
   background-color: #F1FAEC;
   border: 1px solid rgb(119, 206, 68);
   color: rgb(119, 206, 68);
}
.dynamicBgColors.dynamic_0374ff .dx-button-content {
   background-color: #E8F7F9;
   color: rgb(33, 179, 198);
   border: 1px solid rgb(33, 179, 198);
}
.dynamicBgColors.dynamic_30b7d1 .dx-button-content {
   background-color: #eaf8fa;
   color: #30b7d1;
   border: 1px solid #30b7d1;
}
.dynamicBgColors.dynamic_706cce .dx-button-content {
   background-color: #f0f0fa;
   color: #706cce;
   border: 1px solid #706cce;
}
.dynamicBgColors.dynamic_e37655 .dx-button-content {
   background-color: #fcf1ee;
   color: #e37655;
   border: 1px solid #e37655;
}
.dynamicBgColors.dynamic_03b86c .dx-button-content {
   background-color: #e5f8f0;
   color: #03b86c;
   border: 1px solid #03b86c;
}
.dynamicBgColors.dynamic_ff752a .dx-button-content {
   background-color: #fff1e9;
   color: #ff752a;
   border: 1px solid #ff752a;
}
.dynamicBgColors.dynamic_05c1a0 .dx-button-content {
   background-color: #e6f9f5;
   color: #05c1a0;
   border: 1px solid #05c1a0;
}
.dynamicBgColors.dynamic_f278ab .dx-button-content {
   background-color: #fef1f6;
   color: #f278ab;
   border: 1px solid #f278ab;
}
.dynamicBgColors.dynamic_21b3c6 .dx-button-content i:before {
   color: #21b3c6;
}
.dynamicBgColors.dynamic_77ce44 .dx-button-content i:before {
   color: rgb(119, 206, 68);
}
.dynamicBgColors.dynamic_0374ff .dx-button-content i:before {
   color: rgb(33, 179, 198);
}
.dynamicBgColors.dynamic_30b7d1 .dx-button-content i:before {
   color: #30b7d1;
}
.dynamicBgColors.dynamic_706cce .dx-button-content i:before {
   color: #706cce;
}
.dynamicBgColors.dynamic_e37655 .dx-button-content i:before {
   color: #e37655;
}
.dynamicBgColors.dynamic_03b86c .dx-button-content i:before {
   color: #03b86c;
}
.dynamicBgColors.dynamic_ff752a .dx-button-content i:before {
   color: #ff752a;
}
.dynamicBgColors.dynamic_05c1a0 .dx-button-content i:before {
   color: #05c1a0;
}
.dynamicBgColors.dynamic_f278ab .dx-button-content i:before {
   color: #f278ab;
}
.dynamicBgColors.dynamic_f2b80c .dx-button-content {
   background-color: #fef8e6;
   color: #f2b80c;
   border: 1px solid #f2b80c;
}
.dynamicBgColors.dynamic_f2b80c .dx-button-content i:before {
   color: #f2b80c;
}
.dynamicarow .dx-icon.dx-icon-spindown:before {
   content: "\e997";
   font-family: 'IXPORTAL';
   font-size: 9px;
   position: relative;
   top: -4px;
   left: 2px
}
.actionBtnBorderNone.actionBtnIx .dx-item.dx-state-hover .dx-button-content {
   background-color: #fff !important;
}
.actionBtnBorderNone.actionBtnIx .dx-icon-spindown {
   transform: rotate(90deg);
}
.actionBtnBorderNone.actionBtnIx {
   background-color: transparent;
}
.actionBtnBorderNone.actionBtnIx .dx-button-content {
   background-color: transparent !important;
   padding: 7px !important;
   border: none;
   display:inline-block;
}
.actionBtnBorderNone.actionBtnIx .dx-button-content i.dx-icon-spindown::before {
   top: -5.6px;
}
.actionBtnBorderNone.actionBtnIx .dx-item {
   border-radius: 50%;
}
.actionBtnBorderNone.actionBtnIx .dx-buttongroup-item.dx-button.dx-button-mode-contained:not(.dx-item-selected) {
   background-color: #f9f9f900 !important;
   border: none;
}
.actionBtnBorderNone.actionBtnIx .dx-button-mode-contained.dx-buttongroup-first-item.dx-button {
   border-radius: 50%;
}
.ix-textleftalign .dx-button-content .dx-button-text {
   float: left;
}
.ix-textleftalign .dx-button-content i.dx-icon {
   float: right;
}
.dx-popup-content .dx-item.dx-list-item.dx-state-focused {
    font-weight: normal !important;
}


.dx-texteditor.dx-state-focused.dx-editor-outlined {
    border-color:#706cce !important;
}

.dx-switch-on-value .dx-switch-handle::before {
    background-color: #706cce !important;
}

#userprofile .borderBox.addCustomer .userProfileEdit .custIcon{
  float:left;
  margin:0;
} 

.dx-button .dx-icon-xlsxfile:before {
  content: "\e938" !important;
  width: 19px;
  height: 19px;
  display: inline-block;
  font-family: 'IXPortal';
}

.dx-icon-exportpdf:before{
   content: "\e970" !important;
   font-family: 'IXPortal';
}
.dx-icon-exportxlsx:before{
   content: "\e938" !important;
   font-family: 'IXPortal';
}
.dx-popup-content .dx-item.dx-list-item.dx-state-focused {
    font-weight: normal !important;
}

.ix-searchbox .dx-placeholder{
    padding-left: 20px
}
.ix-searchbox .dx-icon-search{
    padding-left: 10px
}
.ix-searchbox .dx-texteditor-input{
    border:none;
}

.dx-icon-addaction:before {
    content: "\e975" !important;
    color: #21b3c6;
    font-size: 19px;
    font-family: 'IXPortal' !important;
    position: relative;
    top: 1px;
}
dx-select-box.dx-selectbox .dx-selectbox-container .dx-texteditor-input,
dx-date-box.dx-datebox .dx-texteditor-input,
dx-text-area.dx-textarea .dx-texteditor-input{
    border:none !important;
}

.actionTextWithIconBtnIx .dx-icon-spindown:before {
    content: "\e900";
    color: #21b3c6;
    font-size: 5px;
    position: relative;
    top: px;
    font-family: 'IXPortal';
}
.actionTextWithIconBtnIx .dx-button-content .dx-button-text {
     padding-right: 7px;
     line-height:normal;
}
dx-drop-down-button.secondaryBtn .dx-item.dx-buttongroup-item{
    border: none;
    border-radius: 0;
}

.dx-button.dx-button-bg-white .dx-button-content{
    background: #fff !important;
    color: #333 !important;
}
.dx-button.dx-button-bg-white.dx-state-hover .dx-button-content {
    background-color: #E8F7F9 !important;
}
.ix-InputHeightSet tr td .dx-texteditor-input-container input.dx-texteditor-input {
    min-height: 52px;
}

.ix-DropdownHeightSet .dx-datagrid-content tr.dx-row.dx-data-row.dx-column-lines{
    height: 52px;
}

.ix-EditTableHeightSet .dx-datagrid-rowsview .dx-editor-cell .dx-texteditor .dx-texteditor-input {
   height: auto !important;
   min-height: 53px !important;
}
.ix-EditTableHeightSet .dx-datagrid-table .dx-row td {
   height: 54px;
}


/*********Add New*************//*********dx-tree-view*********/

.mainContentIncentxConfig{
    border: 1px solid #dfdfdf;
    height: auto;
    overflow: auto;
 }
 .mainContentIncentxConfig .dx-treeview-item-without-checkbox.dx-state-focused > .dx-treeview-item {
   border-left: 3px solid #6e6ab2 !important;
   background: #eaeaf4 !important;
   color: #000 !important;
}
.mainContentIncentxConfig .dx-treeview-item-without-checkbox.dx-state-selected > .dx-treeview-item {
   border-left: 3px solid #6e6ab2 !important;
   background: #eaeaf4 !important;
   color: #000 !important;
}
.mainContentIncentxConfig .dx-treeview-item-without-checkbox.dx-state-focused > .dx-treeview-item:after {
   right: -1px;
   top: 50%;
   border: solid transparent;
   content: " ";
   height: 0;
   width: 0;
   position: absolute;
   pointer-events: none;
   border-color: rgba(255, 255, 255, 0);
   border-right-color: #ffffff;
   border-width: 10px;
   margin-top: -10px;
}
.mainContentIncentxConfig .dx-treeview-item-without-checkbox.dx-state-selected > .dx-treeview-item:after {
   right: -1px;
   top: 50%;
   border: solid transparent;
   content: " ";
   height: 0;
   width: 0;
   position: absolute;
   pointer-events: none;
   border-color: rgba(255, 255, 255, 0);
   border-right-color: #ffffff;
   border-width: 10px;
   margin-top: -10px;
}
.mainContentIncentxConfig .dx-treeview-node {
        padding-left: 0px;
        box-shadow: 0 0 1px 0 #ccc;
}
.mainContentIncentxConfig ul .dx-item.dx-treeview-item{
   color: #565656;
   font-size: 15px;
   padding: 10px 20px;
   display: block;
   border-left: 3px solid transparent;
   
   position: relative;
}

.mainContentIncentxConfig.appSettingTabs .appTabFirst .dx-treeview .dx-treeview-toggle-item-visibility-opened::before {
    font-family: DXIcons;
    position: absolute;
    right: 10px;
    content: "\e998" !important;
}
.mainContentIncentxConfig.appSettingTabs .appTabFirst .dx-treeview .dx-treeview-toggle-item-visibility {
    width: 100%;
    float: left;
    height: 45px;
}
.mainContentIncentxConfig.appSettingTabs .appTabFirst .dx-treeview .dx-treeview-toggle-item-visibility::before {
    content: "\e997";
    float: right;
    left: 90%;
    top: 22px;
    font-size: 8px;
    font-family: 'IXPORTAL';
}
.dx-switch-handle::before {
    background-color: #ddd !important;
 }
.inputDisable .dx-texteditor-container, .inputDisable .dx-dropdowneditor-button {
    border-radius: 0px;
}
dx-data-grid td .icon-verification:before {
    margin-left: 5px;
}
.dx-icon-convertorder:before {
   content: "\e99c" !important;
   font-family: 'IXPORTAL';
}

.dx-invalid.dx-texteditor.dx-editor-filled.dx-show-invalid-badge .dx-texteditor-input-container::after,
.dx-invalid.dx-texteditor.dx-editor-outlined.dx-show-invalid-badge .dx-texteditor-input-container::after,
.dx-invalid.dx-texteditor.dx-editor-underlined.dx-show-invalid-badge .dx-texteditor-input-container::after {
   font-size: 16px !important;
   content: "\e9a4" !important;
   font-family: 'IXPortal';
   background: none !important;
   color: #FF0000 !important;
   font-weight: normal !important;
}
.dynamicBgColors.dynamic_f99300 .dx-state-hover .dx-button-content {
   background-color: #fef8e6 !important;
}
.dynamicBgColors.dynamic_f99300 .dx-button-content {
   background-color: #fef8e6;
   color: #f2b80c;
   border: 1px solid #f99300;
}
.dynamicBgColors.dynamic_f99300 .dx-button-content i:before {
   color: #f2b80c;
}
.editBillToShipToPopup .dx-item.dx-list-item.dx-state-active {
   background-color: unset !important;
}

.editBillToShipToPopup .dx-item.dx-list-item p {
    margin-bottom: 0;
}

/*new css*/
.dx-toolbar-button .dx-button .dx-icon-edit:before {
   content: "\e932" !important;
   width: 19px;
   height: 19px;
   display: inline-block;
   font-family: 'IXPortal';
   font-size: 16px;
}
#crmCustomerDetail .dx-datagrid-content table.dx-datagrid-table .dx-datagrid-summary-item {
   color: #565656;
   font-weight: normal;
}
dx-button.secondaryBtn.grayButton .dx-icon-save{
   opacity:9;
}
.dx-switch.dx-state-hover .dx-switch-container {
   border-color: #706cce !important;
}

.dx-icon-importwhite:before {
   content: "\e938";
   color: #fff;
}

span.dynamicBgColors {
   display: inline-block;
   width: 100%;
   text-align: left;
   padding: 1px 30px 0 15px;
   line-height: 30px;
   position: relative;
   text-transform: uppercase;
   cursor: pointer;
   height: 30px;
   white-space: nowrap;
}
span.dynamicBgColors.dynamic_03b86c{
   background-color: #e5f8f0;
   color: #03b86c;
   border: 1px solid #03b86c;
}
span.dynamicBgColors.dynamic_03b86c::after {
   position: absolute;
   width: 30px;
   height: 30px;
   right: 0;
   top: 0;
   content: "\e997";
   color: #03b86c;
   font-family: 'IXPORTAL';
   font-size: 9px;
}
span.dynamicBgColors.dynamic_f2b80c{
   background-color: #fef8e6;
   color: #f2b80c;
   border: 1px solid #f2b80c;
}
span.dynamicBgColors.dynamic_f2b80c::after {
   position: absolute;
   width: 30px;
   height: 30px;
   right: 0;
   top: 0;
   content: "\e997";
   color: #f2b80c;
   font-family: 'IXPORTAL';
   font-size: 9px;
}
.dxgridcellMode .dx-datagrid-revert-tooltip .dx-revert-button{
   display: none;
}

.tdvalidationMessage .dx-datagrid-rowsview, .tdvalidationMessage .dx-cell-focus-disabled, .tdvalidationMessage .dx-scrollable-container{
   overflow:unset !important;
}

.dx-border-bottom-add .dx-datagrid-headers +.dx-datagrid-rowsview {
   border-bottom: 1px solid #ddd;
}
.editBillToShipToPopup .dx-scrollable-wrapper{
   padding:0;
}

.editBillToShipToPopup .dx-list-item-before-bag .dx-list-select-radiobutton {
    margin-left: 0px;
}

.dx-calendar-navigator-previous-month.dx-button .dx-icon, .dx-calendar-navigator-previous-view.dx-button .dx-icon, .dx-calendar-navigator-next-month.dx-button .dx-icon, .dx-calendar-navigator-next-view.dx-button .dx-icon{
    color: #706cce !important;
}

.dynamicBgColors.secondaryBtn.dynamicarow .dx-button-content{
   display:inline-block;
}

.dynamicBgColors.secondaryBtn.dynamicarow .dx-button-content .dx-button-text{
   line-height:normal;
}

.dynamicBgColors.secondaryBtn.dynamicarow .dx-buttongroup{
   vertical-align: middle;
}

.dx-icon-key:before { content: "\e93d" !important; font-family: 'IXPortal'; font-size: 21px; }
.dx-scrollable-content{
    padding-bottom: 0;
}
.dx-box-light {
    background: #f3f3f3;
    font-size: 18px !important;
    padding: 10px;
}
.dx-box-dark {
    background: #ddd7d7;
}
.dx-dropdownbutton-popup-wrapper .dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item-content {
   padding: 9px 9px !important;
}
.datePicker dx-date-box {
   height: 42px;
}
dx-button .dx-button-text {
   align-items: center;
   display: inherit;
   line-height: normal;
}
dx-button .dx-button-content {
   display: flex;
}
dx-number-box .dx-texteditor-container .dx-texteditor-input-container input{
   border:none !important;
} 

.itemClaimDetailsPopupWrapper.dx-popup-wrapper .dx-toolbar-items-container .dx-toolbar-item.dx-toolbar-button .dx-button-normal .dx-button-content {
   background: #ffffff !important;
   color: #565656 !important;
}


.dx-scrollable-scrollbars-alwaysvisible.dx-scrollable-both>.dx-scrollable-wrapper>.dx-scrollable-container>.dx-scrollable-content, .dx-scrollable-scrollbars-alwaysvisible.dx-scrollable-horizontal>.dx-scrollable-wrapper>.dx-scrollable-container>.dx-scrollable-content{
       padding-bottom: 0px !important;
}

.icon-view.icon-view_action-col::before{
    right: 3px !important;
}
.ixShowScrollbarTagBox .dx-texteditor-container {
   overflow-y: auto;
   max-height: 50px;
}

.dxDatagridHeightPopup {
   height: calc(80vh - 240px) !important;
}

.customAlertText {
   font-size: 16px !important;
   margin-bottom: 0 !important;
}

.customAlertButton .dx-button-content {
   background: #21b3c6 !important;
   border: 0 !important;
   font-size: 18px !important;
   font-family: 'proxima_nova semiBold' !important;
   padding: 9px 16px !important;
   line-height: 24px !important;
}

.customAlertButton {
   border-radius: 0 !important;
   min-width: max-content !important;
}

.customPrimaryBtn .dx-button-content {
   background: #21b3c6 !important;
   color: #fff !important;
   font-size: 16px !important;
   padding-top: 11px !important;
   padding-bottom: 11px !important;
}

.customPrimaryBtn .dx-button-content:hover {
   background: #1EA1B2 !important;
   border-radius: 0 !important;
}

.customSecondaryBtn .dx-button-content {
   background: #fff !important;
   color: #21b3c6 !important;
   font-size: 16px !important;
   padding-top: 11px !important;
   padding-bottom: 11px !important;
   border: 1px solid #21b3c6 !important;
}

.customSecondaryBtn .dx-button-content:hover {
   background: #E8F7F9 !important;
}


/* notification pop style */
.notificationPopup dx-button.secondaryBtn{
    width: 47%;
    float:left;
    margin-right:3%;
}
.notificationPopup dx-button.primaryBtn{
    width: 47%;
    float:right;
    margin-left:3%;
}
.notificationPopup dx-button .dx-button-text{
    line-height:normal;
}

.notificationPopupWrapper {
    background-color: rgb(0 0 0 / 80%) !important;
}
.notificationPopupIcon {
   position: absolute;
   top: -62px;
   background-color: #fff;
   padding: 10px;
   border-radius: 50%;
   right: 29px;
   color: #767676;
}
.notificationPopupIcon .notiCount {
   width: 8px;
   height: 8px;
   top: 12px;
   right: 12px;
}
.notificationPopupWrapper .dx-overlay-content{
    overflow:inherit !important;
}

/*End notification pop style */

.button-loader-indicator-blue .dx-loadindicator-icon .dx-loadindicator-segment {
   background: #21b3c6 !important;
}

.dx-calendar-cell {
   border-bottom: 0 !important;
}

.dx-calendar-cell.dx-calendar-contoured-date span {
   box-shadow: none !important;
}

.dx-calendar-cell.dx-calendar-selected-date span {
   background-color: #706cce !important;
}

.dx-calendar-cell span {
   width: 100% !important;
   height: 30px !important;
}

.dx-calendar-navigator .dx-button .dx-button-text {
   text-transform: uppercase !important;
   color: #706cce !important;
}

.dx-calendar-body thead th {
   text-transform: uppercase !important;
}

.dx-calendar-body thead th {
   font-size: 11px !important;
   text-align: center !important;
}

.dx-calendar-cell.dx-calendar-today span {
   color: #706cce !important;
   border: 1px solid #706cce !important;
}

.dx-radiobutton-icon-checked .dx-radiobutton-icon-dot {
   display: block;
   margin-top: -14px !important;
   margin-left: 7px !important;
   width: 7px !important;
   height: 7px !important;
   background: #706cce !important;
   content: "";
   border: unset !important;
   border-radius: 50% !important;
}

.dx-radiobutton-icon {
   border: unset !important;
}

/* secondary button in dx-pop-up css */

.dx-popup-wrapper .dx-toolbar-items-container .dx-toolbar-item.dx-toolbar-button .dx-button-normal.secondaryBtn .dx-button-content {
   background: #fff !important;
   color: #21b3c6 !important;
}

.dx-popup-wrapper .dx-toolbar-items-container .dx-toolbar-item.dx-toolbar-button .dx-button-normal.secondaryBtn.dx-state-hover .dx-button-content {
   background: #E8F7F9 !important;
}

.dx-field.remarksinputDisable textarea {
   color: #565656 !important;
   padding: 13px;
}

.remarksinputDisable {
   border: 1px solid #706cce !important;
}

.popupZIndex {
   z-index: 1502 !important;
}

.dx-popup-content .dx-icon-filter:before {
   content: "\e93a" !important;
   width: 19px;
   height: 19px;
   display: inline-block;
}

.dx-icon-cleariconix:before {
   content: "\e9ba" !important;
   width: 18px;
   height: 18px;
   display: inline-block;
   font-size: 16px;
   font-family: 'IXPortal';
}

.dx-popup-content.dx-dropdownbutton-content .dx-icon-cleariconix::before {
   position: relative;
   top: -2px;
}

.dx-popup-content.dx-dropdownbutton-content .dx-icon:before {
   color: #706cce;
   vertical-align: top;
}

.actionBtnToolbarIx .dx-button-content {
   padding: 7.2px 11px !important;
}


.dx-datagrid-header-panel .dx-toolbar .dx-button {
   border-radius: 0px !important;
}

.dx-icon-columnChooser:before {
   content: "\e9b8" !important;
   width: 16px;
   height: 16px;
   display: inline-block;
   font-family: 'IXPortal';
   font-size: 12px;
   vertical-align: top;
}

.dx-popup-content.dx-dropdownbutton-content .dx-icon-xlsxfilepopupix:before {
   content: "\e938" !important;
   font-size: 15px;
   font-family: 'IXPortal';
   vertical-align: top;
}

.toolbarbtnix .dx-button-content,
.toolbarbtnix {
   height: 34.01px !important;
}

.customerDetail.custdtlTop .customerdetailsettingiconix:hover,
.customerDetail.custdtlTop .customerdetailsettingiconix .dx-buttongroup-item.dx-button.dx-button-mode-outlined.dx-state-hover .dx-button-content,
.customerdetailsettingiconix .dx-buttongroup-item.dx-button.dx-button-mode-outlined.dx-state-hover {
   background-color: transparent !important;
}

dx-button.secondaryBtn.grayButton i::before{
    color:#767676 !important;
}

dx-button.secondaryBtn .dx-icon-sendix::before {
    content: "\e9bb" !important;
    color: #54c4d3;
    font-family: 'IXPortal' !important;
}

.dx-datagrid-search-text {
   background-color: #706cce !important;
}
.dx-calendar-cell.dx-calendar-empty-cell{
    background:none !important;
}

.dx-calendar-cell.dx-calendar-today span {
    border: 1px solid #706cce!important;
}
.dx-calendar-cell.dx-calendar-selected-date span {
    background-color: #706cce!important;
    color: #fff !important;
}
.dx-calendar-navigator .dx-button .dx-button-text {
    text-transform: uppercase !important;
    color: #706cce !important;
    font-family: 'proxima_nova';
    font-weight: 400 !important;
}

/* close multiple settlment css */
.dx-overlay-shader-disable {
    background: transparent !important; 
    pointer-events: none !important;
}
.dx-icon-closeMultipleSettlement:before {
    content: "\e9bc";
    width: 16px;
    height: 16px;
    display: inline-block;
    font-family: 'IXPortal';
    font-size: 13px;
    vertical-align: top;
}
/*End close multiple settlment css */

#fileManager .dx-filemanager-toolbar .dx-item-content .dx-icon-upload::before {
   color: #333 !important;
}
.popupToolbarBottomPaddingRemoveIx .dx-toolbar.dx-popup-bottom {
    padding-top: 0px !important;
    padding-bottom: 10px !important;
}
.dx-popup-wrapper.dx-overlay-shader {
    background-color: #222c !important;
}

.dx-overlay-wrapper{
    font-family: "proxima_nova" !important;
}
.dx-datagrid-focus-overlay {
   border: unset !important
}
.dx-tooltip-wrapper .dx-overlay-content .dx-popup-content{
    white-space:normal !important;
}

.customPrimaryBtn,
.customSecondaryBtn {
   min-width: 145px !important;
   border-radius: 0 !important;
}

/* ADD DX-style.css */

.fileDetailSystemConfiguration h4 {
   font-size: 16px;
   font-weight: 600;
   margin: 0;
   padding: 0;
   font-family: proxima_nova semiBold;
   width: 100%;
}

.fileDetailSystemConfiguration .fileProgText {
   color: #7e7e7e;
   font-size: 11px;
   font-family: 'proxima_nova';
   text-transform: uppercase;
}

.fileDetailSystemConfiguration .dx-progressbar-status {
   font-size: 12px;
   font-family: 'proxima_nova';
   color: #767676;
   padding-top: 4px;
}

.fileDetailSystemConfiguration .uploadSystemConfiguration {
   font-size: 12px;
   font-family: 'proxima_nova';
   color: #767676;
}

.fileDetailSystemConfiguration h4 .icon-close {
   padding-left: 20px;
   position: relative;
   top: 3px;
}

.fileDetailSystemConfiguration h4 .icon-close:before {
   color: #767676;
}

.fileDetailSystemConfiguration .dxFileProgressBar {
   padding-top: 3px;
}

.dx-progressbar-range {
   border: 1px solid #706CCE !important;
   background-color: #706CCE !important;
}

.dx-progressbar-container {
   border: 1px solid #E2E2F5 !important;
   background-color: #E2E2F5 !important;
   border-radius: 0px;
}
dx-list .dx-empty-message {
    text-align: center !important;
    border-top: unset !important;
}

.dx-toolbarmrgBix .dx-toolbar .dx-toolbar-items-container {
    display: inline-block !important;
}

dx-slider .dx-slider-bar {
    margin: 14px 13px !important; 
}


/* claim preview pop-up css */
.dx-scrollable-content-padR0 .dx-scrollable-content{
   padding-right: 0px !important;
}
.pageNavigationpadB10 .dx-cell-focus-disabled.dx-master-detail-cell{
   padding-bottom:9px !important;
}
.dx-popup-content-pad0 .dx-popup-content{
   padding: 0 !important;
}
 
.popup-dx-overlay-content .dx-overlay-content{
   border-left: 2px solid #EDEDEE;
   background-color: #fff;
}
/* End claim preview pop-up css */

 .dx-datagrid .dx-datagrid-header-panel dx-date-box input[type='text']{
   font-size: 14px;
   height: 34px !important;
}

.dx-icon-exportpdfwhiteicon:before {
    content: "\e92f" !important;
    font-family: 'IXPortal';
    color: #fff !important;
}
.dx-daterangebox .dx-daterangebox-separator .dx-icon-to:before {
   content: "" !important;
   border-right: 1px solid #ddd;
   display: inline-block;
   height: 24px;
   position: relative;
   top: -3px;
}

.dx-daterangebox .dx-texteditor.dx-state-hover::before, .dx-daterangebox .dx-texteditor.dx-state-focused::before {
   border-bottom-color: transparent !important;
}

.dx-daterangebox.dx-editor-outlined .dx-label span {
   padding: 3px 5px !important;
   font-size: 14px !important;
   background: #fff;
   top: -2px;
   color: #999999;
}

.dx-daterangebox{
   max-width: 606px;
}

.dx-icon-viewDetails:before {
   content: "\e93e" !important;
   width: 16px;
   height: 16px;
   display: inline-block;
   font-family: 'IXPortal';
   font-size: 15px;
}

.dx-datagrid-summary-item {
   color: unset !important;
   font-family: 'proxima_nova semiBold' !important;
   font-weight: unset !important;
}  

.inputAddHeightSet .dx-datagrid-rowsview .dx-edit-row .dx-editor-cell .dx-texteditor-input-container .dx-texteditor-input{
  height: 52px;
}

.inputAddHeightSet .dx-datagrid-rowsview .dx-datagrid-focus-overlay{
   border-right: 2px solid #706cce !important;
}

.dx-datagrid-total-footer>.dx-datagrid-content {
   padding-top: 0 !important;
   padding-bottom: 0 !important;
}

.dx-button {
   border-radius: 0 !important;
}

.dx-toolbar-button .dx-button .dx-icon-columnChooser:before {
   width: 19px;
   height: 19px;
   line-height: 20px;
   font-size: 13px;
}
.dx-tabpanel-tabs-position-top.dx-state-focused .dx-tabs-wrapper{
   border-block-end: 1px solid #ddd !important;
}

.dx-tabpanel.dx-state-focused .dx-multiview-wrapper.dx-state-focused, .dx-tabpanel.dx-state-focused .dx-tabpanel-tab.dx-state-focused::after{
   border-color: #ddd !important
}



.dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-state-active {
   background-color: #706cce !important;
   color: #fff !important;
}

.dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-state-active .dx-icon:before{
   color: #fff !important;
}

.dx-texteditor.dx-state-hover {
    border-color: #B7B5E6 !important;
}

/*new add css*/
.dx-datagrid-headers .dx-datagrid-table .dx-row.dx-header-row>td {
   border-bottom: 2px solid #ddd !important;
}

.dx-datagrid-headers .dx-datagrid-filter-row td.dx-editor-cell {
   border-top: 1px solid transparent !important;
   border-bottom: 1px solid transparent !important;
}

.dx-datagrid-headers .dx-datagrid-filter-row td.dx-editor-cell .dx-editor-with-menu {
   border-bottom: 1px solid transparent !important;
}

.dx-datagrid-headers .dx-datagrid-filter-row td.dx-editor-cell:hover {
   border: 1px solid #B7B5E6 !important;
   border-bottom: 1px solid transparent !important;
}

.dx-datagrid-headers .dx-datagrid-filter-row td.dx-editor-cell:hover .dx-editor-with-menu {
   border-bottom: 1px solid #B7B5E6 !important;
}

.dx-datagrid-headers .dx-datagrid-filter-row td.dx-editor-cell.dx-focused {
   border: 1px solid #706cce !important;
   border-bottom: 1px solid transparent !important;
}

.dx-datagrid-headers .dx-datagrid-filter-row td.dx-editor-cell.dx-focused .dx-editor-with-menu {
   border-bottom: 1px solid #706cce !important;
}

.dx-datagrid-headers .dx-datagrid-filter-row td.dx-editor-cell input:hover {
   border: unset !important;
}
/* ADD */

dx-html-editor {
   border-radius: 0 !important;
}

.dx-htmleditor .dx-htmleditor-toolbar-wrapper {
   padding: 0 !important;
}

dx-check-box .dx-checkbox-text{
   padding-left: 10px;
   padding-right: 20px;
   font-size: 16px;
}

.dx-datagrid-column-chooser .dx-popup-content .dx-scrollable-content {
   min-height: 100% !important;
}

.dx-datagrid-column-chooser .dx-popup-content .dx-scrollable-content .dx-empty-message{
   padding: 0 !important;
}
.button-loader-indicator-white .dx-loadindicator-icon .dx-loadindicator-segment {
   background: #ffffff !important;
}


.dx-scrollable-scroll-content, 
.dx-scrollbar-hoverable.dx-scrollable-scrollbar-active .dx-scrollable-scroll-content {
    background-color: #ADADAD !important;
}

dx-tab-panel .dx-tabpanel-tabs span {
	font-size: 18px!important;
	color: #767676!important;
	padding-right: 0px!important;
	padding-left: 0px!important;
}

dx-tab-panel .dx-tabpanel-tabs .dx-tab-selected span {
	color: #706cce !important;
	text-shadow: 0px 0px 1px #706cce;
	font-family: 'proxima_nova';
}

dx-tab-panel .dx-tabpanel-tabs .dx-tab-selected:after {
   content: '';
   background: #706cce !important;
   height: 4px !important;
   position: absolute !important;
   top: 41px !important;
   border: none;
}

dx-tab-panel .dx-tabpanel-tabs .dx-tabs-wrapper, 
dx-tab-panel .dx-tabpanel-tab {
    background-color: transparent !important;
    min-height: 45px;
}

.dx-tabpanel-tabs .dx-tabs:not(.dx-tabs-vertical) .dx-tabpanel-tab {
	max-width: -moz-fit-content!important;
	max-width: fit-content!important;
	margin-left: 5px;
	padding-right: 3px;
	margin-right: 20px;
	padding-left: 3px;
}
.dx-treeview-node-container .dx-treeview-expander-icon-stub{
   display: none !important;
}

.dx-treeview-item-without-checkbox.dx-state-focused>.dx-treeview-item {
   background-color: #706cce !important;
   color: #333 !important;
}

.dx-popup-wrapper .dx-state-focused.dx-overlay-content {
	border: 1px solid #706cce !important;
}

.dx-filterbuilder .dx-filterbuilder-group .dx-filterbuilder-group-item .dx-filterbuilder-text .dx-filterbuilder-item-value-text,
.dx-filterbuilder .dx-filterbuilder-group .dx-filterbuilder-group-item .dx-filterbuilder-text.dx-filterbuilder-group-operation,
.dx-filterbuilder .dx-filterbuilder-group .dx-filterbuilder-group-item .dx-filterbuilder-text.dx-filterbuilder-item-field,
.dx-filterbuilder .dx-filterbuilder-group .dx-filterbuilder-group-item .dx-filterbuilder-text.dx-filterbuilder-item-operation {
	padding-top: 3px !important;
}

.dx-tab .dx-tab-content {
  min-height: 24px;
}

.dx-tab.dx-tab-selected::after, .dx-tab.dx-state-active::after {
    border-block-start: none !important;
    border-inline-end: none !important;
    border-block-end: none !important;
    border-inline-start: none !important;
}

.dx-tabpanel-tabs-position-top .dx-tabpanel-tab.dx-tab-selected {
    border-block-end: 1px solid #ddd !important;
}

dx-tab-panel .dx-tab.dx-tabpanel-tab:hover::after {
    content: '';
    background: #c7c7c7;
    height: 4px !important;
    position: absolute !important;
    top: 41px !important;
    border: none;
}

.dx-datagrid-columns-separator{
	background-color: #706cce !important;
}

.dx-datagrid-columns-separator-transparent{
	background-color:transparent !important;
}

.dx-treeview-item-without-checkbox.dx-state-selected > .dx-treeview-item {
   border-left: 3px solid #706cce !important;
   background: #eaeaf4;
   color: #000 !important;
}

.tabbing ul li.active {
   color: #706cce;
   font-family: 'proxima_nova';
   text-shadow: 0px 0px 1px #706cce;
}

.dx-datagrid-drop-highlight>td {
   background-color: #e2e2f5 !important;
   color: #565656 !important;
}

.dx-icon-download:before {
   content: "\e92f" !important;
   font-family: 'IXPortal' !important;
   font-size: 14px;
}

.dx-icon-upload:before {
   content: "\e9a0" !important;
   font-family: 'IXPortal' !important;
   font-size: 14px;
}

.secondaryBtn .dx-icon-download:before {
   margin-right: 5px;
}
.dx-scrollbar-horizontal .dx-scrollable-scroll {
   padding-bottom: 0px !important;
   padding-left: 0px !important;
   cursor: pointer;
   height: 12px !important;
}

.dx-scrollbar-vertical .dx-scrollable-scroll {
   padding-inline-end: 0px !important;
   padding-top: 0 !important;
   width: 12px !important;
   cursor: pointer;
}

.dx-scrollbar-horizontal.dx-scrollbar-hoverable {
   height: 12px !important;
}

.dx-scrollbar-horizontal.dx-scrollbar-hoverable.dx-scrollable-scrollbar-active,
.dx-scrollbar-horizontal.dx-scrollbar-hoverable.dx-scrollable-scrollbar-active .dx-scrollable-scroll,
.dx-scrollbar-horizontal.dx-scrollbar-hoverable.dx-state-hover,
.dx-scrollbar-horizontal.dx-scrollbar-hoverable.dx-state-hover .dx-scrollable-scroll {
   height: 12px !important;
}

.dx-scrollbar-vertical.dx-scrollbar-hoverable.dx-scrollable-scrollbar-active,
.dx-scrollbar-vertical.dx-scrollbar-hoverable.dx-scrollable-scrollbar-active .dx-scrollable-scroll,
.dx-scrollbar-vertical.dx-scrollbar-hoverable.dx-state-hover,
.dx-scrollbar-vertical.dx-scrollbar-hoverable.dx-state-hover .dx-scrollable-scroll {
   width: 12px !important;
}